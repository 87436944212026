import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core';
import React, { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { InputFiled } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { addPencatatanRetur, setEmptyMessageRetur, setMessagePencatatanRetur } from '../../../config/redux/action';
import { useDispatch, useSelector } from 'react-redux';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormReturBarang = ({HandleClickCloseDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        avaliableEdit:{
            display:'flex',
        }
    }));
    const [openSnackBar,setOpenSnackBar] = useState(false);
    const {fetchMessage,FormAddRetur,FormPencatatanRetur} = useSelector(state=>state.returReducers);
    const {idbarang,namabarang}=FormAddRetur;
    const handleCloseSnackBar = () =>{
        setOpenSnackBar(false);
        setTimeout(()=>{
            setEmptyMessageRetur();
        },2000)
    }
    const dispatch = useDispatch();
    const classes = useStyle();
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required('Jumlah barang harus diisi').min(1,"Jumlah barang tidak boleh kurang dari 1"),
        keterangan:yup.string()
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang||'',
            namabarang:namabarang||'',
            jumlah:0,
            keterangan:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = FormPencatatanRetur.filter(retur =>retur.idbarang === idbarang);
            if(existBarang.length>0){
                dispatch(setMessagePencatatanRetur('Barang telah terdaftar dalam daftar retur',setOpenSnackBar));
            }else{
                const data={
                    'idbarang':values.idbarang,
                    'namabarang':values.namabarang,
                    'jumlah':values.jumlah,
                    'keterangan':values.keterangan
                }
                dispatch(addPencatatanRetur(data,setOpenSnackBar,HandleClickCloseDialog));
            }
        }
    })
    return (
        <div>
            <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-form-retur">Rincian Retur Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                id="namabarang"
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <InputFiled 
                id="keterangan" 
                name="keterangan" 
                label="keterangan"
                multiline
                value={formik.values.keterangan}
                error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
                helperText={formik.touched.keterangan && formik.errors.keterangan}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" 
                onClick={formik.handleSubmit}
                >Simpan</Button>
                <Button 
                onClick={HandleClickCloseDialog}
                 variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={fetchMessage === 'Berhasil menambahan pencatatan retur' ? "success" : "error"}>
                        {fetchMessage}
                </Alert>
            </Snackbar>
        </div>
        </div>
    )
}

export default FormReturBarang
