import { Divider, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPartner, getDetailsPartner, setFillFormPartner, setNewIDPartner, setSearchPartner } from '../../config/redux/action/partnerAction';
import Cookies from 'js-cookie';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Search } from '../../component/molecules';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FormPartner from '../../component/organism/FormPartner';
import FormPartnerDelete from '../../component/organism/FormPartnerDelete';


const PartnerPage = () => {
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            display:"flex",
            justifyContent:"center",
            flexDirection:'column',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        searchWapper:{
            width:'70%'
        },
        tableWapper:{
            display:'flex',
            flexDirection:'column',
            width:'70%',
            maxWidth:'100%',
            marginLeft:'2%',
            alignItems:'center',
        },
        table:{
            marginTop:'5px',
            [theme.breakpoints.up('md')]:{
                width:'100%',
            },
            width:'100%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
                width:'100%'
            },
        },
        page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            width:'100%',
            justifyContent:'flex-end',
        },
        buttonAddButton:{
            display:'flex',
            paddingRight:'5px',
            width:'100%',
            justifyContent:'flex-end',
        },
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const classes = useStyles();
      const {dataPartner,pagePartner,searchPartner} = useSelector(state=>state.partnerReducers);
      const {data} = useSelector(state=>state.loginReducers);
      const [counter,setCounter] = useState(1);
      const [token,setToken]=useState(data.token); 
      const [statusForm,setStatusForm]=useState('');
      const [open,setOpen]=useState(false);
      const [openDelete,setOpenDelete]=useState(false);
      if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const dispatch = useDispatch();
      useEffect(()=>{
            dispatch(getAllPartner(searchPartner,counter,token));
      },[searchPartner,counter,token,dispatch])
      const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pagePartner.totalPage ? pagePartner.totalPage : counter + 1);
    }
    const focus = () =>{
        setCounter(1);
        dispatch(getAllPartner(searchPartner,counter,token));
    }
    const handleSearchPartner = (e) =>{
        dispatch(setSearchPartner(e.target.value))
    }
    const clickNewDialog = () =>{
        setStatusForm('Data Partner Baru');
        dispatch(setNewIDPartner(token));
        setOpen(true);
    }
    const HandleClickCloseDialog = ()=>{
        setOpen(false);
        dispatch(setFillFormPartner());
        setTimeout(()=>{
            dispatch(getAllPartner(searchPartner,counter,token));
        },1000)
    }
    const editDialog = (idpartner)=>{
        setStatusForm('Ubah Data Partner');
        dispatch(getDetailsPartner(idpartner,token));
        setOpen(true);
    }
    const clickCloseDeleteDialog = () =>{
        setOpenDelete(false);
        dispatch(setFillFormPartner());
        setTimeout(()=>{
            dispatch(getAllPartner(searchPartner,counter,token));
        },1000)
    }
    const clickOpenDeleteDialog = (idpartner) =>{
        dispatch(getDetailsPartner(idpartner,token));
        setOpenDelete(true);
    }
  return (
    <div className={classes.root}>
        <Typography className={classes.title} variant="h5">Data Partner</Typography>
        <div style={{width:'100%'}}>   
        <Divider/>
        </div>
            <div className={classes.searchWapper}>
            <Search className={classes.search} label="Cari Partner" name="cari-partner" id="cari-barang" onFocus={focus} onChange={(e)=>handleSearchPartner(e)}/>
            </div>
            <div className={classes.tableWapper}>
                <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table data partner" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='20%' align="center">Nama Perusahaan</SytledTableCell>
                                        <SytledTableCell width='20%' align="center">Alamat</SytledTableCell>
                                        <SytledTableCell width='15%' align="center">No Tlp</SytledTableCell>
                                        <SytledTableCell width='15%' align="center">Nama Sales</SytledTableCell>
                                        <SytledTableCell width='20%' align="center">Tipe Partner</SytledTableCell>
                                        <SytledTableCell width='10%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataPartner.map((dataPartners)=>(
                                        <StyledTableRow key={dataPartners.idpartner}>
                                            <SytledTableCell align="center">{dataPartners.namaperusahaan}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPartners.alamat}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPartners.notlp}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPartners.namasales}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPartners.tipepartner}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                <Tooltip title="Ubah">
                                                    <IconButton size='small' 
                                                    onClick={()=>{editDialog(dataPartners.idpartner)}}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                 </Tooltip>
                                                <Tooltip title="Hapus">
                                                    <IconButton size='small' 
                                                    onClick={()=>clickOpenDeleteDialog(dataPartners.idpartner)}
                                                    >
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </SytledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrev}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                <Typography className={classes.textPage}>{pagePartner.currentPage} / {pagePartner.totalPage >= 1 ? pagePartner.totalPage : pagePartner.currentPage}</Typography>
                                <Tooltip title="Next">
                                {counter === pagePartner.totalPage?
                                    <IconButton size='small' onClick={handleNext}>
                                        <NavigateNext/>
                                    </IconButton>
                                :   <IconButton size='small'>
                                    <NavigateNext/>
                                    </IconButton>
                                }
                                </Tooltip> 
                                </div>
                                <div className={classes.buttonAddButton}>
                                <Tooltip title="Tambah data partner">
                                    <Fab size="small" 
                                    onClick={()=>{clickNewDialog()}} 
                                    color="primary" 
                                    aria-label="add">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                                <FormPartner open={open} statusForm={statusForm} aria-labelledby="new-gudang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                                <FormPartnerDelete open={openDelete} aria-labelledby="delete-partner-dialog" HandleClickCloseDeleteDialog={clickCloseDeleteDialog}/>
                                {/* <JenisBarangForm open={open} aria-labelledby="new-jenisbarang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                                <JenisBarangEditForm open={openEdit} aria-labelledby="ubah-jenisbarang-dialog" HandleClickCloseEditDialog={HandleClickCloseEditDialog}/>
                                <JenisBarangDeleteForm open={openDelete} aria-labelledby="delete-jenisbarang-dialog" HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}/> */}
                        </div>
                         </div>
                         
                    </Paper>
                </div> 
            </div>
    </div>
  )
}

export default PartnerPage