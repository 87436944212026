import { makeStyles, Divider,Typography , 
    createMuiTheme, 
    ThemeProvider  } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const PrintPengeluaran = React.forwardRef ((props,ref) => {
    const theme = createMuiTheme({
        typography:{
            fontFamily:[
                'Raleway',
            ]
        }
    })
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'21.59cm',
            height:'13.97cm',
            marginTop:'30px'
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        title:{
            textAlign:'center',
            paddingTop:'5px',
            paddingBottom:'5px',
            borderBottom:'1px dotted black'
        },
        tableBody:{
            height:'100%',
            textAlign:'center',
        },
        table:{
            marginTop:'3px',
            width:'100%',
        },
        tableContainer:{
            height:'100%',
            width:'100%',
            textAlign:'center',
        },
        tableHead:{
            borderTop:'1px dotted black',
            borderBottom:'1px dotted black'
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            justifyContent:'center',
            width:'100%',
            marginTop:'10px',
        },
        bottomTable:{
            borderBottom:'1px dotted black',
            paddingTop:'2px'
        },
        bottomPage:{
            display:'flex',
            flexDirection:'column',
            marginTop:'15px'
        },
        columnTextSign:{
            display:'flex',
            justifyContent:'space-between',
        },
        columnSign:{
            display:'flex',
            justifyContent:'space-between',
        },
        textSign:{
            width:'200px',
            textAlign:'center'
        },
        sign:{
            height:'10px',
            width:'200px',
            paddingTop:'50px',
            paddingBottom:'10px',
            textAlign:'center'
        }
    }));
    const {data} = useSelector(state => state.loginReducers);
    const {dataPengeluaranDetails,pengeluaranField} = useSelector(state => state.pengeluaranReducers);
    const {nosuratjalan,tanggal,admin,supir}=pengeluaranField;
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
      const classes = useStyle();
    return (
        <ThemeProvider theme={theme}>
        <div className={classes.root} ref={ref}>
                <Typography variant='body1' className={classes.title}><b>Pengeluaran Barang</b></Typography>
                <Divider variant='middle'/>
                <div className={classes.mainWapper}>
                    <Typography variant="body1">No Surat Jalan :{nosuratjalan}</Typography>
                    <Typography variant="body1" style={{paddingLeft:'20px'}}>Tanggal :{tanggal}</Typography>
                    <Typography variant="body1" style={{paddingLeft:'20px'}}>Admin :{admin}</Typography>
                    <Typography variant="body1" style={{paddingLeft:'20px'}}>Penerima :{supir}</Typography>
                </div>
                <div className={classes.content}>
                    <div className={classes.table}>
                        <table aria-label="table barang" size="small" className={classes.tableContainer}>
                            <thead>
                                <tr>
                                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>ID Barang</Typography></th>
                                <th className={classes.tableHead} style={{width:'25%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Varian</Typography></th>
                                </tr>
                            </thead>
                            <tbody>
                            {dataPengeluaranDetails.map((dataPengeluaranDetail=>(
                                <tr className={classes.tableBody} key={dataPengeluaranDetail.idpengeluaran}>
                                    <td align="center"><Typography variant='body1'>{dataPengeluaranDetail.idbarang}</Typography></td>
                                    <td align="center"><Typography variant='body1'>{dataPengeluaranDetail.namabarang}</Typography></td>
                                    <td align="center"><Typography variant='body1'>{dataPengeluaranDetail.jumlah}</Typography></td>
                                    <td align="center"><Typography variant='body1'>{dataPengeluaranDetail.variant}</Typography></td>
                                </tr>
                            )))}
                            </tbody>
                        </table>
                        <div className={classes.bottomTable}>
                        </div>
                    </div>
                </div>
                <div className={classes.bottomPage}>
                    <div className={classes.columnTextSign}>
                        <div className={classes.textSign}>
                            Admin
                        </div>
                        <div className={classes.textSign}>
                            Admin Gudang
                        </div>
                        <div className={classes.textSign}>
                            Penerima
                        </div>
                    </div>
                    <div className={classes.columnSign}>
                        <div className={classes.sign}>
                            -----------------
                        </div>
                        <div className={classes.sign}>
                            -----------------
                        </div>
                        <div className={classes.sign}>
                            -----------------
                        </div>
                    </div>
                </div>
            </div>
            </ThemeProvider>
    )
})

export default PrintPengeluaran


