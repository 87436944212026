import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPenyimpanan } from '../../../config/redux/action';
import Cookies from 'js-cookie';

const PenyimpananCombo = ({name,id,label,error,helperText,valueOption,...rest}) => {
  const {dataPenyimpanan} = useSelector(state => state.penyimpananReducers);
  const {data} = useSelector(state=>state.loginReducers);
  const dispatch = useDispatch();
  const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
      dispatch(getAllPenyimpanan('',1,token));
    },[token,dispatch])
    const useStyle = makeStyles({
        formControl:{
            width:'100%',
            paddingBottom:'7px',
        },
    });
    const classes = useStyle();
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}
              error={error}
              >
              <InputLabel htmlFor='penyimpananSelect'>Penyimpanan</InputLabel>
              <Select
              native
              {...rest}
              id={id}
              name={name}
              label="Penyimpanan"
              inputProps={{
                name: 'idpenyimpanan',
                id: 'idpenyimpanan',
              }}
              >
              <option aria-label="None" value="" />
              {dataPenyimpanan.map((dataPenyimpanans=>(
                  <option key={dataPenyimpanans.idpenyimpanan}  value={dataPenyimpanans.idpenyimpanan}>{dataPenyimpanans.keterangan}</option>
              )))}
              </Select>
              <FormHelperText style={{color:'red'}}>{helperText}</FormHelperText>
              </FormControl>
      </div>
    )
}

export default PenyimpananCombo
