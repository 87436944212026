import React, { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { postHutang, postHutangWithPenyesuaian, setMessageHutang } from '../../../config/redux/action/hutangAction';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { InputFiled } from '../../molecules';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const PembayaranHutang = ({HandleClickCloseDialog,handleClickBayar,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        checkBoxWapper:{
          width:'100%',
          display:'flex',
          justifyContent:'center'
        }
    }));
    const {data} = useSelector(state=>state.loginReducers);
    const {dataPayment} = useSelector(state=>state.hutangReducers);
    const {hutangMessage,isLoadingHutang} = useSelector(state=>state.hutangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    const [username,setUsername]=useState(data.username);
    const [penyesuaianCheck,setPenyesuaianCheck]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    if(username===''){
      setUsername(Cookies.get('username'));
    }
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        HandleClickCloseDialog();
        setTimeout(() => {
            dispatch(setMessageHutang(""));
        }, 100);
      };
      const classes = useStyle();
      const validationSchema = yup.object().shape({
        pembayaran:yup.number().required("Nilai pembayaran tidak boleh kosong").min(0,"Jumlah minimum tidak boleh kurang dari 0"),
    })
      const formik = useFormik({
        initialValues:{
            idtransaksi:dataPayment.idtransaksi||0,
            totalhutang:dataPayment.totalhutang-dataPayment.pembayaran||0,
            pembayaran:0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            var totalHutang = dataPayment.totalhutang-dataPayment.pembayaran;
            if(parseInt(totalHutang)<values.pembayaran){
                dispatch(setMessageHutang("Pembayaran melebihi total hutang"));
                setOpenSnackbar(true);
            }else if(parseInt(totalHutang)>=values.pembayaran){
              var sisaPembayaran = parseInt(totalHutang) - values.pembayaran;
              if(sisaPembayaran<=parseInt('100000')){
                if(penyesuaianCheck===true){
                  const dataPembelian = {
                    'idpartner' : dataPayment.idpartner,
                    'idtransaksi' : dataPayment.idtransaksi,
                    'tanggal' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'jenishutang' : dataPayment.jenishutang,
                    'keterangan' :"Pembayaran hutang dilakukan oleh "+username,
                    'jatuhtempo' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'totalhutang' : 0,
                    'pembayaran' : values.pembayaran,
                    'notaasli' : dataPayment.notaasli,
                  }
                  const dataPenyesuian = {
                    'idpartner' : dataPayment.idpartner,
                    'idtransaksi' : dataPayment.idtransaksi,
                    'tanggal' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'jenishutang' : dataPayment.jenishutang,
                    'keterangan' :"Penyesuaian pembayaran hutang dilakukan oleh "+username,
                    'jatuhtempo' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'totalhutang' : 0,
                    'pembayaran' : totalHutang-values.pembayaran,
                    'notaasli' : dataPayment.notaasli,
                  }
                  dispatch(postHutangWithPenyesuaian(dataPembelian,dataPenyesuian,token,setOpenSnackbar,formik,handleClickBayar));
                }
                else if(penyesuaianCheck===false){
                  const data = {
                    'idpartner' : dataPayment.idpartner,
                    'idtransaksi' : dataPayment.idtransaksi,
                    'tanggal' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'jenishutang' : dataPayment.jenishutang,
                    'keterangan' :"Pembayaran hutang dilakukan oleh "+username,
                    'jatuhtempo' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                    'totalhutang' : 0,
                    'pembayaran' : values.pembayaran,
                    'notaasli' : dataPayment.notaasli,
                  }
                  dispatch(postHutang(data,token,setOpenSnackbar,formik,handleClickBayar));
                }
              }else if(sisaPembayaran>parseInt('100000')){
                const data = {
                  'idpartner' : dataPayment.idpartner,
                  'idtransaksi' : dataPayment.idtransaksi,
                  'tanggal' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                  'jenishutang' : dataPayment.jenishutang,
                  'keterangan' :"Pembayaran hutang dilakukan oleh "+username,
                  'jatuhtempo' : format(new Date(),'yyyy-MM-dd HH:mm:ss'),
                  'totalhutang' : 0,
                  'pembayaran' : values.pembayaran,
                  'notaasli' : dataPayment.notaasli,
                }
                dispatch(postHutang(data,token,setOpenSnackbar,formik,handleClickBayar));
              }
            }
        }
      })
      const handleChangePembayaran = (e) =>{
        formik.setFieldValue('pembayaran',e.target.value)
      }
      const handleChangeCheck = () =>{
        setPenyesuaianCheck(!penyesuaianCheck);
      }
      const checkBox =(
        <FormControlLabel
            className={classes.checkBoxWapper}
            control={<Checkbox checked={penyesuaianCheck} onChange={handleChangeCheck} name="penyesuaian" />}
            label="Penyesuaian Pelunasan Faktur"
          />
      )
      const handleClickClose = () =>{
        formik.handleReset();
        setPenyesuaianCheck(false);
        HandleClickCloseDialog();
      }
  return (
    <div><Dialog open={false} {...rest}>
        <DialogTitle className={classes.titleDialog} id="new-barang-dialog">Pembayaran Hutang</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idtransaksi" 
                name="idtransaksi" 
                label="ID Transaksi" 
                disabled={true} 
                value={dataPayment.idtransaksi}/>
                <InputFiled 
                id="totalhutang" 
                name="totalhutang" 
                label="Total Hutang"
                disabled={true} 
                value={dataPayment.totalhutang-dataPayment.pembayaran}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <InputFiled 
                id="pembayaran" 
                name="pembayaran" 
                label="Pembayaran"
                disabled={dataPayment.totalhutang-dataPayment.pembayaran <=0 ? true : false }
                value={formik.values.pembayaran}
                error={formik.touched.pembayaran && Boolean(formik.errors.pembayaran)}
                helperText={formik.touched.pembayaran && formik.errors.pembayaran}
                onChange={(e)=>handleChangePembayaran(e)}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                {parseInt(dataPayment.totalhutang)===0 ? 
                <Typography variant='h6' align='center' color='primary'>Hutang Telah Lunas</Typography> : 
                parseInt(dataPayment.totalhutang-dataPayment.pembayaran) - formik.values.pembayaran<parseInt('100000') && 
                parseInt(dataPayment.totalhutang-dataPayment.pembayaran) - formik.values.pembayaran!==0 ? 
                checkBox : null}
                <div className={classes.button}>
                <Button variant="contained" color="primary" 
                onClick={formik.handleSubmit} 
                disabled={parseInt(dataPayment.totalhutang-dataPayment.pembayaran)===0 ? 
                  true : isLoadingHutang}
                >BAYAR</Button>
                <Button 
                onClick={handleClickClose} 
                variant="contained" color="secondary"  
                disabled={isLoadingHutang}
                >Keluar</Button>
                </div>
                </DialogContent>
        </Dialog>
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                { hutangMessage === 'Pembayaran hutang berhasil dicatat'||"Pembayaran dan Penyesuaian pembayaran berhasil dicatat"?
                    <Alert onClose={handleCloseSnackBar} severity="success">{hutangMessage}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error">{hutangMessage}</Alert>
                }
            </Snackbar>
    </div>
  )
}

export default PembayaranHutang