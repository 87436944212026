import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, {useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {  deleteOrderBarang, setEmptyMessageOrder} from '../../../config/redux/action';
import MuiAlert from '@material-ui/lab/Alert';


const OrderDeleteForm = ({HandleClickCloseDeleteDialog,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingOrder,messageOrder,dataOrder} = useSelector(state=>state.orderReducers);
    const {idbarang,namabarang,qtyorder,tglorder} = dataOrder;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'90%',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setTimeout(() => {
            setOpenSnackbar(false);
            dispatch(setEmptyMessageOrder(""));
        }, 100);
      };
    const onSubmit=()=>{
        const data = {
            'idbarang':idbarang,
            'tanggal':null,
            'qty':0,
            'qtyfulfilled':0
        }
        dispatch(deleteOrderBarang(data,token,setOpenSnackbar,HandleClickCloseDeleteDialog));
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-barang-dialog">Hapus Data Order</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data order tanggal {tglorder} untuk barang ({idbarang}){namabarang} dengan jumlah order {qtyorder}?</Typography>
                    <Typography align='center'></Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingOrder}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog}  disabled={isLoadingOrder}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {messageOrder==='Order berhasil dibatalkan'?
                    <Alert onClose={handleCloseSnackBar} severity="success" onClick={HandleClickCloseDeleteDialog}>{messageOrder}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error" onClick={HandleClickCloseDeleteDialog}>{messageOrder}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default OrderDeleteForm
