import { Divider ,Dialog, DialogTitle,Button,Typography,makeStyles,DialogContent,Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGudang, setEmptyGudangMessage, setFillFormGudang } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormGudangDelete = ({HandleClickCloseDeleteDialog,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingGudang,gudangMessage,FormGudang} = useSelector(state=>state.gudangReducers);
    const {idgudang,namagudang} = FormGudang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    useEffect(() => {
        if(gudangMessage !== ''){
            setOpenSnackbar(true);
        }
    }, [gudangMessage])
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setFillFormGudang());
            dispatch(setEmptyGudangMessage());
        },500)
      };
    const onSubmit=()=>{
        dispatch(deleteGudang(idgudang,token));
        dispatch(setFillFormGudang());
        HandleClickCloseDeleteDialog();
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-gudang-dialog">Hapus Data Gudang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data gudang</Typography>
                    <Typography align='center'>({namagudang})?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingGudang}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog} disabled={isLoadingGudang}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={gudangMessage === 'Data gudang berhasil dihapus' ?"success" :"error"} onClick={HandleClickCloseDeleteDialog}>{gudangMessage}</Alert>
            </Snackbar>
        </div>
    )
}

export default FormGudangDelete
