import axios from 'axios';
import {apiUrl} from '../..';

export const setFormPengeluaran =  (idstokgudang,idbarang,namabarang,sisastok,variant) =>(dispatch)=>{
    const data = {
        'idstokgudang':idstokgudang,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'sisastok':sisastok,
        'variant':variant
    }
    dispatch({
        type: 'SET FROM PENGELUARAN',
        payload: data
    })
}
export const addListPengeluaran =  (id,idstokgudang,idbarang,namabarang,jumlah,variant) =>(dispatch)=>{
    const data = {
        'id':id,
        'idstokgudang':idstokgudang,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'variant':variant
    }
    dispatch({
        type: 'ADD LIST PENGELUARAN GUDANG',
        payload: data,
        message:'Berhasil menambahkan data ke daftar'
    })
}
export const setMessagePengeluaranGudang = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE PENGELUARAN GUDANG',
        payload:message
    })
}
export const setEmptyMessagePengeluaranGudang = () =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE PENGELUARAN GUDANG',
        payload:""
    })
}
export const setTempIdPengeluaran = (id) =>(dispatch)=>{
    dispatch({
        type:'SET TEMP ID PENGELUARAN GUDANG',
        payload:id
    })
}
export const getAllPengeluaranList = () =>(dispatch)=>{
    dispatch({
        type:'GET ALL LIST PENGELUARAN'
    })
}
export const emptyPengeluaranList = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY PENGELUARAN FORM'
    })
}
export const postPengeluaranToAPI = (data,token,setOpenPrint,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'SET LOADING PENGELUARAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/newpengeluaran`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS POST PENGELUARAN GUDANG',
            payload:ResponseAPI.message,
        })
        setOpenPrint(true);
        setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED POST PENGELUARAN GUDANG',
                    payload:ResponseError.message
                })
            setOpenSnackbar(true);
        }else if(error.request){
            dispatch({
                    type:'FAILED POST PENGELUARAN GUDANG',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const getNoSuratJalan = (token)=>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/getid`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseApi = res.data;
        dispatch({
            type:'SET NO SURAT JALAN',
            payload:ResponseApi.data
        })
    })
    .catch(err=>{
        dispatch({
            type:'FAILED GET NO SURAT JALAN',
            payload:""
        })
    })
}
export const setEmptyNoSuratJalan = ()=>(dispatch)=>{
    dispatch({
        type:'FAILED GET NO SURAT JALAN',
        payload:""
    })
}
