const initialValuesPenyimpanan = {
    dataPenyimpanan:[],
    isLoadingPenyimpanan:false,
    searchPenyimpanan:'',
    pagePenyimpanan : {
        currentPage:'1',
        totalPage:'1'
    },
    messagePenyimpanan:'',
    tempNewIDPenyimpanan:'',
}

const penyimpananReducers = (state=initialValuesPenyimpanan,action)=>{
    switch(action.type){
        case 'FETCH DATA PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:action.payload,
            }
        }
        case 'SET SEARCH TEXT PENYIMPANAN':{
            return{
                ...state,
                searchPenyimpanan : action.payload
            }
        }
        case 'UPDATE PAGE PENYIMPANAN':{
            return {
                ...state,
                pagePenyimpanan:action.payload
            }
        }
        case 'UPDATE DATA PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                dataPenyimpanan:action.payload,
            }
        }
        case 'FETCH NEW ID PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:action.payload
            }
        }
        case 'UPDATE NEW ID PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                tempNewIDPenyimpanan:action.payload
            }
        }
        case 'FAILED GET NEW ID PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET SUCESSED PENYIMPANAN POST':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET FAILED ADD PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'EMPTY MESSAGE PENYIMPANAN':{
            return{
                ...state,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET SUCESSED PENYIMPANAN PATCH':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET FAILED PATCH PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET SUCESSED PENYIMPANAN DELETE':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        case 'SET FAILED DELETE PENYIMPANAN':{
            return{
                ...state,
                isLoadingPenyimpanan:false,
                messagePenyimpanan:action.payload
            }
        }
        default:
            return state
    }
}

export default penyimpananReducers;