import { makeStyles, TextField } from '@material-ui/core'
import React from 'react'

const InputFiled = ({title,id,label,name,disabled,...rest}) => {
    const useStyles = makeStyles({
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    });
    const classes = useStyles();
    return (
        // <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.root}>
            <TextField 
            id={id}
            name={name}
            label={label}
            disabled={disabled}
            className={classes.textFiled} 
            variant="outlined"
            {...rest}/>
        </div>
        // </form>
    )
}

export default InputFiled
