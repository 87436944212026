const initialStatePartner={
    dataPartner:[],
    isLoadingPartner:false,
    messagePartner:'',
    FormPartner:[],
    searchPartner:'',
    newIDPartner:'',
    pagePartner : {
        currentPage:'1',
        totalPage:'1'
    },
}

const partnerReducers = (state=initialStatePartner,action)=>{
    switch(action.type){
        case 'FETCH ACTION PARTNER':{
            return{
                ...state,
                isLoadingPartner:action.payload
            }
        }
        case 'UPDATE DATA PARTNER':{
            return{
                ...state,
                isLoadingPartner:false,
                dataPartner:action.payload
            }
        }
        case 'UPDATE PAGE PARTNER':{
            return {
                ...state,
                pagePartner:action.payload
            }
        }
        case 'FAILED ACTION PARTNER':{
            return{
                ...state,
                isLoadingPartner:false,
                messagePartner:action.message
            }
        }
        case 'SET FORM PARTNER':{
            return{
                ...state,
                FormPartner:action.payload
            }
        }
        case 'SET SUCESSED PARTNER ACTION':{
            return{
                ...state,
                isLoadingPartner:false,
                messagePartner:action.message
            }
        }
        case 'SET FAILED ACTION PARTNER':{
            return{
                ...state,
                isLoadingPartner:false,
                messagePartner:action.message
            }
        }
        case 'SET EDIT PARTNER':{
            return{
                ...state,
                FormPartner:action.payload
            }
        }
        case 'SET EMPTY FORM PARTNER':{
            return{
                ...state,
                FormPartner:[],
            }
        }
        case 'SET EMPTY MESSAGE PARTNER':{
            return{
                ...state,
                messagePartner:''
            }
        }
        case 'SET SEARCH PARTNER':{
            return{
                ...state,
                searchPartner : action.payload
            }
        }
        case 'UPDATE NEW ID PARTNER':{
            return{
                ...state,
                isLoadingPartner:false,
                newIDPartner:action.payload
            }
        }
        default:
        return state
    }
}

export default partnerReducers;