import axios from 'axios';
import { apiUrl } from '../..';

export const setDataGudang = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA GUDANG',
        payload:true
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/gudang/`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'UPDATE DATA GUDANG',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH GUDANG',
                    message:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH GUDANG',
                    message:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED FETCH GUDANG',
                    message:'Disconnect from server'
            })
        }
    })
}
export const setNewIDGudang = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH NEW ID GUDANG',
        payload:true
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/gudang/getid`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI =results.data;
        dispatch({
            type:'UPDATE NEW ID GUDANG',
            payload:ResponseAPI.data,
            message:'Successed get new id'
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:'Disconnect from server'
            })
        }
    })
}
export const getDetailsGudang = (idgudang,token) =>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/gudang/details/${idgudang}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI =results.data;
        dispatch({
            type:'SET FORM GUDANG',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NEW ID GUDANG',
                    gudangMessage:'Disconnect from server'
            })
        }
    })
}
export const setFillFormGudang = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY FORM GUDANG'
    })
}
export const addNewGudang = (data,token)=>(dispatch)=>{
    dispatch({
        type:'FECTH GUDANG POST',
        payload:true
    })

    axios.post(`${apiUrl.url}v1/suryaharapan/gudang/newgudang/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SET SUCESSED GUDANG POST',
            message:ResponseAPI.message
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ADD GUDANG',
                    message:ResponseError.message,
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED ADD GUDANG',
                    message:'Disconnect from server',
            })
        }
    })
}
export const setEmptyGudangMessage = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY MESSAGE'
    })
}
export const editGudang = (data,id,token) =>(dispatch)=>{
    dispatch({
        type:'FECTH GUDANG EDIT',
    })
    axios.patch(`${apiUrl.url}v1/suryaharapan/gudang/${id}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED GUDANG EDIT',
            message:ResponseAPI.message
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED EDIT GUDANG',
                    message:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED EDIT GUDANG',
                    message:'Disconnect from server'
            })
        }
    })
}
export const deleteGudang = (id,token) =>(dispatch)=>{
    dispatch({
        type:'FECTH GUDANG DELETE',
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/gudang/${id}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED GUDANG DELETE',
            message:ResponseAPI.message
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED DELETE GUDANG',
                    message:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED DELETE GUDANG',
                    message:'Disconnect from server'
            })
        }
    })
}
