import React, { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { deleteHutangByTransaksi, setMessageHutang } from '../../../config/redux/action/hutangAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DeleteHutang = ({HandleClickCloseDeleteDialog,tempID,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes=useStyle();
    const {data} = useSelector(state=>state.loginReducers);
    const {hutangMessage,isLoadingHutang} = useSelector(state=>state.hutangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setMessageHutang(""));
        },1000)
      };
    const onSubmit=()=>{
        dispatch(deleteHutangByTransaksi(token,tempID,setOpenSnackbar,HandleClickCloseDeleteDialog));
    }
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog}>Hapus Data Hutang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data hutang</Typography>
                    <Typography align='center'>{tempID}?</Typography>
                    </div>
                <div className={classes.button}>
                <Button 
                id='hapus'
                name='hapus'
                variant="contained" 
                color="primary" onClick={onSubmit} 
                disabled={isLoadingHutang}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                onClose={handleCloseSnackBar} 
                severity={hutangMessage === 'Penghapusan hutang berhasil' ? "success" : "error"} 
                onClick={HandleClickCloseDeleteDialog}>{hutangMessage}</Alert>
            </Snackbar>
        </div>
    )
}

export default DeleteHutang
