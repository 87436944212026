import DateFnsUtils from '@date-io/date-fns';
import { FormHelperText, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react'

const DateField = ({label,name,id,errors,defaultValue,onChange,helperText,...rest}) => {
  const useStyle = makeStyles((theme)=>({
    root:{
      paddingBottom:'5px',
      width:'150px',
      marginLeft:'5px',
      [theme.breakpoints.up('md')]:{
        width:'150px',
    },
    },
  }));
  const classes = useStyle();
  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker 
          {...rest}
          className={classes.root}
          margin="dense"
          id={id}
          name={name}
          label={label}
          format="dd/MM/yyyy"
          onChange={onChange}
          helperText={helperText}
          error={errors}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <FormHelperText style={{color:'red'}}></FormHelperText>
        
    </MuiPickersUtilsProvider>
  )
}

export default DateField;
