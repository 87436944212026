import { Dialog,withStyles, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,Button, Snackbar} from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
import { deleteDraftPenjualan, emptyMessageDraftPenjualan, getAllListPenjualanFromDraft, loadDataPenjualan, loadDataToPenjualan, setEmptyKeuntungan } from '../../../config/redux/action';
import { format } from 'date-fns';
import { SingleDialog } from '../../molecules';
import { OKIcon } from '../../assets';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const GetDataDraft = ({OpenDraftListDialog,CloseDraftListDialog,formikEdit,aksesfrom,...rest}) => {
  const useStyle = makeStyles(theme=>({
    titleDialog:{
      maxWidth:'500px',
      [theme.breakpoints.down('sm')]:{
          maxWidth:'250px',
      },
      textAlign:'center',
  },
  table:{
    marginTop:'5px',
    marginLeft:'0px',
    width:'100%',
    // maxWidth:'800px',
    [theme.breakpoints.up('md')]:{
        // marginLeft:'5%',
    },
  },
  tableContainer:{
      height:'520px',
      [theme.breakpoints.up('md')]:{
          height:'440px',
      },
      fontSize:'10px'
  },
  buttonClose:{
    display:'flex',
    justifyContent:'center',
    marginTop:'5px'
}
  }))
  const SytledTableCell = withStyles((theme)=>({
    head: {
        backgroundColor: 'grey',
        color:theme.palette.common.black,
      },
      body: {
        fontSize: 14,
        // border:'1px solid black'
      },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  const classes = useStyle();
  const {loadDraftPenjualan,messageDraftPenjualan} = useSelector(state=>state.draftPenjualanReducers);
  const {loadFromDraft}=useSelector(state=>state.penjualanReducers);
  const {data} = useSelector(state => state.loginReducers);
  const [token,setToken] = useState(data.token);
  const [openSnackbar,setOpenSnackbar] = useState(false);
  const [openDelete,setOpenDelete]=useState(false);
  const [tempId,setTempId]=useState("");
  if(token ===''){
      setToken(Cookies.get('u_tkn'));
  }
  const dispatch = useDispatch();
  const handleCloseSnackBar = () => {
    setOpenSnackbar(false);
    setTimeout(()=>{
        dispatch(emptyMessageDraftPenjualan(""));
        // history.push('/historypembelian');
    },200)
};
const deleteDaftarDraftPenjualan = () =>{
   dispatch(deleteDraftPenjualan(tempId,token,setOpenSnackbar,setTempId,onCloseDialog));
   setTimeout(()=>{
    dispatch(getAllListPenjualanFromDraft(token,setOpenSnackbar));
   },2000)

}
const onCloseDialog = () =>{
  setOpenDelete(false);
}
const openDeletePenjualanDetails = (idpenjualan)=>{
  setOpenDelete(true);
  setTempId(idpenjualan);
}
const openLoadPenjualanDetails = (idpenjualan)=>{
  dispatch(loadDataPenjualan(idpenjualan,token,setOpenSnackbar,formikEdit));
  CloseDraftListDialog();
}
const openLoadToPenjualanDetails = (idpenjualan)=>{
  dispatch(setEmptyKeuntungan());
  dispatch(loadDataToPenjualan(idpenjualan,token,setOpenSnackbar,formikEdit));
  CloseDraftListDialog();
}
const tableForDraft = (
  <TableBody>
      {loadDraftPenjualan.map((listDraft=>(
          <StyledTableRow key={listDraft.idpenjualan}>
              <SytledTableCell align="center">{listDraft.idpenjualan}</SytledTableCell>
              <SytledTableCell align="center">{listDraft.pelanggan}</SytledTableCell>
              <SytledTableCell align="center">{format(new Date(listDraft.tanggal),'dd/MM/yyyy')}</SytledTableCell>
              <SytledTableCell align="center">{new Intl.NumberFormat().format(listDraft.total)}</SytledTableCell>
              <SytledTableCell align="center">
                <Tooltip title="Tambah ke daftar">
                      <IconButton size='small' 
                      onClick={()=>openLoadPenjualanDetails(listDraft.idpenjualan)}
                      >
                        <img src={OKIcon} alt="OKIcon"/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Hapus dari list">
                      <IconButton size='small' 
                      onClick={()=>openDeletePenjualanDetails(listDraft.idpenjualan)}
                      >
                          <CloseIcon style={{color:'red'}}/>
                      </IconButton>
                  </Tooltip>
              </SytledTableCell>
          </StyledTableRow>
      )))}
  </TableBody>
)
const tableForPenjualan = (
  <TableBody>
      {loadFromDraft.map((listDraft=>(
          <StyledTableRow key={listDraft.idpenjualan}>
              <SytledTableCell align="center">{listDraft.idpenjualan}</SytledTableCell>
              <SytledTableCell align="center">{listDraft.pelanggan}</SytledTableCell>
              <SytledTableCell align="center">{format(new Date(listDraft.tanggal),'dd/MM/yyyy')}</SytledTableCell>
              <SytledTableCell align="center">{new Intl.NumberFormat().format(listDraft.total)}</SytledTableCell>
              <SytledTableCell align="center">
                <Tooltip title="Tambah ke daftar">
                      <IconButton size='small' 
                      onClick={()=>openLoadToPenjualanDetails(listDraft.idpenjualan)}
                      >
                        <img src={OKIcon} alt="OKIcon"/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Hapus dari list">
                      <IconButton size='small' 
                      onClick={()=>openDeletePenjualanDetails(listDraft.idpenjualan)}
                      >
                          <CloseIcon style={{color:'red'}}/>
                      </IconButton>
                  </Tooltip>
              </SytledTableCell>
          </StyledTableRow>
      )))}
  </TableBody>
)
  const tableDraftList = (
    <div className={classes.table}>
            <Paper style={{width:'550px'}}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang">
                        <TableHead>
                            <TableRow>
                            <SytledTableCell width='20%' align="center">No Faktur</SytledTableCell>
                                    <SytledTableCell width='15%' align="center">Pelanggan</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Tanggal</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Total</SytledTableCell>
                                    <SytledTableCell width='20%' align="center"/>
                            </TableRow>
                        </TableHead>
                        {aksesfrom==="Draft" ? tableForDraft : tableForPenjualan}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
  )
  return (
    <div>
      <Dialog open={OpenDraftListDialog} {...rest}>
      <DialogTitle className={classes.titleDialog} id="add-barang-cart">Daftar Draft Penjualan</DialogTitle>
      <Divider/>
      <DialogContent>
      {tableDraftList}
      <div className={classes.buttonClose}>
        <Button variant='contained' color="secondary" onClick={CloseDraftListDialog}>Keluar</Button>
      </div>
      </DialogContent>
      </Dialog>
      <SingleDialog openDialog={openDelete} handleClick={deleteDaftarDraftPenjualan} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageDraftPenjualan === "Data penjualan berhasil dihapus" ? "success" : "error"}
                >
                    {aksesfrom === "Draft" ? messageDraftPenjualan : messageDraftPenjualan}
                    </Alert>
            </Snackbar>
    </div>
  )
}

export default GetDataDraft