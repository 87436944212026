const initialValuesPenjualanReducers={
    messagePenjualan:'',
    listPenjualan:[],
    nofaktur:'',
    loadingPenjualan:false,
    penjualanToPrint:'',
    detailsToPrint:[],
    loadFromDraft:[],
    dataDraftPenjualan:{
        "idpenjualan": "",
        "pelanggan": "",
        "tanggal": new Date(),
        "pembayaran": "Tunai",
        "jatuhtempo": new Date(),
        "lamatempo":0,
        "diskon": 0,
        "total": 0,
        "ongkir": 0,
        "status": "",
    },
    statusDraftPenjualan:"Penjualan"
}

const penjualanReducers = (state=initialValuesPenjualanReducers,action) =>{
    switch(action.type){
        case 'SET MESSAGE PENJUALAN':{
            return{
                ...state,
                messagePenjualan:action.payload
            }
        }
        case 'ADD LIST PENJUALAN':{
            return{
                ...state,
                listPenjualan:[...state.listPenjualan,action.payload],
                messagePenjualan:action.messagePayload
            }
        }
        case 'FETCH GET ID PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:true
            }
        }
        case 'SUCCESSED GET NO FAKTUR':{
            return{
                ...state,
                nofaktur:action.payload,
                loadingPenjualan:false
            }
        }
        case 'FAILED GET NO FAKTUR':{
            return{
                ...state,
                messagePenjualan:action.payload,
                loadingPenjualan:false
            }
        }
        case 'GET DATA PENJUALAN LIST':{
            return{
                ...state,
                listPenjualan:[...state.listPenjualan]
            }
        }
        case 'SUCCESSED SAVE PENJUALAN':{
            return{
                ...state,
                messagePenjualan:action.payload,
                loadingPenjualan:false
            }
        }
        case 'FAILED SAVE PENJUALAN':{
            return{
                ...state,
                messagePenjualan:action.payload,
                loadingPenjualan:false
            }
        }
        case 'EMPTY MESSAGE PENJUALAN':{
            return{
                ...state,
                messagePenjualan:''
            }
        }
        case 'RESET PENJUALAN':{
            return{
                ...state,
                listPenjualan:[],
                nofaktur:''
            }
        }
        case 'GET DATA PENJUALAN TO PRINT':{
            return{
                ...state,
                penjualanToPrint:action.payload,
                detailsToPrint:action.payloadDetails
            }
        }
        case 'FAILED GET DATA TO PRINT':{
            return{
                ...state,
                messagePenjualan:action.payload
            }
        }
        case 'EMPTY DATA PENJUALAN TO PRINT':{
            return{
                ...state,
                penjualanToPrint:[],
                detailsToPrint:[]
            }
        }
        case 'FETCH UPDATE STOK DISKON':{
            return{
                ...state,
                loadingPenjualan:true
            }
        }
        case 'SUCCESSED UPDATE STOK DISKON':{
            return{
                ...state,
                loadingPenjualan:false,
                messagePenjualan:action.payload
            }
        }
        case 'FECTH DATA DRAFT':{
            return{
                ...state,
                loadingPenjualan:true
            }
        }
        case 'GET DATA FROM DRAFT TO PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:false,
                loadFromDraft:action.payload
            }
        }
        case 'FAILED GET DATA DRAFT TO PENJUALAN':{
            return{
                ...state,
                messagePenjualan:action.payload,
                loadingPenjualan:false
            }
        }
        case 'EMPTY PENJUALAN FROM DRAFT':{
            return{
                ...state,
                listPenjualan:[]
            }
        }
        case 'SUCCESSED GET NO FAKTUR DRAFT TO PENJUALAN':{
            return{
                ...state,
                nofaktur:action.payload,
                loadingPenjualan:false
            }
        }
        case 'GET DRAFT TO PENJUALAN FROM API':{
            return{
                ...state,
                dataDraftPenjualan:action.payload
            }
        }
        case 'ADD DRAFT TO PENJUALAN LIST FROM API':{
            return{
                ...state,
                listPenjualan:[...state.listPenjualan,action.payload],
                loadingPenjualan:false
            }
        }
        case 'EDIT STATUS DRAFT TO PENJUALAN':{
            return{
                ...state,
                statusDraftPenjualan:action.payload
            }
        }
        case 'FAILED ACTION DRAFT TO PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:false,
                messagePenjualan:action.payload
            }
        }
        case 'RESET PENJUALAN FROM DRAFT':{
            return{
                ...state,
                listPenjualan:[],
                nofaktur:'',
                dataDraftPenjualan:{
                    "idpenjualan": "",
                    "pelanggan": "",
                    "tanggal": new Date(),
                    "pembayaran": "Tunai",
                    "jatuhtempo": new Date(),
                    "lamatempo":0,
                    "diskon": 0,
                    "total": 0,
                    "ongkir": 0,
                    "status": "",
                },
            }
        }
        case 'FETCH ACTION PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:true,
                messagePenjualan:action.payload
            }
        }
        case 'SUCESSED ACTION PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:false,
                messagePenjualan:action.payload
            }
        }
        case 'FAILED ACTION PENJUALAN':{
            return{
                ...state,
                loadingPenjualan:false,
                messagePenjualan:action.payload
            }
        }
        default:
            return state
    }
}
export default penjualanReducers;