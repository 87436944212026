import { Divider, Fab, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { Search } from '../../component/molecules';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setDataBarang, setEditBarang, setFetchStatus, setSearch,setNewIDBarang } from '../../config/redux/action';
import { BarangDeleteForm, BarangEditForm, BarangForm } from '../../component/organism';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const Barang = () => {
    const {dataBarang,pageBarang,search} = useSelector(state => state.barangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const [open,setOpen] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [openDelete,setOpenDelete] = useState(false);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataBarang(search,counter,token));
    },[search,counter,token,dispatch])
    const focus = () =>{
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageBarang.totalPage ? pageBarang.totalPage : counter + 1);
    }
    const HandleClickCloseDialog = () =>{
        setOpen(false);
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
    }
    const HandleClickOpenDialog = () =>{
        setOpen(true);
        dispatch(setNewIDBarang(token));
        dispatch(setSearch(''));
    }
    const HandleClickCloseEditDialog = () =>{
        setOpenEdit(false);
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
        dispatch(setEditBarang("","","","",""));
    }
    const HandleClickOpenEditDialog = (idbarang,namabarang,idjenisbarang,satuan,varian,idpenyimpanan,jmlh_min) =>{
        setOpenEdit(true);
        dispatch(setEditBarang(idbarang,namabarang,idjenisbarang,satuan,varian,idpenyimpanan,jmlh_min));
        dispatch(setSearch(''));
        dispatch(setFetchStatus(''));
    }
    const HandleClickCloseDeleteDialog = () =>{
        setOpenDelete(false);
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
        dispatch(setEditBarang("",""));
    }
    const HandleClickOpenDeleteDialog = (idbarang,namabarang) =>{
        setOpenDelete(true);
        dispatch(setEditBarang(idbarang,namabarang));
        dispatch(setSearch(''));
        dispatch(setFetchStatus(''));
    }
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginLeft:'5%',
            marginTop:'20px',
            maxWidth:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },addIcon:{
            paddingRight:'10px'
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h5">Data Barang</Typography>
                <Divider/>
                <Search label="Cari Barang" name="cari-barang" id="cari-barang" onFocus={focus} onChange={(e)=>dispatch(setSearch(e.target.value))}/>
                <div className={classes.table}>
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                    <SytledTableCell width='25%' align="center">Nama Barang</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Jenis Barang</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                    <SytledTableCell width='10%' align="center"></SytledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataBarang.map((dataBarangs=>(
                                    <StyledTableRow key={dataBarangs.idbarang}>
                                        <SytledTableCell align="center">{dataBarangs.idbarang}</SytledTableCell>
                                        <SytledTableCell align="center">{dataBarangs.namabarang}</SytledTableCell>
                                        <SytledTableCell align="center" id={dataBarangs.idjenisbarang}>{dataBarangs.jenisbarang}</SytledTableCell>
                                        <SytledTableCell align="center">{dataBarangs.satuan}</SytledTableCell>
                                        <SytledTableCell align="center">{dataBarangs.varian}</SytledTableCell>
                                        <SytledTableCell align="center">
                                                <Tooltip title="Ubah">
                                                    <IconButton size='small' onClick={()=>HandleClickOpenEditDialog(dataBarangs.idbarang,dataBarangs.namabarang,dataBarangs.idjenisbarang,dataBarangs.satuan,dataBarangs.varian,dataBarangs.idpenyimpanan,dataBarangs.jmlh_min)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                 </Tooltip>
                                                <Tooltip title="Hapus">
                                                    <IconButton size='small' onClick={()=>HandleClickOpenDeleteDialog(dataBarangs.idbarang,dataBarangs.namabarang)}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </SytledTableCell>
                                    </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.tableBottom}>
                            <div className={classes.page}>
                            <Tooltip title="Prev">
                                <IconButton size='small' onClick={handlePrev}>
                                    <NavigateBefore/>
                                </IconButton>
                            </Tooltip>
                            <Typography className={classes.textPage}>{pageBarang.currentPage} / {pageBarang.totalPage >= 1 ? pageBarang.totalPage : pageBarang.currentPage}</Typography>
                            <Tooltip title="Next">
                                <IconButton size='small' onClick={handleNext}>
                                    <NavigateNext/>
                                </IconButton>
                            </Tooltip> 
                            </div>
                            <div className={classes.addIcon}>
                                <Tooltip title="Tambah jenis barang">
                                    <Fab size="small" color="primary" aria-label="add" onClick={()=>{HandleClickOpenDialog()}}>
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                            </div>
                        </div>
                </Paper>
                <BarangForm open={open} aria-labelledby="new-barang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                <BarangEditForm open={openEdit} aria-labelledby="edit-barang-dialog" HandleClickCloseEditDialog={HandleClickCloseEditDialog}/>
                <BarangDeleteForm open={openDelete} aria-labelledby="delete-barang-dialog" HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}/>
            </div>
        </div>
    )
}

export default Barang