import React, { useState } from 'react'
import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles, Divider, Tooltip, Fab } from '@material-ui/core';
import { DateField, GudangCombo } from '../../component/molecules';
import SearchIcon from '@material-ui/icons/Search';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDataPengeluaran, getDataPengeluaranDetails, setDataStokGudang, setFieldPengeluaran } from '../../config/redux/action';
import { IconButton } from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Details';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import DeleteForever from '@material-ui/icons/DeleteForever';
// import { DeletePengeluaran } from '../../component/organism';

const DataPengeluaranGudang = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'95%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                width:'50%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'430px',
            },
        },
        textPage:{
            padding:'10px',
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'10px',
            width:'95%',
            justifyContent:'left',
            [theme.breakpoints.up('md')]:{
                width:'50%',
                marginLeft:'335px',
            },
        },
        buttonCari:{
           height:'30px',
           marginLeft:'10px',
           marginTop:'22px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.loginReducers);
    const {DataPengeluaran} = useSelector(state=>state.pengeluaranReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const history = useHistory();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const validationSchema = yup.object().shape({
        tanggal:yup.date().max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
        idgudang:yup.string().required("Gudang belum dipilih")
    })
    const formik = useFormik({
        initialValues:{
            tanggal:new Date(),
            idgudang:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('tanggal',format(values.tanggal,'yyyy-MM-dd'));
            data.append('idgudang',values.idgudang);
            dispatch(getDataPengeluaran(data,token));
        }
    })
    const handleOpenDetails = (nosuratjalan,tanggal,admin,supir) =>{
        dispatch(getDataPengeluaranDetails(nosuratjalan,token));
        dispatch(setDataStokGudang("",1,token));
        let tempTanggal = format(parseISO(tanggal),'dd-MM-yyyy');

        dispatch(setFieldPengeluaran(nosuratjalan,tempTanggal,admin,supir));
        history.push('/datapengeluarangudanglist');
    }
    const handleClickPrint = (nosuratjalan,tanggal,admin,supir) =>{
        dispatch(getDataPengeluaranDetails(nosuratjalan,token));
        let tempTanggal = format(parseISO(tanggal),'dd-MM-yyyy');

        dispatch(setFieldPengeluaran(nosuratjalan,tempTanggal,admin,supir));
        history.push('/printPengeluaran')
    }
    // const [openDeletePengeluaran,setOpenDeletePengeluaran]=useState(false);
    // const [tempSuratJalan,setTempSuratJalan]=useState('');
    // const handleOpenDeletePengeluaran =(noSuratJalan)=>{
    //     setOpenDeletePengeluaran(true);
    //     setTempSuratJalan(noSuratJalan);
    // }
    // const handleCloseDeletePengeluaran = () =>{
    //     // setOpenDeletePengeluaran(false);
    //     setTimeout(()=>{
    //         const data = new URLSearchParams();
    //         data.append('tanggal',format(formik.values.tanggal,'yyyy-MM-dd'));
    //         data.append('idgudang',formik.values.idgudang);
    //         dispatch(getDataPengeluaran(data,token));
    //     },1000)
    // }
    return (
        <div className={classes.root}>
            <Typography variant='h5' className={classes.title}>Data Pengeluaran Barang</Typography>
            <Divider/>
        <div className={classes.mainWapper}>
            <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                onChange={(value)=>formik.setFieldValue('tanggal',value)}
            />
            <GudangCombo 
            id="idgudang" 
            name="idgudang"
            error={formik.touched.idgudang && Boolean(formik.errors.idgudang)}
            onChange={formik.handleChange}
            value={formik.values.idgudang}
            helperText={formik.touched.idgudang && formik.errors.idgudang}
            />
            <Button 
            className={classes.buttonCari}
            variant='contained'
            color='primary'
            size='small'
            aria-label="search"
            onClick={formik.handleSubmit}
            >
                <SearchIcon/> Cari
            </Button>
        </div>
        <div className={classes.content}>
            <div className={classes.table}>
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='10%' align="center">No Surat Jalan</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Admin</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Supir</SytledTableCell>
                                    <SytledTableCell width='7%' align="center"></SytledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {DataPengeluaran.map((DataPengeluarans=>(
                                    <StyledTableRow key={DataPengeluarans.nosuratjalan}>
                                        <SytledTableCell align="center">{DataPengeluarans.nosuratjalan}</SytledTableCell>
                                        <SytledTableCell align="center">{DataPengeluarans.admin}</SytledTableCell>
                                        <SytledTableCell align="center">{DataPengeluarans.supir}</SytledTableCell>
                                        <SytledTableCell align="center">
                                            <Tooltip title="Print">
                                                <IconButton size='small' 
                                                onClick={()=>{handleClickPrint(DataPengeluarans.nosuratjalan,DataPengeluarans.tanggal,DataPengeluarans.admin,DataPengeluarans.supir)}}
                                                >
                                                    <PrintIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Rincian keluar barang">
                                                <IconButton size='small' 
                                                onClick={()=>{handleOpenDetails(DataPengeluarans.nosuratjalan,DataPengeluarans.tanggal,DataPengeluarans.admin,DataPengeluarans.supir)}}
                                                >
                                                    <DetailsIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title="Hapus data pengeluaran">
                                                <IconButton size='small' 
                                                onClick={()=>{handleOpenDeletePengeluaran(DataPengeluarans.nosuratjalan)}}
                                                >
                                                    <DeleteForever/>
                                                </IconButton>
                                            </Tooltip> */}
                                        </SytledTableCell>
                                    </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.push('/pengeluarangudang')}>
                <ArrowBackIcon/>
            </Fab>
        {/* <DeletePengeluaran open={openDeletePengeluaran} aria-labelledby="delete-pengeluaran-dialog" HandleClickCloseDeleteDialog={handleCloseDeletePengeluaran} noSuratJalan={tempSuratJalan}/> */}
        </div>
    )
}

export default DataPengeluaranGudang
