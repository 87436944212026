import { Divider, makeStyles, Typography,TableCell,TableRow, Paper, TableContainer, Table, TableHead, Tooltip, IconButton, withStyles, TableBody, Snackbar, Button} from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import DetailsIcon from '@material-ui/icons/Details';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { DateField, SingleDialog } from '../../component/molecules';
import SearchIcon from '@material-ui/icons/Search';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { emptyTableRiwayatPenjualan, getAllDataRiwayatPenjualan, getPenjualanDetails } from '../../config/redux/action/riwayatPenjualanActions';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import IconPrint from '@material-ui/icons/Print';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deleteAllPenjualan, deleteSinglePenjualan, setMessagePenjualanEmpty } from '../../config/redux/action';
import { PrintDraft } from '../../component/assets';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const RiwayatPenjualan = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        table:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'5px',
            width:'100%',
        },
        paper:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'95%',
            [theme.breakpoints.down('xs')]:{
                width:'90%',
            },
        },
        dateWapper:{
            display:'flex',
            justifyContent:'space-between',
            width:'25%',
            [theme.breakpoints.down('xs')]:{
                width:'80%',
            },
            alignItems:'center'
        },
        tableContainer:{
            height:'380px',
            [theme.breakpoints.down('xs')]:{
                height:'520px'
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        buttonBottomTabel:{
            display:'flex',
            justifyContent:'center',
            marginTop:'10px',
            marginBottom:'10px',
        }
    }));
    const classes = useStyle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const {dataRiwayatPenjualan,messageRiwayatPenjualan} = useSelector(state=>state.riwayatPenjualanReducers);
      const {messagePenjualan} = useSelector(state=>state.penjualanReducers);
      const {data} = useSelector(state => state.loginReducers);
      const validationSchema = yup.object().shape({
            tanggalAwal:yup.date().max(yup.ref('tanggalAkhir'),'Tanggal awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
            tanggalAkhir:yup.date().min(yup.ref('tanggalAwal'),'Tanggal Akhir tidak boleh kurang dari tanggal awal').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
      })
      const formik = useFormik({
        initialValues:{
            tanggalAwal:new Date(),
            tanggalAkhir:new Date()
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'tanggalAwal':format(values.tanggalAwal,'yyyy-MM-dd'),
                'tanggalAkhir':format(values.tanggalAkhir,'yyyy-MM-dd')
            }
            dispatch(getAllDataRiwayatPenjualan(data,token));
        }
      })
      const dispatch = useDispatch();
      const [token,setToken] = useState(data.token);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const history=useHistory();
      const handleClickOpenDetailsPenjualan = (idpenjualan,diskon,ongkir,total)=>{
        dispatch(getPenjualanDetails(token,idpenjualan,diskon,ongkir,total,setOpenSnackbar,history));
      }
      const [openSnackbar,setOpenSnackbar] = useState(false);
      const handleCloseSnackBar = () => {
          setOpenSnackbar(false);
      };
      const [openDelete,setOpenDelete]=useState(false);
      const [statusButton,setStatusButton]=useState("");
      const [refIdPenjualan,setRefIdPenjualan]=useState("");
      const onCloseDialog = () =>{
        setOpenDelete(false);
    }
      const handleDeleteAll = (e) =>{
        setStatusButton("All");
        setOpenDelete(true);
    }
    const handleDeleteSinglePenjualan = (idpenjualan) =>{
        setRefIdPenjualan(idpenjualan);
        setStatusButton("Single");
        setOpenDelete(true);
    }
    const deleteAll = () =>{
        if(dataRiwayatPenjualan.length>0){
            const data={
                'details':dataRiwayatPenjualan,
                'tanggalawal':format(formik.values.tanggalAwal,'yyyy-MM-dd'),
                'tanggalakhir':format(formik.values.tanggalAkhir,'yyyy-MM-dd')
            }
          dispatch(deleteAllPenjualan(data,token,setOpenSnackbar,setOpenDelete));
        }else{
            dispatch(setMessagePenjualanEmpty("Tidak terdapat data penjualan",setOpenSnackbar));
            setOpenDelete(false)
        }
      }
      const deleteSingle = () =>{
        const data={
            'tanggalAwal':format(formik.values.tanggalAwal,'yyyy-MM-dd'),
            'tanggalAkhir':format(formik.values.tanggalAkhir,'yyyy-MM-dd')
        }
        dispatch(deleteSinglePenjualan(refIdPenjualan,data,token,setOpenSnackbar,setOpenDelete));
      }
      useEffect(()=>{
        dispatch(emptyTableRiwayatPenjualan());
        if(format(formik.values.tanggalAwal,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd') && format(formik.values.tanggalAkhir,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd')){
        setTimeout(()=>{
                const data = {
                    'tanggalAwal':format(formik.values.tanggalAwal,'yyyy-MM-dd'),
                    'tanggalAkhir':format(formik.values.tanggalAkhir,'yyyy-MM-dd')
                }
                dispatch(getAllDataRiwayatPenjualan(data,token));
            },1000)
          }
      },[dispatch,token,formik.values.tanggalAwal,formik.values.tanggalAkhir])
      const handleClickPrint = (idpenjualan,status)=>{
            history.push(`/PrintPenjualan/${status}/${idpenjualan}`);
      }
      const handleClickPrintSuratJalan = (idpenjualan)=>{
        history.push(`/PrintPenjualan/suratjalan/${idpenjualan}`);
       }
      const tableRiwayatPenjualan =(
        <TableBody>
            {dataRiwayatPenjualan.map((dataRiwayatPenjualans=>(
            <StyledTableRow key={dataRiwayatPenjualans.idpenjualan}>
                <SytledTableCell align="center">
                    {dataRiwayatPenjualans.status==='Penjualan' ? 
                        <Tooltip title="Hapus Penjualan">
                        <IconButton size='small' 
                        onClick={()=>{handleDeleteSinglePenjualan(dataRiwayatPenjualans.idpenjualan)}}
                        >
                            <DeleteForeverIcon/>
                        </IconButton>
                        </Tooltip> : null
                    }
                    
                </SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPenjualans.idpenjualan}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPenjualans.pelanggan}</SytledTableCell>
                <SytledTableCell align="center">{format(new Date(dataRiwayatPenjualans.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPenjualans.pembayaran}</SytledTableCell>
                <SytledTableCell align="center">{format(new Date(dataRiwayatPenjualans.jatuhtempo),'dd/MM/yyyy')}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPenjualans.diskon)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPenjualans.ongkir)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPenjualans.total)}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPenjualans.status}</SytledTableCell>
                <SytledTableCell align="center">
                        <Tooltip title="Rincian transaksi">
                            <IconButton size='small' 
                            onClick={()=>{handleClickOpenDetailsPenjualan(dataRiwayatPenjualans.idpenjualan,dataRiwayatPenjualans.diskon,dataRiwayatPenjualans.ongkir,dataRiwayatPenjualans.total)}}
                            >
                                <DetailsIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Print transaksi">
                            <IconButton size='small' 
                            onClick={()=>{handleClickPrint(dataRiwayatPenjualans.idpenjualan,dataRiwayatPenjualans.status)}}
                            >
                                <IconPrint/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Print Surat Jalan">
                            <IconButton size='small' 
                            onClick={()=>{handleClickPrintSuratJalan(dataRiwayatPenjualans.idpenjualan)}}
                            >
                                <img src={PrintDraft} style={{width:'20px',height:'20px'}} alt="pdraft"/>
                            </IconButton>
                        </Tooltip>
                    </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
      )
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>Riwayat Penjualan</Typography>
            </div>
            <Divider/>
            <div className={classes.table}>
                <div className={classes.dateWapper}>
                    <DateField id="tanggalAwal" 
                    name="tanggalAwal" 
                    label="Tanggal Awal" 
                    value={formik.values.tanggalAwal}
                    errors={formik.touched.tanggalAwal && Boolean(formik.errors.tanggalAwal)}
                    helperText={formik.touched.tanggalAwal && formik.errors.tanggalAwal}
                    onChange={(value)=>formik.setFieldValue('tanggalAwal',value)}
                    />

                    <DateField id="tanggalAkhir" 
                    name="tanggalAkhir" 
                    label="Tanggal Akhir" 
                    value={formik.values.tanggalAkhir}
                    errors={formik.touched.tanggalAkhir && Boolean(formik.errors.tanggalAkhir)}
                    helperText={formik.touched.tanggalAkhir && formik.errors.tanggalAkhir}
                    onChange={(value)=>formik.setFieldValue('tanggalAkhir',value)}
                    />
                    <Tooltip title="Cari data pembelian">
                    <IconButton
                    id='searchButton'
                    name='searchButton'
                    onClick={formik.handleSubmit}
                    >
                       <SearchIcon/>
                    </IconButton>
                    </Tooltip>
                </div>
            <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='5%' align="center"/>
                                    <SytledTableCell width='10%' align="center">No Faktur</SytledTableCell>
                                    <SytledTableCell width='15%' align="center">Pelanggan</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Tanggal</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Pembayaran</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Jatuh Tempo</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Diskon</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Ongkir</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Total</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Status</SytledTableCell>
                                    <SytledTableCell width='10%' align="center"/>
                                </TableRow>
                            </TableHead>
                                {dataRiwayatPenjualan.length <1 ? null : tableRiwayatPenjualan}
                        </Table>
                    </TableContainer>
                </Paper>
                <div className={classes.buttonBottomTabel}>
                    <Button variant='contained' color="secondary" onClick={()=>handleDeleteAll()}>Hapus semua Penjualan</Button>
                    </div>
                </div>
                <SingleDialog openDialog={openDelete} handleClick={statusButton==="All" ? deleteAll :deleteSingle} okCommand="Hapus" handleCloseDialog={onCloseDialog} message={statusButton === "All"?"Anda yakin akan menghapus semua penjualan?":"Anda yakin akan menghapus data penjualan "+refIdPenjualan+"?"}/>
            
                <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageRiwayatPenjualan === "Berhasil menampilkan rincian penjualan" ? "success" : "error" || messagePenjualan === "Data penjualan berhasil dihapus" ? "success" : "error"} 
                >
                    {messageRiwayatPenjualan || messagePenjualan}</Alert>
                </Snackbar>
        </div>
    )
}

export default RiwayatPenjualan
