import { Divider, makeStyles, TableCell, TableRow,Tooltip, Typography,IconButton, withStyles,Paper,TableContainer,Fab,Table,TableHead,TableBody } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Search } from '../../component/molecules';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPenyimpanan, setNewIDPenyimpanan, setSearchPenyimpanan } from '../../config/redux/action';
import Cookies from 'js-cookie';
import { AddNewPenyimpanan } from '../../component/organism';
import DeletePenyimpanan from '../../component/organism/DeletePenyimpanan';

const Penyimpanan = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        tableWapper:{
            display:'flex',
            flexDirection:'column',
            maxWidth:'90%',
            marginLeft:'5%',
            alignItems:'center',
        },
        table:{
            marginTop:'20px',
            [theme.breakpoints.up('md')]:{
                width:'50%',
            },
            width:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },addIcon:{
            paddingRight:'10px'
        },
        nullText:{
            width:'100%',
            display:'flex',
            textAlign:'center',
        }
    }))
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const dispatch = useDispatch();
    const {data} = useSelector(state=>state.loginReducers);
    const {dataPenyimpanan,searchPenyimpanan,pagePenyimpanan} = useSelector(state=>state.penyimpananReducers);
    const [token,setToken] = useState(data.token);
    const [counter,setCounter] = useState(1);
    const [open,setOpen]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
            dispatch(getAllPenyimpanan(searchPenyimpanan,counter,token))
    },[searchPenyimpanan,counter,token,dispatch])
    const focus = () =>{
        setCounter(1);
        dispatch(getAllPenyimpanan(searchPenyimpanan,counter,token));
    }
    const handlePrev=()=>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pagePenyimpanan.totalPage ? pagePenyimpanan.totalPage : counter + 1);
    }
    const [keterangan,setKeterangan]=useState('');
    const [tempID,setTempID] = useState('');
    const [idForm,setIdForm] = useState('');
    const [status,setStatus] = useState('');
    const HandleClickCloseDialog = () =>{
        setOpen(false);
        setCounter(1);
        dispatch(getAllPenyimpanan(searchPenyimpanan,counter,token));
    }
    const HandleClickOpenDialog = () =>{
        dispatch(setNewIDPenyimpanan(token,setOpen,setTempID));
        setKeterangan('');
        setIdForm('new-penyimpanan-dialog');
        setStatus('baru');
    }
    const HandleClickOpenEditDialog = (tempID,keterangan) =>{
        setOpen(true);
        setKeterangan(keterangan);
        setTempID(tempID);
        setIdForm('edit-penyimpanan-dialog');
        setStatus('ubah');
    }
    const [openDelete,setOpenDelete]=useState(false);
    const HandleClickOpenDeleteDialog = (tempID,keterangan)=>{
        setOpenDelete(true);
        setTempID(tempID);
        setKeterangan(keterangan);
        setIdForm('delete-penyimpanan-dialog');
        setStatus('delete');
    }
    const HandleClickCloseDeleteDialog = () =>{
        setOpenDelete(false);
        setCounter(1);
        dispatch(getAllPenyimpanan(searchPenyimpanan,counter,token));
    }
    const tabelPenyimpanan=(
        <TableBody>
        {dataPenyimpanan.map((datapenyimpanans)=>( 
            <StyledTableRow key={datapenyimpanans.idpenyimpanan}>
            <SytledTableCell align="center">{datapenyimpanans.idpenyimpanan}</SytledTableCell>
            <SytledTableCell align="center">{datapenyimpanans.keterangan}</SytledTableCell>
                <SytledTableCell align="center">
                    <Tooltip title="Ubah">
                        <IconButton size='small' 
                        onClick={()=>HandleClickOpenEditDialog(datapenyimpanans.idpenyimpanan,datapenyimpanans.keterangan)}
                        >
                            <EditIcon/>
                        </IconButton>
                        </Tooltip>
                    <Tooltip title="Hapus">
                        <IconButton size='small' 
                        onClick={()=>HandleClickOpenDeleteDialog(datapenyimpanans.idpenyimpanan,datapenyimpanans.keterangan)}
                        >
                            <DeleteForeverIcon/>
                        </IconButton>
                    </Tooltip>
                </SytledTableCell>
            </StyledTableRow>
        ))}
        </TableBody>
    )
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h5">Penyimpanan</Typography>
            <Divider/>
            <Search 
            label="Cari Penyimpanan" 
            name="cari-rak" 
            id="cari-rak" 
            onFocus={focus} 
            onChange={(e)=>dispatch(setSearchPenyimpanan(e.target.value))}
            />
            <div className={classes.tableWapper}>
                <div className={classes.table}>
                <Paper>    
                    <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table jenis barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Penyimpanan</SytledTableCell>
                                <SytledTableCell width='25%' align="center">Keterangan</SytledTableCell>
                                <SytledTableCell width='5%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        {dataPenyimpanan.length < 1 ? null: tabelPenyimpanan}
                    </Table>
                    </TableContainer>
                    <div className={classes.tableBottom}>
                        <div className={classes.page}>
                        <Tooltip title="Prev">
                            <IconButton size='small' 
                            onClick={handlePrev}
                            >
                                <NavigateBefore/>
                            </IconButton>
                        </Tooltip>
                        <Typography className={classes.textPage}>
                            {pagePenyimpanan.currentPage} / {pagePenyimpanan.totalPage >= 1 ? pagePenyimpanan.totalPage : pagePenyimpanan.currentPage}
                            </Typography>
                        <Tooltip title="Next">
                            <IconButton size='small' 
                            onClick={handleNext}
                            >
                                <NavigateNext/>
                            </IconButton>
                        </Tooltip> 
                        </div>
                        <div className={classes.addIcon}>
                            <Tooltip title="Tambah jenis barang">
                                <Fab size="small" 
                                onClick={()=>{HandleClickOpenDialog()}} 
                                color="primary" aria-label="add">
                                    <AddIcon/>
                                </Fab>
                            </Tooltip>
                            <AddNewPenyimpanan 
                            open={open} 
                            aria-labelledby={idForm} 
                            idform={idForm} 
                            tempID={tempID} 
                            tempKeterangan={keterangan} 
                            status={status} 
                            HandleClickCloseDialog={HandleClickCloseDialog}
                            />
                            <DeletePenyimpanan
                            open={openDelete}
                            aria-labelledby={idForm} 
                            idform={idForm}
                            tempID={tempID} 
                            tempKeterangan={keterangan}
                            status={status} 
                            HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}
                            />
                            </div>
                    </div>
                    </Paper>
                 </div>
            </div>
        </div>
    )
}

export default Penyimpanan
