import { Button,  Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Snackbar, Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { InputFiled } from '../../molecules';
import { addCartBarangMasuk, failedAddCartBarangMasuk, setMessageAddToCart} from '../../../config/redux/action/pembelianAction';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { postHargaJual, postRecordKeuntungan } from '../../../config/redux/action';
import Cookies from 'js-cookie';
import StokDiskonDialog from '../StokDiskonDialog';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const AddCartPembelian = ({HandleClickCloseAddCartDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        textInput:{
            width:'400px',
            padding:'5px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        avaliableEdit:{
            display:'flex',
        },
        check:{
            display:'flex',
            alignItems:'center',
            marginBottom:'5px'
        },

    }));
    const dispatch = useDispatch();
    const {FormBarang} = useSelector(state=>state.barangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const {idbarang,satuan,namabarang,modal,nominal,hargajual2,modalprogram}=FormBarang;
    const {cartBarangMasuk,message} = useSelector(state => state.pembelianReducers);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [editModal,setEditModal] = useState(true);
    const [editKeuntungan,setEditKeuntungan] = useState(true);
    const [token,setToken] = useState(data.token);
    const [check,setCheck] = useState(false);
    const [stokDialog,setStokDialog]=useState(false);
    const [editHargajual2,setEdithargajual2]=useState(true);
    const [editModalProgram,setEditModalProgram]=useState(true);
    const [tempModalProgram,setTempModalProgram]=useState(0);
    const [pembelianStokDiskon,setPembelianStokDiskon]=useState('Tidak');
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackBar = () => {
        dispatch(setMessageAddToCart(''))
        setOpenSnackbar(false);
    };
    const validationSchema = yup.object().shape({
        idbarang:yup.string(),
        namabarang:yup.string(),
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
        modal:yup.number().required('Modal tidak boleh kosong').positive('Modal tidak boleh bernilai negative').min(1,'Modal tidak boleh kurang dari 1'),
        keuntungan:yup.number().required('Keuntungan tidak boleh kosong').positive('Keuntungan tidak boleh bernilai negative').min(1,'Harga jual tidak boleh sama dengan modal'),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang||"",
            namabarang:namabarang||"",
            jumlah:0,
            modal:modal||0,
            modalprogram:modalprogram||0,
            keuntungan:nominal||0,
            hargajual:modal+nominal||0,
            hargajual2:hargajual2||0,
            hargaprogram:tempModalProgram
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            if(pembelianStokDiskon==='Ya'){
                const subtotal = values.jumlah * values.modalprogram;
                const existBarang = cartBarangMasuk.filter(barang =>barang.idbarang === idbarang);
                if(existBarang.length > 0){
                    dispatch(failedAddCartBarangMasuk());
                }else{
                    dispatch(postRecordKeuntungan(idbarang,formik.values.keuntungan,token));
                    dispatch(postHargaJual(idbarang,formik.values.hargajual,values.hargajual2,values.modalprogram,values.hargaprogram,token));
                    if(check===true){
                        dispatch(addCartBarangMasuk(idbarang,namabarang,values.jumlah,satuan,values.modalprogram,subtotal,values.jumlah,pembelianStokDiskon));
                    }else{
                        dispatch(addCartBarangMasuk(idbarang,namabarang,values.jumlah,satuan,values.modalprogram,subtotal,0,pembelianStokDiskon));
                    }
                    handleResetButtonSave();
                    setCheck(false);
                }
            }else if(pembelianStokDiskon==='Tidak'){
                const subtotal = values.jumlah * values.modal;
                const existBarang = cartBarangMasuk.filter(barang =>barang.idbarang === idbarang);
                if(existBarang.length > 0){
                    dispatch(failedAddCartBarangMasuk());
                }else{
                    dispatch(postRecordKeuntungan(idbarang,formik.values.keuntungan,token));
                    dispatch(postHargaJual(idbarang,formik.values.hargajual,values.hargajual2,values.modalprogram,values.hargaprogram,token));
                    if(check===true){
                        dispatch(addCartBarangMasuk(idbarang,namabarang,values.jumlah,satuan,values.modal,subtotal,values.jumlah,pembelianStokDiskon));
                    }else{
                        dispatch(addCartBarangMasuk(idbarang,namabarang,values.jumlah,satuan,values.modal,subtotal,0,pembelianStokDiskon));
                    }
                    handleResetButtonSave();
                    setCheck(false);
                }
            }
            setStokDialog(false);
            setPembelianStokDiskon("Tidak");
        }
    })
    useEffect(() => {
        if(message !== ""){
            setOpenSnackbar(true);
        }else{
            setOpenSnackbar(false);
        }
        if(modalprogram>0){
            setTempModalProgram(modalprogram+nominal);
        }else{
            setTempModalProgram(0);
        }
    }, [message,openSnackbar,modalprogram,nominal,setTempModalProgram])
    const handleClickUbahModal=()=>{
        setEditModal(false);
    }
    const handleClickSaveModal=()=>{
        setEditModal(true);
    }
    const handleClickUbahKeuntungan=()=>{
        setEditKeuntungan(false);
    }
    const handleClickSaveKeuntungan=()=>{
        setEditKeuntungan(true);
    }
    const handleOpenStokDiskonDialog = () =>{
        setStokDialog(true);
    }
    const handleaddStokDiskonDialog=()=>{
        if(formik.values.modalprogram>0){
            setCheck(true);
            setPembelianStokDiskon("Ya");
            formik.handleSubmit();
        }else{
            setCheck(true);
            dispatch(setMessageAddToCart('Barang ini tidak dapat dimasukan stok diskon, dikarenakan tidak memiliki modal program'));
            setOpenSnackbar(true);
            setPembelianStokDiskon("Tidak");
        }
    }
    const handleCloseStokDiskonDialog=()=>{
        setStokDialog(false);
        setCheck(false);
        formik.handleSubmit();
    }
    const handleClickUbahHargaJual2=()=>{
        setEdithargajual2(false);
    }
    const handleClickSaveHargaJual2=()=>{
        setEdithargajual2(true);
    }
    const handleClickUbahModalProgram=()=>{
        setEditModalProgram(false);
    }
    const handleClickSaveModalProgram=()=>{
        setEditModalProgram(true);
    }
    const handleResetButtonSave = () =>{
        setEditKeuntungan(true);
        setEditModal(true);
        setEdithargajual2(true);
        HandleClickCloseAddCartDialog();
    }
    const classes = useStyle();
    const handleEditKeuntungan = (e)=>{
        if(formik.values.modalprogram>0){
            formik.setFieldValue('hargaprogram',parseFloat(formik.values.modalprogram) + parseFloat(e.target.value))
        }else{
            formik.setFieldValue('hargaprogram',0)
        }
        formik.setFieldValue('keuntungan',e.target.value);
    }
    const handleEditModalProgram = (e) =>{
        if(e.target.value>0){
            formik.setFieldValue('hargaprogram',parseFloat(e.target.value) + parseFloat(formik.values.keuntungan))
        }else{
            formik.setFieldValue('hargaprogram',0)
        }
        formik.setFieldValue('modalprogram',e.target.value);
    }
    return (
        <div>
            <Dialog open={false} {...rest} maxWidth="md">
            <DialogTitle className={classes.titleDialog} id="add-barang-cart">Rincian Barang Masuk</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                className={classes.textInput}
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                className={classes.textInput}
                id="namabarang"
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                className={classes.textInput}
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
               
                <div className={classes.avaliableEdit}>
                <InputFiled 
                className={classes.textInput}
                id="modal" 
                name="modal" 
                label="Modal"
                value={formik.values.modal}
                error={formik.touched.modal && Boolean(formik.errors.modal)}
                helperText={formik.touched.modal && formik.errors.modal}
                onChange={(formik.handleChange)}
                disabled={editModal}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <Tooltip title={editModal ? "Ubah modal" : "Simpan modal"}>
                    <IconButton size='small' onClick={editModal ? handleClickUbahModal : handleClickSaveModal}>
                        {editModal ? <EditIcon style={{color:'brown'}}/> :<SaveIcon style={{color:'blue'}}/>}
                    </IconButton>
                </Tooltip>
                </div>
                <div className={classes.avaliableEdit}>
                <InputFiled 
                className={classes.textInput}
                id="modalprogram" 
                name="modalprogram" 
                label="Modal Program"
                value={formik.values.modalprogram}
                error={formik.touched.modalprogram && Boolean(formik.errors.modalprogram)}
                helperText={formik.touched.modalprogram && formik.errors.modalprogram}
                onChange={(e)=>handleEditModalProgram(e)}
                disabled={editModalProgram}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <Tooltip title={editModalProgram ? "Ubah modal program" : "Simpan modal program"}>
                    <IconButton size='small' onClick={editModalProgram ? handleClickUbahModalProgram : handleClickSaveModalProgram}>
                        {editModalProgram ? <EditIcon style={{color:'brown'}}/> :<SaveIcon style={{color:'blue'}}/>}
                    </IconButton>
                </Tooltip>
                </div>
                <div className={classes.avaliableEdit}>
                <InputFiled 
                className={classes.textInput}
                id="keuntungan" 
                name="keuntungan" 
                label="Keuntungan"
                value={formik.values.keuntungan}
                error={formik.touched.keuntungan && Boolean(formik.errors.keuntungan)}
                helperText={formik.touched.keuntungan && formik.errors.keuntungan}
                onChange={(e)=>handleEditKeuntungan(e)}
                disabled={editKeuntungan}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <Tooltip title={editKeuntungan ? "Ubah Keuntungan" : "Simpan keuntungan"}>
                    <IconButton size='small' onClick={editKeuntungan ? handleClickUbahKeuntungan : handleClickSaveKeuntungan}>
                        {editKeuntungan ? <EditIcon style={{color:'brown'}}/> :<SaveIcon style={{color:'blue'}}/>}
                    </IconButton>
                </Tooltip>
                </div>
                <InputFiled
                className={classes.textInput}
                id="hargajual" 
                name="hargajual" 
                label="Harga Jual"
                value={parseFloat(formik.values.modal) + parseFloat(formik.values.keuntungan)}
                error={formik.touched.hargajual && Boolean(formik.errors.hargajual)}
                helperText={formik.touched.hargajual && formik.errors.hargajual}
                onChange={formik.handleChange}
                disabled={true}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent:NumberFormatCustom
                }}/>
                <div className={classes.avaliableEdit}>
                <InputFiled 
                className={classes.textInput}
                id="hargajual2" 
                name="hargajual2" 
                label="Harga Jual 2"
                value={formik.values.hargajual2}
                error={formik.touched.hargajual2 && Boolean(formik.errors.hargajual2)}
                helperText={formik.touched.hargajual2 && formik.errors.hargajual2}
                onChange={formik.handleChange}
                disabled={editHargajual2}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <Tooltip title={editHargajual2 ? "Ubah Harga jual 2" : "Simpan Harga jual 2"}>
                    <IconButton size='small' onClick={editHargajual2 ? handleClickUbahHargaJual2 : handleClickSaveHargaJual2}>
                        {editHargajual2 ? <EditIcon style={{color:'brown'}}/> :<SaveIcon style={{color:'blue'}}/>}
                    </IconButton>
                </Tooltip>
                </div>
                <div className={classes.avaliableEdit}>
                <InputFiled 
                className={classes.textInput}
                id="hargaprogram" 
                name="hargaprogram" 
                label="Harga Program"
                value={formik.values.hargaprogram}
                error={formik.touched.hargaprogram && Boolean(formik.errors.hargaprogram)}
                helperText={formik.touched.hargaprogram && formik.errors.hargaprogram}
                onChange={formik.handleChange}
                disabled={true}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                </div>
                <div className={classes.button}>
                <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenStokDiskonDialog} 
                disabled={editModal === false || editKeuntungan===false ? true : false}
                >Tambah</Button>
                <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleResetButtonSave}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackBar}>
                {message === 'Berhasil menambahkan data ke daftar' ?
                    <Alert onClose={handleCloseSnackBar} severity={"success"}>{message}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity={"error"}>{message}</Alert>
                }
            </Snackbar>
            <StokDiskonDialog open={stokDialog} handleAdd={handleaddStokDiskonDialog} handleCancel={handleCloseStokDiskonDialog}/>
        </div>
    )
}

export default AddCartPembelian
