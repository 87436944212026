import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, makeStyles, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TransferAll, TransferOneItem } from '../../component/assets';
import { getAllDataRiwayatPembelian, getItemPembelian, setTempFakturForTransfer } from '../../config/redux/action';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { DateField } from '../../component/molecules';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ListItemForDirectTransfer, TransferAllItemPembelian } from '../../component/organism';


const DirectTransfer = () => {
    const useStyles=makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%'
        },
        comboStyle:{
            width:'150px',
            marginBottom:'9px'
        },
        content:{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'21%',
            },
        },
        table:{
            marginTop:'5px',
            width:'80%',
        },
        tableContainer:{
            height:'580px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }))
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    const {dataRiwayatPembelian} = useSelector(state=>state.riwayatPembelianReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [token,setToken] = useState(data.token);
    const [openListTransfer,setOpenListTransfer]=useState(false);
    const [fakturPembelian,setFakturPembelian]=useState('');
    const [openTransferAll,setOpenTransferAll]=useState(false);
    const dispatch = useDispatch();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const validationSchema = yup.object().shape({
        tanggal:yup.date().max(yup.ref('tanggalAkhir'),'Tanggal awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
    })
    const formik = useFormik({
        initialValues:{
            tanggal:new Date()
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'tanggalAwal':format(values.tanggal,'yyyy-MM-dd'),
                'tanggalAkhir':format(values.tanggal,'yyyy-MM-dd')
            }
            dispatch(getAllDataRiwayatPembelian(data,token,1));
        }
    })
    useEffect(()=>{
        setTimeout(()=>{
            const data = {
                'tanggalAwal':format(formik.values.tanggal,'yyyy-MM-dd'),
                'tanggalAkhir':format(formik.values.tanggal,'yyyy-MM-dd')
            }
            dispatch(getAllDataRiwayatPembelian(data,token,1));
        },1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleChangeTanggal = (value)=>{
        formik.setFieldValue('tanggal',value);
        setTimeout(()=>{
            const data = {
                'tanggalAwal':format(value,'yyyy-MM-dd'),
                'tanggalAkhir':format(value,'yyyy-MM-dd')
            }
            dispatch(getAllDataRiwayatPembelian(data,token,1));
        },1000)
    }
    const handleClickOpenListItemForTransfer = (idpembelian) =>{
        setFakturPembelian(idpembelian);
        setOpenListTransfer(true);
        dispatch(getItemPembelian(idpembelian,token,""));
    }
    const handleCloseOpenListItemForTransfer = () =>{
        setOpenListTransfer(false);
    }
    const handleClickTransferAll = (idpembelian) =>{
        dispatch(setTempFakturForTransfer(idpembelian));
        setOpenTransferAll(true);
    }
    const handleCloseTransferAll = () =>{
        setOpenTransferAll(false);
    }
    const tabelPembelian = (
        <TableBody>
            {dataRiwayatPembelian.map((dataRiwayatPembelians=>(
            <StyledTableRow key={dataRiwayatPembelians.idpembelian}>
                <SytledTableCell align="center">{dataRiwayatPembelians.idpembelian}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPembelians.supplier}</SytledTableCell>
                <SytledTableCell align="center">{format(new Date(dataRiwayatPembelians.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPembelians.totalpembelian)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPembelians.total)}</SytledTableCell>
                <SytledTableCell width='10%' align="center">
                    <Tooltip title="Transfer per item" style={{borderRight:'1px solid black',marginRight:'5px'}}>
                        <IconButton size='small' 
                        onClick={()=>{handleClickOpenListItemForTransfer(dataRiwayatPembelians.idpembelian,dataRiwayatPembelians.noretur,dataRiwayatPembelians.totalpembelian,dataRiwayatPembelians.totalretur)}}
                        >
                            <img src={TransferOneItem} alt='transfer1'/>
                        </IconButton>
                    </Tooltip>
                     
                    <Tooltip title="Transfer Semua" style={{border:'1px solid black'}}>
                        <IconButton size='small' 
                        onClick={()=>{handleClickTransferAll(dataRiwayatPembelians.idpembelian)}}
                        >
                            <img src={TransferAll} alt='transferAll'/>
                        </IconButton>
                    </Tooltip>
                </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
    )
  return (
    <div className={classes.root}>
        <div className={classes.content}>
                    <div className={classes.table}>
                    <DateField id="tanggal" 
                        name="tanggal" 
                        label="Tanggal" 
                        value={formik.values.tanggal}
                        errors={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                        helperText={formik.touched.tanggal && formik.errors.tanggal}
                        onChange={(value)=>handleChangeTanggal(value)}
                        />
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table barang" size="small">
                                <TableHead>
                                    <TableRow>
                                    <SytledTableCell width='20%' align="center">No Faktur</SytledTableCell>
                                    <SytledTableCell width='20%' align="center">Supplier</SytledTableCell>
                                    <SytledTableCell width='15%' align="center">Tanggal</SytledTableCell>
                                    <SytledTableCell width='15%' align="center">Total Pembelian</SytledTableCell>
                                    <SytledTableCell width='15%' align="center">Total Transaksi</SytledTableCell>
                                    <SytledTableCell width='20%' align="center"/>
                                    </TableRow>
                                </TableHead>
                                {dataRiwayatPembelian.length>0?tabelPembelian:null}
                            </Table>
                        </TableContainer>
                    </Paper>
                    </div>
        </div>
        <ListItemForDirectTransfer open={openListTransfer} HandleClickCloseDialog={handleCloseOpenListItemForTransfer} nofaktur={fakturPembelian} statusTransfer="singleTransfer"/>
        <TransferAllItemPembelian open={openTransferAll} HandleClickCloseDeleteDialog={handleCloseTransferAll}/>
    </div>
  )
}

export default DirectTransfer