const loginInitialState = {
    isLoading:false,
    data:{
        nama:'',
        username:'',
        token:'',
        level:'',
        identifier:'',
        errorMessage:''
    },
    identifier:null,
    messageLogin:''
}

const loginReducers = (state=loginInitialState,action)=>{
    switch(action.type){
        case 'FETCH LOGIN':{
            return{
                ...state,
                isLoading:action.payload
            }
        }
        case 'FETCH GET IDENTIFIER':{
            return{
                ...state,
                isLoading:false
            }
        }
        case 'FAILED GET IDENTIFIER':{
            return{
                ...state,
                isLoading:false,
                messageLogin:action.payload
            }
        }
        case 'LOGIN SUCCESSED':{
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        }
        case 'LOGIN FAILED':{
            return{
                ...state,
                isLoading:false,
                data:action.payload
            }
        }
        case 'RELOAD IDENTIFIER':{
            return{
                ...state,
                isLoadingPengguna:false,
                identifier:action.payload
            }
        }
        default: return state
    }
}
export default loginReducers;