import { Divider, Fab, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';

const RiwayatPembelianDetails = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            // maxWidth:'800px',
            
        },header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'100%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            marginBottom:'10px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const history = useHistory();
    const {dataRiwayatPembelianDetails,totalPembelian} = useSelector(state=>state.riwayatPembelianReducers);
    const {dataReturDetails,totalRetur} = useSelector(state=>state.returSupplierReducers);
    const {idpembelian} = useParams();
    
    const dataPembelian=(
        <div className={classes.content}>
            <Typography>Data Pembelian</Typography>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRiwayatPembelianDetails.map((pembelianDetails=>(
                                <StyledTableRow key={pembelianDetails.idbarang}>
                                    <SytledTableCell align="center">{pembelianDetails.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{pembelianDetails.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{pembelianDetails.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{pembelianDetails.satuan}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(pembelianDetails.harga)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(pembelianDetails.subtotal)}</SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Pembelian</Typography>
                        <Typography><b>{new Intl.NumberFormat().format(totalPembelian)}</b></Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    const dataRetur=(
        <div className={classes.content}>
            <Typography>Data Retur</Typography>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table retur" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataReturDetails.map((dataRetur=>(
                                <StyledTableRow key={dataRetur.idbarang}>
                                    <SytledTableCell align="center">{dataRetur.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{dataRetur.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{dataRetur.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRetur.harga)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRetur.jumlah * dataRetur.harga)}</SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Retur</Typography>
                        <Typography><b>{totalRetur === '' ? 0 : new Intl.NumberFormat().format(totalRetur)}</b></Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    useEffect(()=>{
        if(dataRiwayatPembelianDetails.length<1){
            history.goBack()
        }
    },[dataRiwayatPembelianDetails,history])
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>{idpembelian}</Typography>
            </div>
            <Divider/>
            {dataPembelian}
            <div className={classes.divinder}/>
            {dataRetur}
            <div className={classes.divinder}/>
            <div className={classes.content}>
            <div className={classes.GrandTotal}>
                <Typography>Grand Total</Typography>
                <Typography><b><u>
                    {new Intl.NumberFormat().format(totalPembelian - totalRetur)}
                    </u></b></Typography>
            </div>
            </div>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                <ArrowBackIcon/>
            </Fab>
        </div>
    )
}

export default RiwayatPembelianDetails
