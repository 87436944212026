import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'

const ProtectedRoute = ({component:Component,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const [user,setUser] = useState(data.username);
    const history = useHistory();
    if(user ===''){
        setUser(Cookies.get('username'));
    }
    return (
        <Route {...rest} render={
            props=>{
                if(!user){
                    history.push("/login")
                }else{
                    return <Component {...rest} {...props}/>
                }
            }
        }/>
    )
}

export default ProtectedRoute
