import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataGudang } from '../../../config/redux/action';

const GudangCombo = ({name,id,label,error,helperText,valueOption,...rest}) => {
    const {dataGudang} = useSelector(state=>state.gudangReducers);
  const {data} = useSelector(state=>state.loginReducers);
  const dispatch = useDispatch();
  const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataGudang(token));
    },[token,dispatch])
    const useStyle = makeStyles({
        formControl:{
            width:'100%',
            marginLeft:'3px',
            marginTop:'5px',
        },
    });
    const classes = useStyle();
    return (
        <div>
            <FormControl className={classes.formControl}
              error={error}
              >
              <InputLabel htmlFor='idgudang'>Gudang</InputLabel>
              <Select
              native
              {...rest}
              id={id}
              name={name}
              label="Gudang"
              inputProps={{
                name: 'idgudang',
                id: 'idgudang',
              }}
              >
              <option aria-label="None" value="" />
              {dataGudang.map((dataGudangs=>(
                  <option key={dataGudangs.idgudang} value={dataGudangs.idgudang}>{dataGudangs.namagudang}</option>
              )))}
              </Select>
              <FormHelperText style={{color:'red'}}>{helperText}</FormHelperText>
              </FormControl>
      </div>
    )
}

export default GudangCombo
