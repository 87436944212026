import axios from 'axios';
import {apiUrl} from '../..';

export const getDataPengeluaran = (data,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA PENGELUARAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/getlist`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS FETCH PENGELUARAN',
            payload:ResponseAPI.data,
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED FETCH DATA PENGELUARAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED FETCH DATA PENGELUARAN',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const setFillTableDataPengeluaranGudang = () =>(dispatch)=>{
    dispatch({
        type:'FILL FORM DATA PENGELUARAN GUDANG'
    })
}
export const getDataPengeluaranDetails = (nosuratjalan,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA PENGELUARAN DETAILS'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/${nosuratjalan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS FETCH PENGELUARAN DETAILS',
            payload:ResponseAPI.data,
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED FETCH DATA PENGELUARAN DETAILS',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED FETCH DATA PENGELUARAN DETAILS',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const setFieldPengeluaran = (nosuratjalan,tanggal,admin,supir)=>(dispatch)=>{
    const data={
        'nosuratjalan':nosuratjalan,
        'tanggal':tanggal,
        'admin':admin,
        'supir':supir
    }
    dispatch({
        type:'SET FIELD PENGELUARAN',
        payload:data
    })
}
export const setEmptyFieldPengeluaran = ()=>(dispatch)=>{
    dispatch({
        type:'SET FIELD PENGELUARAN',
        payload:[]
    })
}
export const setFormDetails = (idpengeluaran,idbarang,namabarang,jumlah,variant)=>(dispatch)=>{
    const data={
        'idpengeluaran':idpengeluaran,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'variant':variant
    }
    dispatch({
        type:'SET FORM DETAILS',
        payload:data
    })
}
export const setEmptyFormDetails = ()=>(dispatch)=>{
    dispatch({
        type:'SET FORM DETAILS',
        payload:[]
    })
}
export const setMessagePengeluaran = (message) => (dispatch)=>{
    dispatch({
        type:'SET PENGELUARAN MESSAGE',
        payload:message
    })
}
export const patchPengeluaran = (data,token)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING PATCH PENGELUARAN'
    })

    axios.patch(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS PATCH PENGELUARAN',
            payload:ResponseAPI.message
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED PATCH PENGELUARAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED PATCH PENGELUARAN',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const deletePengeluaran = (idpengeluaran,token)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING DELETE PENGELUARAN'
    })

    axios.delete(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/${idpengeluaran}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS DELETE PENGELUARAN',
            payload:ResponseAPI.message
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED DELETE PENGELUARAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED DELETE PENGELUARAN',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const deleteAllPengeluaran = (nosuratjalan,token)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING DELETE PENGELUARAN'
    })

    axios.delete(`${apiUrl.url}v1/suryaharapan/pengeluarangudang/pengeluaran/${nosuratjalan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS DELETE PENGELUARAN',
            payload:ResponseAPI.message
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED DELETE PENGELUARAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED DELETE PENGELUARAN',
                    payload:'Disconnect from server'
            })
        }
    })
}