import axios from "axios";
import { differenceInDays } from "date-fns";
import { apiUrl } from "../..";

export const setMessageDraftPenjualan = (message,setOpenSnackbar) =>(dispatch)=>{
    if(message==='Modal lebih kecil dari harga jual dipotong diskon'){
        dispatch({
            type:'SET MESSAGE DRAFT PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else if(message==='Barang telah terdaftar dalam keranjang penjualan'){
        dispatch({
            type:'SET MESSAGE DRAFT PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else if(message==='Stok Barang Kurang dari 0'){
        dispatch({
            type:'SET MESSAGE DRAFT PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else if(message==="Draft Penjualan gagal disimpan,terdapat item yang sama dalam 1 keranjang penjualan"){
        dispatch({
            type:'SET MESSAGE DRAFT PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else{
        dispatch({
            type:'SET MESSAGE DRAFT PENJUALAN',
            payload:message
        })
    }
}

export const addToCartDraftPenjualan = (data,dataKeuntungan,token,setOpenSnackbar,statusDraft) =>(dispatch)=>{
    if(statusDraft==="EditDraft"){
        dispatch({
            type:'FETCH DATA'
        })
        axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/addsingledetails`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            dispatch({
                type:'SUCCESSED ADD DETAILS DRAFT',
                payload:ResponseAPI.message
            })
            setOpenSnackbar(true);
            dispatch({
                type:'EMPTY DRAFT PENJUALAN'
            })
            axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getDetails`,data,{
                headers:{
                    'Authorization':`Bearer ${token}`
                }
            })
            .then(res=>{
                const ResponseAPI=res.data;
                ResponseAPI.data.map(list=>{
                    const newDataDetails = {
                        "idpenjualandetails":list.idpenjualandetails,
                        "idpenjualan":list.idpenjualan,
                        "idbarang":list.idbarang,
                        "namabarang": list.namabarang,
                        "jumlah": list.jumlah,
                        "satuan": list.satuan,
                        "note": list.note,
                        "barangstokdiskon":list.barangstokdiskon,
                        "harga": list.harga,
                        "subtotal": list.subtotal,
                        "stokdiskon": list.stokdiskon
                    }
                    dispatch({
                        type:'ADD LIST FROM API',
                        payload:newDataDetails
                    })
                    return list
                })
                    dispatch({
                        type:'ADD LIST KEUNTUNGAN',
                        payload:dataKeuntungan
                    })
            })
            .catch(error=>{
                if(error.response){
                    if(error.response.status === 500){
                        dispatch({
                            type:'FAILED ACTION DRAFT PENJUALAN',
                            payload:error.response.data.message
                        })
                    }else if(error.response.status === 401){
                        dispatch({
                            type:'FAILED ACTION DRAFT PENJUALAN',
                            payload:error.response.data.message
                        })
                    }
                }else if(error.request){
                    dispatch({
                            type:'FAILED ACTION DRAFT PENJUALAN',
                            payload:'Disconnect from server'
                    })
                }
                setOpenSnackbar(true);
            })
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ADD DETAILS DRAFT',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ADD DETAILS DRAFT',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED ADD DETAILS DRAFT',
                        payload:'Disconnect from server'
                })
            }
        })
    }else if(statusDraft==="Draft"){
        dispatch({
            type:'ADD LIST DRAFT PENJUALAN',
            payload:data,
            messagePayload:'Berhasil menambahkan ke keranjang draft penjualan'
        })
        dispatch({
            type:'ADD LIST KEUNTUNGAN',
            payload:dataKeuntungan,
        })
    }
    setOpenSnackbar(true);
}
export const getNewIDDraftPenjualan = (tanggal,token)=>(dispatch)=>{
    const data = {
        'tanggal':tanggal
    }
    dispatch({
        type:'FETCH GET ID DRAFT PENJUALAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getID`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET NO FAKTUR DRAFT', 
            payload:ResponseAPI.data
         })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NO FAKTUR DRAFT',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NO FAKTUR DRAFT',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NO FAKTUR DRAFT',
                    payload:'Disconnect from server'
            })
        }
})
}

export const getAllListDraftPenjualan = (token,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getList`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(res=>{
        const responseAPI = res.data;
        dispatch({
            type:'GET DATA DRAFT PENJUALAN LIST',
            payload:responseAPI.data
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED SAVE DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED SAVE DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const postDraftPenjualanToAPI = (data,token,setOpenSnackbar,setOpenPrint)=>(dispatch)=>{
    dispatch({
        type:'FETCH GET ID DRAFT PENJUALAN'
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/savedraftpenjualan`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(results=>{
            const ResponseAPI = results.data;
            dispatch({
                type:'SUCCESSED SAVE DRAFT PENJUALAN', 
                payload:ResponseAPI.message,
             })
            dispatch({
                type:'EDIT STATUS DRAFT',
                payload:"Draft"
            })
             setOpenSnackbar(true);
             setOpenPrint(true);
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED SAVE DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED SAVE DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED SAVE DRAFT PENJUALAN',
                        payload:'Disconnect from server'
                })
                setOpenSnackbar(true);
            }
        })
    },2000)
}
export const emptyMessageDraftPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE DRAFT PENJUALAN'
    })
}
export const resetAllFormDraftPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'RESET DRAFT PENJUALAN'
    })
    dispatch({
        type:'RESET KEUNTUNGAN LIST'
    })
}

export const emptyDataDraftPrint = () =>(dispatch)=>{
    dispatch({
        type:'GET DATA DRAFT PENJUALAN TO PRINT'
    })
}
export const updateStokDraftDiskon = (idbarang,token)=>(dispatch)=>{
    const data={
        'idbarang':idbarang
    }
    dispatch({
        type:'FETCH UPDATE STOK DRAFT DISKON'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/updatestokdiskon/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED UPDATE STOK DRAFT DISKON',
            payload:ResponseAPI.message
            })
            dispatch(getAllListDraftPenjualan(token,""));
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA DRAFT TO PRINT',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA DRAFT TO PRINT',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA DRAFT TO PRINT',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const deleteDraftPenjualan =(idpenjualan,token,setOpenSnackbar,setTempId,onCloseDialog)=>(dispatch)=>{
    const data={
        'idpenjualan':idpenjualan
    }
    dispatch({
        type:'FETCH DATA'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/delete`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED ACTION DRAFT PENJUALAN',
            payload:ResponseAPI.message
        })
        dispatch(getAllListDraftPenjualan(token,setOpenSnackbar));
        setTempId("");
        setOpenSnackbar(true);
        onCloseDialog();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
    
}
export const loadDataPenjualan = (idpenjualan,token,setOpenSnackbar,formikEdit)=>(dispatch)=>{
    const data={
        'idpenjualan':idpenjualan
    }
    dispatch({
        type:'FETCH DATA'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getPenjualan`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'EMPTY DRAFT PENJUALAN'
        })
        dispatch({
            type:'SUCCESSED GET NO FAKTUR DRAFT', 
            payload:ResponseAPI.data[0].idpenjualan
         })
         let days=0;
         days=differenceInDays(new Date(ResponseAPI.data[0].jatuhtempo),new Date(ResponseAPI.data[0].tanggal));
         const dataPenjualan={
            "idpenjualan": ResponseAPI.data[0].idpenjualan,
            "pelanggan": ResponseAPI.data[0].pelanggan,
            "tanggal": new Date(ResponseAPI.data[0].tanggal),
            "pembayaran": ResponseAPI.data[0].pembayaran,
            "jatuhtempo": new Date(ResponseAPI.data[0].jatuhtempo),
            "lamatempo":days,
            "diskon": ResponseAPI.data[0].diskon,
            "total": ResponseAPI.data[0].total,
            "ongkir": ResponseAPI.data[0].ongkir,
            "status": "Draft",
        }
        dispatch({
            type:'GET DRAFT FROM API',
            payload:dataPenjualan
        })
        formikEdit.setFieldValue('namapelanggan',ResponseAPI.data[0].pelanggan);
        formikEdit.setFieldValue('tanggal',ResponseAPI.data[0].tanggal);
        formikEdit.setFieldValue('jatuhtempo',ResponseAPI.data[0].jatuhtempo);
        formikEdit.setFieldValue('lamatempo',days);
        formikEdit.setFieldValue('pembayaran',ResponseAPI.data[0].pembayaran);
        formikEdit.setFieldValue('ongkir',ResponseAPI.data[0].ongkir);
        formikEdit.setFieldValue('diskon',ResponseAPI.data[0].diskon);
        dispatch({
            type:'SELECTED ID PELANGGAN',
            payload:{
                selectedID:"FromDraft",
                selectedNama:ResponseAPI.data[0].pelanggan
            }
        })
        axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getDetails`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            ResponseAPI.data.map(list=>{
                const newDataDetails = {
                    "idpenjualandetails":list.idpenjualandetails,
                    "idpenjualan":list.idpenjualan,
                    "idbarang":list.idbarang,
                    "namabarang": list.namabarang,
                    "jumlah": list.jumlah,
                    "satuan": list.satuan,
                    "note": list.note,
                    "barangstokdiskon":list.barangstokdiskon,
                    "harga": list.harga,
                    "subtotal": list.subtotal,
                    "stokdiskon": list.stokdiskon
                }
                dispatch({
                    type:'ADD LIST FROM API',
                    payload:newDataDetails
                })
                return list
            })
            ResponseAPI.keuntungan.map(listuntung=>{
                dispatch({
                    type:'ADD LIST KEUNTUNGAN',
                    payload:listuntung
                })
                return listuntung;
            })
            dispatch({
                type:'EDIT STATUS DRAFT',
                payload:"EditDraft"
            })
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:'Disconnect from server'
                })
            }
            setOpenSnackbar(true);
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
}

export const setEmptyLoadDraft = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY LOAD DRAFT PENJUALAN'
    })
}
export const editStatusDraft = (status) =>(dispatch)=>{
    dispatch({
        type:'EDIT STATUS DRAFT',
        payload:status
    })
}
export const editSingleJumlah = (data,token,setOpenSnackbar,onCloseDialogEditJumlah,formik)=>(dispatch)=>{
    dispatch({
        type:'FETCH DATA'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/editsingledetails`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED EDIT DETAILS DRAFT',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        dispatch({
            type:'EMPTY DRAFT PENJUALAN'
        })
        axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getDetails`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(resDetails=>{
            const ResponseAPI=resDetails.data;
            ResponseAPI.data.map(list=>{
                const newDataDetails = {
                    "idpenjualandetails":list.idpenjualandetails,
                    "idpenjualan":list.idpenjualan,
                    "idbarang":list.idbarang,
                    "namabarang": list.namabarang,
                    "jumlah": list.jumlah,
                    "satuan": list.satuan,
                    "note": list.note,
                    "barangstokdiskon":list.barangstokdiskon,
                    "harga": list.harga,
                    "subtotal": list.subtotal,
                    "stokdiskon": list.stokdiskon
                }
                dispatch({
                    type:'ADD LIST FROM API',
                    payload:newDataDetails
                })
                return list
            })
            dispatch({
                type:'RESET KEUNTUNGAN LIST'
            })
            ResponseAPI.keuntungan.map(listuntung=>{
                dispatch({
                    type:'ADD LIST KEUNTUNGAN',
                    payload:listuntung
                })
                return listuntung;
            })
            formik.handleReset();
            onCloseDialogEditJumlah();
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:'Disconnect from server'
                })
            }
            setOpenSnackbar(true);
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
}
export const deleteAPIPenjualanDetails = (data,token,setOpenSnackbar,onCloseDialog) =>(dispatch)=>{
    dispatch({
        type:"FETCH DATA"
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/deletesingledetails`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED DELETE DETAILS DRAFT',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        dispatch({
            type:'EMPTY DRAFT PENJUALAN'
        })
        axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getDetails`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(resDetails=>{
            const ResponseAPI=resDetails.data;
            ResponseAPI.data.map(list=>{
                const newDataDetails = {
                    "idpenjualandetails":list.idpenjualandetails,
                    "idpenjualan":list.idpenjualan,
                    "idbarang":list.idbarang,
                    "namabarang": list.namabarang,
                    "jumlah": list.jumlah,
                    "satuan": list.satuan,
                    "note": list.note,
                    "barangstokdiskon":list.barangstokdiskon,
                    "harga": list.harga,
                    "subtotal": list.subtotal,
                    "stokdiskon": list.stokdiskon
                }
                dispatch({
                    type:'ADD LIST FROM API',
                    payload:newDataDetails
                })
                return list
            })
            onCloseDialog();
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED ACTION DRAFT PENJUALAN',
                        payload:'Disconnect from server'
                })
            }
            setOpenSnackbar(true);
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
}
export const postEditDraftPenjualan = (data,token,setOpenSnackbar,setOpenPrint) =>(dispatch)=>{
    dispatch({
        type:"FETCH DATA"
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/saveagain`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED SAVE DRAFT PENJUALAN',
            payload:ResponseAPI.message
        })
        dispatch({
            type:'EDIT STATUS DRAFT',
            payload:"Draft"
        })
        setOpenSnackbar(true);
        setOpenPrint(true);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
}