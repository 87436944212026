import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { FilterBarangDeleteToAPI, setEmptyFilterMessage } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DeleteFilterBarang = ({openDeleteDialogFilter,HandleClickCloseDeleteDialog,idbarangToko,namabarang,stokfilter,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {filterMessage,isLoadingFilter}=useSelector(state=>state.filterBarangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'550px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setEmptyFilterMessage(""));
        },500)
      };
      const onSubmit = ()=>{
            dispatch(FilterBarangDeleteToAPI(idbarangToko,stokfilter,token,setOpenSnackbar,HandleClickCloseDeleteDialog));
      }
  return (
    <div>
            <Dialog open={openDeleteDialogFilter} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-pengeluaran-dialog">Hapus data filter barang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data {namabarang} dari {stokfilter}?</Typography>
                    <Typography align='center' style={{color:'red'}}>Perhatian!!! Data yang telah dihapus tidak dapat dikembalikan</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingFilter}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog} disabled={isLoadingFilter}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={filterMessage === 'Data filter barang berhasil dihapus' ?"success" :"error"} onClick={HandleClickCloseDeleteDialog}>{filterMessage}</Alert>
            </Snackbar>
        </div>
  )
}

export default DeleteFilterBarang