export const setLeftMenuOpen = (open) => (dispatch) =>{
    dispatch({
        type : 'SET LEFT MENU OPEN',
        payload : open
    })
}
export const setKonfigurasiNetwork = (value) => (dispatch)=>{
    dispatch({
        type:'SET NETWORK',
        payload:value
    })
}
export const setOpenHutang = (value) => (dispatch)=>{
    dispatch({
        type:"SET OPEN HUTANG",
        payload:value
    })
}