import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core'
import React, { useState } from 'react'
import { InputFiled } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { postFilterBarang, setEmptyFilterMessage } from '../../../config/redux/action';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AddDataFilterDialog = ({openDataFilter,closeDataFilter,idbarang,namabarang,stok,...rest}) => {
  const useStyles=makeStyles((theme)=>({
    root:{
      width:'100%'
    },
    titleDialog:{
      width:'400px',
      [theme.breakpoints.down('sm')]:{
          width:'250px',
      },
      textAlign:'center',
    },button:{
      display:'flex',
      justifyContent:'space-between',
      paddingBottom:"5px",
    },
    textFiled:{
        width:'100%',
        paddingBottom:"7px",
    },
  }))
  const classes = useStyles();
  const dispatch = useDispatch();
  const {data} = useSelector(state => state.loginReducers);
  const {filterMessage,isLoadingFilter,dataFilter}=useSelector(state=>state.filterBarangReducers);
  const [token,setToken] = useState(data.token);
  const [openSnackbar,setOpenSnackbar] = useState(false);
  if(token ===''){
      setToken(Cookies.get('u_tkn'));
  }
  const resetForm = () =>{
    formik.resetForm();
}
  const validationScheme = yup.object().shape({
    qty:yup.number().required('Jumlah minimal tidak boleh kosong').min(1,'jumlah minimal tidak boleh kurang dari 1')
  })
  const formik = useFormik({
    initialValues:{
      qty:0,
    },
    validationSchema:validationScheme,
    onSubmit:(values)=>{
      const existBarang = dataFilter.filter(barang =>barang.idbarang === idbarang && barang.stok === stok);
      if(existBarang.length > 0){
        dispatch(setEmptyFilterMessage("Barang dari "+stok+" ini telah terdaftar"));
        setOpenSnackbar(true);
      }else{
        const data = new URLSearchParams();
        data.append('idbarang',idbarang);
        data.append('namabarang',namabarang);
        data.append('stok',stok);
        data.append('minstok',values.qty);
        dispatch(postFilterBarang(data,token,setOpenSnackbar,closeDataFilter,resetForm));
      }
    }
  })
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(() => {
            dispatch(setEmptyFilterMessage(""));
        }, 100);
      };
  return (
    <div>
      <Dialog open={openDataFilter} {...rest}>
        <DialogTitle className={classes.titleDialog} id="titleDataFilter">Filter Barang</DialogTitle>
        <Divider/>
        <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                disabled={true} 
                value={idbarang}/>
                <InputFiled 
                id="namabarang" 
                name="namabarang" 
                label="Nama Barang"
                value={namabarang}
                disabled={true} 
                />
                <InputFiled 
                id="qty" 
                name="qty" 
                label="Minimal Stok"
                value={formik.values.qty}
                error={formik.touched.qty && Boolean(formik.errors.qty)}
                helperText={formik.touched.qty && formik.errors.qty}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" 
                onClick={formik.handleSubmit} 
                disabled={isLoadingFilter}
                >Simpan</Button>
                <Button onClick={closeDataFilter} variant="contained" color="secondary"  
                disabled={isLoadingFilter}
                >Keluar</Button>
                </div>
        </DialogContent>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} 
        severity={filterMessage==='Data filter barang berhasil disimpan' ? 'success' : 'error'}
        >{filterMessage}</Alert>
      </Snackbar>
    </div>
  )
}

export default AddDataFilterDialog