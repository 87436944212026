import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchRetur = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH RETUR',
        payload :search
    })
}
export const setDataRetur = (search,page,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA RETUR',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/retur/?search=${search}&page=${page}&perPage=50`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA RETUR', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed'
        })
        dispatch({
            type:'UPDATE PAGE RETUR',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_StokRetur/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH RETUR',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH RETUR',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH RETUR',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setReturFromStok = (idbarang,ref,token) =>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/details/${idbarang}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    }).then(res=>{
        const ResponseApi = res.data.data;
        dispatch({
            type:'SET FORM EDIT RETUR',
            payload:{
                'idbarang':idbarang,
                'namabarang':ResponseApi.namabarang,
                'modal':ResponseApi.modal,
                'sisastok':ResponseApi.sisastok,
                'ref':ref
            }
        })
    })
}
export const setReturFromRetur = (idbarang,ref,token) =>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/retur/${idbarang}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    }).then(res=>{
        const ResponseApi = res.data.data;
        dispatch({
            type:'SET FORM EDIT RETUR',
            payload:{
                'idbarang':idbarang,
                'namabarang':ResponseApi.namabarang,
                'modal':ResponseApi.modal,
                'sisastok':ResponseApi.jumlah,
                'ref':ref
            }
        })
    })
}
export const addDataPencatatanRetur = (idbarang,token)=>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/details/${idbarang}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    }).then(res=>{
        const ResponseApi = res.data.data;
        dispatch({
            type:'SET FORM ADD RETUR',
            payload:{
                'idbarang':idbarang,
                'namabarang':ResponseApi.namabarang
            }
        })
    })
}
export const setMessagePencatatanRetur = (message,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE RETUR',
        payload:message
    })
    setOpenSnackbar(true);
}
export const setFillRetur =()=>(dispatch)=>{
    dispatch({
        type:'SET FILL FORM RETUR',
        payload:[]
    })
}

export const addReturBarang = (data) =>(dispatch)=>{
    dispatch({
        type:'SET FORM RETUR BARANG',
        payload:data,
        messageAddRetur:'Penambahan data barang berhasil'
    })
}
export const addPencatatanRetur = (data,setOpenSnackBar,HandleClickCloseDialog) =>(dispatch)=>{
    dispatch({
        type:'SET FORM PENCATATAN RETUR',
        payload:data,
        messageAddRetur:'Berhasil menambahan pencatatan retur'
    })
    setOpenSnackBar(true);
    HandleClickCloseDialog();
}
export const setEmptyMessageRetur = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY MESSAGE RETUR',
    })
}

export const getNoPencatatanRetur = (tanggal,token)=>(dispatch)=>{
    const data={
        'tanggal':tanggal
    }
    axios.post(`${apiUrl.url}v1/suryaharapan/returbarang/getID`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(res=>{
        const ResponseAPI = res.data.data;
        dispatch({
            type:'SUCCESSED GET NO PENCATATAN RETUR',
            payload:ResponseAPI
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NO PENCATATAN RETUR',
                    fetchPayload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NO PENCATATAN RETUR',
                    fetchPayload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NO PENCATATAN RETUR',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}

export const savePencatatanRetur = (data,token,setOpenSnackBar,history)=>(dispatch)=>{
    dispatch({
        type:'FETCH SAVE PENCATATAN'
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/returbarang/saveReturBarang`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'FETCH SUCCESSED SAVE PENCATATAN RETUR',
                payload:ResponseAPI.message
            })
            setOpenSnackBar(true);
            setTimeout(()=>{
                history.push('/returbarang');
                dispatch({
                    type:'SET EMPTY FORM PENCATATAN RETUR'
                })
            },2000)
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FETCH FAILED SAVE PENCATATAN RETUR',
                        Payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FETCH FAILED SAVE PENCATATAN RETUR',
                        Payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FETCH FAILED SAVE PENCATATAN RETUR',
                        Payload:'Disconnect from server'
                })
            }
        })
    },2000)
}
export const getDetailsService = (data) =>(dispatch) =>{
    dispatch({
        type:'GET DATA SERVICE',
        payload:data
    })
}

export const finishService = (idbarang,data,token,setOpenSnackBar,handleCloseServiceDialog) => (dispatch)=>{
    dispatch({
        type:'FETCH SAVE PENCATATAN'
    })
    setTimeout(()=>{
        axios.patch(`${apiUrl.url}v1/suryaharapan/returbarang/${idbarang}`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SUCCESSED EDIT SERVICE',
                payload:ResponseAPI.message
            })
            setOpenSnackBar(true);
            handleCloseServiceDialog();
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED EDIT SERVICE',
                        Payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED EDIT SERVICE',
                        Payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED EDIT SERVICE',
                        Payload:'Disconnect from server'
                })
            }
        })
    },2000)
}

export const forwardReturToSupplier = (data) =>(dispatch)=>{
    dispatch({
        type:'SET FORM EDIT RETUR',
        payload:data
    })
}