import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const SnackBarComp = (status) => {
    const {statusAdd,message} = useSelector(state => state.pembelianReducers);
    const [open,setOpen] = useState(true);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    useEffect(() => {
          setOpen(true);
        },
        [status]
      );
    return (
        <Snackbar open={open} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
            {statusAdd === true ?
                <Alert severity="success">{message}</Alert>:
                <Alert severity="error">{message}</Alert>
            }
        </Snackbar>
    )
}

export default SnackBarComp
