import { makeStyles,withStyles,TableCell,TableRow,Paper,TableContainer,Table,TableHead, TableBody, Typography, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Snackbar } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '../../../component/molecules';
import Cookies from 'js-cookie';
import { dataBestSelling, emptyDataSales, emptyStokMessage, setDataViewFilterGudang, setDataViewFilterToko, setSearchViewFilterGudang, setSearchViewFilterToko } from '../../../config/redux/action';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ViewMinStok = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            display:'flex',
            // flexDirection:'column',
            textAlign:'center'
        },
        content:{
          justifyContent:'center',
          [theme.breakpoints.up('md')]:{
              display:'flex',
              width:'100%',
              marginRight:'30px',
          },
          [theme.breakpoints.down('md')]:{
            display:'flex',
            flexDirection:'column',
            width:'100%',
            alignItems:'center',
          },
      },
      tableContainer:{
        maxHeight:'520px',
        [theme.breakpoints.up('md')]:{
            height:'365px',
        },
    },
      tableContent1:{
          marginRight:'30px'
      },paper1:{
        width:'600px'
      },paper2:{
        width:'400px'
      },
      search:{
        marginBottom:'10px',
        [theme.breakpoints.up('md')]:{
            marginRight:'5px',
        },
      },
      tableWapper:{
        display:'flex',
        flexDirection:'column'
      },
      radio:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center'
      }
    }))
    const SytledTableCell = withStyles((theme)=>({
      head: {
          backgroundColor: 'grey',
          color: theme.palette.common.black,
        },
        body: {
          fontSize: 14,
          // border:'1px solid black'
        },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);
    const classes = useStyle();
    const {dataStokTokoFilter,dataStokGudangFilter,searchFilterToko,searchFilterGudang}=useSelector(state=>state.filterBarangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const {fetchMessage,dataSales} = useSelector(state=>state.stokbarangReducers);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const dispatch=useDispatch();
    const [token,setToken] = useState(data.token);
    const [statusView,setStatusView]=useState('MinimalStok');
    const [valueSales,SetValueSales]=useState(20);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackBar = () => {
      setOpenSnackbar(false);
      setTimeout(()=>{
          dispatch(emptyStokMessage(""));
      },200)
    };
    useEffect(()=>{
      dispatch(setDataViewFilterToko(searchFilterToko,token));
      dispatch(setDataViewFilterGudang(searchFilterGudang,token));
      if(dataSales.length<1){
        const data={
          'chooseday':valueSales
        }
        dispatch(dataBestSelling(data,token,setOpenSnackbar))
      }
    },[dispatch,searchFilterGudang,searchFilterToko,token,dataSales,valueSales]);
    
    const tableDetailsStokToko=(
      <TableBody>
            {dataStokTokoFilter.map((dataStokTokoFilters,i)=>(
                <StyledTableRow key={i}>
                    <SytledTableCell align="center">{dataStokTokoFilters.idbarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokTokoFilters.namabarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokTokoFilters.stoktoko}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokTokoFilters.stokgudang}</SytledTableCell>
                </StyledTableRow> 
                ))}
        </TableBody>
    )
    const handleSearchFilterToko = (e)=>{
      dispatch(setSearchViewFilterToko(e.target.value));
    }
    const tableStokToko = (
      <div className={classes.tableContent1}>
        <div className={classes.tableWapper}>
          <div style={{display:'flex'}}>
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
              <Typography variant='h6' style={{textAlign:"center",padding:'10px'}}>Minimal Stok</Typography>
            </div>
            <div style={{width:'60%'}}>
                <Search className={classes.search} label="Cari Barang" name="caribarangtoko" id="caribarangtoko" 
                  onChange={(e)=>handleSearchFilterToko(e)}
                />
            </div>
          </div>
          <Paper className={classes.paper1}>
              <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader aria-label="table jenis barang" size="small">
                      <TableHead>
                          <TableRow>
                              <SytledTableCell width='20%' align="center">ID Barang</SytledTableCell>
                              <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                              <SytledTableCell width='20%' align="center">Stok Toko</SytledTableCell>
                              <SytledTableCell width='35%' align="center">Stok Gudang</SytledTableCell>
                          </TableRow>
                      </TableHead> 
                      {tableDetailsStokToko}
                </Table> 
            </TableContainer>
          </Paper>
        </div>
      </div>
    )
    const tableDetailsStokGudang=(
      <TableBody>
            {dataStokGudangFilter.map((dataStokGudangFilter,i)=>(
                <StyledTableRow key={i}>
                    <SytledTableCell align="center">{dataStokGudangFilter.idbarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokGudangFilter.namabarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokGudangFilter.sisastok}</SytledTableCell>
                </StyledTableRow> 
                ))}
        </TableBody>
    )
    const handleSearchFilterGudang = (e)=>{
      dispatch(setSearchViewFilterGudang(e.target.value));
    }
    const tableStokGudang = (
      <div className={classes.tableContent1}>
      <div className={classes.tableWapper}>
      <div style={{display:'flex'}}>
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
          <Typography variant='h6' style={{textAlign:"center",padding:'10px'}}>Minimal Stok Gudang
          </Typography>
        </div>
        <div style={{width:'60%'}}>
        <Search className={classes.search} label="Cari Barang" name="caribaranggudang" id="caribaranggudang" 
        onChange={(e)=>handleSearchFilterGudang(e)}
        />
        </div>
      </div>
        <Paper className={classes.paper2}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="table jenis barang" size="small">
                    <TableHead>
                        <TableRow>
                            <SytledTableCell width='25%' align="center">ID Barang</SytledTableCell>
                            <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                            <SytledTableCell width='35%' align="center">Stok Gudang</SytledTableCell>
                        </TableRow>
                    </TableHead> 
                    {tableDetailsStokGudang}
              </Table> 
          </TableContainer>
        </Paper>
      </div>
      </div>
  )
  const onChangeValueSales = (e)=>{
    SetValueSales(e.target.value);
    const data={
      'chooseday':e.target.value
    }
    dispatch(dataBestSelling(data,token,setOpenSnackbar));
  }
  const bestSelling = (
    <div className={classes.tableContent1}>
    <div className={classes.tableWapper}>
    <div style={{display:'flex'}}>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
        <Typography variant='h6' style={{textAlign:"center",padding:'10px'}}>30 Barang Terlaris dalam
        </Typography>
        <Select
        labelId='sales'
        id='sales'
        value={valueSales}
        onChange={(e)=>onChangeValueSales(e)}
        >
         <MenuItem value={5}>5 Hari</MenuItem>
         <MenuItem value={10}>10 Hari</MenuItem>
         <MenuItem value={15}>15 Hari</MenuItem>
         <MenuItem value={20}>20 Hari</MenuItem>
         <MenuItem value={30}>30 Hari</MenuItem>
         <MenuItem value={45}>45 Hari</MenuItem>
         <MenuItem value={60}>60 Hari</MenuItem>
        </Select>
     </div>
    </div>
      <Paper className={classes.paper2}>
          <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="table jenis barang" size="small">
                  <TableHead>
                      <TableRow>
                          <SytledTableCell width='80%' align="center">Nama Barang</SytledTableCell>
                          <SytledTableCell width='20%' align="center">Jumlah</SytledTableCell>
                      </TableRow>
                  </TableHead> 
                  <TableBody>
                    {dataSales.map((dataS)=>(
                        <StyledTableRow key={dataS.idbarang}>
                            <SytledTableCell align="center">{dataS.namabarang}</SytledTableCell>
                            <SytledTableCell align="center">{dataS.jumlah}</SytledTableCell>
                        </StyledTableRow> 
                        ))}
                </TableBody>
            </Table> 
        </TableContainer>
      </Paper>
    </div>
    </div>
)
const minimalStokView=(
  <Fragment>
    {tableStokToko}
    {dataStokGudangFilter.length > 0 ? tableStokGudang : null}
  </Fragment>
)
const bestSellingView=(
  <Fragment>
  {bestSelling}
  </Fragment>
)
const handleChangeRadio = (e) =>{
  setStatusView(e.target.value);
  if(e.target.value==="MinimalStok"){
    dispatch(emptyDataSales());
  }
}
  return (
    <div>
      <RadioGroup className={classes.radio} aria-label="typeData" name="typeData" 
        value={statusView} onChange={handleChangeRadio}
        >
            <FormControlLabel value="MinimalStok" control={<Radio />} label="Minimal Stok" />
            <FormControlLabel value="PenjualanTerlaris" control={<Radio />} label="Penjualan Terlaris" />
        </RadioGroup>
      <div className={classes.content}>
      {statusView==="MinimalStok"?minimalStokView:bestSellingView}
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={fetchMessage === "Berhasil menampilkan data penjualan terlaris" ? "success" : "error"}
                >
                    {fetchMessage}
                    </Alert>
            </Snackbar>
    </div>
  )
}

export default ViewMinStok