const initialStatePengeluaran={
    DataPengeluaran:[],
    dataPengeluaranDetails:[],
    isLoading:false,
    pengeluaranMessage:'',
    pengeluaranField:{},
    formDetails:{}
}

const pengeluaranGudangReducers = (state=initialStatePengeluaran,action)=>{
    switch(action.type){
        case 'FETCH DATA PENGELUARAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESS FETCH PENGELUARAN':{
            return{
                ...state,
                DataPengeluaran:action.payload,
                isLoading:false
            }
        }case 'FAILED FETCH DATA PENGELUARAN':{
            return{
                ...state,
                isLoading:false,
                pengeluaranMessage:action.payload
            }
        }
        case 'FETCH DATA PENGELUARAN DETAILS':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESS FETCH PENGELUARAN DETAILS':{
            return{
                ...state,
                dataPengeluaranDetails:action.payload,
                isLoading:false
            }
        }case 'FAILED FETCH DATA PENGELUARAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                pengeluaranMessage:action.payload
            }
        }
        case 'FILL FORM DATA PENGELUARAN GUDANG':{
            return{
                ...state,
                DataPengeluaran:[]
            }
        }
        case 'SET FIELD PENGELUARAN':{
            return{
                ...state,
                pengeluaranField:action.payload
            }
        }
        case 'SET FORM DETAILS':{
            return{
                ...state,
                formDetails:action.payload
            }
        }
        case 'SET PENGELUARAN MESSAGE':{
            return{
                ...state,
                pengeluaranMessage:action.payload
            }
        }
        case 'SET LOADING PATCH PENGELUARAN':{
            return{
                ...state,
                isloading:false
            }
        }
        case 'SUCCESS PATCH PENGELUARAN':{
            return{
                ...state,
                pengeluaranMessage:action.payload,
                isLoading:false
            }
        }
        case 'FAILED PATCH PENGELUARAN':{
            return{
                ...state,
                pengeluaranMessage:action.payload,
                isLoading:false
            }
        }
        case 'SET LOADING DELETE PENGELUARAN':{
            return{
                ...state,
                isloading:false
            }
        }
        case 'SUCCESS DELETE PENGELUARAN':{
            return{
                ...state,
                pengeluaranMessage:action.payload,
                isLoading:false
            }
        }
        case 'FAILED DELETE PENGELUARAN':{
            return{
                ...state,
                pengeluaranMessage:action.payload,
                isLoading:false
            }
        }
        default:
            return state
        }
    }
export default pengeluaranGudangReducers;