import { Button, Fab, makeStyles } from '@material-ui/core';
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { PrintPengeluaran } from '../../component/organism';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const PrintPage = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'95%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const history = useHistory();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
    });
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <PrintPengeluaran ref={componentRef}/>
                <Button
                variant="contained"
                color="primary"
                 onClick={handlePrint}>Print Pengeluaran Barang</Button>    
            </div> 
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.push('/pengeluarangudang')}>
                <ArrowBackIcon/>
            </Fab>
        </div>
    )
}

export default PrintPage
