import React, {useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Button, Dialog, DialogContent, DialogTitle, makeStyles, Snackbar } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Divider } from '@material-ui/core';
import { InputFiled } from '../../molecules';
import { editStokGudang, emptyGudangMessage } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const KoreksiStokGudang = ({handleCloseKoreksiStokGudang,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {formStokGudang,editStokMessage} = useSelector(state=>state.stokGudangReducers);
    const {idstokgudang,idbarang,namabarang,variant,stokmasuk,stokkeluar,sisastok} = formStokGudang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'300px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(emptyGudangMessage());
        },500)
      };
    const validationSchema = yup.object().shape({
        stokmasuk:yup.number().required("Stok masuk tidak boleh kosong").min(0,"Stok masuk tidak boleh kurang dari 0"),
        stokkeluar:yup.number().required("Stok keluar tidak boleh kosong").min(0,"Stok keluar tidak boleh kurang dari 0"),
        sisastok:yup.number().min(0,"Sisa stok tidak boleh kurang dari 0"),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang || '',
            namabarang:namabarang || '',
            variant:variant || '',
            stokmasuk:stokmasuk || 0,
            stokkeluar:stokkeluar || 0,
            sisastok:sisastok || 0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('stokmasuk',values.stokmasuk);
            data.append('stokkeluar',values.stokkeluar);
            data.append('sisastok',values.sisastok);
            dispatch(editStokGudang(idstokgudang,data,token));
            setOpenSnackbar(true);
            handleCloseKoreksiStokGudang();
        }
    })
    const stokMasukHandleChange = (e) =>{
        if(e.target.value !== ''){
            let tempSisaStok = parseInt(e.target.value) - formik.values.stokkeluar;
            formik.setFieldValue('sisastok',tempSisaStok);
        }else{
            formik.setFieldValue('stokmasuk',0);
            let tempSisaStok = formik.values.stokmasuk - formik.values.stokkeluar;
            formik.setFieldValue('sisastok',tempSisaStok);
        }
    }
    const stokKeluarHandleChange = (e) =>{
        if(e.target.value !== ''){
            let tempSisaStok = formik.values.stokmasuk - parseInt(e.target.value);
            formik.setFieldValue('sisastok',tempSisaStok);
        }else{
            formik.setFieldValue('stokkeluar',0);
            let tempSisaStok = formik.values.stokmasuk - formik.values.stokkeluar;
            formik.setFieldValue('sisastok',tempSisaStok);
        }
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="koreksi-stokgudang-dialog">Koreksi Stok Gudang</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID Barang" 
                disabled={true} 
                value={formik.values.idbarang}/>
                <InputFiled 
                id="namabarang" 
                name="namabarang" 
                label="Nama Barang" 
                disabled={true} 
                value={formik.values.namabarang}/>
                <InputFiled 
                id="variant" 
                name="variant" 
                label="Varian" 
                disabled={true} 
                value={formik.values.variant}/>
                <InputFiled 
                id="stokmasuk" 
                name="stokmasuk" 
                label="Stok Masuk"
                value={formik.values.stokmasuk}
                error={formik.touched.stokmasuk && Boolean(formik.errors.stokmasuk)}
                helperText={formik.touched.stokmasuk && formik.errors.stokmasuk}
                onChange={formik.handleChange}
                onKeyUp={stokMasukHandleChange}
                />
                <InputFiled 
                id="stokkeluar" 
                name="stokkeluar" 
                label="Stok Keluar"
                value={formik.values.stokkeluar}
                error={formik.touched.stokkeluar && Boolean(formik.errors.stokkeluar)}
                helperText={formik.touched.stokkeluar && formik.errors.stokkeluar}
                onChange={formik.handleChange}
                onKeyUp={stokKeluarHandleChange}
                />
                <InputFiled 
                id="sisastok" 
                name="sisastok" 
                label="Sisa Stok"
                value={formik.values.sisastok}
                error={formik.touched.sisastok && Boolean(formik.errors.sisastok)}
                helperText={formik.touched.sisastok && formik.errors.sisastok}
                disabled={true}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button variant="contained" color="secondary" onClick={handleCloseKoreksiStokGudang}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={editStokMessage==='Perubahan stok gudang berhasil disimpan' ? "success" : "error"}
                onClick={handleCloseSnackBar}>
                    {editStokMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default KoreksiStokGudang
