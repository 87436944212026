import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchPenyimpanan = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH TEXT PENYIMPANAN',
        payload :search
    })
}
export const getAllPenyimpanan = (search,page,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA PENYIMPANAN',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/penyimpanan/?search=${search}&page=${page}&perPage=50`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(res=>{
        const ResponseAPI = res.data;
       dispatch({
           type:'UPDATE DATA PENYIMPANAN', 
           payload:ResponseAPI.data,
        })
        dispatch({
            type:'UPDATE PAGE PENYIMPANAN',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_Penyimpanan/ResponseAPI.per_page)
            }
        })
    })
}
export const setNewIDPenyimpanan = (token,setOpen,setTempID) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA PENYIMPANAN',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/penyimpanan/getID/`,
    {
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
           type:'UPDATE NEW ID PENYIMPANAN', 
           payload:ResponseAPI.data
        })
        setOpen(true);
        setTempID(ResponseAPI.data);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NEW ID PENYIMPANAN',
                    Payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NEW ID PENYIMPANAN',
                    Payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NEW ID PENYIMPANAN',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}

export const penyimpananPostToAPI = (data,token,setOpenSnackbar,HandleClickCloseDialog) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA PENYIMPANAN',
        payload:true,
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/penyimpanan/newPenyimpanan`,data,{
            headers:{
                'Authorization':`Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED PENYIMPANAN POST',
                payload:ResponseAPI.message,
                })
            setOpenSnackbar(true);
            HandleClickCloseDialog();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED ADD PENYIMPANAN',
                        payload:ResponseError.message,
                    })
                setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED ADD PENYIMPANAN',
                        payload:'Disconnect from server',
                })
                setOpenSnackbar(true);
            }
        })
    },2000)
}
export const emptyMessagePenyimpanan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE PENYIMPANAN',
        payload:''
    })
}

export const penyimpananPatchToApi = (data,token,setOpenSnackbar,HandleClickCloseDialog) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA PENYIMPANAN',
        payload:true
    })
    setTimeout(()=>{
        axios.patch(`${apiUrl.url}v1/suryaharapan/penyimpanan/${data.idpenyimpanan}`,data,{
            headers:{
                'Authorization':`Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED PENYIMPANAN PATCH',
                payload:ResponseAPI.message,
                })
            setOpenSnackbar(true);
            HandleClickCloseDialog();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED PATCH PENYIMPANAN',
                        payload:ResponseError.message
                    })
                setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED PATCH PENYIMPANAN',
                        payload:'Disconnect from server'
                })
                setOpenSnackbar(true);
            }
        })
    },2000);    
}
export const penyimpananDeleteToAPI = (id,token,setOpenSnackbar,HandleClickCloseDeleteDialog) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA PENYIMPANAN',
        payload:true,
    })
    setTimeout(()=>{
        axios.delete(`${apiUrl.url}v1/suryaharapan/penyimpanan/${id}`,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED PENYIMPANAN DELETE',
                payload:ResponseAPI.message,
            })
            setOpenSnackbar(true);
            HandleClickCloseDeleteDialog();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED DELETE PENYIMPANAN',
                        payload:ResponseError.message,
                    })
                    setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED DELETE PENYIMPANAN',
                        payload:'Disconnect from server',
                })
                setOpenSnackbar(true);
            }
        })
    },2000)
}
