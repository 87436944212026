const initialStateJenisBarang = {
    datajenisbarang:[],
    pageJeniBarang : {
        currentPage:'1',
        totalPage:'1'
    },
    FormJenisBarang:[],
    tempNewID:'',
    isLoadingNewID:false,
    searchJenisBarang : '',
    fetchMessageJenisBarang : '',
    isLoadingJenisBarang : false,
    isLoadingJenisBarangSave:false,
    PostJenisBarangStatus:'',
    perPage:'1',
}
const jenisbarangReducers = (state=initialStateJenisBarang,action) =>{
    switch(action.type){
        case 'FETCH DATA JENIS BARANG':{
            return{
                ...state,
                isLoadingJenisBarang:action.payload
            }
        }
        case 'SET SEARCH JENIS BARANG':{
            return{
                ...state,
                searchJenisBarang:action.payload
            }
        }
        case 'UPDATE PAGE JENIS BARANG':{
            return{
                ...state,
                pageJeniBarang:action.payload
            }
        }
        case 'UPDATE DATA JENIS BARANG':{
            return{
                ...state,
                isLoadingJenisBarang:false,
                datajenisbarang:action.payload,
                fetchMessageJenisBarang:action.messagePayload
            }
        }
        case 'FAILED FETCH JENIS BARANG':{
            return{
                ...state,
                isLoadingJenisBarang:false,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'FETCH NEW ID':{
            return{
                ...state,
                isLoadingNewID:action.payload
            }
        }
        case 'UPDATE NEW ID':{
            return{
                ...state,
                isLoadingNewID:false,
                tempNewID:action.payload,
                fetchMessageJenisBarang:action.fetchPayload
            }
        }
        case 'FAILED GET NEW ID':{
            return{
                ...state,
                isLoadingNewID:false,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'FECTH JENIS BARANG POST':{
            return{
                ...state,
                isLoadingJenisBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED JENIS BARANG POST':{
            return{
                ...state,
                isLoadingJenisBarangSave:false,
                PostJenisBarangStatus:action.PostPayload,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'SET FAILED ADD JENIS BARANG':{
            return{
                ...state,
                PostJenisBarangStatus:action.PostPayload,
                isLoadingJenisBarangSave:false,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'SET EDIT JENIS BARANG':{
            return{
                ...state,
                FormJenisBarang:action.payload
            }
        }
        case 'FECTH JENIS BARANG PATCH':{
            return{
                ...state,
                isLoadingJenisBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED JENIS BARANG PATCH':{
            return{
                ...state,
                isLoadingJenisBarangSave:false,
                PostJenisBarangStatus:action.PostPayload,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'SET FAILED EDIT JENIS BARANG':{
            return{
                ...state,
                PostJenisBarangStatus:action.PostPayload,
                isLoadingJenisBarangSave:false,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'SET FETCH STATUS':{
            return{
                ...state,
                PostJenisBarangStatus:action.payload
            }
        }
        case 'FECTH JENIS BARANG DELETE':{
            return{
                ...state,
                isLoadingJenisBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED JENIS BARANG DELETE':{
            return{
                ...state,
                isLoadingJenisBarangSave:false,
                PostJenisBarangStatus:action.PostPayload,
                fetchMessageJenisBarang:action.payload
            }
        }
        case 'SET FAILED EDIT JENIS DELETE':{
            return{
                ...state,
                PostJenisBarangStatus:action.PostPayload,
                isLoadingJenisBarangSave:false,
                fetchMessageJenisBarang:action.payload
            }
        }
        default:
            return state
    }
}
export default jenisbarangReducers