import axios from 'axios';
import { apiUrl } from '../..';

export const setSearch = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH TEXT',
        payload :search
    })
}
export const setDataBarang = (search,page,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/?search=${search}&page=${page}&perPage=50`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA BARANG', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed'
        })
        dispatch({
            type:'UPDATE PAGE BARANG',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
                dispatch({
                   type:'FAILED FETCH BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setNewIDBarang = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH NEW ID BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/getID/`,
    {
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
           type:'UPDATE NEW ID BARANG', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed',
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NEW ID BARANG',
                    Payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NEW ID BARANG',
                    Payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NEW ID BARANG',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}
export const BarangPostToAPI = (data,token,setOpenSnackbar,HandleClickCloseDialog,resetForm) => (dispatch) =>{
    dispatch({
        type:'FECTH BARANG POST',
        payload:true,
    })
    setTimeout(() => {
        axios.post(`${apiUrl.url}v1/suryaharapan/barang/newBarang`,data,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'content-type':'application/x-www-form-urlencoded'
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED BARANG POST',
                payload:ResponseAPI.message,
                PostPayload:'Successed',
                })
                setOpenSnackbar(true);
                HandleClickCloseDialog();
                resetForm();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED ADD BARANG',
                        payload:ResponseError.message,
                        PostPayload:'Failed',
                    })
                setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED ADD BARANG',
                        payload:'Disconnect from server',
                        PostPayload:'Failed',
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
}
export const setEditBarang = (idbarang,namabarang,idjenisbarang,satuan,varian,idpenyimpanan,jmlh_min) => (dispatch) =>{
    dispatch({
        type:'SET EDIT BARANG',
        payload:{
            idbarang,
            namabarang,
            idjenisbarang,
            satuan,
            varian,
            idpenyimpanan,
            jmlh_min
        }
    })
}
export const setAddBarangToCart = (idbarang,token) => (dispatch) =>{
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/details/${idbarang}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    }).then(res=>{
        const ResponseApi = res.data;
        dispatch({
            type:'SET ADD BARANG TO CART',
            payload:ResponseApi.data
        })
    })
}
export const setFillReturFromStok =()=>(dispatch)=>{
    dispatch({
        type:'SET FILL BARANG TO CART',
        payload:[]
    })
}
export const setFillFormBarang =()=>(dispatch)=>{
    dispatch({
        type:'SET FILL BARANG TO CART',
        payload:[]
    })
}
export const BarangPutToAPI = (data,idbarang,token,setOpenSnackbar,HandleClickCloseEditDialog) => (dispatch) =>{
    dispatch({
        type:'FECTH BARANG PATCH',
        payload:true,
    })
    setTimeout(() => {
        axios.patch(`${apiUrl.url}v1/suryaharapan/barang/${idbarang}`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED BARANG PATCH',
                payload:ResponseAPI.message,
                PostPayload:'Successed',
                })
            setOpenSnackbar(true);
            HandleClickCloseEditDialog();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED EDIT BARANG',
                        payload:ResponseError.message,
                        PostPayload:'Failed',
                    })
                    setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED EDIT BARANG',
                        payload:'Disconnect from server',
                        PostPayload:'Failed',
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
    
}
export const setFetchStatus = (status) =>(dispatch) =>{
    dispatch({
        type:'SET FETCH STATUS BARANG',
        payload:status
    })
}
export const BarangDeleteToAPI = (id,token,setOpenSnackbar,HandleClickCloseDeleteDialog) => (dispatch) =>{
    dispatch({
        type:'FECTH BARANG DELETE',
        payload:true,
    })
    setTimeout(() => {
        axios.delete(`${apiUrl.url}v1/suryaharapan/barang/${id}`,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'content-type':'application/x-www-form-urlencoded',
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED BARANG DELETE',
                payload:ResponseAPI.message,
                PostPayload:'Successed',
                })
                setOpenSnackbar(true);
                HandleClickCloseDeleteDialog();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED DELETE BARANG',
                        payload:ResponseError.message,
                        PostPayload:'Failed',
                    })
                    setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED DELETE BARANG',
                        payload:'Disconnect from server',
                        PostPayload:'Failed',
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
}
export const emptyMessage =()=>(dispatch)=>{
    dispatch({
        type:'SET EMPTY MESSAGE',
        payload:""
    })
}
export const emptyIDBarang =()=>(dispatch)=>{
    dispatch({
        type:'SET EMPTY NEWIDBARANG',
        payload:""
    })
}

export const getDetailsBarang = (idbarang,token,setOpenCart)=>(dispatch)=>{
    axios.get(`${apiUrl.url}v1/suryaharapan/barang/penjualan/${idbarang}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
        }
    }).then((res)=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'GET DETAILS BARANG',
            payload:ResponseAPI.data
        })
        setOpenCart(true);
    })
}
