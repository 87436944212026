const initialStateStokRetur={
    dataStokRetur : [],
    pageStokRetur : {
        currentPage:'1',
        totalPage:'1'
    },
    isLoadingStokRetur:false,
    searchStokRetur:'',
    messageStokRetur:''
}

const stokReturReducers = (state=initialStateStokRetur,action)=>{
    switch(action.type){
        case 'FETCH DATA STOK RETUR':{
            return{
                ...state,
                isLoadingStokRetur:action.payload,
            }
        }
        case 'SET SEARCH STOK RETUR':{
            return{
                ...state,
                searchStokRetur : action.payload
            }
        }
        case 'UPDATE PAGE STOK RETUR':{
            return {
                ...state,
                pageStokRetur:action.payload
            }
        }
        case 'UPDATE DATA STOK RETUR':{
            return{
                ...state,
                isLoadingStokRetur:false,
                dataStokRetur:action.payload
            }
        }
        case 'FAILED FETCH STOK RETUR':{
            return{
                ...state,
                isLoadingStokRetur:false,
                messageStokRetur:action.fetchPayload
            }
        }
        default:
            return state
    }
}

export default stokReturReducers