import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { addOrderBarang, setEmptyMessageOrder } from '../../../config/redux/action';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { format } from 'date-fns';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const NewOrderForm = ({onCloseDialogNewOrder,...rest}) => {
    const useStyle = makeStyles(theme=>({
        titleDialog:{
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%'
            },
            display:'flex',
            justifyContent:'center'
        },input:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'150px',
                marginLeft:'5px',
            },
        },
        inputArea:{
            display:'flex',
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%',
            },
            justifyContent:'center',
            
        },
        buttonArea:{
            display:'flex',
            marginTop:'10px',
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%',
            },
            justifyContent:'space-between',
            
        }
    }))
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const {data} = useSelector(state => state.loginReducers);
    const {isLoadingOrder,messageOrder,dataOrder} = useSelector(state=>state.orderReducers);
    const {idbarang,namabarang,judulsales,qtysales,qtyforecast,avg30,stokbarang} = dataOrder;
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setEmptyMessageOrder());
        },200)
    };
    const validationSchema = yup.object().shape({
        jumlah:yup.number().min(1,'Jumlah barang tidak boleh kurang dari 1')
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            jumlah:0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'idbarang':idbarang,
                'tanggal':format(new Date(),'yyyy-MM-dd'),
                'qty':values.jumlah,
                'qtyfulfilled':0
            }
            dispatch(addOrderBarang(data,token,setOpenSnackbar,onCloseDialogNewOrder,formik));
        }
    })
  return (
    <div>
      <Dialog open={false} {...rest}>
      <DialogTitle className={classes.titleDialog} id="add-barang-cart"><Typography variant='body1'>Form Penambahan Order</Typography></DialogTitle>
      <Divider/>
      <DialogContent>
          <Typography>({idbarang}) {namabarang}</Typography>
          <Box style={{width:'100%', display:'flex',flexDirection:"row",justifyContent:'center'}}>
            <Box>
                <Typography>Sales H - {judulsales} </Typography>
                <Typography>Forecast Order</Typography>
                <Typography>Avg 30 Hari</Typography>
                <Typography>Stok Barang</Typography>
            </Box>
            <Box marginLeft={2} textAlign="right">
                <Typography>{qtysales}</Typography>
                <Typography>{qtyforecast}</Typography>
                <Typography>{avg30}</Typography>
                <Typography>{stokbarang}</Typography>
            </Box>
          </Box>
          <div className={classes.inputArea}>
          <TextField className={classes.input}
            id="jumlah"
            name="jumlah"
            label="Jumlah Order"
            value={formik.values.jumlah}
            error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
            helperText={formik.touched.jumlah && formik.errors.jumlah}
            onChange={formik.handleChange}
            />
         </div>
         <div className={classes.buttonArea}>
            <Button variant='contained' color='primary' disabled={isLoadingOrder} onClick={formik.handleSubmit}>Simpan</Button>
            <Button variant='contained' color='secondary' disabled={isLoadingOrder} onClick={onCloseDialogNewOrder}>Batal</Button>
         </div>
      </DialogContent>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageOrder === "Berhasil melakukan pencatatan order" ? "success" : "error"}
                >
                    {messageOrder}
                    </Alert>
            </Snackbar>
    </div>
  )
}

export default NewOrderForm