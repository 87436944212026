import { Divider, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import Cookies from 'js-cookie';
import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '../../component/molecules'
import { addFormKoreksiGudang, emptyFormKoreksiStokGudang, setDataStokGudang, setSearchStokGudang } from '../../config/redux/action';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteStokGudang, KoreksiStokGudang } from '../../component/organism';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const StokGudang = () => {
    const {dataStokGudang,pageGudang,searchGudang} = useSelector(state => state.stokGudangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataStokGudang(searchGudang,counter,token));
    },[searchGudang,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageGudang.totalPage ? pageGudang.totalPage : counter + 1);
    }
    const focus = () =>{
        setCounter(1);
        dispatch(setDataStokGudang(searchGudang,counter,token));
    }
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginLeft:'5%',
            marginTop:'20px',
            maxWidth:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'5px',
        },
    }))
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const [OpenKoreksi,setOpenKoreksi]=useState(false);
    const handleOpenKoreksiStokGudang = (idstokgudang,idbarang,namabarang,variant,stokmasuk,stokkeluar,sisastok) =>{
        dispatch(addFormKoreksiGudang(idstokgudang,idbarang,namabarang,variant,stokmasuk,stokkeluar,sisastok));
        setOpenKoreksi(true);
    }
    const handleCloseKoreksiStokGudang = () =>{
        dispatch(emptyFormKoreksiStokGudang());
        setOpenKoreksi(false);
        dispatch(setDataStokGudang(searchGudang,counter,token));
    }
    const [tempIdStokgudang,setTempIdStokgudang]=useState('');
    const [varian,setVarian]=useState('');
    const [namabarang,setNamabarang]=useState('');
    const handleDeleteStokGudang = (idstokgudang,varian,namabarang) =>{
        setOpenDeleteGudang(true);
        setTempIdStokgudang(idstokgudang);
        setVarian(varian);
        setNamabarang(namabarang);
    }
    const [openDeleteGudang,setOpenDeleteGudang]=useState(false);
    const handleCloseDeleteGudang = () =>{
        setOpenDeleteGudang(false);
        setTempIdStokgudang('');
        setVarian('');
        setNamabarang('');
        dispatch(setDataStokGudang(searchGudang,counter,token));
    }
    return (
        <div className={classes.root}>
            <div>
                <Typography className={classes.title} variant="h5">Stok Gudang</Typography>
                <Divider/>
                <Search label="Cari Barang" name="cari-barang" id="cari-barang" onFocus={focus} onChange={(e)=>dispatch(setSearchStokGudang(e.target.value))}/>
                <div className={classes.table}>
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='25%' align="center">Nama Barang</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Sisa Stok</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Stok Masuk</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Stok Keluar</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Jenis Barang</SytledTableCell>
                                    <SytledTableCell width='10%' align="center"></SytledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataStokGudang.map((dataStokGudangs=>(
                                    <StyledTableRow key={dataStokGudangs.idstokgudang}>
                                        <SytledTableCell align="center">{dataStokGudangs.namabarang}</SytledTableCell>
                                        <SytledTableCell align="center">{dataStokGudangs.variant}</SytledTableCell>
                                        <SytledTableCell align="center"><b>{dataStokGudangs.sisastok}</b></SytledTableCell>
                                        <SytledTableCell align="center">{dataStokGudangs.stokmasuk}</SytledTableCell>
                                        <SytledTableCell align="center">{dataStokGudangs.stokkeluar}</SytledTableCell>
                                        <SytledTableCell align="center">{dataStokGudangs.jenisbarang}</SytledTableCell>
                                        <SytledTableCell align="center">
                                            <Tooltip title="Koreksi stok">
                                                <IconButton size='small' 
                                                    onClick={()=>{handleOpenKoreksiStokGudang(dataStokGudangs.idstokgudang,
                                                        dataStokGudangs.idbarang,
                                                        dataStokGudangs.namabarang,
                                                        dataStokGudangs.variant,
                                                        dataStokGudangs.stokmasuk,
                                                        dataStokGudangs.stokkeluar,
                                                        dataStokGudangs.sisastok)}}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <IconButton size='small' 
                                                disabled={dataStokGudangs.sisastok > 0 ? true : false}
                                                onClick={()=>{handleDeleteStokGudang(dataStokGudangs.idstokgudang,
                                                    dataStokGudangs.variant,
                                                    dataStokGudangs.namabarang,)}}
                                            >
                                                <Tooltip title="Hapus stok">
                                                    <DeleteForeverIcon/>
                                                </Tooltip>
                                            </IconButton>
                                        
                                            
                                        </SytledTableCell>
                                    </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        <div className={classes.page}>
                        <Tooltip title="Prev">
                            <IconButton size='small' onClick={handlePrev}>
                                <NavigateBefore/>
                            </IconButton>
                        </Tooltip>
                        <Typography className={classes.textPage}>{pageGudang.currentPage} / {pageGudang.totalPage >= 1 ? pageGudang.totalPage : pageGudang.currentPage}</Typography>
                        <Tooltip title="Next">
                            <IconButton size='small' onClick={handleNext}>
                                <NavigateNext/>
                            </IconButton>
                        </Tooltip> 
                        </div>
                </Paper>
                </div>
                <KoreksiStokGudang open={OpenKoreksi} handleCloseKoreksiStokGudang={handleCloseKoreksiStokGudang} aria-labelledby="koreksi-stokgudang-dialog"/>
                <DeleteStokGudang
                open={openDeleteGudang} 
                aria-labelledby="delete-stokgudang-dialog" 
                HandleClickCloseDeleteDialog={handleCloseDeleteGudang}
                idstokgudang={tempIdStokgudang}
                varian={varian}
                namabarang={namabarang}
                />
            </div>    
        </div>
    )
}

export default StokGudang
