const initialValueRiwayatPembelian ={
    dataRiwayatPembelian : [],
    dataRiwayatPembelianDetails:[],
    tempRiwayatTransferAll:[],
    isLoadingRiwayatPembelian:false,
    pageRiwayatPembelian : {
        currentPage:'1',
        totalPage:'1'
    },
    messageRiwayatPembelian:'',
    totalPembelian:'',
    tempFakturForTransfer:''
}

const riwayatPembelianReducers = (state=initialValueRiwayatPembelian,action)=>{
    switch (action.type) {
        case 'FETCH RIWAYAT PEMBELIAN':{
            return{
                ...state,
                isLoadingRiwayatPembelian:true
            }
        }
        case 'UPDATE DATA RIWAYAT PEMBELIAN':{
            return{
                ...state,
                isLoadingRiwayatPembelian:false,
                dataRiwayatPembelian:action.payload
            }
        }
        case 'UPDATE PAGE RIWAYAT PEMBELIAN':{
            return {
                ...state,
                pageRiwayatPembelian:action.payload
            }
        }
        case 'SUCCESSED GET DATA DETAILS PEMBELIAN':{
            return{
                ...state,
                dataRiwayatPembelianDetails:action.payload,
                totalPembelian:action.totalPembelian
            }
        }
        case 'FAILED RIWAYAT PEMBELIAN':{
            return{
                ...state,
                isLoadingRiwayatPembelian:false,
                messageRiwayatPembelian:action.payload
            }
        }
        case 'EMPTY RIWAYAT PEMBELIAN':{
            return{
                ...state,
                dataRiwayatPembelian:[]
            }
        }
        case 'EMPTY RIWAYAT PEMBELIAN DETAILS':{
            return{
                ...state,
                dataRiwayatPembelianDetails:[],
                totalPembelian:''
            }
        }
        case 'EMPTY MESSAGE PEMBELIAN DETAILS':{
            return{
                ...state,
                messageRiwayatPembelian:""
            }
        }
        case 'TEMP RIWAYAT PEMBELIAN DETAILS':{
            return{
                ...state,
                tempRiwayatTransferAll:[...state.tempRiwayatTransferAll,action.payload]
            }
        }
        case 'SET EMPTY RIWAYAT PEMBELIAN DETAILS':{
            return{
                ...state,
                tempRiwayatTransferAll:[]
            }
        }
        case 'SET TEMP FAKTUR FOR TRANSFER':{
            return{
                ...state,
                tempFakturForTransfer:action.payload
            }
        }
        default:
            return state;
    }
}

export default riwayatPembelianReducers;