import { FormControlLabel, IconButton, makeStyles, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { Search } from '../../component/molecules';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setDataBarang, setSearch } from '../../config/redux/action/barangAction';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { setDataRetur, setFillRetur, setReturFromRetur, setReturFromStok, setSearchRetur } from '../../config/redux/action/returAction';
import AddCartRetur from '../../component/organism/AddCartRetur';

const DataRetur = () => {
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'5%',
            },
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        },
        wapperRadio:{
            display:'flex',
            marginLeft:'5%',
            width:'90%',
            maxWidth:'800px',
            flexDirection:'row',
        },
        radio:{
            width:"600px",
            display:'flex',
            flexDirection:'row',
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'580px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();


    //function for data
    const {dataBarang,pageBarang,search} = useSelector(state => state.barangReducers);
    const {dataRetur,pageRetur,searchRetur} = useSelector(state=>state.returReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const [value,setValue]=useState("stok");
    const [openRetur,setOpenRetur] = useState(false);
    const [pageChoose,setPageChoose] = useState('');
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataBarang(search,counter,token));
        dispatch(setDataRetur(searchRetur,counter,token));
    },[search,searchRetur,counter,token,dispatch])
    const focus = () =>{
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
        dispatch(setDataRetur(searchRetur,counter,token));
    }
    
    const HandleClickCloseReturStokDialog = () =>{
        setOpenRetur(false);
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
        dispatch(setFillRetur());
    }
    const HandleClickOpenReturStokDialog = (idbarang,ref) =>{
        setOpenRetur(true);
        if(ref==='Stok Toko'){
            dispatch(setReturFromStok(idbarang,ref,token));
        }else if(ref==='Stok Retur'){
            dispatch(setReturFromRetur(idbarang,ref,token));
        }
        dispatch(setSearch(''));
    }
    const handleChangeRadio =(e)=>{
        setValue(e.target.value);
        dispatch(setSearch(''));
        dispatch(setSearchRetur(''));
        if(value==='stok'){
            setPageChoose(pageBarang);
        }else{
            setPageChoose(pageRetur);
        }
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageChoose.totalPage ? pageChoose.totalPage : counter + 1);
    }
    const tabelBarang = (
        <TableBody>
        {dataBarang.map((dataBarangs=>(
            <StyledTableRow key={dataBarangs.idbarang}>
                <SytledTableCell align="center">{dataBarangs.idbarang}</SytledTableCell>
                <SytledTableCell align="center">{dataBarangs.namabarang}</SytledTableCell>
                <SytledTableCell align="center">
                        <Tooltip title="Tambah ke list">
                            <IconButton size='small' onClick={()=>HandleClickOpenReturStokDialog(dataBarangs.idbarang,'Stok Toko')}>
                                <AddShoppingCartIcon/>
                            </IconButton>
                        </Tooltip>
                    </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
    )
    const tabelRetur = (
        <TableBody>
        {dataRetur.map((dataReturs=>(
            <StyledTableRow key={dataReturs.idbarang}>
                <SytledTableCell align="center">{dataReturs.idbarang}</SytledTableCell>
                <SytledTableCell align="center">{dataReturs.namabarang}</SytledTableCell>
                <SytledTableCell align="center">
                        <Tooltip title="Tambah ke list">
                            <IconButton size='small' onClick={()=>HandleClickOpenReturStokDialog(dataReturs.idbarang,'Stok Retur')}>
                                <AddShoppingCartIcon/>
                            </IconButton>
                        </Tooltip>
                    </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
    )
    const viewPageBarang=(
        <div className={classes.page}>
            <Tooltip title="Prev">
                <IconButton size='small' onClick={handlePrev}>
                    <NavigateBefore/>
                </IconButton>
            </Tooltip>
            <Typography className={classes.textPage}>{pageBarang.currentPage} / {pageBarang.totalPage >= 1 ? pageBarang.totalPage : pageBarang.currentPage}</Typography>
            <Tooltip title="Next">
                <IconButton size='small' onClick={handleNext}>
                <   NavigateNext/>
                </IconButton>
            </Tooltip>
        </div>
    )
    const viewPageRetur=(
        <div className={classes.page}>
            <Tooltip title="Prev">
                <IconButton size='small' onClick={handlePrev}>
                    <NavigateBefore/>
                </IconButton>
            </Tooltip>
            <Typography className={classes.textPage}>{pageRetur.currentPage} / {pageRetur.totalPage >= 1 ? pageRetur.totalPage : pageRetur.currentPage}</Typography>
            <Tooltip title="Next">
                <IconButton size='small' onClick={handleNext}>
                <   NavigateNext/>
                </IconButton>
            </Tooltip>
        </div>
    )
    
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.wapperRadio}>
                    <RadioGroup className={classes.radio} aria-label="typeData" name="typeData" value={value} onChange={handleChangeRadio}>
                        <FormControlLabel value="stok" control={<Radio />} label="Stok Barang" />
                        <FormControlLabel value="retur" control={<Radio />} label="Stok Retur" />
                    </RadioGroup>
                    <Search 
                    className={classes.search} 
                    label="Cari Barang" 
                    name="cari-barang" 
                    id="cari-barang" 
                    value={value === 'stok' ? search : searchRetur}
                    onFocus={focus} 
                    onChange={value === 'stok' ? (e)=>dispatch(setSearch(e.target.value)) : (e)=>dispatch(setSearchRetur(e.target.value))}
                    />
                </div>
            <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='3%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                    {value === 'stok' ? tabelBarang : tabelRetur}
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                {value === 'stok' ? viewPageBarang : viewPageRetur}
                         </div>
                    </Paper>
                    <AddCartRetur open={openRetur} aria-labelledby="retur-barang-dialog" HandleClickCloseReturDialog={HandleClickCloseReturStokDialog}/>
                </div>
            </div>
        </div>
    )
}

export default DataRetur
