const initialStateGudang = {
    dataGudang:[],
    FormGudang:[],
    gudangMessage:'',
    isLoadingGudang:false,
    newIDGudang:'',
    searchGudang:''
}

const gudangReducers = (state=initialStateGudang,action)=>{
    switch(action.type){
        case 'FETCH DATA GUDANG':{
            return{
                ...state,
                isLoadingGudang:action.payload
            }
        }
        case 'UPDATE DATA GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                dataGudang:action.payload
            }
        }
        case 'FAILED FETCH GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'FETCH NEW ID GUDANG':{
            return{
                ...state,
                isLoadingGudang:action.payload
            }
        }
        case 'UPDATE NEW ID GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                newIDGudang:action.payload
            }
        }
        case 'FAILED GET NEW ID GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET FORM GUDANG':{
            return{
                ...state,
                FormGudang:action.payload
            }
        }
        case 'FECTH GUDANG POST':{
            return{
                ...state,
                isLoadingGudang:action.payload,
            }
        }
        case 'SET SUCESSED GUDANG POST':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET FAILED ADD GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET EDIT GUDANG':{
            return{
                ...state,
                FormGudang:action.payload
            }
        }
        case 'FECTH GUDANG EDIT':{
            return{
                ...state,
                isLoadingGudang:true,
            }
        }
        case 'SET SUCESSED GUDANG EDIT':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET FAILED EDIT GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET FETCH STATUS GUDANG':{
            return{
                ...state,
                gudangMessage:action.message
            }
        }
        case 'FECTH GUDANG DELETE':{
            return{
                ...state,
                isLoadingGudang:true,
            }
        }
        case 'SET SUCESSED GUDANG DELETE':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET FAILED DELETE GUDANG':{
            return{
                ...state,
                isLoadingGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET EMPTY FORM GUDANG':{
            return{
                ...state,
                newIDGudang:'',
                FormGudang:[],
            }
        }
        case 'SET EMPTY MESSAGE':{
            return{
                ...state,
                gudangMessage:''
            }
        }
        default:
        return state
    }
    
}
export default gudangReducers