import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputFiled, SingleDialog } from '../../molecules';
import { addListPengeluaran, setEmptyMessagePengeluaranGudang, setMessagePengeluaranGudang } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormPengeluaranGudang = ({HandleClickCloseDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        avaliableEdit:{
            display:'flex',
        },
        sisaStokWapper:{
            marginBottom:'10px',
            fontSize:'13px'
        },
    }));
    const {FormPengeluaranGudang,FormListPengeluaranGudang,pengeluaranGudangMessage}=useSelector(state=>state.pengeluaranGudangReducers);
    const {idstokgudang,idbarang,namabarang,sisastok,variant}=FormPengeluaranGudang;
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [id,setId]=useState(0);
    const [openMinDialog,setOpenMinDialog]=useState(false);
    const dispatch=useDispatch();
    useEffect(()=>{
        if(FormListPengeluaranGudang.length>0){
            let tempID = FormListPengeluaranGudang[FormListPengeluaranGudang.length-1].id;
            let temp=tempID+1
            setId(temp);
        }
    },[FormListPengeluaranGudang])
    const handleCloseSnackBar = ()=>{
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setEmptyMessagePengeluaranGudang())
        },500)
    }
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang || '',
            namabarang:namabarang || '',
            jumlah:sisastok || 0,
            variant:variant || ''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = FormListPengeluaranGudang.filter(barang =>barang.idstokgudang === idstokgudang);
            if(existBarang.length>0){
                dispatch(setMessagePengeluaranGudang('Data barang dengan variant ini telah terdapat di daftar pengeluaran'));
                setOpenSnackbar(true);
            }else{
                if(sisastok<values.jumlah){
                    setOpenMinDialog(true);
                }else{
                    insertData();
                }
            }
        }
    })

    const handleCloseMinDialog = () =>{
        setOpenMinDialog(false);
    }
    const insertData = () =>{
        dispatch(addListPengeluaran(id,idstokgudang,formik.values.idbarang,formik.values.namabarang,formik.values.jumlah,formik.values.variant));
        setOpenSnackbar(true);
        HandleClickCloseDialog();
        handleCloseMinDialog();
    }
    const classes=useStyle();
    return (
        <div>
            <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-form-pengeluaran">Form Pengeluaran</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                id="namabarang"
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <Typography className={classes.sisaStokWapper}>Sisa Stok : {sisastok}</Typography>
                <InputFiled 
                id="variant" 
                name="variant" 
                label="variant"
                value={formik.values.variant}
                disabled={true}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={pengeluaranGudangMessage === 'Berhasil menambahkan data ke daftar' ? "success" : "error"}>
                        {pengeluaranGudangMessage}
                </Alert>
            </Snackbar>
        </div>
        <SingleDialog 
            openDialog={openMinDialog}
            message="Jumlah barang kurang dari sisa stok,apakah anda yakin akan melanjutkan?" 
            okCommand="Iya" 
            handleCloseDialog={handleCloseMinDialog}
            handleClick={insertData}/>
        </div>
    )
}

export default FormPengeluaranGudang;
