const initialStateDraftPenjualan  = {
    messageDraftPenjualan:'',
    listDraftPenjualan:[],
    nofakturDraft:'',
    loadingDraftPenjualan:false,
    penjualanDraftToPrint:'',
    detailsDraftToPrint:[],
    loadDraftPenjualan:[],
    dataPenjualan:{
        "idpenjualan": "",
        "pelanggan": "",
        "tanggal": new Date(),
        "pembayaran": "Tunai",
        "jatuhtempo": new Date(),
        "lamatempo":0,
        "diskon": 0,
        "total": 0,
        "ongkir": 0,
        "status": "",
    },
    statusDraft:"Draft"
}

const draftPenjualanReducers = (state=initialStateDraftPenjualan,action)=>{
    switch(action.type){
        case 'SET MESSAGE DRAFT PENJUALAN':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        case 'ADD LIST DRAFT PENJUALAN':{
            return{
                ...state,
                listDraftPenjualan:[...state.listDraftPenjualan,action.payload],
                messageDraftPenjualan:action.messagePayload
            }
        }
        case 'GET DRAFT FROM API':{
            return{
                ...state,
                dataPenjualan:action.payload
            }
        }
        case 'ADD LIST FROM API':{
            return{
                ...state,
                listDraftPenjualan:[...state.listDraftPenjualan,action.payload],
                loadingDraftPenjualan:false
            }
        }
        case 'FETCH GET ID DRAFT PENJUALAN':{
            return{
                ...state,
                loadingDraftPenjualan:true
            }
        }
        case 'SUCCESSED GET NO FAKTUR DRAFT':{
            return{
                ...state,
                nofakturDraft:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'FAILED GET NO FAKTUR DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'GET DATA DRAFT PENJUALAN LIST':{
            return{
                ...state,
                loadingDraftPenjualan:false,
                loadDraftPenjualan:action.payload
            }
        }
        case 'EMPTY LOAD DRAFT PENJUALAN':{
            return{
                ...state,
                loadingDraftPenjualan:false,
                loadDraftPenjualan:[]
            }
        }
        case 'SUCCESSED SAVE DRAFT PENJUALAN':{
            return{
                ...state,
                messageDraftPenjualan:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'FAILED SAVE DRAFT PENJUALAN':{
            return{
                ...state,
                messageDraftPenjualan:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'EMPTY MESSAGE DRAFT PENJUALAN':{
            return{
                ...state,
                messageDraftPenjualan:''
            }
        }
        case 'RESET DRAFT PENJUALAN':{
            return{
                ...state,
                listDraftPenjualan:[],
                nofakturDraft:'',
                dataPenjualan:{
                    "idpenjualan": "",
                    "pelanggan": "",
                    "tanggal": new Date(),
                    "pembayaran": "Tunai",
                    "jatuhtempo": new Date(),
                    "lamatempo":0,
                    "diskon": 0,
                    "total": 0,
                    "ongkir": 0,
                    "status": "",
                },
            }
        }
        case 'EMPTY DRAFT PENJUALAN':{
            return{
                ...state,
                listDraftPenjualan:[]
            }
        }
        case 'GET DATA DRAFT PENJUALAN TO PRINT':{
            return{
                ...state,
                penjualanDraftToPrint:action.payload,
                detailsDraftToPrint:action.payloadDetails
            }
        }
        case 'FAILED GET DATA DRAFT TO PRINT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        case 'EMPTY DATA DRAFT PENJUALAN TO PRINT':{
            return{
                ...state,
                penjualanDraftToPrint:[],
                detailsDraftToPrint:[]
            }
        }
        case 'FETCH UPDATE STOK DRAFT DISKON':{
            return{
                ...state,
                loadingDraftPenjualan:true
            }
        }
        case 'SUCCESSED UPDATE STOK DRAFT DISKON':{
            return{
                ...state,
                loadingDraftPenjualan:false,
                messageDraftPenjualan:action.payload
            }
        }
        case 'FETCH DATA':{
            return{
                ...state,
                loadingDraftPenjualan:true
            }
        }
        case 'SUCCESSED ACTION DRAFT PENJUALAN':{
            return{
                ...state,
                loadingDraftPenjualan:false,
                messageDraftPenjualan:action.payload
            }
        }
        case 'FAILED ACTION DRAFT PENJUALAN':{
            return{
                ...state,
                loadingDraftPenjualan:false,
                messageDraftPenjualan:action.payload
            }
        }
        case 'EDIT STATUS DRAFT':{
            return{
                ...state,
                statusDraft:action.payload
            }
        }
        case 'SUCCESSED ADD DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'FAILED ADD DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        case 'SUCCESSED EDIT DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        case 'FAILED EDIT DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        case 'SUCCESSED DELETE DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload,
                loadingDraftPenjualan:false
            }
        }
        case 'FAILED DELETE DETAILS DRAFT':{
            return{
                ...state,
                messageDraftPenjualan:action.payload
            }
        }
        default:
            return state
    }
}

export default draftPenjualanReducers;
