import axios from 'axios';
import { apiUrl } from '../..';

export const addFormOrder = (idbarang,namabarang,judulSales,qtySales,qtyForecast,Avg30,stokbarang) =>(dispatch)=>{
    const listValue = {
        'idbarang':idbarang,
        'namabarang':namabarang,
        'judulsales':judulSales,
        'qtysales':qtySales,
        'qtyforecast':qtyForecast,
        'avg30':Avg30,
        'stokbarang':stokbarang
    }
    dispatch({
        type:'ADD ITEM TO FORM ORDER',
        payload:listValue
    })
}
export const deleteFormOrder = (idbarang,namabarang,qtyorder,tglorder) =>(dispatch)=>{
    const listValue = {
        'idbarang':idbarang,
        'namabarang':namabarang,
        'qtyorder':qtyorder,
        'tglorder':tglorder
    }
    dispatch({
        type:'ADD ITEM TO FORM ORDER',
        payload:listValue
    })
}
export const emptyFormOrder = () =>(dispatch)=>{
    dispatch({
        type:"SET EMPTY FORM ORDER"
    })
}
export const setEmptyMessageOrder = () =>(dispatch)=>{
    dispatch({
        type:"EMPTY MESSAGE ORDER"
    })
}
export const addOrderBarang = (data,token,setOpenSnackbar,onCloseDialogNewOrder,formik) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION ORDER'
    })
    axios.put(`${apiUrl.url}v1/suryaharapan/stok/editorder`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED PUT ORDER',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        onCloseDialogNewOrder();
        formik.handleReset();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED PUT ORDER',
                    payload:'Disconnect from server'
            })
        }
})
}
export const deleteOrderBarang = (data,token,setOpenSnackbar,onCloseDialogNewOrder) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION ORDER'
    })
    axios.put(`${apiUrl.url}v1/suryaharapan/stok/editorder`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED PUT ORDER',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        onCloseDialogNewOrder();
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED PUT ORDER',
                    payload:'Disconnect from server'
            })
        }
})
}
export const getListDataOrder = (token)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION ORDER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/stok/listorder`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET DATA ORDER',
            payload:ResponseAPI.data,
            message:ResponseAPI.message
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED PUT ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
} 
export const getDataPendingOrder =(token,data)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION ORDER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/stok/listpendingorder`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET PENDING ORDER',
            payload:ResponseAPI.data
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED PUT ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED PUT ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}