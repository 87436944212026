import React, { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { deletePOOrderDetails, setEmptyPoOrderMessage } from '../../../config/redux/action/PoOrderAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DeletePoOrderDetails = ({HandleClickCloseDeleteDialog,tempData,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes=useStyle();
    const {data} = useSelector(state=>state.loginReducers);
    const {messagePoOrder,isLoadingPoOrder} = useSelector(state=>state.PoOrderReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setEmptyPoOrderMessage(""));
        },100)
      };
    const onSubmit=()=>{
        dispatch(deletePOOrderDetails(tempData.idpoorderDetails,tempData.idpoorder,token,setOpenSnackbar,HandleClickCloseDeleteDialog));
    }
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog}>Hapus Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus </Typography>
                    <Typography align='center'>Barang {tempData.namabarang} dari PO Order ini?</Typography>
                    </div>
                <div className={classes.button}>
                <Button 
                id='hapus'
                name='hapus'
                variant="contained" 
                color="primary" onClick={onSubmit} 
                disabled={isLoadingPoOrder}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                onClose={handleCloseSnackBar} 
                severity={messagePoOrder === 'Data barang berhasil di hapus dari PO Order' ? "success" : "error"} 
                onClick={HandleClickCloseDeleteDialog}>{messagePoOrder}</Alert>
            </Snackbar>
        </div>
    )
}

export default DeletePoOrderDetails
