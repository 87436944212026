const initialStateReturSupplier = {
    noretur : '',
    messageRetur : '',
    isLoading : false,
    dataReturDetails:[],
    totalRetur:'',
    dataReturSupplier:[],
    returSupplierToPrint:''
}
const returSupplierReducers = (state=initialStateReturSupplier,action) =>{
    switch(action.type){
        case 'FETCH GET ID RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:action.payload,
            }
        }
        case 'SUCCESS GET ID RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:false,
                noretur:action.payload,
                messageRetur:action.message
            }
        }
        case 'SET LOADING SAVE RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:action.payload
            }
        }
        case 'SUCCESS SAVE RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:false,
                messageRetur:action.payload
            }
        }
        case 'FAILED SAVE RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:action.payload,
                messageRetur:action.message
            }
        }
        case 'FETCH DATA RETUR SUPPLIER':{
            return{
                ...state,
                isLoading:true,
            }
        }
        case 'SUCCESSED GET DATA RETUR DETAILS':{
            return{
                ...state,
                dataReturDetails:action.payload,
                totalRetur:action.totalRetur
            }
        }
        case 'FAILED GET DATA RETUR DETAILS':{
            return{
                ...state,
                isLoading:false,
                messageRetur:action.fetchPayload
            }
        }
        case 'EMPTY DATA RETUR DETAILS':{
            return{
                ...state,
                dataReturDetails:[],
                totalRetur:''
            }
        }case 'EMPTY NO RETUR':{
            return{
                ...state,
                noretur:''
            }
        }
        case 'FETCH POST DATA RETUR TO SUPPLIER':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SET RETUR TO SUPPLIER':{
            return{
                ...state,
                dataReturSupplier:[...state.dataReturSupplier,action.payload],
                messageRetur:action.messageAddRetur
            }
        }
        case 'SET MESSAGE RETUR TO SUPPLIER':{
            return{
                ...state,
                messageRetur:action.payload
            }
        }
        case 'SET EMPTY DATA RETUR TO SUPPLIER':{
            return{
                ...state,
                dataReturSupplier:[],
            }
        }
        case 'SET RETUR SUPPLIER TO PRINT':{
            return{
                ...state,
                returSupplierToPrint:action.payload
            }
        }
        default:
            return state
    }
}

export default returSupplierReducers