import { Divider, Fab, makeStyles, Snackbar, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptyDetailsDataKeuntungan, getDataDetailsKeuntungan } from '../../../config/redux/action';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const RincianKeuntungan = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },mainWapper:{
            display:'flex',
            marginBottom:'10px',
            justifyContent:'center',
            width:'100%',
            marginTop:'10px',
        },content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        table:{
            marginTop:'3px',
            width:'95%',
        },
        tableContainer:{
            height:'100%',
            width:'100%',
            textAlign:'center',
        },
        tableHead:{
            borderTop:'1px dotted black',
            borderBottom:'1px dotted black'
        },
        bottomTable:{
            width:'100%',
            borderTop:'1px dotted black',
            paddingTop:'2px',
            textAlign:'right'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }))
    
    const classes = useStyle();
    const {mainKeuntungan,detailsKeuntungan,messageKeuntungan}=useSelector(state=>state.keuntunganReducers);
    const params = useParams();
    let idpenjualan = params.idpenjualan;
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.loginReducers);
    const [token,setToken] = useState(data.token);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
    };
    useEffect(()=>{
        if(mainKeuntungan.length<1){
            dispatch(getDataDetailsKeuntungan(idpenjualan,token,setOpenSnackbar,""));
        }
    },[mainKeuntungan,dispatch,token,idpenjualan])
    const history = useHistory();
    const handleClickBack = () =>{
        dispatch(emptyDetailsDataKeuntungan(history));
    }
  return (
    <div className={classes.root}>
        <Typography variant='h5' className={classes.title}><b>Rincian Keuntungan</b></Typography>
        <Divider variant='middle'/>
        <div className={classes.mainWapper}>
            <Typography variant="body1">No Faktur : {mainKeuntungan[0].idpenjualan}</Typography>
            <Typography variant="body1" style={{paddingLeft:'20px'}}>Tanggal : {format(new Date(mainKeuntungan[0].tanggal),'dd/MM/yyyy')}</Typography>
            <Typography variant="body1" style={{paddingLeft:'20px'}}>Pelanggan : {mainKeuntungan[0].pelanggan}</Typography>
            <Typography variant="body1" style={{paddingLeft:'20px'}}>Pembayaran : {mainKeuntungan[0].pembayaran}</Typography>
        </div>
        <div className={classes.content}>
            <div className={classes.table}>
                <table aria-label="table barang" size="small" className={classes.tableContainer}>
                    <thead>
                        <tr>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>ID Barang</Typography></th>
                        <th className={classes.tableHead} style={{width:'25%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Harga</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Modal</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Subtotal Harga</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Subtotal Modal</Typography></th>
                        <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Laba/Rugi</Typography></th>
                        </tr>
                    </thead>
                    <tbody>
                    {detailsKeuntungan.map((dataKeuntunganDetails=>(
                        <tr className={classes.tableBody} key={dataKeuntunganDetails.idkeuntungandetails}>
                            <td align="center"><Typography variant='body2'>{dataKeuntunganDetails.idbarang}</Typography></td>
                            <td align="center"><Typography variant='body2'>{dataKeuntunganDetails.namabarang}</Typography></td>
                            <td align="center"><Typography variant='body2'>{dataKeuntunganDetails.jumlah}</Typography></td>
                            <td align="center"><Typography variant='body2'>{new Intl.NumberFormat().format(dataKeuntunganDetails.harga)}</Typography></td>
                            <td align="center"><Typography variant='body2'>{new Intl.NumberFormat().format(dataKeuntunganDetails.modal)}</Typography></td>
                            <td align="center"><Typography variant='body2'>{new Intl.NumberFormat().format(dataKeuntunganDetails.subtotalharga)}</Typography></td>
                            <td align="center"><Typography variant='body2'>{new Intl.NumberFormat().format(dataKeuntunganDetails.subtotalmodal)}</Typography></td>
                            <td align="center"><Typography variant='body2'>{new Intl.NumberFormat().format(dataKeuntunganDetails.laba)}</Typography></td>
                        </tr>
                    )))}
                    </tbody>
                </table>
                <div className={classes.bottomTable}>
                <Typography variant="body1">Diskon : {new Intl.NumberFormat().format(mainKeuntungan[0].diskon)}</Typography>
                <Typography variant="body1">Ongkir : {new Intl.NumberFormat().format(mainKeuntungan[0].ongkir)}</Typography>
                <Typography variant="body1">Laba/Rugi : {new Intl.NumberFormat().format(mainKeuntungan[0].keuntungan)}</Typography>
                </div>
            </div>
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} 
        severity={messageKeuntungan === "Berhasil menampilkan rincian keuntungan" ? "success" : "error"} 
        >
            {messageKeuntungan}
            </Alert>
        </Snackbar>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
    </div>
  )
}

export default RincianKeuntungan