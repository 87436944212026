import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, makeStyles, Radio, RadioGroup, Snackbar, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { InputFiled, PilihHargaCombo } from '../../molecules';
import MuiAlert from '@material-ui/lab/Alert';
import * as yup from 'yup';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartPenjualan, setMessagePenjualan } from '../../../config/redux/action';
import {SingleDialog} from '../../molecules';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const AddCartPenjualan = ({HandleClickCloseAddCartDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        sisaStokWapper:{
            marginBottom:'2px'
        },
        radio:{
            width:'300px',
            display:'flex',
            flexDirection:'row',
        },
        stkDiskon:{
            display:'flex',
            justifyContent:'center'
        }
    }));
    const {data} = useSelector(state => state.loginReducers);
    const {DetailsBarang} = useSelector(state=>state.barangReducers);
    const {messagePenjualan,listPenjualan,statusDraftPenjualan}=useSelector(state=>state.penjualanReducers);
    const {idbarang,namabarang,satuan,modal,hargajual,sisastok,hargaprogram,modalprogram} = DetailsBarang;
    const [token,setToken] = useState(data.token);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [valueRadio,setValueRadio]=useState("harga");
    const [openMinDialog,setOpenMinDialog]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setMessagePenjualan(''))
        },200)
    };

    const classes = useStyle();
    const validationSchema = yup.object().shape({
        idbarang:yup.string().required('ID barang harus diisi'),
        namabarang:yup.string().required('Nama barang harus diisi'),
        modal:yup.number().required('Modal tidak boleh kosong').positive('Modal tidak boleh bernilai negative').min(1,'Modal tidak boleh kurang dari 1'),
        hargajual:yup.number().required('Keuntungan tidak boleh kosong').positive('Keuntungan tidak boleh bernilai negative').min(1,'Harga jual tidak boleh sama dengan modal'),
        pilihharga:yup.string().required('Belum memilih harga'),
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
        harga:yup.number().positive('Harga tidak boleh bernilai negative').min(0,'Harga tidak boleh kurang dari 0'),
        note:yup.string(),
        sisaStok:yup.number(),
        diskonsup:yup.number().positive('Persentase tidak boleh kurang dari 0')
    })
    const dataCombo = [
        {id:'Tetap',tipe:'Tetap'},
        {id:'Penambahan',tipe:"Penambahan"},
        {id:'Diskon',tipe:"Diskon"}
    ]
    const dispatch = useDispatch();
    const [openDialog,setOpenDialog]=useState(false);
    const handleCloseSingleDialog = () =>{
        setOpenDialog(false);
    }
    const handleChangeRadio =(e)=>{
        setValueRadio(e.target.value);
        if(e.target.value==="harga"){
            formik.setFieldValue('modal',modal);
            formik.setFieldValue('hargajual',hargajual);
            formik.setFieldValue('pilihharga',"Tetap");
            formik.setFieldValue('harga',0);
        }
        else if(e.target.value==="diskon"){
            formik.setFieldValue('modal',modalprogram);
            formik.setFieldValue('hargajual',hargaprogram);
        }
    }
    const addToCartIfMin = () =>{
        if(valueRadio === 'harga'){
            let newHarga = parseInt(formik.values.hargajual) - parseInt(formik.values.harga);
            const data ={ 
                'idbarang':formik.values.idbarang,
                'namabarang':formik.values.namabarang,
                'jumlah':formik.values.jumlah,
                'satuan':satuan,
                'note':formik.values.note,
                "barangstokdiskon":"Tidak",
                'harga':newHarga,
                'subtotal':formik.values.jumlah * newHarga,
                'stokdiskon':DetailsBarang.stokdiskon
            }
            let tempSubtotalHarga =formik.values.jumlah * newHarga;
            let tempSubtotalModal = formik.values.jumlah * modal;
            const dataKeuntungan={
                'idbarang':formik.values.idbarang,
                'jumlah':formik.values.jumlah,
                'harga':newHarga,
                'modal':modal,
                'subtotalharga':tempSubtotalHarga,
                'subtotalmodal':tempSubtotalModal,
                'laba':tempSubtotalHarga-tempSubtotalModal
            }
            dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar,HandleClickCloseAddCartDialog));
            setOpenDialog(false);
        }else if(valueRadio==='Diskon'){
            let newHarga = parseInt(formik.values.hargajual) - parseInt(formik.values.harga);
            const data ={ 
                'idbarang':formik.values.idbarang,
                'namabarang':formik.values.namabarang,
                'jumlah':formik.values.jumlah,
                'satuan':satuan,
                'note':formik.values.note,
                "barangstokdiskon":"Ya",
                'harga':newHarga,
                'subtotal':formik.values.jumlah * newHarga,
                'stokdiskon':DetailsBarang.stokdiskon-formik.values.jumlah
            }
            let tempSubtotalHarga =formik.values.jumlah * newHarga;
            let tempSubtotalModal = formik.values.jumlah * modalprogram;
            const dataKeuntungan={
                'idbarang':formik.values.idbarang,
                'jumlah':formik.values.jumlah,
                'harga':newHarga,
                'modal':modalprogram,
                'subtotalharga':tempSubtotalHarga,
                'subtotalmodal':tempSubtotalModal,
                'laba':tempSubtotalHarga-tempSubtotalModal
            }
            dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar,HandleClickCloseAddCartDialog));
            setOpenDialog(false);
        }
        
    }
    const handleCloseMinDialog = () =>{
        setOpenMinDialog(false);
    }
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang || '',
            namabarang:namabarang||'',
            modal:modal||'0',
            hargajual:hargajual || '0',
            pilihharga:"Tetap" || '',
            harga:0,
            note:'',
            sisaStok:sisastok || '0',
            jumlah:0 || '',
            diskonsup:1||''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = listPenjualan.filter(barang =>barang.idbarang === idbarang);
            if(existBarang.length>0){
                dispatch(setMessagePenjualan('Barang telah terdaftar dalam keranjang penjualan',setOpenSnackbar));
            }else{
                    if(statusDraftPenjualan==="Penjualan"){
                        if(valueRadio==="diskon"){
                            if(DetailsBarang.stokdiskon<values.jumlah && sisastok >= values.jumlah){
                                dispatch(setMessagePenjualan('Jumlah yang dimasukan melebihin stok diskon yang tersedia',setOpenSnackbar));
                                setOpenSnackbar(true);
                            }else if(DetailsBarang.stokdiskon>=values.jumlah && sisastok < values.jumlah){
                                setOpenMinDialog(true);
                            }else{
                                insertData();
                            }
                        }else if(valueRadio==="harga"){
                            if(sisastok < values.jumlah){
                                setOpenMinDialog(true);
                            }else{
                                insertData();
                            }
                        }
                    }else if(statusDraftPenjualan==="Draft"){
                        dispatch(setMessagePenjualan("Tidak dapat melakukan penambahan item untuk transaksi yang berasal dari draft"));
                        setOpenSnackbar(true);
                    }
            }
        }
    }) 
    const handleClose = () =>{
        HandleClickCloseAddCartDialog();
        setValueRadio('harga');
        formik.setFieldValue('modal',modal);
        formik.setFieldValue('hargajual',hargajual);
    }
    const insertData =()=>{
        if(valueRadio==="harga"){
            if(formik.values.pilihharga==='Tetap'){
                const data ={ 
                    'idbarang':formik.values.idbarang,
                    'namabarang':formik.values.namabarang,
                    'jumlah':formik.values.jumlah,
                    'satuan':satuan,
                    'note':formik.values.note,
                    "barangstokdiskon":"Tidak",
                    'harga':formik.values.hargajual,
                    'subtotal':formik.values.jumlah * formik.values.hargajual,
                    'stokdiskon':DetailsBarang.stokdiskon
                }
                let tempSubtotalHarga =formik.values.jumlah * formik.values.hargajual;
                let tempSubtotalModal = formik.values.jumlah * modal;
                const dataKeuntungan={
                    'idbarang':formik.values.idbarang,
                    'jumlah':formik.values.jumlah,
                    'harga':formik.values.hargajual,
                    'modal':modal,
                    'subtotalharga':tempSubtotalHarga,
                    'subtotalmodal':tempSubtotalModal,
                    'laba':tempSubtotalHarga-tempSubtotalModal
                }
                dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar,HandleClickCloseAddCartDialog));
            }else if(formik.values.pilihharga==='Diskon'){
                let newHarga = formik.values.hargajual - formik.values.harga;
                if(newHarga < formik.values.modal){
                    setOpenDialog(true);
                    // dispatch(setMessagePenjualan('Modal lebih kecil dari harga jual dipotong diskon',setOpenSnackbar));
                }else{
                    let newHarga = parseInt(formik.values.hargajual) - parseInt(formik.values.harga);
                    const data ={ 
                        'idbarang':formik.values.idbarang,
                        'namabarang':formik.values.namabarang,
                        'jumlah':formik.values.jumlah,
                        'satuan':satuan,
                        'note':formik.values.note,
                        "barangstokdiskon":"Tidak",
                        'harga':newHarga,
                        'subtotal':formik.values.jumlah * newHarga,
                        'stokdiskon':DetailsBarang.stokdiskon
                    }
                    let tempSubtotalHarga =formik.values.jumlah * newHarga;
                    let tempSubtotalModal = formik.values.jumlah * modal;
                    const dataKeuntungan={
                        'idbarang':formik.values.idbarang,
                        'jumlah':formik.values.jumlah,
                        'harga':newHarga,
                        'modal':modal,
                        'subtotalharga':tempSubtotalHarga,
                        'subtotalmodal':tempSubtotalModal,
                        'laba':tempSubtotalHarga-tempSubtotalModal
                    }
                    dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar));
                }
            }else if(formik.values.pilihharga==='Penambahan'){
                let newHarga = parseInt(formik.values.hargajual) + parseInt(formik.values.harga);
                const data ={ 
                    'idbarang':formik.values.idbarang,
                    'namabarang':formik.values.namabarang,
                    'jumlah':formik.values.jumlah,
                    'satuan':satuan,
                    'note':formik.values.note,
                    "barangstokdiskon":"Tidak",
                    'harga':newHarga,
                    'subtotal':formik.values.jumlah * newHarga,
                    'stokdiskon':DetailsBarang.stokdiskon
                }
                let tempSubtotalHarga =formik.values.jumlah * newHarga;
                let tempSubtotalModal = formik.values.jumlah * modal;
                const dataKeuntungan={
                    'idbarang':formik.values.idbarang,
                    'jumlah':formik.values.jumlah,
                    'harga':newHarga,
                    'modal':modal,
                    'subtotalharga':tempSubtotalHarga,
                    'subtotalmodal':tempSubtotalModal,
                    'laba':tempSubtotalHarga-tempSubtotalModal
                }
                dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar));
            }
        }
        else if(valueRadio==="diskon"){
            if(DetailsBarang.stokdiskon>=formik.values.jumlah){
                if(formik.values.pilihharga==='Tetap'){
                    const data ={ 
                        'idbarang':formik.values.idbarang,
                        'namabarang':formik.values.namabarang,
                        'jumlah':formik.values.jumlah,
                        'satuan':satuan,
                        'note':formik.values.note,
                        "barangstokdiskon":"Ya",
                        'harga':formik.values.hargajual,
                        'subtotal':formik.values.jumlah * formik.values.hargajual,
                        'stokdiskon':DetailsBarang.stokdiskon - formik.values.jumlah
                    }
                    let tempSubtotalHarga =formik.values.jumlah * formik.values.hargajual;
                    let tempSubtotalModal = formik.values.jumlah * modalprogram;
                    const dataKeuntungan={
                        'idbarang':formik.values.idbarang,
                        'jumlah':formik.values.jumlah,
                        'harga':formik.values.hargajual,
                        'modal':modalprogram,
                        'subtotalharga':tempSubtotalHarga,
                        'subtotalmodal':tempSubtotalModal,
                        'laba':tempSubtotalHarga-tempSubtotalModal
                    }
                    dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar,HandleClickCloseAddCartDialog));
                }else if(formik.values.pilihharga==='Diskon'){
                    let newHarga = formik.values.hargajual - formik.values.harga;
                    if(newHarga < formik.values.modal){
                        setOpenDialog(true);
                        // dispatch(setMessagePenjualan('Modal lebih kecil dari harga jual dipotong diskon',setOpenSnackbar));
                    }else{
                        let newHarga = parseInt(formik.values.hargajual) - parseInt(formik.values.harga);
                        const data ={ 
                            'idbarang':formik.values.idbarang,
                            'namabarang':formik.values.namabarang,
                            'jumlah':formik.values.jumlah,
                            'satuan':satuan,
                            'note':formik.values.note,
                            "barangstokdiskon":"Ya",
                            'harga':newHarga,
                            'subtotal':formik.values.jumlah * newHarga,
                            'stokdiskon':DetailsBarang.stokdiskon - formik.values.jumlah
                        }
                        let tempSubtotalHarga =formik.values.jumlah * newHarga;
                        let tempSubtotalModal = formik.values.jumlah * modalprogram;
                        const dataKeuntungan={
                            'idbarang':formik.values.idbarang,
                            'jumlah':formik.values.jumlah,
                            'harga':newHarga,
                            'modal':modalprogram,
                            'subtotalharga':tempSubtotalHarga,
                            'subtotalmodal':tempSubtotalModal,
                            'laba':tempSubtotalHarga-tempSubtotalModal
                        }
                        dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar));
                    }
                }else if(formik.values.pilihharga==='Penambahan'){
                    let newHarga = parseInt(formik.values.hargajual) + parseInt(formik.values.harga);
                    const data ={ 
                        'idbarang':formik.values.idbarang,
                        'namabarang':formik.values.namabarang,
                        'jumlah':formik.values.jumlah,
                        'satuan':satuan,
                        'note':formik.values.note,
                        "barangstokdiskon":"Ya",
                        'harga':newHarga,
                        'subtotal':formik.values.jumlah * newHarga,
                        'stokdiskon':DetailsBarang.stokdiskon - formik.values.jumlah
                    }
                    let tempSubtotalHarga =formik.values.jumlah * newHarga;
                    let tempSubtotalModal = formik.values.jumlah * modalprogram;
                    const dataKeuntungan={
                        'idbarang':formik.values.idbarang,
                        'jumlah':formik.values.jumlah,
                        'harga':newHarga,
                        'modal':modalprogram,
                        'subtotalharga':tempSubtotalHarga,
                        'subtotalmodal':tempSubtotalModal,
                        'laba':tempSubtotalHarga-tempSubtotalModal
                    }
                    dispatch(addToCartPenjualan(data,dataKeuntungan,setOpenSnackbar));
                }
                setValueRadio('harga');
            }
        }
        HandleClickCloseAddCartDialog();
        handleCloseMinDialog();
    }
    // const diskonSupplierClick = () => {
    //     formik.setFieldValue('modal',modalprogram);
    //     formik.setFieldValue('hargajual',hargaprogram);
    // }
    // const diskonSupplier = (
    //     <InputFiled 
    //         id="diskonsup" 
    //         name="diskonsup" 
    //         label="Diskon Supplier (%)" 
    //         value={formik.values.diskonsup}
    //         error={formik.touched.diskonsup && Boolean(formik.errors.diskonsup)}
    //         helperText={formik.touched.diskonsup && formik.errors.diskonsup}
    //         onChange={formik.handleChange}
    //     />
    // )
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-barang-cart">Rincian Penjualan</DialogTitle>
            <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                id="namabarang" 
                name="namabarang" 
                label="Nama barang" 
                value={formik.values.namabarang}
                disabled={true} 
                />
                <InputFiled 
                id="modal" 
                name="modal" 
                label="Modal" 
                value={formik.values.modal}
                disabled={true} 
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <InputFiled 
                id="hargajual" 
                name="hargajual" 
                label="Harga Jual" 
                value={formik.values.hargajual}
                disabled={true} 
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <RadioGroup className={classes.radio} aria-label="typeData" name="typeData" 
                value={valueRadio} 
                onChange={handleChangeRadio}
                >
                        <FormControlLabel value="harga" control={<Radio />} label="Pilih Harga" />
                        <FormControlLabel value="diskon" control={<Radio />} disabled={DetailsBarang.stokdiskon>0?false:true} label="Diskon Supplier" />
                </RadioGroup>
                <PilihHargaCombo
                id="pilihharga"
                name="pilihharga"
                data={dataCombo}
                label="Pilih Harga"
                value={formik.values.pilihharga}
                error={formik.touched.pilihharga && Boolean(formik.errors.pilihharga)}
                helperText={formik.touched.pilihharga && formik.errors.pilihharga}
                handleChange={formik.handleChange}
                />
                <InputFiled 
                id="harga" 
                name="harga" 
                disabled={formik.values.pilihharga === 'Tetap' ? true : false}
                value={formik.values.harga}
                error={formik.touched.harga && Boolean(formik.errors.harga)}
                helperText={formik.touched.harga && formik.errors.harga}
                onChange={formik.handleChange}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <InputFiled 
                id="note" 
                name="note" 
                label="Catatan" 
                value={formik.values.note}
                error={formik.touched.note && Boolean(formik.errors.note)}
                helperText={formik.touched.note && formik.errors.note}
                onChange={formik.handleChange}
                />
                <Typography className={classes.sisaStokWapper}>Sisa Stok : {formik.values.sisaStok}</Typography>
                {DetailsBarang.stokdiskon > 0 ?<Typography style={{marginBottom:'10px'}} color='secondary'>Sisa stok diskon : {DetailsBarang.stokdiskon}</Typography>
                : <Typography style={{marginBottom:'10px'}} color='secondary'>Sisa stok diskon : 0</Typography>}
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah" 
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                {/* {DetailsBarang.stokdiskon > 0 && valueRadio!=="harga" ?<Typography className={classes.stkDiskon} color='secondary'>Sisa stok diskon {DetailsBarang.stokdiskon}</Typography>
                : null} */}
                <div className={classes.button}>
                <Button 
                variant="contained" 
                color="primary" 
                onClick={formik.handleSubmit} 
                >Tambah</Button>
                <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleClose}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
             open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                onClose={handleCloseSnackBar} 
                severity={messagePenjualan === 'Berhasil menambahkan ke keranjang penjualan' ? "success" : "error"}>
                    {messagePenjualan}</Alert>
            </Snackbar>
            <SingleDialog 
            openDialog={openDialog}
            message="Modal lebih kecil dari harga jual,apakah anda yakin akan melanjutkan?" 
            okCommand="Iya" 
            handleCloseDialog={handleCloseSingleDialog}
            handleClick={addToCartIfMin}/>
            <SingleDialog 
            openDialog={openMinDialog}
            message="Jumlah barang kurang dari sisa stok,apakah anda yakin akan melanjutkan?" 
            okCommand="Iya" 
            handleCloseDialog={handleCloseMinDialog}
            handleClick={insertData}/>
        </div>
    )
}

export default AddCartPenjualan
