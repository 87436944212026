import axios from 'axios';
import { apiUrl } from '../..';

export const getDataPOOrder = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/poorder/`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'UPDATE DATA PO ORDER', 
            payload:ResponseAPI.data,
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const getDataPODetails = (idpoorder,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/poorder/byid/${idpoorder}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'UPDATE DATA DETAILS PO ORDER', 
            payload:ResponseAPI.data,
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const createNewPO = (data,token,setOpenSnackbar,formik,handleCloseDialog) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/poorder/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCESSED ACTION PO ORDER',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        formik.handleReset();
        setTimeout(()=>{
            handleCloseDialog();
        },2000)
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const createNewPODetails = (data,token,setOpenSnackbar,formik) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/poorder/details`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCESSED ACTION PO ORDER',
            payload:ResponseAPI.message,
        })
        setOpenSnackbar(true);
        formik.handleReset();
        axios.get(`${apiUrl.url}v1/suryaharapan/poorder/byid/${ResponseAPI.newId}`,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'UPDATE DATA DETAILS PO ORDER', 
                payload:ResponseAPI.data,
             })
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                     dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:'Disconnect from server'
                })
            }
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const setEmptyPoOrderMessage = (message) =>(dispatch) =>{
    dispatch({
        type:'SET EMPTY MESSAGE PO ORDER',
        payload:message
    })
}
export const setEmptyPoOrderDetails = () =>(dispatch) =>{
    dispatch({
        type:'UPDATE DATA DETAILS PO ORDER',
        payload:[]
    })
}
export const deletePOOrder = (idpoorder,token,setOpenSnackbar,HandleClickCloseDeleteDialog) =>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/poorder/all/${idpoorder}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCESSED ACTION PO ORDER',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        setTimeout(()=>{
            HandleClickCloseDeleteDialog();
        },1500)
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const deletePOOrderDetails = (idpoorderdetails,idpoorder,token,setOpenSnackbar,HandleClickCloseDeleteDialog) =>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION PO ORDER'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/poorder/details/${idpoorderdetails}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCESSED ACTION PO ORDER',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        setTimeout(() => {
            HandleClickCloseDeleteDialog();
        }, 1500);
        axios.get(`${apiUrl.url}v1/suryaharapan/poorder/byid/${idpoorder}`,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'UPDATE DATA DETAILS PO ORDER', 
                payload:ResponseAPI.data,
             })
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                     dispatch({
                        type:'FAILED FETCH ACTION PO ORDER',
                        payload:'Disconnect from server'
                })
            }
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED FETCH ACTION PO ORDER',
                    payload:'Disconnect from server'
            })
        }
    })
}
