import axios from "axios"
import { apiUrl } from "../.."

export const getAllDataRiwayatPembelian = (data,token,page) =>(dispatch)=>{
    dispatch({
        type:'FETCH RIWAYAT PEMBELIAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/pembelian/list/?page=${page}&perPage=50`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'UPDATE DATA RIWAYAT PEMBELIAN', 
            payload:ResponseAPI.data,
         })
         dispatch({
             type:'UPDATE PAGE RIWAYAT PEMBELIAN',
             payload:{
                 currentPage: ResponseAPI.current_page,
                 totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
             }
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}

export const emptyTableRiwayatPembelian = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY RIWAYAT PEMBELIAN'
    })
}

export const getPembelianDetails = (idpembelian,token,setOpenSnackbar,totalPembelian,history)=>(dispatch)=>{
    dispatch({
        type:'FETCH RIWAYAT PEMBELIAN'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/pembelian/details/${idpembelian}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET DATA DETAILS PEMBELIAN', 
            payload:ResponseAPI.data,
            totalPembelian:totalPembelian
         })
         history.push(`/historyPembelian/details/${idpembelian}`);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:'Disconnect from server'
            })
            setOpenSnackbar(true)
        }
    })
}
export const getItemPembelian = (idpembelian,token,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH RIWAYAT PEMBELIAN'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/pembelian/details/${idpembelian}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET DATA DETAILS PEMBELIAN', 
            payload:ResponseAPI.data,
            totalPembelian:0
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED RIWAYAT PEMBELIAN',
                    fetchPayload:'Disconnect from server'
            })
            setOpenSnackbar(true)
        }
    })
}
export const emptyRiwayatPembelianDetails = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY RIWAYAT PEMBELIAN DETAILS'
    })
}
export const emptyMessagePembelianDetails = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE PEMBELIAN DETAILS'
    })
}
export const setRiwayatTransferAll=(id,idbarang,namabarang,jumlah,varian,satuan,status)=>(dispatch)=>{
    const data = {
        'id':id,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'variant':varian,
        'satuan':satuan,
        'status':status
    }
    dispatch({
        type:'TEMP RIWAYAT PEMBELIAN DETAILS',
        payload:data
    })
}
export const setEmptyRiwayatTransferAll =()=>(dispatch)=>{
    dispatch({
        type:'SET EMPTY RIWAYAT PEMBELIAN DETAILS'
    })
}
export const setTempFakturForTransfer = (faktur)=>(dispatch)=>{
    dispatch({
        type:'SET TEMP FAKTUR FOR TRANSFER',
        payload:faktur
    })
}
