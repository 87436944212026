const initialStateOrder={
    dataOrder:[],
    isLoadingOrder:false,
    messageOrder:"",
    dataPendingOrder:[],
}

const orderReducers = (state=initialStateOrder,action)=>{
    switch(action.type){
        case 'FETCH ACTION ORDER':{
            return{
                ...state,
                isLoadingOrder:true
            }
        }
        case 'ADD ITEM TO FORM ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                dataOrder:action.payload
            }
        }
        case 'SET EMPTY FORM ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                dataOrder:[]
            }
        }
        case 'SUCCESSED PUT ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                messageOrder:action.payload
            }
        }
        case 'SUCCESSED GET PENDING ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                dataPendingOrder:action.payload
            }
        }
        case 'FAILED PUT ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                messageOrder:action.payload
            }
        }
        case 'DELETE PUT ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                messageOrder:action.payload
            }
        }
        case 'EMPTY MESSAGE ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                messageOrder:""
            }
        }
        case 'SUCCESSED GET DATA ORDER':{
            return{
                ...state,
                isLoadingOrder:false,
                dataOrder:action.payload,
                messageOrder:action.message
            }
        }
        default:return state
    }
}
export default orderReducers;