import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import MuiAlert from '@material-ui/lab/Alert'
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core';
import { InputFiled } from '../../molecules';
import { patchPengeluaran, setMessagePengeluaran } from '../../../config/redux/action';
import Cookies from 'js-cookie';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormEditPengeluaran = ({HandleClickCloseDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        avaliableEdit:{
            display:'flex',
        }
    }));
    const {formDetails,pengeluaranMessage} = useSelector(state=>state.pengeluaranReducers);
    const {data} = useSelector(state => state.loginReducers);
    const {idpengeluaran,idbarang,namabarang,jumlah,variant} = formDetails;
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch=useDispatch();
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idpengeluaran:idpengeluaran || '',
            idbarang:idbarang || '',
            namabarang:namabarang || '',
            jumlah:jumlah || '',
            variant:variant || ''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idpengeluaran',idpengeluaran);
            data.append('jumlah',values.jumlah);
            dispatch(patchPengeluaran(data,token));
            setOpenSnackbar(true);
            HandleClickCloseDialog();
        }
    })
    const handleCloseSnackBar = () =>{
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setMessagePengeluaran(""));
        },500)
    }
    const classes = useStyle();
    return (
        <div>
            <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-edit-pengeluaran">Form Ubah Pengeluaran</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                id="namabarang"
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <InputFiled 
                id="variant" 
                name="variant" 
                label="variant"
                value={formik.values.variant}
                disabled={true}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={pengeluaranMessage === 'Perubahan data pengeluaran gudang berhasil' ? "success" : "error"}>
                        {pengeluaranMessage}
                </Alert>
            </Snackbar>
        </div>
        </div>
    )
}

export default FormEditPengeluaran
