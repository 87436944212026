import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUrl } from '../..';
import {v4 as uuidv4} from 'uuid';


export const postToAPI = (username,password,width,history) => (dispatch) =>{
    dispatch({
      type:'FETCH LOGIN',
      payload:{
        isLoading: true
      }
    })
    const data={
      'username':username
    }
      axios.post(`${apiUrl.url}v1/suryaharapan/auth/identifier`,data)
      .then(res=>{
        const ResponseApiCheck = res.data;
        let finalCookie="";
        let dataInputLogin="";
        if(ResponseApiCheck.data.level==="Kasir"){
          if(ResponseApiCheck.data.identifierCode===null){
            const checkCookie = document.cookie.indexOf("hexiden");
            if(checkCookie>0){
              finalCookie=Cookies.get('hexiden');
            }else{
              const uuid = uuidv4();
              finalCookie=uuid;
              Cookies.set('hexiden',finalCookie,{expires:200000,path:'/'});
            }
          }else{
              finalCookie=Cookies.get('hexiden');
          }
          dataInputLogin={
            'username':username,
            'password':password,
            'width':width,
            'uuid':finalCookie
          }
        }else {
          dataInputLogin={
            'username':username,
            'password':password
          }
        }
          axios.post(`${apiUrl.url}v1/suryaharapan/auth/`,dataInputLogin)
        .then(res=>{
            const ResponseApi = res.data;
            if(res){
              if(ResponseApi.Login === true){
                dispatch({
                  type:'LOGIN SUCCESSED',
                  payload:{
                    nama:ResponseApi.data.nama,
                    username : ResponseApi.data.username,
                    token:ResponseApi.data.token,
                    level:ResponseApi.data.level,
                    identifier:ResponseApi.data.identifier,
                    errorMessage:'',
                  }
                })
                Cookies.set('u_tkn',ResponseApi.data.token,{expires:1,path:'/'});
                Cookies.set('username',ResponseApi.data.username,{expires:1,path:'/'});
                Cookies.set('level',ResponseApi.data.level,{expires:1,path:'/'});
                Cookies.set('identifier',ResponseApi.data.identifier,{expires:1,path:'/'});
                history.push('/');
              }
            }
        })
        .catch(error=>{
          if(error.response){
            if(error.response.status === 401){
              dispatch({
                  type:'LOGIN FAILED',
                  payload:{
                    nama:'',
                    username : '',
                    errorMessage:"Username or password incorrect",
                  }
                })
                localStorage.removeItem('token');
                Cookies.set('identifier',"");
            }else if(error.response.status === 404){
              dispatch({
                  type:'LOGIN FAILED',
                  payload:{
                    nama:'',
                    username : '',
                    errorMessage:"Username not found",
                  }
                })
                localStorage.removeItem('token');
                Cookies.set('identifier',"");
            }
            else if(error.response.status === 500){
              dispatch({
                  type:'LOGIN FAILED',
                  payload:{
                    nama:'',
                    username : '',
                    errorMessage:"Internal Server Error",
                  }
                })
                localStorage.removeItem('token');
                Cookies.set('identifier',"");
            }
          }else if(error.request){
            dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : '',
                  errorMessage:'Disconnect from server',
                }
            })
            localStorage.removeItem('token');
            Cookies.set('identifier',"");
        }
        })
      })
      .catch(error=>{
        if(error.response.status === 500){
          dispatch({
              type:'LOGIN FAILED',
              payload:{
                nama:'',
                username : '',
                errorMessage:"Cannot get identifier",
              }
            })
        }
      })
      
  }
  export const resetIdentifier = (data,token)=>(dispatch) =>{
    dispatch({
      type:'FETCH GET IDENTIFIER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/auth/identifier`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then((res)=>{
      const ResponseAPI=res.data;
      dispatch({
        type:'RELOAD IDENTIFIER',
        payload:ResponseAPI.data.identifier
      })
      Cookies.set('identifier',ResponseAPI.data.identifier);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET IDENTIFIER',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET IDENTIFIER',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED GET IDENTIFIER',
                    payload:'Disconnect from server'
            })
        }
    })
  }