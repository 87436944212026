import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, {useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { jenisBarangDeleteToAPI } from '../../../config/redux/action';
import MuiAlert from '@material-ui/lab/Alert';


const JenisBarangDeleteForm = ({HandleClickCloseDeleteDialog,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingJenisBarangSave,PostJenisBarangStatus,fetchMessageJenisBarang,FormJenisBarang} = useSelector(state=>state.jenisbarangReducers);
    const {idjenisbarang,jenisbarang} = FormJenisBarang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
    const onSubmit=()=>{
        dispatch(jenisBarangDeleteToAPI(idjenisbarang,token));
        setOpenSnackbar(true);
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-jenisbarang-dialog">Hapus Jenis Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus jenis barang</Typography>
                    <Typography align='center'>({idjenisbarang}){jenisbarang}?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={PostJenisBarangStatus==='Successed' ? true : isLoadingJenisBarangSave}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog} disabled={PostJenisBarangStatus==='Successed' ? true : false}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {fetchMessageJenisBarang==='Data jenis barang berhasil dihapus' ?
                    <Alert onClose={handleCloseSnackBar} severity="success" onClick={HandleClickCloseDeleteDialog}>{fetchMessageJenisBarang}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error" onClick={HandleClickCloseDeleteDialog}>{fetchMessageJenisBarang}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default JenisBarangDeleteForm
