import { Divider, Fab, Paper, TableBody } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateField, GudangCombo } from '../../component/molecules';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { TableContainer } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { useFormik } from 'formik';
import { getAllDataTransferList, setArrayListEmpty, setEmptyFormDelete, setFormTransferDelete } from '../../config/redux/action/TransferBarangAction';
import { format } from 'date-fns';
import * as yup from 'yup';
import DeleteTransferList from '../../component/organism/DeleteTransferList';

const DataTransferList = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'95%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                width:'50%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },
        textPage:{
            padding:'10px',
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'10px',
            width:'95%',
            justifyContent:'left',
            [theme.breakpoints.up('md')]:{
                width:'50%',
                marginLeft:'335px',
            },
        },
        buttonCari:{
           height:'30px',
           marginLeft:'10px',
           marginTop:'22px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        },
        tableBottom:{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center'
        }
    }));
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.loginReducers);
    const [token,setToken] = useState(data.token);
    const {transferBarangList,tempIdGudang} = useSelector(state => state.transferBarangReducers);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const history = useHistory();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const validationSchema = yup.object().shape({
        idgudang: yup.string().required('Data gudang belum dipilih')
    })
    const formik = useFormik({
        initialValues:{
            tanggal : new Date(),
            idgudang : tempIdGudang || ''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data= new URLSearchParams();
            data.append('tanggal',format(values.tanggal,'yyyy-MM-dd'));
            data.append('idgudang',values.idgudang);
            dispatch(getAllDataTransferList(data,token));
        }
    })
    const [openDelete,setOpenDelete] = useState(false);
    const handleOpenDeleteDialog = () =>{
        if(formik.values.idgudang === ""){
            formik.setFieldTouched('idgudang','Data gudang belum dipilih');
        }else{
            dispatch(setFormTransferDelete(formik.values.idgudang,format(formik.values.tanggal,'dd-MM-yyyy'),format(formik.values.tanggal,'yyyy-MM-dd')));
            setOpenDelete(true);
        }
    }
    const HandleClickCloseDeleteDialog =()=>{
        setOpenDelete(false);
        const data= new URLSearchParams();
        data.append('tanggal',format(formik.values.tanggal,'yyyy-MM-dd'));
        data.append('idgudang',formik.values.idgudang);
        dispatch(getAllDataTransferList(data,token));
        dispatch(setEmptyFormDelete());
    }
    const handleClickBack = () =>{
        dispatch(setArrayListEmpty());
        history.push('/transferbarang')
    }
    return (
        <div className={classes.root}>
            <Typography variant='h5' className={classes.title}>Data Transfer List</Typography>
            <Divider/>
        <div className={classes.mainWapper}>
            <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                onChange={(value)=>formik.setFieldValue('tanggal',value)}
            />
            <GudangCombo 
            id="idgudang" 
            name="idgudang"
            value={formik.values.idgudang}
            error={formik.touched.idgudang && Boolean(formik.errors.idgudang)}
            helperText={formik.touched.idgudang && formik.errors.idgudang}
            onChange={formik.handleChange}
            />
            <Button 
            className={classes.buttonCari}
            variant='contained'
            color='primary'
            size='small'
            aria-label="search"
            onClick={formik.handleSubmit}
            >
                <SearchIcon/> Cari
            </Button>
        </div>
        <div className={classes.content}>
            <div className={classes.table}>
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='7%' align="center">ID Barang</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Nama Barang</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Variant</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Jumlah</SytledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transferBarangList.map((transferBarangLists=>(
                                    <StyledTableRow key={transferBarangLists.idtransfer}>
                                        <SytledTableCell align="center">{transferBarangLists.idbarang}</SytledTableCell>
                                        <SytledTableCell align="center">{transferBarangLists.namabarang}</SytledTableCell>
                                        <SytledTableCell align="center">{transferBarangLists.variant}</SytledTableCell>
                                        <SytledTableCell align="center">{transferBarangLists.jumlah}</SytledTableCell>
                                    </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.tableBottom}>
                        <Button 
                        variant='contained' 
                        color="secondary"
                        style={{marginBottom:'5px'}}
                        disabled={transferBarangList.length > 0 ? false : true}
                        onClick={handleOpenDeleteDialog}>
                            Hapus data transfer barang
                        </Button>
                    </div>
                </Paper>
            </div>
        </div>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={handleClickBack}>
            <ArrowBackIcon/>
        </Fab>
        <DeleteTransferList open={openDelete} aria-labelledby="delete-transfer-dialog" HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}/>
        </div>
    )
}

export default DataTransferList
