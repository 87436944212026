import React, {useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Button, Divider } from '@material-ui/core';
import { InputFiled } from '../../molecules';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import {makeStyles,Dialog,DialogTitle,DialogContent,Snackbar} from '@material-ui/core'
import { addNewGudang, editGudang, setEmptyGudangMessage, setFillFormGudang } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormGudang = ({HandleClickCloseDialog,statusForm,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingGudang,newIDGudang,FormGudang,gudangMessage} = useSelector(state=>state.gudangReducers);
    const {idgudang,namagudang} = FormGudang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setFillFormGudang());
            dispatch(setEmptyGudangMessage());
        },500)
      };
    useEffect(()=>{
        if(gudangMessage !== ''){
            setOpenSnackbar(true);
        }
    },[gudangMessage])
    const validationSchema = yup.object().shape({
        namagudang:yup.string().required("Nama gudang tidak boleh kosong"),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idgudang:newIDGudang || idgudang || '',
            namagudang:namagudang || '',
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            if(statusForm === 'Data Gudang Baru'){
                const data = new URLSearchParams(); 
                data.append('idgudang',values.idgudang);
                data.append('namagudang',values.namagudang);
                dispatch(addNewGudang(data,token));
                formik.resetForm();
                HandleClickCloseDialog();
            }else if(statusForm === 'Ubah Data Gudang'){
                const data = new URLSearchParams();
                data.append('namagudang',values.namagudang);
                dispatch(editGudang(data,idgudang,token));
                formik.resetForm();
                HandleClickCloseDialog();
            }
        }
    })
    const classes = useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-gudang-dialog">{statusForm}</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled
                id="idgudang" 
                name="idgudang" 
                label="ID Gudang" 
                disabled={true} 
                value={formik.values.idgudang}
                />
                <InputFiled 
                id="namagudang" 
                name="namagudang" 
                label="Nama Gudang"
                value={formik.values.namagudang}
                error={formik.touched.namagudang && Boolean(formik.errors.namagudang)}
                helperText={formik.touched.namagudang && formik.errors.namagudang}
                onChange={(e)=>formik.setFieldValue('namagudang',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingGudang}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={gudangMessage === 'Data gudang baru berhasil disimpan' || 'Perubahan data gudang berhasil' ? "success" :"error"}>
                {gudangMessage}
            </Alert>
            </Snackbar>
        </div>
    )
}

export default FormGudang
