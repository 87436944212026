import { Button, Divider, Fab, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getListDataOrder } from '../../config/redux/action';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ListOrder = React.forwardRef ((props,ref)  => {
    const useSytle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        selectWapper:{
            height:'100%',
            display:'flex',
            width:'100%',
            marginTop:'10px',
            justifyContent:'center'
        },
        table:{
            marginTop:'20px',
            // width:'350px',
            maxWidth:'100%'
        },buttonPrintWapper:{
            marginTop:'20px',
            marginBottom:'10px',
            width:'100%',
            display:'flex',
            justifyContent:'center'
        },buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }))
    const classes=useSytle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const {data} = useSelector(state=>state.loginReducers);
    const {dataOrder}=useSelector(state=>state.orderReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getListDataOrder(token));
    },[token,dispatch])
    const tablePrintStok=(
        <TableBody>
            {dataOrder.map((newDataOrder=>(
                <StyledTableRow key={newDataOrder.idbarang}>
                    <SytledTableCell variant='body1' width='50%' align="center"><b>{newDataOrder.namabarang} :</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='25%' align="center"><b>{newDataOrder.qtyorder}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='25%' align="center"><b>{newDataOrder.sisastok}</b></SytledTableCell>
                </StyledTableRow>
            )))}
        </TableBody>
    )
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
    });
    const history = useHistory();
    const handleClickBack = () =>{
        history.push('/filterstok')
    }
  return (
    <div className={classes.root}>
        <div ref={componentRef}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>List Order</Typography>
                <Typography variant='h5' className={classes.title}>Tanggal {format(new Date(),"dd MMMM yyyy")}</Typography>
             </div>
            <Divider/>
            <div className={classes.selectWapper}> 
                <div className={classes.table}>
                    {dataOrder.length > 0 ?
                    <Paper>
                        <TableContainer>
                            <Table stickyHeader aria-label="table list order" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell variant='body1' width='50%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell variant='body1' width='25%' align="center">Qty Order</SytledTableCell>
                                        <SytledTableCell variant='body1' width='25%' align="center">Stok Barang</SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                {tablePrintStok}
                            </Table>
                        </TableContainer>                        
                    </Paper>
                : <Typography variant='h5' align='center'>Tidak terdapat daftar order</Typography>}
                </div> 
             </div>
        </div>
        <div className={classes.buttonPrintWapper}> 
            <Button 
            size="small" 
            variant="contained" 
            color="primary" 
            aria-label="add"
            disabled={dataOrder.length>0 ? false : true}
            onClick={handlePrint}
            >
                Print
            </Button>
        </div>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={handleClickBack}>
            <ArrowBackIcon/>
        </Fab>
    </div>
  )
})

export default ListOrder