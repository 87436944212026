import axios from 'axios';
import { apiUrl } from '../..';

export const getDataPelanggan = (search,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH Pelanggan'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/pelanggan/?search=${search}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED FETCH DATA PELANGGAN', 
            payload:ResponseAPI.data
         })
    }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED FETCH DATA PELANGGAN',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED FETCH DATA PELANGGAN',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED FETCH DATA PELANGGAN',
                        payload:'Disconnect from server'
                })
            }
    })
}
export const emptyDataPelanggan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA PELANGGAN'
    })
}

export const selectedIDPelanggan = (idpelanggan,nama) =>(dispatch)=>{
    dispatch({
        type:'SELECTED ID PELANGGAN',
        payload:{
            selectedID:idpelanggan,
            selectedNama:nama
        }
    })
}