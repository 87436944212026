import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataJenisBarang } from '../../../config/redux/action';

const JenisBarangCombo = ({name,id,label,error,helperText,valueOption,...rest}) => {
  const {datajenisbarang} = useSelector(state => state.jenisbarangReducers);
  const {data} = useSelector(state=>state.loginReducers);
  const dispatch = useDispatch();
  const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
      dispatch(setDataJenisBarang('',1,token));
    },[token,dispatch])
    const useStyle = makeStyles({
        formControl:{
            width:'100%',
            paddingBottom:'7px',
        },
    });
    const classes = useStyle();
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}
              error={error}
              >
              <InputLabel htmlFor='jenisbarangSelect'>Jenis Barang</InputLabel>
              <Select
              native
              {...rest}
              id={id}
              name={name}
              label="Jenis Barang"
              inputProps={{
                name: 'idjenisbarang',
                id: 'jenisbarangSelect',
              }}
              >
              <option aria-label="None" value="" />
              {datajenisbarang.map((datajenisbarangs=>(
                  <option key={datajenisbarangs.idjenisbarang}  value={datajenisbarangs.idjenisbarang}>{datajenisbarangs.jenisbarang}</option>
              )))}
              </Select>
              <FormHelperText style={{color:'red'}}>{helperText}</FormHelperText>
              </FormControl>
      </div>
    )
}

export default JenisBarangCombo
