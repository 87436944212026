import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchStokRetur = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH STOK RETUR',
        payload :search
    })
}

export const setDataStokRetur = (search,page,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA STOK RETUR',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/retur/?search=${search}&page=${page}&perPage=50`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA STOK RETUR', 
           payload:ResponseAPI.data
        })
        dispatch({
            type:'UPDATE PAGE STOK RETUR',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH STOK RETUR',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH STOK RETUR',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH STOK RETUR',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}