import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { jenisBarangPostToAPI } from '../../../config/redux/action';
import { InputFiled } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@material-ui/lab/Alert';


const JenisBarangForm = ({HandleClickCloseDialog,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {data} = useSelector(state=>state.loginReducers);
    const {tempNewID,isLoadingJenisBarangSave,fetchMessageJenisBarang} = useSelector(state=>state.jenisbarangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
    const validationSchema = yup.object().shape({
        jenisbarang:yup.string().required("Jenis barang tidak boleh kosong")
    })
    const formik = useFormik({
        initialValues:{
            jenisbarang:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idjenisbarang',values.idjenisbarang);
            data.append('jenisbarang',values.jenisbarang);
            dispatch(jenisBarangPostToAPI(data,token));
            setOpenSnackbar(true);
            formik.resetForm();
            HandleClickCloseDialog();
        }
    })
    const classes=useStyle();

    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-jenisbarang-dialog">Jenis Barang Baru</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idjenisbarang" 
                name="idjenisbarang" 
                label="ID jenis barang" 
                disabled={true} 
                value={tempNewID}/>
                <InputFiled 
                id="jenisbarang" 
                name="jenisbarang" 
                label="Jenis Barang"
                value={formik.values.jenisbarang}
                error={formik.touched.jenisbarang && Boolean(formik.errors.jenisbarang)}
                helperText={formik.touched.jenisbarang && formik.errors.jenisbarang}
                onChange={(e)=>formik.setFieldValue('jenisbarang',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingJenisBarangSave}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {fetchMessageJenisBarang==='Data Jenis Barang baru berhasil disimpan' ?
                    <Alert onClose={handleCloseSnackBar} severity="success">{fetchMessageJenisBarang}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error">{fetchMessageJenisBarang}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default JenisBarangForm
