import { Divider, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ReplayIcon from '@material-ui/icons/Replay';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import DataBarang from '../DataBarang';
import DataRetur from '../DataRetur';
import KeranjangBelanja from '../KeranjangBelanja';
import { useDispatch } from 'react-redux';
import { setMessageAddToCart, setMessageAddToRetur, setMessageSave } from '../../config/redux/action';

const Pembelian = () => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'inherit',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            alignContent:'center',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                textAlign:'center',
            },
            padding:'5px',
        },
        title:{
            paddingTop:'10px'
        },navigationButton:{
            paddingTop:'0px',
            width:'100%',
            [theme.breakpoints.up('md')]:{
                width:'70%',
            },
        },
        content:{
            display:'flex',
            flexDirection:'column',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                alignItems:'flex-end',
                marginRight:'10px',
            },
        },buttonNext:{
            height:'30px',
            marginTop:'0px',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                marginTop:'0px',
            },
        },
    }));
    
    const handleChange = (e, newValue) => {
        setValue(newValue);
        dispatch(setMessageAddToCart(""));
        dispatch(setMessageAddToRetur(""));
        dispatch(setMessageSave(""));
    };
    
    const classes = useStyle();
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            hidden={value !== index}
            id={`standart-prevent-tabpanel-${index}`}
            aria-labelledby={`standart-prevent-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>{children}</div>
            )}
          </div>
        );
      }
      function a11yProps(index) {
        return {
          id: `standart-prevent-tab-${index}`,
          'aria-controls': `standart-prevent-tabpanel-${index}`,
        };
      }
      
    return (
        <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant='h5' className={classes.title}>Data Barang Masuk</Typography>
                    <div className={classes.navigationButton}>
                        <Tabs 
                        value={value}
                        onChange={handleChange} 
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{height:'58px'}}
                        >
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'30px',paddingTop:'0px'}} label="Barang" {...a11yProps(0)} icon={<EventOutlinedIcon fontSize="small"/>}/>
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'30px',paddingTop:'0px'}} label="Retur" {...a11yProps(1)} icon={<ReplayIcon fontSize="small"/>}/>
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'30px',paddingTop:'0px'}} label="Daftar" {...a11yProps(2)} icon={<ShoppingBasketIcon fontSize="small"/>}/>
                        </Tabs>
                    </div>
                </div>
                <Divider/>
                <TabPanel value={value} index={0}>
                    <DataBarang/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DataRetur/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <KeranjangBelanja/>
                </TabPanel>
         </div>
    )
}

export default Pembelian
