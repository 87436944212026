import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const StokDiskonDialog = ({handleAdd,handleCancel,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'470px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes = useStyle();
  return (
      <div>
        <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-pengeluaran-dialog">Penambahan Stok Diskon</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda akan memasukan barang ini pada stok diskon?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={handleAdd}>Ya</Button>
                <Button variant="contained" color="secondary" onClick={handleCancel}>Tidak</Button>
                </div>
                </DialogContent>
            </Dialog>
        </div>
  )
}

export default StokDiskonDialog