import { Divider, FormControlLabel, IconButton, makeStyles, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '../../component/molecules';
import { setDataBarang, setDataFilterBarang,setDataStokBarang,setDataStokGudang,setSearchFilter, setSearchStok, setSearchStokGudang } from '../../config/redux/action';
import Cookies from 'js-cookie';
import {AddIcon, DeleteIcon}  from '../../component/assets';
import { AddDataFilterDialog } from '../../component/organism';
import DeleteFilterBarang from '../../component/organism/DeleteFilterBarang';

const FilterBarangPage = () => {
    const useStyles=makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },
        search:{
            marginBottom:'10px',
            [theme.breakpoints.up('md')]:{
                marginRight:'5px',
            },
        },
        radioSearchWapper:{
            display:'flex'
        },
        contentTabel1:{ 
            width:'40%', 
            [theme.breakpoints.up('md')]:{
                marginRight:'10px'
            },
            [theme.breakpoints.down('md')]:{
                marginRight:'10px',
                marginLeft:'10px'
            },
        },
        paper1:{
            width:'100%', 
        },
        contentTabel2:{ 
            width:'60%', 
            [theme.breakpoints.up('md')]:{
                marginRight:'10px'
            },
            [theme.breakpoints.down('md')]:{
                marginRight:'10px'
            },
        },
        paper2:{
            width:'100%'
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            marginTop:'20px'
        },
        tableContent:{
            [theme.breakpoints.up('md')]:{
                width:'80%',
            },
            [theme.breakpoints.down('md')]:{
                width:'100%',
            },
            display:'flex',
            justifyContent:'space-between'
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
            justifyContent:'right'
        },
        radio:{
            width:"600px",
            display:'flex',
            flexDirection:'row',
        },
        tableBottom:{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    const {dataStokBarang,page,search} = useSelector(state => state.stokbarangReducers);
    const {dataStokGudang,pageGudang,searchGudang} = useSelector(state => state.stokGudangReducers);
    const {dataFilter,pageFilter,searchFilter}=useSelector(state=>state.filterBarangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counterBarang,setCounterBarang]=useState(1);
    const [counterMin,setCounterMin]=useState(1);
    const [openDataFilter,setOpenDataFilter]=useState(false);
    const [idbarangFilter,setIdbarangFilter]=useState('');
    const [idbarangToko,setIdbarangToko]=useState('');
    const [namabarangFilter,setNamabarangFilter]=useState('');
    const [stokFilter,setStokFilter]=useState('');
    const [statusView,setStatusView]=useState('Toko');
    const [openDeleteFilter,setOpenDeleteFilter]=useState(false);
    const dispatch=useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        if(statusView==="Toko"){
            dispatch(setDataStokBarang(search,15,counterBarang,token));
        }else if(statusView==="Gudang"){
            dispatch(setDataStokGudang(searchGudang,counterBarang,token));
        }
        dispatch(setDataFilterBarang(searchFilter,counterMin,token));
    },[search,statusView,searchFilter,searchGudang,counterMin,counterBarang,token,dispatch])
    const focus = () =>{
        setCounterBarang(1);
        dispatch(setDataBarang(search,counterBarang,token));
    }
    const handleOpenDataFilter = (idbarang,namabarang)=>{
        setIdbarangFilter(idbarang);
        setNamabarangFilter(namabarang);
        setOpenDataFilter(true);
    }
    const handleCloseDataFilter = ()=>{
        setIdbarangFilter('');
        setNamabarangFilter('');
        setOpenDataFilter(false);
        dispatch(setDataFilterBarang(searchFilter,counterMin,token));
    }
    const handlePrevBarang = () =>{
        setCounterBarang(counterBarang <= 1? 1 : counterBarang - 1);
    }
    const handleNextBarang = () =>{
        if(statusView==='Toko'){
            setCounterBarang(counterBarang === page.totalPage ? page.totalPage : counterBarang + 1);
        }else if(statusView==='Gudang'){
            setCounterBarang(counterBarang === pageGudang.totalPage ? pageGudang.totalPage : counterBarang + 1);
        }
    }
    const handleSearchStokBarang = (e)=>{
        if(statusView==='Toko'){
            dispatch(setSearchStok(e.target.value));
        }else if(statusView==='Gudang'){
            dispatch(setSearchStokGudang(e.target.value));
        }
    }
    const handlePrevMin = () =>{
        setCounterMin(counterMin <= 1? 1 : counterMin - 1);
    }
    const handleNextMin = () =>{
        setCounterMin(counterMin === pageFilter.totalPage ? pageFilter.totalPage : counterMin + 1);
    }
    const handleChangeRadio = (e) =>{
        setStatusView(e.target.value);
        dispatch(setSearchStok(''));
        dispatch(setSearchStokGudang(''));
    }
    const handleOpenDeleteFilter = (idbarangtoko,namabarang,stok)=>{
        setIdbarangToko(idbarangtoko);
        setNamabarangFilter(namabarang);
        setStokFilter(stok);
        setOpenDeleteFilter(true);
    }
    const handleCloseDeleteFilter = ()=>{
        setIdbarangToko('');
        setNamabarangFilter('');
        setStokFilter('');
        setOpenDeleteFilter(false);
        dispatch(setDataFilterBarang(searchFilter,counterMin,token));
    }
    const tableBarangToko = (
        <TableBody>
            {dataStokBarang.map((dataStokBarangs,i)=>(
                <StyledTableRow key={i}>
                    <SytledTableCell align="center">{dataStokBarangs.idbarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokBarangs.namabarang}</SytledTableCell>
                    <SytledTableCell align="center">
                        <Tooltip title="Tambah">
                            <IconButton size='small' 
                            onClick={()=>{handleOpenDataFilter(dataStokBarangs.idbarang,dataStokBarangs.namabarang,dataStokBarangs.stok)}}
                            >
                              <img src={AddIcon} alt="addIcon"/>
                            </IconButton>
                            </Tooltip>
                    </SytledTableCell>
                </StyledTableRow> 
                ))}
        </TableBody>
    )
    const tableBarangGudang = (
        <TableBody>
            {dataStokGudang.map((dataStokGudangs,i)=>(
                <StyledTableRow key={i}>
                    <SytledTableCell align="center">{dataStokGudangs.idbarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataStokGudangs.namabarang}</SytledTableCell>
                    <SytledTableCell align="center">
                        <Tooltip title="Tambah">
                            <IconButton size='small' 
                            onClick={()=>{handleOpenDataFilter(dataStokGudangs.idbarang,dataStokGudangs.namabarang,dataStokGudangs.stok)}}
                            >
                              <img src={AddIcon} alt="addIcon"/>
                            </IconButton>
                            </Tooltip>
                    </SytledTableCell>
                </StyledTableRow> 
                ))}
        </TableBody>
    )
    const tableFilterBarang = (
        <TableBody>
            {dataFilter.map((dataFilters)=>(
                <StyledTableRow key={dataFilters.idbarangtoko}>
                    <SytledTableCell align="center">{dataFilters.idbarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataFilters.namabarang}</SytledTableCell>
                    <SytledTableCell align="center">{dataFilters.stok}</SytledTableCell>
                    <SytledTableCell align="center">{dataFilters.minstok}</SytledTableCell>
                    <SytledTableCell align="center">
                        <Tooltip title="Hapus">
                            <IconButton size='small' 
                            onClick={()=>{handleOpenDeleteFilter(dataFilters.idbarangtoko,dataFilters.namabarang,dataFilters.stok)}}
                            >
                              <img src={DeleteIcon} alt="addIcon"/>
                            </IconButton>
                            </Tooltip>
                    </SytledTableCell>
                </StyledTableRow> 
                ))}
        </TableBody>
    )
  return (
    <div className={classes.root}>
        <Typography className={classes.title} variant="h5">Minimal Stok Display</Typography>
        <Divider/>
        <div className={classes.content}>
            <div className={classes.tableContent}>
                <div className={classes.contentTabel1}>
                    <div className={classes.radioSearchWapper}>
                    <RadioGroup className={classes.radio} aria-label="typeData" name="typeData" value={statusView} onChange={handleChangeRadio}>
                        <FormControlLabel value="Toko" control={<Radio />} label="Toko" />
                        <FormControlLabel value="Gudang" control={<Radio />} label="Gudang" />
                    </RadioGroup>
                    <Search className={classes.search} label="Cari Barang" name="cari-barang" id="cari-barang" 
                    onFocus={focus} 
                    onChange={(e)=>handleSearchStokBarang(e)}/>
                    </div>
                    <Paper className={classes.paper1}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table jenis barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='20%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='10%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead> 
                                {statusView==='Toko'?tableBarangToko : tableBarangGudang}
                            </Table> 
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrevBarang}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                {statusView==='Toko' ?<Typography className={classes.textPage}>{page.currentPage} / {page.totalPage >= 1 ? page.totalPage : page.currentPage}</Typography>
                                :<Typography className={classes.textPage}>{pageGudang.currentPage} / {pageGudang.totalPage >= 1 ? pageGudang.totalPage : pageGudang.currentPage}</Typography> }
                                <Tooltip title="Next">
                                    <IconButton size='small' onClick={handleNextBarang}>
                                        <NavigateNext/>
                                    </IconButton>
                                </Tooltip> 
                                </div>
                         </div>   
                    </Paper>
                </div>
                <div className={classes.contentTabel2}>
                    <Search className={classes.search} label="Cari Barang" name="cari-barang" id="cari-barang" 
                    onFocus={focus} 
                    onChange={(e)=>dispatch(setSearchFilter(e.target.value))}
                    />
                    <Paper className={classes.paper2}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table jenis barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='20%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='20%' align="center">Stok Asal</SytledTableCell>
                                        <SytledTableCell width='25%' align="center">Stok Minimal</SytledTableCell>
                                        <SytledTableCell width='10%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>   
                                {tableFilterBarang} 
                            </Table> 
                        </TableContainer>   
                        <div className={classes.tableBottom}>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrevMin}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                <Typography className={classes.textPage}>{pageFilter.currentPage} / {pageFilter.totalPage >= 1 ? pageFilter.totalPage : pageFilter.currentPage}</Typography>
                                <Tooltip title="Next">
                                    <IconButton size='small' onClick={handleNextMin}>
                                        <NavigateNext/>
                                    </IconButton>
                                </Tooltip> 
                                </div>
                         </div>   
                    </Paper>
                </div>
            </div>
            <AddDataFilterDialog 
            openDataFilter={openDataFilter} 
            closeDataFilter={handleCloseDataFilter} 
            idbarang={idbarangFilter} 
            namabarang={namabarangFilter}
            stok={statusView}/>
            <DeleteFilterBarang
            openDeleteDialogFilter={openDeleteFilter}
            HandleClickCloseDeleteDialog={handleCloseDeleteFilter}
            idbarangToko={idbarangToko}
            namabarang={namabarangFilter}
            stokfilter={stokFilter}/>
        </div>
    </div>
  )
}

export default FilterBarangPage