import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { DateField, InputFiled } from '../../../component/molecules';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { createNewPO } from '../../../config/redux/action/PoOrderAction';
import Cookies from 'js-cookie';
import { format } from 'date-fns';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DialogAddPoOrder = ({handleCloseDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        }
    }));
    const classes = useStyle();
    const {messagePoOrder} = useSelector(state=>state.PoOrderReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const validationSchema = yup.object().shape({
        tanggal:yup.date().max(new Date(),'Tanggal tidak valid'),
        pelanggan:yup.string().required("Nama Pelanggan tidak boleh kosong")
    })
    const formik=useFormik({
        initialValues:{
            tanggal:new Date(),
            pelanggan:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data={
                'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                'pelanggan':values.pelanggan
            }
            dispatch(createNewPO(data,token,setOpenSnackbar,formik,handleCloseDialog));
        }
    })
    const handleChangeTanggal = (value)=>{
        formik.setFieldValue('tanggal',value)
    }
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        handleCloseDialog();
      };
  return (
    <div>
        <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-partner-dialog">Tambah PO Order</DialogTitle>
                <Divider/>
                <DialogContent>
                <DateField 
                    id="tanggal" 
                    name="tanggal" 
                    label="Tanggal" 
                    value={formik.values.tanggal}
                    onChange={(value)=>handleChangeTanggal(value)}
                />
                <InputFiled
                id="pelanggan" 
                name="pelanggan" 
                label="Pelanggan" 
                value={formik.values.pelanggan}
                error={formik.touched.pelanggan && Boolean(formik.errors.pelanggan)}
                helperText={formik.touched.pelanggan && formik.errors.pelanggan}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button onClick={handleCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
            </DialogContent>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={messagePoOrder === 'Berhasil membuat PO Order' ? "success" :"error"}>
                {messagePoOrder}
            </Alert>
            </Snackbar>
        </Dialog>
    </div>
  )
}

export default DialogAddPoOrder