import axios from "axios"
import { apiUrl } from "../.."

export const getAllDataRiwayatPenjualan = (data,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH RIWAYAT PENJUALAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/list/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'UPDATE DATA RIWAYAT PENJUALAN', 
            payload:ResponseAPI.data,
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}
export const emptyTableRiwayatPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY RIWAYAT PENJUALAN'
    })
}

export const getPenjualanDetails = (token,idpenjualan,diskon,ongkir,total,setOpenSnackbar,history)=>(dispatch)=>{
    dispatch({
        type:'FETCH RIWAYAT PENJUALAN'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/penjualan/details/${idpenjualan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET DATA DETAILS PENJUALAN', 
            payload:ResponseAPI.data,
            details:{
                'diskon':diskon,
                'ongkir':ongkir,
                'total':total
            }
         })
         history.push(`/historyPenjualan/details/${idpenjualan}`);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED RIWAYAT PENJUALAN',
                    fetchPayload:'Disconnect from server'
            })
            setOpenSnackbar(true)
        }
    })
}