const initialStateFilterBarang = {
    dataFilter:[],
    pageFilter:{
        currentPage:'1',
        totalPage:'1'
    },
    searchFilter:'',
    filterMessage:'',
    isLoadingFilter:false,
    dataStokTokoFilter:[],
    dataStokGudangFilter:[],
    searchFilterToko:'',
    searchFilterGudang:''
}

const filterBarangReducers = (state=initialStateFilterBarang,action)=>{
    switch(action.type){
        case 'FETCH DATA FILTER BARANG':{
            return{
                ...state,
                isLoadingFilter:action.payload
            }
        }
        case 'SET SEARCH FILTER':{
            return{
                ...state,
                searchFilter : action.payload
            }
        }
        case 'UPDATE PAGE FILTER BARANG':{
            return {
                ...state,
                pageFilter:action.payload
            }
        }
        case 'UPDATE DATA FILTER BARANG':{
            return{
                ...state,
                isLoadingFilter:false,
                dataFilter:action.payload,
            }
        }
        case 'SET SUCESSED FILTER BARANG POST':{
            return{
                ...state,
                isLoadingFilter:false,
                filterMessage:action.payload
            }
        }
        case 'SET FAILED FILTER BARANG POST':{
            return{
                ...state,
                isLoadingFilter:false,
                filterMessage:action.payload
            }
        }
        case 'FAILED FETCH FILTER BARANG':{
            return{
                ...state,
                isLoadingFilter:false,
                filterMessage:action.fetchPayload
            }
        }
        case 'FECTH FILTER BARANG POST':{
            return{
                ...state,
                isLoadingFilter:action.payload
            }
        }
        case 'SET EMPTY FILTER MESSAGE':{
            return{
                ...state,
                filterMessage : action.payload
            }
        }
        case 'FECTH FILTER BARANG DELETE':{
            return{
                ...state,
                isLoadingFilter:action.payload
            }
        }
        case 'SET SUCESSED FILTER BARANG DELETE':{
            return{
                ...state,
                isLoadingFilter:false,
                filterMessage:action.payload
            }
        }
        case 'SET FAILED FILTER BARANG DELETE':{
            return{
                ...state,
                isLoadingFilter:false,
                filterMessage:action.payload
            }
        }
        case 'SET SEARCH FILTER TOKO':{
            return{
                ...state,
                searchFilterToko : action.payload
            }
        }
        case 'SET SEARCH FILTER GUDANG':{
            return{
                ...state,
                searchFilterGudang : action.payload
            }
        }
        case 'UPDATE DATA FILTER TOKO':{
            return{
                ...state,
                isLoadingFilter:false,
                dataStokTokoFilter:action.payload,
            }
        }
        case 'UPDATE DATA FILTER GUDANG':{
            return{
                ...state,
                isLoadingFilter:false,
                dataStokGudangFilter:action.payload,
            }
        }
        default:
        return state
    }
}
export default filterBarangReducers;