const initialStateKeuntungan = {
    dataKeuntungan:[],
    listKeuntungan:[],
    messageKeuntungan:'',
    dataKeuntunganFromAPI:[],
    isLoadingKeuntungan:false,
    mainKeuntungan:[],
    detailsKeuntungan:[],
    rincianLabaRugi:{
        'totalpenjualan':"",
        'hpp':"",
        'diskon':"",
        'ongkir':"",
        "labarugi":"",
        "tglawal":"",
        "tglakhir":""
    }
}

const keuntunganReducers = (state=initialStateKeuntungan,action)=>{
    switch(action.type){
        case 'FAILED GET ID KEUNTUNGAN':{
            return{
                ...state,
                messageKeuntungan:action.payload
            }
        }
        case 'ADD LIST KEUNTUNGAN':{
            return{
                ...state,
                listKeuntungan:[...state.listKeuntungan,action.payload]
            }
        }
        case 'ADD LIST KEUNTUNGAN FROM DRAFT':{
            return{
                ...state,
                listKeuntungan:action.payload
            }
        }
        case 'SUCCESSED SAVE KEUNTUNGAN':{
            return{
                ...state,
                messageKeuntungan:action.payload
            }
        }
        case 'FAILED SAVE KEUNTUNGAN':{
            return{
                ...state,
                messageKeuntungan:action.payload
            }
        }
        case 'EMPTY MESSAGE KEUNTUNGAN':{
            return{
                ...state,
                messageKeuntungan:''
            }
        }
        case 'RESET KEUNTUNGAN LIST':{
            return{
                ...state,
                listKeuntungan:[]
            }
        }
        case 'FETCH DATA KEUNTUNGAN':{
            return{
                ...state,
                isLoadingKeuntungan:true
            }
        }
        case 'UPDATE DATA KEUNTUNGAN':{
            return{
                ...state,
                dataKeuntunganFromAPI:action.payload,
                isLoadingKeuntungan:false
            }
        }
        case 'FAILED GET DATA KEUNTUNGAN':{
            return{
                ...state,
                isLoadingKeuntungan:false,
                messageKeuntungan:action.payload
            }
        }
        case 'SUCCESSED GET DATA DETAILS KEUNTUNGAN':{
            return{
                ...state,
                isLoadingKeuntungan:false,
                mainKeuntungan:action.main,
                detailsKeuntungan:action.details,
                messageKeuntungan:action.message
            }
        }
        case 'SET EMPTY DATA KEUNTUNGAN':{
            return{
                ...state,
                dataKeuntunganFromAPI:[],
                listKeuntungan:[]
            }
        }
        case 'SET EMPTY DATA DETAILS KEUNTUNGAN':{
            return{
                ...state,
                mainKeuntungan:[],
                detailsKeuntungan:[],
            }
        }
        case 'SET MESSAGE KEUNTUNGAN':{
            return{
                ...state,
                messageKeuntungan:action.payload
            }
        }
        case 'SET LABA RUGI':{
            return{
                ...state,
                rincianLabaRugi:action.payload
            }
        }
        case 'RESET LABA RUGI':{
            return{
                ...state,
                rincianLabaRugi:{
                    'totalpenjualan':"",
                    'hpp':"",
                    'diskon':"",
                    'ongkir':"",
                    "labarugi":"",
                    "tglawal":"",
                    "tglakhir":""
                }
            }
        }
        default:
            return state
    }
}
export default keuntunganReducers;