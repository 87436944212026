import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, Typography } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, {useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BarangDeleteToAPI, emptyMessage} from '../../../config/redux/action';
import MuiAlert from '@material-ui/lab/Alert';


const BarangDeleteForm = ({HandleClickCloseDeleteDialog,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingBarangSave,PostBarangStatus,FormBarang} = useSelector(state=>state.barangReducers);
    const {idbarang,namabarang} = FormBarang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(() => {
            dispatch(emptyMessage(""));
        }, 100);
      };
    const onSubmit=()=>{
        dispatch(BarangDeleteToAPI(idbarang,token,setOpenSnackbar,HandleClickCloseDeleteDialog));
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-barang-dialog">Hapus Data Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data barang</Typography>
                    <Typography align='center'>({idbarang}){namabarang}?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingBarangSave}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog}  disabled={isLoadingBarangSave}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {PostBarangStatus==='Data barang berhasil dihapus' ?
                    <Alert onClose={handleCloseSnackBar} severity="success" onClick={HandleClickCloseDeleteDialog}>{PostBarangStatus}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error" onClick={HandleClickCloseDeleteDialog}>{PostBarangStatus}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default BarangDeleteForm
