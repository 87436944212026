const initialStateBarang = {
    dataStokBarang : [],
    dataStokFilter:[],
    page : {
        currentPage:'1',
        totalPage:'1'
    },
    search : '',
    fetchMessage : '',
    isLoadingDataStokBarang : false,
    perPage:'1',
    PenyimpananSelect:{
        idPenyimpanan:'',
        keterangan:''
    },
    dataSales:[]
}

const stokbarangReducers = (state=initialStateBarang,action) =>{
    switch(action.type){
        case 'FETCH DATA STOK BARANG':{
            return{
                ...state,
                isLoadingDataStokBarang:action.payload,
            }
        }
        case 'SET SEARCH TEXT':{
            return{
                ...state,
                search : action.payload
            }
        }
        case 'UPDATE PAGE STOK BARANG':{
            return {
                ...state,
                page:action.payload
            }
        }
        case 'UPDATE DATA STOK BARANG':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                dataStokBarang:action.payload,
                fetchMessage:action.fetchPayload
            }
        }
        case 'FAILED FETCH STOK BARANG':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                fetchMessage:action.fetchPayload
            }
        }
        case 'UPDATE DATA STOK FILTER':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                dataStokFilter:action.payload
            }
        }
        case 'FAILED FETCH STOK FILTER':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
            }
        }
        case 'EMPTY DATA STOK FILTER':{
            return{
                ...state,
                isLoadingDataStokBarang:[],
            }
        }
        case 'SET ID PENYIMPANAN':{
            return{
                ...state,
                PenyimpananSelect:action.payload
            }
        }
        case 'FETCH KOREKSI STOK':{
            return{
                ...state,
                isLoadingDataStokBarang:true,
            }
        }
        case 'SUCCESSED KOREKSI STOK':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                fetchMessage:action.payload
            }
        }
        case 'FAILED KOREKSI STOK':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                fetchMessage:action.payload
            }
        }
        case 'SUCCESSED GET DATA SALES':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                dataSales:action.payload
            }
        }
        case 'FAILED GET DATA SALES':{
            return{
                ...state,
                isLoadingDataStokBarang:false,
                fetchMessage:action.payload
            }
        }
        case 'EMPTY MESSAGE STOK BARANG':{
            return{
                ...state,
                fetchMessage:action.payload
            }
        }
        case 'EMPTY DATA SALES':{
            return{
                ...state,
                dataSales:[]
            }
        }
        default:
            return state
    }
}

export default stokbarangReducers