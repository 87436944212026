import axios from 'axios';
import { apiUrl } from '../..';

export const getDataKeuntungan = (data,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA KEUNTUNGAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/keuntungan/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'UPDATE DATA KEUNTUNGAN',
            payload:ResponseAPI.data
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}

export const deleteSingleKeuntungan = (idkeuntungan,data,token,setOpensnackbar,setOpenDelete)=>(dispatch)=>{
    dispatch({
        type:'FETCH DATA KEUNTUNGAN'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/keuntungan/${idkeuntungan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED SAVE KEUNTUNGAN',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        setOpenDelete(false);
        dispatch(getDataKeuntungan(data,token,setOpensnackbar));
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}
export const deleteAllKeuntungan = (data,token,setOpensnackbar,setOpenDelete)=>(dispatch)=>{
    dispatch({
        type:'FETCH DATA KEUNTUNGAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/keuntungan/all`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED SAVE KEUNTUNGAN',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        setOpenDelete(false);
        dispatch(getDataKeuntungan(data,token,setOpensnackbar));
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}
export const getDataDetailsKeuntungan = (idpenjualan,token,setOpensnackbar,history) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA KEUNTUNGAN'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/keuntungan/details/${idpenjualan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET DATA DETAILS KEUNTUNGAN',
            main:ResponseAPI.datamain,
            details:ResponseAPI.datadetails,
            message:ResponseAPI.message
        })
        history.push(`/keuntungan/rincian/${idpenjualan}`);
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA KEUNTUNGAN',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}

export const emptyDetailsDataKeuntungan = (history) =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY DATA DETAILS KEUNTUNGAN'
    })
    dispatch(setEmptyKeuntungan());
    history.push('/keuntungan');

}
export const setMessageKeuntungan = (message,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE KEUNTUNGAN',
        payload:message
    })
    setOpensnackbar(true);
}
export const setlabarugi = (data) =>(dispatch)=>{
    dispatch({
        type:'SET LABA RUGI',
        payload:{
            'totalpenjualan':data.penjualan,
            'hpp':data.hpp,
            'diskon':data.diskon,
            'ongkir':data.ongkir,
            "labarugi":data.laba,
            "tglawal":data.tglawal,
            "tglakhir":data.tglakhir
        }
    })
}
export const resetLabarugi = (history) =>(dispatch)=>{
    dispatch({
        type:'RESET LABA RUGI',
    })
    dispatch(setEmptyKeuntungan());
    history.push('/keuntungan');
}
export const setEmptyKeuntungan = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY DATA KEUNTUNGAN'
    })
}