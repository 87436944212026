import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchFilter = (search) =>(dispatch)=>{
    dispatch({
        type:'SET SEARCH FILTER',
        payload:search
    })
}
export const setDataFilterBarang = (search,page,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA FILTER BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/filterbarang/?search=${search}&page=${page}&perPage=50`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA FILTER BARANG', 
           payload:ResponseAPI.data
        })
        dispatch({
            type:'UPDATE PAGE FILTER BARANG',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const postFilterBarang = (data,token,setOpenSnackbar,closeDataFilter,resetForm)=>(dispatch)=>{
    dispatch({
        type:'FECTH FILTER BARANG POST',
        payload:true,
    })
    setTimeout(() => {
        axios.post(`${apiUrl.url}v1/suryaharapan/filterbarang/`,data,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'content-type':'application/x-www-form-urlencoded'
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED FILTER BARANG POST',
                payload:ResponseAPI.message
                })
                setOpenSnackbar(true);
                closeDataFilter();
                resetForm();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED FILTER BARANG POST',
                        payload:ResponseError.message,
                        PostPayload:'Failed',
                    })
                setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED FILTER BARANG POST',
                        payload:'Disconnect from server',
                        PostPayload:'Failed',
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
}

export const setEmptyFilterMessage = (message)=>(dispatch)=>{
    dispatch({
        type:'SET EMPTY FILTER MESSAGE',
        payload:message
    })
}
export const FilterBarangDeleteToAPI = (id,stok,token,setOpenSnackbar,handleCloseDeleteFilter) => (dispatch) =>{
    dispatch({
        type:'FECTH FILTER BARANG DELETE',
        payload:true,
    })
    setTimeout(() => {
        axios.delete(`${apiUrl.url}v1/suryaharapan/filterbarang/${id}`,{
            headers:{
                'Authorization':`Bearer ${token}`,
                'content-type':'application/x-www-form-urlencoded',
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SET SUCESSED FILTER BARANG DELETE',
                payload:ResponseAPI.message
                })
                setOpenSnackbar(true);
                handleCloseDeleteFilter();
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'SET FAILED FILTER BARANG DELETE',
                        payload:ResponseError.message,
                        PostPayload:'Failed',
                    })
                    setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'SET FAILED FILTER BARANG DELETE',
                        payload:'Disconnect from server',
                        PostPayload:'Failed',
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
}

export const setSearchViewFilterToko = (search) =>(dispatch)=>{
    dispatch({
        type:'SET SEARCH FILTER TOKO',
        payload:search
    })
}
export const setDataViewFilterToko = (search,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA FILTER BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/filterbarang/toko/?search=${search}`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA FILTER TOKO', 
           payload:ResponseAPI.data
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setSearchViewFilterGudang = (search) =>(dispatch)=>{
    dispatch({
        type:'SET SEARCH FILTER GUDANG',
        payload:search
    })
}
export const setDataViewFilterGudang = (search,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA FILTER BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/filterbarang/gudang/?search=${search}`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA FILTER GUDANG', 
           payload:ResponseAPI.data
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH FILTER BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}