import { Divider } from '@material-ui/core';
import { Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, {useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import { resetAllFormPenjualan, setLeftMenuOpen } from '../../../config/redux/action';
import { DraftLogo, FilterBarangMini, Gudang, HistoryPembelian, HistoryPenjualan, Home, Hutang, KategoriList, Partner, Pembelian, PengeluaranGudang, PenggunaIcon, PenjualanIcon, ProductList, RakIcon, ReturBarang, RPengeluaranGudangMini, StokBarangList, StokGudang, SuryaHarapanText, TransferBarang } from '../../assets';

const LeftMenu = () => {
    const {leftMenuOpen,openHutang} = useSelector(state => state.homeReducers);
    const dispatch = useDispatch();
    const [counter,setCounter]=useState(0);
    const handleClick = () =>{
        setCounter(0);
        dispatch(setLeftMenuOpen(!leftMenuOpen));
    }
    const useStyle = makeStyles((theme)=>({
        root:{
            height:'100%',
            position:'fixed',
            backgroundColor:'#ab8e6a',
        },
        list:{
            marginTop:'10px',
            width:'300px'
        },
        listItem:{
            borderTop:'1px solid grey',
            '&:hover':{
                backgroundColor: '#73c7e6',
            }
        },
        drawer: {
            display:'none',
            // backgroundColor:theme.palette.background.paper,
        },
        paper:{
            backgroundColor:"#e6c08e",
        }
    }));
    const classes = useStyle();
    const openPenjualan =() =>{
        dispatch(resetAllFormPenjualan());
    }
    const modulHutang =(
        <Link to="/hutang" style={{textDecoration:'none',color:'black'}}>
        <ListItem button className={classes.listItem}>
            <ListItemIcon><img src={Hutang} alt="hutang"/></ListItemIcon>
            <ListItemText>Hutang</ListItemText>
        </ListItem> 
        </Link>
    )
    const list = (
        <List className={classes.list}>
            <Link to="/" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={Home} alt="home"/></ListItemIcon>
                <ListItemText>Menu Panel</ListItemText>
            </ListItem>
            </Link>
            <Link to="/pengguna" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={PenggunaIcon} alt="penggunalist"/></ListItemIcon>
                <ListItemText>Pengguna</ListItemText>
            </ListItem> 
            </Link>
            <Link to="/jenisbarang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={KategoriList} alt="kategorilist"/></ListItemIcon>
                <ListItemText>Jenis Barang</ListItemText>
            </ListItem> 
            </Link>
            <Link to="/partner" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={Partner} alt="partnerlist"/></ListItemIcon>
                <ListItemText>Partner</ListItemText>
            </ListItem> 
            </Link>
            <Link to="/pembelian" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={Pembelian} alt="pembelian"/></ListItemIcon>
                <ListItemText>Pembelian</ListItemText>
            </ListItem> 
            </Link>
            {openHutang===true?modulHutang:null}
            <Link to="/transferbarang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={TransferBarang} alt="transferbarang"/></ListItemIcon>
                <ListItemText>Transfer Barang</ListItemText>
            </ListItem>
            </Link>
            <Link to="/barang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={ProductList} alt="productlist"/></ListItemIcon>
                <ListItemText>Data Barang</ListItemText>
            </ListItem>
            </Link> 
            <Link to="/gudang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={Gudang} alt="gudanglist"/></ListItemIcon>
                <ListItemText>Data Gudang</ListItemText>
            </ListItem>
            </Link> 
            <Link to="/stok" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={StokBarangList} alt="stokbaranglist"/></ListItemIcon>
                <ListItemText>Stok Barang</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/stokgudang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={StokGudang} alt="stokgudanglist"/></ListItemIcon>
                <ListItemText>Stok Gudang</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/pengeluarangudang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={PengeluaranGudang} alt="pengeluarangudanglist"/></ListItemIcon>
                <ListItemText>Pengeluaran Gudang</ListItemText>
            </ListItem>
            </Link>
            <Link to="/penyimpanan" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={RakIcon} width="30px" height="30px" alt="Penyimpanan"/></ListItemIcon>
                <ListItemText>Penyimpanan</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/penjualan" onClick={openPenjualan} style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={PenjualanIcon} width="30px" height="30px" alt="penjualan"/></ListItemIcon>
                <ListItemText>Penjualan</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/DraftPenjualan" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={DraftLogo} width="30px" height="30px" alt="DraftPenjualan"/></ListItemIcon>
                <ListItemText>Draft Penjualan</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/historyPembelian" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={HistoryPembelian} width="30px" height="30px" alt="RiwayatPembelian"/></ListItemIcon>
                <ListItemText>R.Pembelian</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/historyPenjualan" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={HistoryPenjualan} width="30px" height="30px" alt="RiwayatPenjualan"/></ListItemIcon>
                <ListItemText>R.Penjualan</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/datapengeluarangudang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={RPengeluaranGudangMini} width="30px" height="30px" alt="RPengeluaranGudang"/></ListItemIcon>
                <ListItemText>R.Pengeluaran Gudang</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/returbarang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={ReturBarang} width="30px" height="30px" alt="RiwayatPenjualan"/></ListItemIcon>
                <ListItemText>Retur Barang</ListItemText>
            </ListItem>    
            </Link>
            <Link to="/filterbarang" style={{textDecoration:'none',color:'black'}}>
            <ListItem button className={classes.listItem}>
                <ListItemIcon><img src={FilterBarangMini} width="30px" height="30px" alt="FilterBarang"/></ListItemIcon>
                <ListItemText>Filter Barang</ListItemText>
            </ListItem>    
            </Link>
            <Divider/>
        </List>
    );
    const kasirList = (
        <List className={classes.list}>
            <Link to="/" style={{textDecoration:'none',color:'black'}}>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon><img src={Home} alt="home"/></ListItemIcon>
                    <ListItemText>Menu Panel</ListItemText>
                </ListItem>
                </Link>
            <Link to="/stok" style={{textDecoration:'none',color:'black'}}>
                <ListItem button className={classes.listItem}>
                    <ListItemIcon><img src={StokBarangList} alt="stokbaranglist"/></ListItemIcon>
                    <ListItemText>Stok Barang</ListItemText>
                </ListItem>    
            </Link>
        </List>
    )
    const history = useHistory();
    const {data} = useSelector(state => state.loginReducers);
    const [token,setToken] = useState(data.token);
    const [level,setLevel]=useState(data.level);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
        if(level===''){
            setLevel(Cookies.get('level'));
        }
    const handleClickKeuntungan = (e)=>{
        setCounter(counter+1);
        if(counter===4){
            history.push('/keuntungan');
        }
    }
    return (
        <nav className={classes.drawer}>
        <Hidden>
        <Drawer 
            classes={{paper:classes.paper}}
            variant='temporary'
            open={leftMenuOpen}
            onClose={handleClick}
            ModalProps={{keepMounted:true}}
        >
            <div style={{display:'flex',justifyContent:'center'}}>
                <img src={SuryaHarapanText} alt="textSuryaHarapan" height="70px" width="200px" 
                onClick={level === "Pemilik"?handleClickKeuntungan:null}
                />
            </div>
            {level==="Pemilik" ? list : kasirList}
        </Drawer>
        </Hidden>
        </nav>
    )
}

export default LeftMenu
