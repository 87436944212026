import { AppBar,  Box,  IconButton, makeStyles, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import { setKonfigurasiNetwork, setLeftMenuOpen, setOpenHutang } from '../../../config/redux/action';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { apiUrl } from '../../../config';
import { ConnectionOff, ConnectionOn } from '../../assets';

const useStyles = makeStyles((theme)=>({
    grow:{
        flexGrow:2,
    },
    appbar:{
        position:'fixed',
        zIndex: theme.zIndex.drawer + 1
    },
    menuButton:{
        marginRight:theme.spacing(2),
    },
}));

const Banner = () => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl,setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    // const handleProfileMenuOpen=(event)=>{
    //     setAnchorEl(event.currentTarget);
    // };
    const handleMenuClose=()=>{
        setAnchorEl(null);
    }
    const [statusNetwork,setStatusNetwork] = useState(()=>{
        if(navigator.onLine){
            return true;
        }else{
            return false;
        }
    })
    const paramUrl = `${apiUrl.url}`;
    const dispatch= useDispatch();
    const {statusServer} = useSelector(state=>state.homeReducers);
    const checkOnlineStatus = useCallback(async () =>{
        try{
            const online = await fetch(paramUrl,{method:'HEAD',mode:'cors'})
            return online.status >= 200 && online.status < 300;
        }catch(err){
            return false;
        }
    },[paramUrl])
    useEffect(()=>{
        setInterval(async ()=>{
            const result = await checkOnlineStatus();
            if(result === true){
                setStatusNetwork(true);
                // dispatch(setKonfigurasiNetwork(true));
            }else{
                setStatusNetwork(false);
                // dispatch(setKonfigurasiNetwork(false));        
            }
        },500)
        if(statusServer!==statusNetwork){
            dispatch(setKonfigurasiNetwork(statusNetwork)); 
        }
    },[checkOnlineStatus,dispatch,statusServer,statusNetwork])

    //open drawer
    const {leftMenuOpen,openHutang} = useSelector(state => state.homeReducers);
    const [counter,setCounter]=useState(0);
    const handleClick = () =>{
        dispatch(setLeftMenuOpen(!leftMenuOpen));
    }
    const menuId = 'primary-account-menu';
    const renderMenu=(
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical:'bottom',horizontal:'right'}}
        id={menuId}
        keepMounted
        transformOrigin={{vertical:'top',horizontal:'right'}}
        open={isMenuOpen}
        onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        </Menu>
    )
    const handleLogOut=(e)=>{
        e.preventDefault();
        Cookies.remove('username');
        Cookies.remove('u_tkn');
        Cookies.remove('identifier');
        history.push('/login');
    }
    const handleClickConnection = () =>{
        setCounter(counter+1);
        if(counter===4){
            dispatch(setOpenHutang(!openHutang));
            setCounter(0);
        }
    }
    return (
        <div className={classes.grow}>
            <AppBar className={classes.appbar} position='static' style={{backgroundColor:'#b57535'} }>
                <Toolbar variant='dense'>
                    <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleClick}
                    >
                    <MenuIcon/>
                    </IconButton>
                <Typography variant="h5" noWrap>
                    Surya Harapan Furniture
                </Typography>
                <div className={classes.grow}/>
                <Typography>{localStorage.getItem('username')}</Typography>
                <Box sx={{display:'flex',alignItems:'center'}}>
                {statusNetwork ? 
                <img src={ConnectionOn} alt="on" style={{width:'15px',height:'15px',marginRight:'10px'}} onClick={handleClickConnection}/>:
                <img src={ConnectionOff} alt="on" style={{width:'15px',height:'15px',marginRight:'10px'}} onClick={handleClickConnection}/>}
                </Box>
                <Tooltip title="Logout">
                    <IconButton 
                    aria-label="logout actions" 
                    onClick={handleLogOut}
                    >
                        <ExitToAppIcon fontSize='default' style={{color:'white'}}/>
                    </IconButton>    
                </Tooltip>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </div>
    )
}
export default Banner;
