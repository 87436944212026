import { Divider, Fab, IconButton, Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { makeStyles, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormEditPengeluaran from '../../component/organism/FormEditPengeluaran';
import { getDataPengeluaranDetails, setEmptyFormDetails, setFormDetails } from '../../config/redux/action';
import Cookies from 'js-cookie';
import FormDeletePengeluaran from '../../component/organism/FormDeletePengeluaran';

const DataPengeluaranGudangList = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'95%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                width:'75%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },
        textPage:{
            padding:'10px',
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'10px',
            width:'95%',
            justifyContent:'center',
            [theme.breakpoints.up('md')]:{
                width:'80%',
                marginTop:'10px',
                marginLeft:'170px',
            },
        },
        buttonBottom:{
           width:'80%',
           paddingTop:'10px',
           textAlign:'center'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.loginReducers);
    const {dataPengeluaranDetails,pengeluaranField} = useSelector(state => state.pengeluaranReducers);
    const {nosuratjalan,tanggal,admin,supir}=pengeluaranField;
    const [open,setOpen] = useState(false);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const HandleClickCloseFormEditPengeluaran = () =>{
        dispatch(setEmptyFormDetails());
        setOpen(false);
        setTimeout(()=>{
            dispatch(getDataPengeluaranDetails(nosuratjalan,token));
        },1000)
    }
    const handleOpenFormEditPengeluaran = (idpengeluaran,idbarang,namabarang,jumlah,variant)=>{
        dispatch(setFormDetails(idpengeluaran,idbarang,namabarang,jumlah,variant));
        setOpen(true);
    }
    const [openDelete,setOpenDelete]=useState(false);
    const handleOpenFormDeletePengeluaran = (idpengeluaran,idbarang,namabarang,jumlah,variant) =>{
        dispatch(setFormDetails(idpengeluaran,idbarang,namabarang,jumlah,variant));
        setOpenDelete(true)
    }
    const HandleClickCloseFormDeletePengeluaran =() =>{
        dispatch(setEmptyFormDetails());
        setOpenDelete(false);
        setTimeout(()=>{
            dispatch(getDataPengeluaranDetails(nosuratjalan,token));
        },1000)
    }
    return (
        <div className={classes.root}>
            <Typography variant='h5' className={classes.title}>List Pengeluaran Barang</Typography>
            <Divider/>
        <div className={classes.mainWapper}>
            <Typography >No Surat Jalan :{nosuratjalan}</Typography>
            <Typography style={{paddingLeft:'20px'}}>Tanggal :{tanggal}</Typography>
            <Typography style={{paddingLeft:'20px'}}>Admin :{admin}</Typography>
            <Typography style={{paddingLeft:'20px'}}>Supir :{supir}</Typography>
        </div>
        <div className={classes.content}>
            <div className={classes.table}>
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                    <SytledTableCell width='25%' align="center">Nama Barang</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Jumlah</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                    <SytledTableCell width='5%' align="center"></SytledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPengeluaranDetails.map((dataPengeluaranDetail=>(
                                    <StyledTableRow key={dataPengeluaranDetail.idpengeluaran}>
                                        <SytledTableCell align="center">{dataPengeluaranDetail.idbarang}</SytledTableCell>
                                        <SytledTableCell align="center">{dataPengeluaranDetail.namabarang}</SytledTableCell>
                                        <SytledTableCell align="center">{dataPengeluaranDetail.jumlah}</SytledTableCell>
                                        <SytledTableCell align="center">{dataPengeluaranDetail.variant}</SytledTableCell>
                                        <SytledTableCell align="center">
                                            <Tooltip title="Ubah jumlah">
                                                <IconButton size='small' 
                                                onClick={()=>{handleOpenFormEditPengeluaran(dataPengeluaranDetail.idpengeluaran,
                                                    dataPengeluaranDetail.idbarang,dataPengeluaranDetail.namabarang,
                                                    dataPengeluaranDetail.jumlah,dataPengeluaranDetail.variant)}}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Hapus Item">
                                                <IconButton size='small' 
                                                onClick={()=>handleOpenFormDeletePengeluaran(dataPengeluaranDetail.idpengeluaran,
                                                    dataPengeluaranDetail.idbarang,dataPengeluaranDetail.namabarang,
                                                    dataPengeluaranDetail.jumlah,dataPengeluaranDetail.variant)}
                                                >
                                                    <DeleteForeverIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </SytledTableCell>
                                    </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.push('/datapengeluarangudang')}>
                <ArrowBackIcon/>
            </Fab>
            <FormEditPengeluaran open={open} aria-labelledby="add-edit-pengeluaran" HandleClickCloseDialog={HandleClickCloseFormEditPengeluaran}/>
            <FormDeletePengeluaran open={openDelete} aria-labelledby="delete-pengeluaran-dialog" HandleClickCloseDeleteDialog={HandleClickCloseFormDeletePengeluaran}/>
            
        </div>
        </div>
    )
}

export default DataPengeluaranGudangList
