import { Divider, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,withStyles, TableRow, Tooltip, Typography, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Search, ServiceDialog } from '../../component/molecules';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setDataStokRetur, setSearchStokRetur } from '../../config/redux/action/stokReturActions';
import {forwardReturToSupplier, getDetailsService} from '../../config/redux/action';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import BuildIcon from '@material-ui/icons/Build';
import { Redo } from '../../component/assets';
import { AddReturToSupplier } from '../../component/organism';

const StokRetur = () => {
    const useStyle=makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'25%',
            [theme.breakpoints.down('xs')]:{
                marginRight:'5%',
            },
        },
        tableWapper:{
            maxWidth:'100%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        },
        table:{
            marginTop:'5px',
            display:'flex',
            width:'700px',
            [theme.breakpoints.down('xs')]:{
                width:'500px', 
            },
        },
        tableContainer:{
            display:'flex',
            flexDirection:'column',
            height:'400px',
            width:'700px',
            maxHeight:'520px',
            [theme.breakpoints.down('xs')]:{
                width:'500px',
                maxWidth:'500px'
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'5px',
        },
        buttonAction:{
            marginTop:'10px',
            width:'700px',
            display:'flex',
            justifyContent:"space-between",
            [theme.breakpoints.down('xs')]:{
                width:'500px'
            },
        }
    }))
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const classes = useStyle();
      const [counter,setCounter] = useState(1);
      const {data} = useSelector(state => state.loginReducers);
      const [token,setToken] = useState(data.token);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const {dataStokRetur,pageStokRetur,searchStokRetur} = useSelector(state=>state.stokReturReducers);
      const dispatch = useDispatch();
      useEffect(() => {
        dispatch(setDataStokRetur(searchStokRetur,counter,token))
      }, [searchStokRetur,counter,token,dispatch])
      const focus = () =>{
        setCounter(1);
        dispatch(setDataStokRetur(searchStokRetur,counter,token));
        }
        const handlePrev = () =>{
            setCounter(counter <= 1? 1 : counter - 1);
        }
        const handleNext = () =>{
            setCounter(counter === pageStokRetur.totalPage ? pageStokRetur.totalPage : counter + 1);
        }
        const history = useHistory();
        const [openServiceDialog,setOpenServiceDialog]=useState(false);
        const HandleOpenServiceDialog = (idbarang,namabarang,jumlah) =>{
            const data={
                'idbarang':idbarang,
                'namabarang':namabarang,
                'jumlah':jumlah
            }
            dispatch(getDetailsService(data));
            setOpenServiceDialog(true);
        }
        const handleCloseService = ()=>{
            setOpenServiceDialog(false);
            dispatch(setDataStokRetur(searchStokRetur,counter,token));
        }
        const [openAddToRetur,setOpenAddToRetur] = useState(false);
        const handleClickForwardToSupplier = (idbarang,namabarang,jumlah,modal) =>{
            setOpenAddToRetur(true);
            const data ={
                'idbarang':idbarang,
                'namabarang':namabarang,
                'sisastok':jumlah,
                'modal':modal
            }
            dispatch(forwardReturToSupplier(data));
        }
        const handleCloseDialogRetur = () =>{
            setOpenAddToRetur(false)
        }
    return (
        <div className={classes.root}>
            <div>
                <Typography className={classes.title} variant="h5">Stok Retur</Typography>
                <Divider/>
                <div className={classes.content}>
                    <Search className={classes.search} label="Cari Barang Retur" name="cari-barang-retur" id="cari-barang-retur" 
                    onFocus={focus} 
                    onChange={(e)=>dispatch(setSearchStokRetur(e.target.value))}
                    />
                </div>
                <div className={classes.tableWapper}>
                    <div className={classes.table}>
                        <Paper>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="table barang" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <SytledTableCell width='25%' align="center">ID Barang</SytledTableCell>
                                            <SytledTableCell width='50%' align="center">Nama Barang</SytledTableCell>
                                            <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                            <SytledTableCell width='20%' align="center"></SytledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataStokRetur.map((dataStokReturs=>(
                                            <StyledTableRow key={dataStokReturs.idbarang}>
                                                <SytledTableCell align="center">{dataStokReturs.idbarang}</SytledTableCell>
                                                <SytledTableCell align="center">{dataStokReturs.namabarang}</SytledTableCell>
                                                <SytledTableCell align="center"><b>{dataStokReturs.jumlah}</b></SytledTableCell>
                                                <SytledTableCell align="center">
                                                    <Tooltip title="Selesai diperbaiki">
                                                        <IconButton size='small' 
                                                        onClick={()=>HandleOpenServiceDialog(dataStokReturs.idbarang,dataStokReturs.namabarang,dataStokReturs.jumlah)}
                                                        >
                                                            <BuildIcon style={{color:'blue'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Retur Ke Supplier">
                                                        <IconButton size='small' 
                                                        onClick={()=>handleClickForwardToSupplier(dataStokReturs.idbarang,dataStokReturs.namabarang,dataStokReturs.jumlah,dataStokReturs.modal)}
                                                        >
                                                            <img src={Redo} alt="redoIcon"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </SytledTableCell>
                                            </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrev}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                <Typography className={classes.textPage}>{pageStokRetur.currentPage} / {pageStokRetur.totalPage >= 1 ? pageStokRetur.totalPage : pageStokRetur.currentPage}</Typography>
                                <Tooltip title="Next">
                                    <IconButton size='small' onClick={handleNext}>
                                        <NavigateNext/>
                                    </IconButton>
                                </Tooltip> 
                                </div>
                        </Paper>
                    </div>
                    <div className={classes.buttonAction} >
                        <Button 
                        size="small" 
                        variant="contained" 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>history.push('/pencatatanretur')}
                        >
                            Pencatatan Retur
                        </Button>
                        <Button 
                        size="small" 
                        variant="contained" 
                        color="secondary" 
                        aria-label="add"
                        onClick={()=>history.push('/retursupplierlist')}
                        >
                            Retur Ke Supplier
                        </Button>
                    </div>
                </div>
                <ServiceDialog openDialog={openServiceDialog} handleCloseServiceDialog={handleCloseService}/>
                <AddReturToSupplier open={openAddToRetur} HandleClickCloseReturDialog={handleCloseDialogRetur}/>
            </div>
        </div>
    )
}

export default StokRetur
