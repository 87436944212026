import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import {Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar} from '@material-ui/core';
import { InputFiled } from '../../molecules';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { emptyMessagePenyimpanan, penyimpananPatchToApi, penyimpananPostToAPI } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const AddNewPenyimpanan = ({HandleClickCloseDialog,idform,tempID,tempKeterangan,status,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingPenyimpanan,messagePenyimpanan} = useSelector(state=>state.penyimpananReducers);
    const [token,setToken] = useState(data.token);
    const dispatch=useDispatch();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes=useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(emptyMessagePenyimpanan());
        },100)
      };
      const validationSchema = Yup.object().shape({
          keterangan : Yup.string().required("Keterangan tidak boleh kosong")
      })
      const formik = useFormik({
          enableReinitialize:true,
          initialValues:{
              idpenyimpanan:tempID || '',
              keterangan:tempKeterangan||''
          },
          validationSchema:validationSchema,
          onSubmit:(values)=>{
            const data = {
                'idpenyimpanan':values.idpenyimpanan,
                'keterangan':values.keterangan
            }
            if(status === 'baru'){
                dispatch(penyimpananPostToAPI(data,token,setOpenSnackbar,HandleClickCloseDialog));
            }else if(status === 'ubah'){
                dispatch(penyimpananPatchToApi(data,token,setOpenSnackbar,HandleClickCloseDialog));
            }
          }
      })
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id={idform}>Penyimpanan Baru</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idpenyimpanan" 
                name="idpenyimpanan" 
                label="ID Penyimpanan" 
                value={formik.values.idpenyimpanan}
                disabled={true} 
                />
                <InputFiled 
                id="keterangan" 
                name="keterangan" 
                label="Keterangan"
                value={formik.values.keterangan}
                error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
                helperText={formik.touched.keterangan && formik.errors.keterangan}
                onChange={(e)=>formik.setFieldValue('keterangan',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" 
                onClick={formik.handleSubmit} 
                disabled={isLoadingPenyimpanan}
                >{status === 'baru' ? 'Simpan' : 'Ubah'}</Button>
                <Button 
                onClick={HandleClickCloseDialog} 
                variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
              <Alert 
              onClose={handleCloseSnackBar} 
              severity={messagePenyimpanan==='Data penyimpanan baru berhasil disimpan' || 'Perubahan data penyimpanan berhasil' ? "success" : "error"}>
                  {messagePenyimpanan}
                  </Alert>
            </Snackbar>              
        </div>
    )
}

export default AddNewPenyimpanan
