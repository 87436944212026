import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { editSingleJumlah, emptyMessageDraftPenjualan } from '../../../config/redux/action';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const EditJumlahDraft = ({openDialogEditJumlah,onCloseDialogEditJumlah,tempIdBarang,tempNamaBarang,tempIdDetails,tempJumlah,tempIdPenjualan,...rest}) => {
    const useStyle = makeStyles(theme=>({
        titleDialog:{
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%',
            },
            display:'flex',
            justifyContent:'center'
        },input:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'150px',
                marginLeft:'5px',
            },
        },
        inputArea:{
            display:'flex',
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%',
            },
            justifyContent:'center',
            
        },
        buttonArea:{
            display:'flex',
            marginTop:'10px',
            maxWidth:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'100%',
            },
            justifyContent:'space-between',
            
        }
    }))
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const {data} = useSelector(state => state.loginReducers);
    const{messageDraftPenjualan} = useSelector(state=>state.draftPenjualanReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(emptyMessageDraftPenjualan(""));
        },200)
    };
    const validationSchema = yup.object().shape({
        jumlah:yup.number().min(1,'Jumlah barang tidak boleh kurang dari 1')
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            jumlah:tempJumlah||0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'idpenjualandetails':tempIdDetails,
                'idpenjualan':tempIdPenjualan,
                'idbarang':tempIdBarang,
                'jumlah':values.jumlah
            }
            dispatch(editSingleJumlah(data,token,setOpenSnackbar,onCloseDialogEditJumlah,formik));
        }
    })
  return (
    <div>
      <Dialog open={openDialogEditJumlah} {...rest}>
      <DialogTitle className={classes.titleDialog} id="add-barang-cart"><Typography variant='body1'>Konfirmasi Edit Jumlah</Typography></DialogTitle>
      <Divider/>
      <DialogContent>
          <Typography>Silahkan ubah jumlah barang {tempNamaBarang} pada kolom dibawah</Typography>
          <div className={classes.inputArea}>
          <TextField className={classes.input}
            id="jumlah"
            name="jumlah"
            label="Jumlah"
            value={formik.values.jumlah}
            error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
            helperText={formik.touched.jumlah && formik.errors.jumlah}
            onChange={formik.handleChange}
            />
         </div>
         <div className={classes.buttonArea}>
            <Button variant='contained' color='primary' onClick={formik.handleSubmit}>Simpan</Button>
            <Button variant='contained' color='secondary' onClick={onCloseDialogEditJumlah}>Batal</Button>
         </div>
      </DialogContent>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageDraftPenjualan === "Perubahan data rincian penjualan berhasil" ? "success" : "error"}
                >
                    {messageDraftPenjualan}
                    </Alert>
            </Snackbar>
    </div>
  )
}

export default EditJumlahDraft