import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { DateField, GudangCombo, SingleDialog } from '../../component/molecules';
import { Button, Tooltip, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles,Snackbar, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { format } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import {emptyPengeluaranList, getAllPengeluaranList, getDataPengeluaranDetails, getNoSuratJalan, postPengeluaranToAPI, setEmptyFieldPengeluaran, setEmptyMessagePengeluaranGudang, setFieldPengeluaran, setTempIdPengeluaran } from '../../config/redux/action';
import { useFormik } from 'formik';
import PrintDialog from '../../component/organism/PrintDialog';
import { useHistory } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const PengeluaranList = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'18%',
            },
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'95%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'95%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'95%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'10px',
            width:'95%',
            justifyContent:'left',
            [theme.breakpoints.up('md')]:{
                marginLeft:'35px',
            },
        },
        header:{
            width:'95%',
            textAlign:'center',
            marginLeft:'10px',
            paddingTop:'3px',
            [theme.breakpoints.up('md')]:{
                marginLeft:'35px',
            },
        },
        input:{
            width:'80px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'120px',
                marginLeft:'5px',
            },
        },
    }));
    const classes = useStyle();
    const dispatch = useDispatch();
    const {FormListPengeluaranGudang,pengeluaranGudangMessage,tempIdPengeluaran,noSuratJalan}=useSelector(state=>state.pengeluaranGudangReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const validationSchema = yup.object().shape({
        admin:yup.string().required('Nama admin gudang tidak boleh kosong'),
        supir:yup.string().required('Nama supir tidak boleh kosong'),
        tanggal:yup.date().max(new Date(),'Tanggal tidak valid'),
        idgudang:yup.string().required('Gudang belum dipilih')
    })
    const openDeleteTransfer=(id)=>{
        setShowDialog(true);
        dispatch(setTempIdPengeluaran(id));
    }
    
    const deleteTransferList = () =>{
        const findBarang = FormListPengeluaranGudang.findIndex(barang=>barang.id===tempIdPengeluaran);
        FormListPengeluaranGudang.splice(findBarang,1);
        dispatch(getAllPengeluaranList());
        setTempIdPengeluaran('');
        setShowDialog(false);
    }
    const onCloseDialog=()=>{
        setShowDialog(false);
    }
    const [totalItem,setTotalItem]=useState(0);
    const [totalJenis,setTotalJenis]=useState(0);
    const [showDialog,setShowDialog]=useState(false);
    const [openSnackbar,setOpenSnackbar]=useState(false);
    useEffect(()=>{
        if(FormListPengeluaranGudang.length>0){
            setTotalJenis(FormListPengeluaranGudang.length);
            let totalItem=FormListPengeluaranGudang.reduce((totalItem,totalI)=>{
                return parseInt(totalItem) + parseInt(totalI.jumlah)
            },0)
            setTotalItem(totalItem);
        }else{
            setTotalJenis(0);
            setTotalItem(0);
        }
  },[FormListPengeluaranGudang])
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setEmptyMessagePengeluaranGudang());
        },500)
    };
    const formik = useFormik({
        initialValues:{
            admin:'',
            supir:'',
            tanggal:new Date(),
            idgudang:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'idgudang':values.idgudang,
                'noSuratJalan':noSuratJalan,
                "supir":values.supir,
                "admin":values.admin,
                'tanggal' :format(values.tanggal,'yyyy-MM-dd'),
                "totalItem":totalItem,
                "totalJenis":totalJenis,
                'list':FormListPengeluaranGudang
            } 
            dispatch(postPengeluaranToAPI(data,token,setOpenPrint,setOpenSnackbar));
            dispatch(setFieldPengeluaran(noSuratJalan,format(values.tanggal,'yyyy-MM-dd'),values.admin,values.supir));
            setTimeout(()=>{
                dispatch(getDataPengeluaranDetails(noSuratJalan,token));
            },500)
            dispatch(getNoSuratJalan(token));
            dispatch(emptyPengeluaranList());
            formik.resetForm();
        }
    })
    const history = useHistory();
    const [openPrint,setOpenPrint]=useState(false);
    const HandleClickCloseFormPrintPengeluaran = () =>{
        setOpenPrint(false);
        dispatch(setEmptyFieldPengeluaran());
    }
    return (
        <div className={classes.root} id="printPage"> 
        <Typography className={classes.header}>Nomor Surat Jalan : {noSuratJalan}</Typography>
        <div className={classes.mainWapper}>
            <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                onChange={(value)=>formik.setFieldValue('tanggal',value)}
            />
            <GudangCombo 
            id="idgudang" 
            name="idgudang"
            error={formik.touched.idgudang && Boolean(formik.errors.idgudang)}
            onChange={formik.handleChange}
            value={formik.values.idgudang}
            helperText={formik.touched.idgudang && formik.errors.idgudang}
            />
            <TextField
            className ={classes.input}
            id="admin"
            nama="admin"
            label="Admin"
            error={formik.touched.admin && Boolean(formik.errors.admin)}
            onChange={formik.handleChange}
            value={formik.values.admin}
            helperText={formik.touched.admin && formik.errors.admin}
            />
            <TextField
            className ={classes.input}
            id="supir"
            nama="supir"
            label="Supir"
            error={formik.touched.supir && Boolean(formik.errors.supir)}
            onChange={formik.handleChange}
            value={formik.values.supir}
            helperText={formik.touched.supir && formik.errors.supir}
            />
        </div>
        <div className={classes.content}>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {FormListPengeluaranGudang.map((FormListPengeluaranGudangs=>(
                                <StyledTableRow key={FormListPengeluaranGudangs.id}>
                                    <SytledTableCell align="center">{FormListPengeluaranGudangs.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{FormListPengeluaranGudangs.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{FormListPengeluaranGudangs.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{FormListPengeluaranGudangs.variant}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        <Tooltip title="Hapus dari list">
                                            <IconButton size='small' 
                                            onClick={()=>openDeleteTransfer(FormListPengeluaranGudangs.id)}
                                            >
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
        </div>
        <div className={classes.content}>
        <div className={classes.GrandTotal}>
            <Typography>Total Pengeluaran Barang</Typography>
            <Typography>{totalItem} Item</Typography>
        </div>
        <div className={classes.GrandTotal}>
            <Typography>Total Jenis Barang</Typography>
            <Typography>{totalJenis} Item</Typography>
        </div>
        <Button 
        aria-label="save transfer" 
        onClick={formik.handleSubmit} 
        variant="contained" 
        color="primary"
        disabled={FormListPengeluaranGudang.length < 1 ? true : false}
        >Simpan Pengeluaran</Button>
        </div>
        <div className={classes.divinder}/>
        <SingleDialog openDialog={showDialog} handleClick={deleteTransferList} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={pengeluaranGudangMessage === "Data pengeluaran gudang berhasil disimpan"?"success":"error"}>
                {pengeluaranGudangMessage}
                </Alert>
        </Snackbar>
        <PrintDialog 
        open={openPrint} 
        aria-labelledby="Print-dialog"
        judul="Print Pengeluaran" 
        content="Apakah anda akan melakukan print pengeluaran barang?"
        forwardLink={()=>history.push('/printPengeluaran')}
        HandleClickClosePrintDialog={HandleClickCloseFormPrintPengeluaran}/>
    </div>
    )
}

export default PengeluaranList
