import axios from 'axios';
import { apiUrl } from '../..';

export const getIDRetur = (tanggal,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH GET ID RETUR SUPPLIER',
        payload: true
    })
    const sendTanggal={
        'tanggal':tanggal
    }
    axios.post(`${apiUrl.url}v1/suryaharapan/retursupplier/getID`,sendTanggal,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESS GET ID RETUR SUPPLIER',
            payload:ResponseAPI.data,
            messageRetur:ResponseAPI.message
        })
    })
}

export const getDataReturByID = (noretur,token,setOpenSnackbar,totalRetur)=>(dispatch)=>{
    dispatch({
        type:'FETCH DATA RETUR SUPPLIER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/retursupplier/details/${noretur}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET DATA RETUR DETAILS', 
            payload:ResponseAPI.data,
            totalRetur:totalRetur
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA RETUR DETAILS',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA RETUR DETAILS',
                    fetchPayload:error.response.data.message
                })
                setOpenSnackbar(true)
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA RETUR DETAILS',
                    fetchPayload:'Disconnect from server'
            })
            setOpenSnackbar(true)
        }
    })
}
export const emptyReturDetails = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA RETUR DETAILS'
    })
}
export const emptyNoRetur = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY NO RETUR'
    })
}
export const addToReturSupplier = (data,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'SET RETUR TO SUPPLIER',
        payload:data,
        messageAddRetur:'Berhasil menambahkan barang ke daftar retur'
    })
    setOpenSnackbar(true);
}
export const FailedAddToReturSupplier = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE RETUR TO SUPPLIER',
        payload:message
    })
}
export const saveReturToSupplier =(data,token,setOpenSnackBar,history)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING SAVE RETUR SUPPLIER',
        payload:true
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/retursupplier/saveretur`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SUCCESS SAVE RETUR SUPPLIER',
                payload:ResponseAPI.message,
            })
            setOpenSnackBar(true);
        })
        .catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'FAILED SAVE RETUR SUPPLIER',
                        payload:false,
                        message:ResponseError.message,
                    })
                setOpenSnackBar(true);
            }else if(error.request){
                dispatch({
                        type:'FAILED SAVE RETUR SUPPLIER',
                        payload:false,
                        message:'Disconnect from server',
                })
                setOpenSnackBar(true);
            }
        })
    },2000)
}
export const setEmptyReturToSupplierList = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY DATA RETUR TO SUPPLIER'
    })
}