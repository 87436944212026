import { Divider, Fab, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RiwayatPenjualanDetails = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            // maxWidth:'800px',
            
        },header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px',
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'100%',
            margin:'0px',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        tableContainer:{
            height:'500px',
            [theme.breakpoints.up('xs')]:{
                height:'400px',
            },
        },
        details:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            marginBottom:'10px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
            [theme.breakpoints.down('xs')]:{
                bottom: theme.spacing(1),
                left: theme.spacing(1),
            },
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const {dataRiwayatPenjualanDetails,details} = useSelector(state=>state.riwayatPenjualanReducers);
    const [totalPenjualan,setTotalPenjualan] =useState(0);
    const history = useHistory();
    useEffect(()=>{
        let newVal=0;
        if(dataRiwayatPenjualanDetails.length>0){
            dataRiwayatPenjualanDetails.map((penjualanDetails=>(
                newVal += penjualanDetails.subtotal
            )))
            setTotalPenjualan(newVal);
        }else{
            history.goBack()
        }
    },[dataRiwayatPenjualanDetails,totalPenjualan,history])
    const dataPenjualan=(
        <div className={classes.content}>
            <Typography>Data Penjualan</Typography>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Note</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRiwayatPenjualanDetails.map((penjualanDetails=>(
                                <StyledTableRow key={penjualanDetails.idbarang}>
                                    <SytledTableCell align="center">{penjualanDetails.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{penjualanDetails.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{penjualanDetails.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{penjualanDetails.satuan}</SytledTableCell>
                                    <SytledTableCell align="center">{penjualanDetails.note}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(penjualanDetails.harga)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(penjualanDetails.subtotal)}</SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Penjualan</Typography>
                        <Typography><b>{new Intl.NumberFormat().format(totalPenjualan)}</b></Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    const {idpenjualan}=useParams();
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>{idpenjualan}</Typography>
            </div>
            <Divider/>
            {dataPenjualan}
            <div className={classes.divinder}/>
            <div className={classes.content}>
                <div className={classes.details}>
                    <Typography>Diskon</Typography>
                    <Typography><b>{new Intl.NumberFormat().format(details.diskon)}</b></Typography></div>
                <div className={classes.details}>
                    <Typography>Ongkir</Typography>
                    <Typography><b>{new Intl.NumberFormat().format(details.ongkir)}</b></Typography>
                </div>
                <div className={classes.GrandTotal}>
                    <Typography>Grand Total</Typography>
                    <Typography><b><u>{new Intl.NumberFormat().format(totalPenjualan - details.diskon + details.ongkir)}</u></b></Typography>
                </div>
            </div>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                <ArrowBackIcon/>
            </Fab>
        </div>
    )
}

export default RiwayatPenjualanDetails
