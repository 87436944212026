import { makeStyles, Divider,Typography,Button, FormControlLabel, Checkbox, 
    // createMuiTheme, 
    // ThemeProvider 
} from '@material-ui/core';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import React, { useEffect, useState , useRef, Fragment } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import { getDataToPrint, resetAllFormPenjualan } from '../../../config/redux/action';
import { useReactToPrint } from 'react-to-print';
import terbilang from 'angka-menjadi-terbilang';
import { WhatsappIcon } from '../../assets';

const PrintFakturPenjualan = React.forwardRef ((props,ref) => {
    // const theme = createMuiTheme({
    //     typography:{
    //         fontFamily:[
    //             'Raleway',
    //         ]
    //     }
    // })
    const useStyle = makeStyles((theme)=>({
        root:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        mainFaktur:{
            width:'21.59cm',
            marginTop:'30px'
        },
        content:{
            display:'flex',
            width:'98%',
            flexDirection:'column',
            alignItems:'center',
            borderBottom:'1px dotted black',
        },
        title:{
            textAlign:'center',
            paddingTop:'5px',
            paddingBottom:'5px',
            borderBottom:'1px dotted black',
        },
        tableBody:{
            height:'100%',
            textAlign:'center',
        },
        table:{
            marginTop:'3px',
            width:'100%',
        },
        tableContainer:{
            height:'100%',
            width:'100%',
            textAlign:'center',
        },
        tableHead:{
            borderTop:'1px dotted black',
            borderBottom:'1px dotted black'
        },
        mainWapper:{
            width:'98%',
            display:'flex',
            marginBottom:'0px',
            justifyContent:'space-between',
            marginTop:'5px',
        },
        subHeaderLeft:{
            width:'300px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            paddingLeft:'20px'
        },
        subHeaderTlp:{
            width:'300px',
            display:'flex',
            paddingLeft:'20px'
        },
        subHeaderCenter:{
            width:'150px',
            display:'flex',
            flexDirection:'column',
            textAlign:'center',
        },
        subHeaderRight:{
            width:'250px',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end',
            paddingRight:'20px'
        },
        subTitle:{
            width:'100%',
            display:'flex',
        },
        title1:{
            width:'90px'
        },
        title2:{
            width:'100px'
        },
        bottomTable:{
            borderBottom:'1px dotted black',
            paddingTop:'2px'
        },
        bottomPage:{
            display:'flex',
            flexDirection:'column',
            marginTop:'5px'
        },
        columnTextSign:{
            display:'flex',
            justifyContent:'space-between',
        },
        columnSign:{
            display:'flex',
            justifyContent:'space-between',
        },
        agreementSign:{
            marginTop:'5px',
            display:'flex',
            flexDirection:'column',
            paddingLeft:'20px'
        },
        textSign:{
            width:'200px',
            textAlign:'center'
        },
        sign:{
            height:'5px',
            width:'200px',
            paddingTop:'30px',
            paddingBottom:'10px',
            textAlign:'center',
        },
        agreement:{
            display:'flex',
            flexDirection:'column',
            height:'150px',
            width:'260px',
            border:'1px solid',
            textAlign:'center',
        },
        contentBottom:{
            width:'98%',
            display:'flex',
            flexDirection:"column",
            justifyContent:'flex-end',
            alignItems:'flex-end',
            borderBottom:'1px dotted black',
        },
        subContentBottom:{
            width:'100%',
            display:'flex',
            justifyContent:'flex-end',
            paddingRight:'15px'
        },
        titleContentBottom:{
            width:'80px',
            textAlign:'right',
            paddingRight:'15px'
        },
        totalItemtBottom:{
            width:'80px',
            textAlign:'center',
        },
        FirstRowContentBottom:{
            width:'350px',
            display:'flex',
            justifyContent:'space-between',
        },
        LastRowContentBottom:{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
        },
        titleOngkirContentBottom:{
            width:'80px',
            textAlign:'right',
            paddingRight:'15px',
            borderBottom:'1px solid black'
        },
        terbilang:{
            marginLeft:'20px',
            width:'550px',
        },
        address:{
            width:'100%',
            display:"flex",
            flexDirection:'column',
            justifyContent:'flex-start',
        },
        alamatLeft:{
            paddingLeft:'20px'
        },
        buttonLast:{
            display:'flex',
            flexDirection:'column',
            marginTop:'20px'
        }
    }));
    const {data} = useSelector(state => state.loginReducers);
    const {penjualanToPrint,detailsToPrint} = useSelector(state => state.penjualanReducers);
    const {pelanggan,tanggal,pembayaran,jatuhtempo,diskon,ongkir,total} = penjualanToPrint;
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const {RFrom,idpenjualan}=useParams();
    const [totalItem,setTotalItem]=useState(0);
    const [totalTransaksi,setTotalTransaksi] = useState(0);
    const [totalPenjualan,setTotalPenjualan] = useState(0);
    useEffect(() => {
        dispatch(getDataToPrint(idpenjualan,token));
        if(totalItem < 1){
            let tempTotalItem=detailsToPrint.reduce((Totalitem,TotalI)=>{
                return Totalitem + TotalI.jumlah
            },0)
            setTotalItem(tempTotalItem);
        }
        if(detailsToPrint.length>0){
            let tempTotal=detailsToPrint.reduce((Total,TotalP)=>{
                return Total + TotalP.subtotal
            },0)
            setTotalPenjualan(tempTotal);
            setTotalTransaksi(totalPenjualan+ongkir-diskon);
        }
        dispatch(resetAllFormPenjualan());
    }, [penjualanToPrint,detailsToPrint,dispatch,idpenjualan,totalItem,token,total,ongkir,diskon,totalPenjualan,totalTransaksi])
      const classes = useStyle();
      const componentRef = useRef();
      const history = useHistory();
      const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
      });
      const handleClickPrint = () =>{
          handlePrint();
          setTimeout(()=>{
            history.push('/historyPenjualan');
          },2000)
      }
      const [printAlamat,setPrintAlamat] = useState(false);
      const handleChangeAlamat = () =>{
          setPrintAlamat(!printAlamat);
      }
      const alamatToko = (
        <div className={classes.address}>
            <Typography variant='h6' className={classes.subHeaderLeft}><b>Surya Harapan</b></Typography>
            <Typography variant='body2' className={classes.subHeaderLeft}><b>Jln. Mangunsarkoro no 168 Cianjur</b></Typography>
            <div className={classes.subHeaderTlp}>
            <img src={WhatsappIcon} alt="waIcon" style={{width:'20px',height:'20px',marginRight:'10px'}}/>
            <Typography variant='body2'><b>0818-1812-2256</b></Typography>
            </div>
        </div>
      )
      const listPenjualan = (
        <table aria-label="table barang" size="small" className={classes.tableContainer}>
            <thead>
                <tr>
                <th className={classes.tableHead} style={{width:'3%',textAlign:"center"}}><Typography variant='body2'>No</Typography></th>
                <th className={classes.tableHead} style={{width:'5%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                <th className={classes.tableHead} style={{width:'7%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
                <th className={classes.tableHead} style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Harga</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Subtotal</Typography></th>
                </tr>
            </thead>
            <tbody>
            {detailsToPrint.map(((detailsToPrints,index)=>(
                <tr className={classes.tableBody} key={detailsToPrints.idbarang}>
                    <td align="center"><Typography variant='body1'>{index+1}</Typography></td>
                    <td align="center"><Typography variant='body1'><b>{detailsToPrints.jumlah  || ''}</b></Typography></td>
                    <td align="center"><Typography variant='body1'><b>{detailsToPrints.satuan || ''}</b></Typography></td>
                    <td align="left"><Typography variant='body1'><b>{detailsToPrints.namabarang +' '+ detailsToPrints.note || ''}</b></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><b>{new Intl.NumberFormat().format(detailsToPrints.harga) || '0'}</b></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><b>{new Intl.NumberFormat().format(detailsToPrints.subtotal) || '0'}</b></Typography></td>
                </tr>
            )))}
            </tbody>
        </table>
      )
      const listDraft = (
        <table aria-label="table barang" size="small" className={classes.tableContainer}>
        <thead>
            <tr>
            <th className={classes.tableHead} style={{width:'3%',textAlign:"center"}}><Typography variant='body2'>No</Typography></th>
            <th className={classes.tableHead} style={{width:'5%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
            <th className={classes.tableHead} style={{width:'7%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
            <th className={classes.tableHead} style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
            </tr>
        </thead>
        <tbody>
        {detailsToPrint.map(((detailsToPrints,index)=>(
            <tr className={classes.tableBody} key={detailsToPrints.idbarang}>
                <td align="center"><Typography variant='body1'>{index+1}</Typography></td>
                <td align="center"><Typography variant='body1'><b>{detailsToPrints.jumlah  || ''}</b></Typography></td>
                <td align="center"><Typography variant='body1'><b>{detailsToPrints.satuan || ''}</b></Typography></td>
                <td align="left"><Typography variant='body1'><b>{detailsToPrints.namabarang +' '+ detailsToPrints.note || ''}</b></Typography></td>
            </tr>
        )))}
        </tbody>
    </table>
      )
      const listSuratJalan = (
        <Fragment>
        <table aria-label="table barang" size="small" className={classes.tableContainer}>
        <thead>
            <tr>
            <th className={classes.tableHead} style={{width:'3%',textAlign:"center"}}><Typography variant='body2'>No</Typography></th>
            <th className={classes.tableHead} style={{width:'5%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
            <th className={classes.tableHead} style={{width:'7%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
            <th className={classes.tableHead} style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
            </tr>
        </thead>
        <tbody>
        {detailsToPrint.map(((detailsToPrints,index)=>(
            <tr className={classes.tableBody} key={detailsToPrints.idbarang}>
                <td align="center"><Typography variant='body1'>{index+1}</Typography></td>
                <td align="center"><Typography variant='body1'><b>{detailsToPrints.jumlah  || ''}</b></Typography></td>
                <td align="center"><Typography variant='body1'><b>{detailsToPrints.satuan || ''}</b></Typography></td>
                <td align="left"><Typography variant='body1'><b>{detailsToPrints.namabarang +' '+ detailsToPrints.note || ''}</b></Typography></td>
            </tr>
        )))}
        </tbody>
    </table>
    </Fragment>
      )
      const bottomFiled = (
        <div className={classes.contentBottom}>
            <div className={classes.FirstRowContentBottom}>
                <div className={classes.subContentBottom}>
                    <div>
                        <Typography variant="body2">Total Item</Typography>
                    </div>
                    <div><Typography variant="body2">:</Typography></div>
                    <div className={classes.totalItemtBottom}>
                        <Typography variant="body2" fontFamily='Raleway' fontWeight='bold'><b>{new Intl.NumberFormat().format(totalItem) || '0'}</b></Typography></div>
                </div>
                <div className={classes.subContentBottom}>
                    <div>
                        <Typography variant="body2">Total</Typography></div>
                    <div>
                        <Typography variant="body2">:</Typography></div>
                    <div className={classes.titleContentBottom}>
                        <Typography variant="body2" fontFamily='Raleway' fontWeight='bold'><b>{new Intl.NumberFormat().format(totalPenjualan) || '0'}</b></Typography></div>
                </div>
            </div>
            <div className={classes.subContentBottom}>
                <div>
                    <Typography variant="body2">Diskon</Typography>
                </div>
                <div>
                    <Typography variant="body2">:</Typography>
                </div>
                <div className={classes.titleContentBottom}>
                    <Typography variant="body2" fontFamily='' fontWeight='bold'><b>{new Intl.NumberFormat().format(diskon) || '0'}</b></Typography>
                </div>
            </div>
            <div className={classes.subContentBottom}>
                <div>
                    <Typography variant="body2">Ongkos Kirim</Typography>
                </div>
                <div>
                    <Typography variant="body2">:</Typography>
                </div>
                <div className={classes.titleOngkirContentBottom}>
                    <Typography variant="body2" fontFamily='Raleway' fontWeight='bold'><b>{new Intl.NumberFormat().format(ongkir) || '0'}</b></Typography>
                </div>
            </div>
            <div className={classes.LastRowContentBottom}>
                <div>
                    <Typography className={classes.terbilang} variant="body2">
                    <b>{totalTransaksi > 0 ? terbilang(totalTransaksi)+' rupiah ' : terbilang(0)+' rupiah '}</b>
                    </Typography>
                </div>
                <div className={classes.subContentBottom}>
                    <div className={classes.titleContentBottom}>
                        <Typography variant="body2">
                            <b>{new Intl.NumberFormat().format(totalTransaksi) || '0'}</b>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
      )
      const headerTransaksi = (
        <Fragment>
            <div className={classes.subHeaderLeft}>
                <Typography variant="caption">
                <div className={classes.subTitle}>
                    <div className={classes.title1}>No Surat Jalan</div>
                    <div>:</div>
                    <div>{penjualanToPrint.idpenjualan || ''}</div>
                </div>
                </Typography>
                <Typography variant="caption">
                    <div className={classes.subTitle}>
                    <div className={classes.title1}>Pelanggan </div>
                    <div>:</div>
                    <div><b>{pelanggan  || ''}</b></div>
                    </div>
                </Typography>
            </div>
            <div className={classes.subHeaderCenter}>
                <Typography variant="caption">
                    <div className={classes.subTitle}>
                        <div className={classes.title2}>Pembayaran</div>
                        <div>:</div>
                        <div> {pembayaran  || ''}</div>
                    </div>
                </Typography>
            </div>
            <div className={classes.subHeaderRight}>
                <Typography variant="caption">
                <div className={classes.subTitle}>
                    <div className={classes.title2}>Tanggal</div>
                    <div>:</div>
                    <div>{tanggal !== undefined ? format(new Date(tanggal),'dd MMM yyyy') : ''}</div>
                </div>
                </Typography>
                <Typography variant="caption">
                <div className={classes.subTitle}>
                    <div className={classes.title2}>Jatuh Tempo</div>
                    <div>:</div>
                    <div>{jatuhtempo !== undefined ? format(new Date(jatuhtempo),'dd MMM yyyy') : ''}</div>
                </div>
                </Typography>
            </div>
        </Fragment>
      )
      const headerSJ = (
        <Fragment>
            <div className={classes.subHeaderLeft}>
                <Typography variant="caption">
                <div className={classes.subTitle}>
                    <div className={classes.title1}>No Nota</div>
                    <div>:</div>
                    <div>{"SJ" +idpenjualan.substring(3,idpenjualan.length) || ''}</div>
                </div>
                </Typography>
                <Typography variant="caption">
                    <div className={classes.subTitle}>
                    <div className={classes.title1}>Kepada Yth </div>
                    <div> </div>
                    <div><b>{pelanggan  || ''}</b></div>
                    </div>
                </Typography>
            </div>
            <div className={classes.subHeaderRight}>
                <Typography variant="caption">
                <div className={classes.subTitle}>
                    <div className={classes.title2}>Tanggal</div>
                    <div>:</div>
                    <div>{tanggal !== undefined ? format(new Date(tanggal),'dd MMM yyyy') : ''}</div>
                </div>
                </Typography>
            </div>
        </Fragment>
      )
      const signAgreement = (
        <Fragment>
        <div className={classes.agreementSign}>
            {/* <div><Typography variant='caption' color='error'>DENGAN UTUH DAN JUMLAH SESUAI NOTA</Typography></div> */}
                <div style={{display:'flex'}}>
                    {/* <div className={classes.agreement}>
                        <div>
                            Pengirim
                        </div>
                        <div>
                            --------------
                        </div>
                    </div> */}
                    <div className={classes.agreement}>
                    <div><Typography variant='body2'><b><i>Barang sudah di cek dengan</i></b></Typography></div>
                    <div><Typography variant='body2'><b><i>kondisi utuh dan jumlah sesuai nota</i></b></Typography></div>
                        <div style={{display:'flex',textAlign:'center',height:'100%',flexDirection:'column',justifyContent:'space-between'}}>
                            <div>
                                Penerima
                            </div>
                            <div>
                                --------------
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft:'2px',display:'flex',flexDirection:'column'}}>
                    <Typography variant='caption'><b><i>*Note :</i></b></Typography>
                    <Typography variant='caption'><b><i>Barang harap diperiksa dengan baik,</i></b></Typography>
                    <Typography variant='caption'><b><i>Kami tidak melayani komplain setelah faktur di tanda tangani</i></b></Typography>
                    </div>
                </div>
        </div>
        </Fragment>
      )
    return (
        // <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <div className={classes.mainFaktur} ref={componentRef}>
                {/* <Typography variant='body1' className={classes.title}>{RFrom === "Penjualan" ? <b>NOTA PENJUALAN</b> : RFrom === "Draft" ? <b>NOTA DRAFT PENJUALAN</b> : <b>SURAT JALAN</b>}</Typography> */}
                {printAlamat === false ? alamatToko : null}
                <Divider variant='middle'/>
                <div className={classes.mainWapper}>
                    {RFrom === "suratjalan" ? headerSJ : headerTransaksi}
                </div>
                <div className={classes.content}>
                    <div className={classes.table}>
                        {RFrom === "Penjualan" ? listPenjualan : RFrom === "Draft" ? listDraft : listSuratJalan}
                    </div>
                </div>
                    {RFrom === "Penjualan" ? bottomFiled : null}
                <div className={classes.bottomPage}>
                    {RFrom === "Penjualan" ?
                    signAgreement
                    // <Fragment>
                    // <Typography variant='caption' className={classes.alamatLeft}><b><i>Barang di terima dalam kondisi baik dan jumlah barang sesuai dengan nota</i></b></Typography>
                    // <Typography variant='caption' className={classes.alamatLeft}><b><i>(Mohon maaf kami tidak menerima komplen setelah faktur di tanda tangan)</i></b></Typography>
                    // </Fragment>
                    : RFrom === "suratjalan" ? <Typography variant='caption' className={classes.alamatLeft}><b><i>Dokumen ini merupakan bukti penerimaan barang dan bukan bukti penjualan, invoice akan disertakan dengan surat jalan ini</i></b></Typography>
                    : null}
                    {RFrom === "suratjalan" ? 
                    <Fragment>
                    <div className={classes.columnTextSign}>
                        <div className={classes.textSign}>
                            Pengirim
                        </div>
                        <div className={classes.textSign}>
                            Penerima
                        </div>
                    </div>
                    <div className={classes.columnSign}>
                        <div className={classes.sign}>
                            -----------------
                        </div>
                        <div className={classes.sign}>
                            -----------------
                        </div>
                    </div>
                    </Fragment>
                    :
                    // <Fragment>
                    // <div className={classes.columnTextSign}>
                    //     <div className={classes.textSign}>
                    //         Pelanggan
                    //     </div>
                    //     <div className={classes.textSign}>
                    //         Kasir
                    //     </div>
                    //     <div className={classes.textSign}>
                    //         Gudang
                    //     </div>
                    // </div>
                    // <div className={classes.columnSign}>
                    //     <div className={classes.sign}>
                    //         -----------------
                    //     </div>
                    //     <div className={classes.sign}>
                    //         -----------------
                    //     </div>
                    //     <div className={classes.sign}>
                    //         -----------------
                    //     </div>
                    // </div>
                    // </Fragment>
                    null}   
                </div>
                </div>
                <div className={classes.buttonLast}>
                <FormControlLabel
                    control={<Checkbox checked={printAlamat} onChange={handleChangeAlamat} name="alamat" />}
                    label="Sembunyikan Alamat"
                />
                    <Button variant='contained' color='primary' onClick={handleClickPrint}>Print</Button>
                </div>
            </div>
            // </ThemeProvider>
    )
})

export default PrintFakturPenjualan
