import { Button, Fab, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { resetLabarugi } from '../../../config/redux/action';

const PrintLabaRugi = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        mainFaktur:{
            width:'21.59cm',
            marginTop:'30px',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        },
        wapper:{
            display:'flex',
            width:'30%',
            justifyContent:'space-between',
        },
        wapperContent:{
            display:'flex',
            width:'100%',
            justifyContent:'center',
            marginTop:'20px'
        },
        title:{
            textAlign:'center',
            paddingTop:'5px',
            paddingBottom:'5px',
            borderBottom:'1px dotted black',
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const classes = useStyle();
    const componentRef = useRef();
    const {rincianLabaRugi} = useSelector(state=>state.keuntunganReducers);
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
      });
      const handleClickPrint = () =>{
        handlePrint();
    }
    const dispatch=useDispatch();
    const history=useHistory();
    const handleClickBack = () =>{
        dispatch(resetLabarugi(history));
    }
  return (
    <div className={classes.root}>
        <div className={classes.mainFaktur} ref={componentRef}>
            <div className={classes.title}>
                <Typography variant='body1' ><b>LAPORAN LABA/RUGI</b></Typography>
                <Typography variant='body1' ><b>Tanggal {format(new Date(rincianLabaRugi.tglawal),'dd MMMM yyyy')} - {format(new Date(rincianLabaRugi.tglakhir),'dd MMMM yyyy')}</b></Typography>
            </div>
            <div className={classes.wapperContent}>
                <div className={classes.wapper}>
                    <div className={classes.content}>
                        <Typography variant='body1' ><b>Penjualan</b></Typography>
                        <Typography variant='body1' ><b>HPP</b></Typography>
                        <Typography variant='body1' ><b>Diskon</b></Typography>
                        <Typography variant='body1' ><b>Ongkir</b></Typography>
                        <Typography variant='body1' ><b>Laba/Rugi</b></Typography>
                    </div>
                    <div className={classes.content}>
                        <Typography variant='body1' ><b>{new Intl.NumberFormat().format(rincianLabaRugi.totalpenjualan)}</b></Typography>
                        <Typography variant='body1' ><b>{new Intl.NumberFormat().format(rincianLabaRugi.hpp)}</b></Typography>
                        <Typography variant='body1' ><b>{new Intl.NumberFormat().format(rincianLabaRugi.diskon)}</b></Typography>
                        <Typography variant='body1' style={{borderBottom:'1px solid black',width:'100%',textAlign:'center'}}><b>{new Intl.NumberFormat().format(rincianLabaRugi.ongkir)}</b></Typography>
                        <Typography variant='body1' ><b>{new Intl.NumberFormat().format(rincianLabaRugi.labarugi)}</b></Typography>
                    </div>
                </div>
            </div>
        </div>
        <Button variant="contained" color="primary" style={{marginTop:'30px'}} onClick={()=>handleClickPrint()}>Cetak laba/rugi</Button>
    
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
    </div>
  )
}

export default PrintLabaRugi