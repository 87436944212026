import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as yup from 'yup'
import { addCartReturBarang, failedAddCartBarangRetur,setMessageAddToRetur } from '../../../config/redux/action/pembelianAction';
import { InputFiled } from '../../molecules';
import MuiAlert from '@material-ui/lab/Alert';
import { getIDRetur } from '../../../config/redux/action/returSupplierActions';
import Cookies from 'js-cookie';
import { format } from 'date-fns';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const AddCartRetur = ({HandleClickCloseReturDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes =useStyle();
    const {FormRetur} = useSelector(state=>state.returReducers);
    const {idbarang,namabarang,modal,sisastok,ref} = FormRetur;
    const {cartReturBarang,messageRetur} = useSelector(state => state.pembelianReducers);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const {data}=useSelector(state=>state.loginReducers);
    const handleCloseSnackBar = () => {
        dispatch(setMessageAddToRetur(''))
    };
    useEffect(() => {
        if(messageRetur !== ""){
            setOpenSnackbar(true);
        }else{
            setOpenSnackbar(false);
        }
    }, [messageRetur,openSnackbar])
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1').max(sisastok,'Jumlah barang tidak mencukupi'),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang||0,
            namabarang:namabarang||0,
            modal:modal||0,
            jumlah:sisastok||0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const subtotal = values.jumlah * modal;
            const existBarang = cartReturBarang.filter(barang =>barang.idbarang === idbarang && barang.ref === ref);
            if(existBarang.length > 0){
                dispatch(failedAddCartBarangRetur());
            }else{
                if(cartReturBarang.length>0){
                    dispatch(addCartReturBarang(idbarang,namabarang,values.jumlah,modal,subtotal,ref));
                    HandleClickCloseReturDialog();
                }else if(cartReturBarang.length<1){
                    dispatch(addCartReturBarang(idbarang,namabarang,values.jumlah,modal,subtotal,ref));
                    dispatch(getIDRetur(format(new Date(),'yyyy-MM-dd'),token));
                    HandleClickCloseReturDialog();
                }
            }
        }
    })
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="retur-barang-dialog">Rincian Barang Retur</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                disabled={true} 
                value={formik.values.idbarang}/>
                <InputFiled 
                id="namabarang" 
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                id="modal" 
                name="modal" 
                label="Modal"
                value={formik.values.modal}
                disabled={true}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} 
                >Tambah</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseReturDialog} 
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {messageRetur === 'Berhasil menambahkan data ke daftar' ?
                    <Alert onClose={handleCloseSnackBar} severity={"success"}>{messageRetur}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity={"error"}>{messageRetur}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default AddCartRetur
