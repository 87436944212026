import { Button, Divider, IconButton, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { DateField, SingleDialog } from '../../component/molecules';
import * as yup from 'yup';
import { format } from 'date-fns';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { deleteAllKeuntungan, deleteSingleKeuntungan, getDataDetailsKeuntungan, getDataKeuntungan, setlabarugi, setMessageKeuntungan } from '../../config/redux/action';
import DetailsIcon from '@material-ui/icons/Details';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useHistory } from 'react-router-dom';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const KeuntunganPage = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        content:{
            display:'flex',
            flexDirection:'row',
            [theme.breakpoints.down('xs')]:{
                flexDirection:'column',
            },
            alignItems:'center',
            marginTop:'5px',
            width:'100%',
        },
        paper:{
            display:'flex',
            flexDirection:'column',
            alignItems:'left',
            marginLeft:'20px',
            width:'70%',
            [theme.breakpoints.down('xs')]:{
                width:'90%',
            }
        },
        dateWapper:{
            display:'flex',
            width:'70%',
            marginLeft:'20px',
            [theme.breakpoints.down('xs')]:{
                width:'90%',
            },
            alignItems:'left'
        },
        tableContainer:{
            height:'420px',
            [theme.breakpoints.down('xs')]:{
                height:'520px'
            },
        },
        page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        rightContent:{
          height:'420px',
          width:'30%',
          [theme.breakpoints.down('xs')]:{
            marginTop:'20px',
            height:'520px',
            width:'90%',
          }
        },
        rightSide:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            height:'150px',
            borderRadius:'10px',
            marginLeft:'15px',
            marginRight:'20px',
            [theme.breakpoints.down('xs')]:{
                marginTop:'20px',
                height:'150px',
                width:'90%',
            },
            padding:'5px',
            border:'1px solid black'
        },
        judul:{
            textAlign:'left'
        },
        isi:{
            textAlign:'right'
        },
        buttonBottom:{
            display:'flex',
            justifyContent:'center',
            marginLeft:'20px',
            marginTop:'20px',
            marginRight:'20px'
        },
        buttonBottomTabel:{
            display:'flex',
            justifyContent:'center',
            marginTop:'10px',
            marginBottom:'10px',
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const classes = useStyle();
      const {data} = useSelector(state => state.loginReducers);
      const dispatch = useDispatch();
      const [token,setToken] = useState(data.token);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const [openSnackbar,setOpenSnackbar] = useState(false);
      const handleCloseSnackBar = () => {
          setOpenSnackbar(false);
      };
      const validationSchema = yup.object().shape({
        tanggalAwal:yup.date().max(yup.ref('tanggalAkhir'),'Tanggal awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
        tanggalAkhir:yup.date().min(yup.ref('tanggalAwal'),'Tanggal Akhir tidak boleh kurang dari tanggal awal').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
      })
      const formik = useFormik({
          initialValues:{
              tanggalawal:new Date(),
              tanggalakhir:new Date()
          },
          validationSchema:validationSchema,
          onSubmit:(values)=>{
              const data={
                  'tanggalawal':format(values.tanggalawal,'yyyy-MM-dd'),
                  'tanggalakhir':format(values.tanggalakhir,'yyyy-MM-dd')
              }
              dispatch(getDataKeuntungan(data,token,setOpenSnackbar));
          }
      })
      const history = useHistory();
      const handleClickOpenDetailsKeuntungan = (idpenjualan)=>{
          dispatch(getDataDetailsKeuntungan(idpenjualan,token,setOpenSnackbar,history));
      }
      const {dataKeuntunganFromAPI,messageKeuntungan} = useSelector(state=>state.keuntunganReducers);
      const tableKeuntungan =(
        <TableBody>
            {dataKeuntunganFromAPI.map((dataKeuntungan=>(
            <StyledTableRow key={dataKeuntungan.idkeuntungan}>
                <SytledTableCell align="center">{dataKeuntungan.idpenjualan}</SytledTableCell>
                <SytledTableCell align="center">{format(new Date(dataKeuntungan.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataKeuntungan.penjualan)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataKeuntungan.modal)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataKeuntungan.diskon)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataKeuntungan.ongkir)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataKeuntungan.keuntungan)}</SytledTableCell>
                <SytledTableCell align="center">
                        <Tooltip title="Rincian Keuntungan">
                            <IconButton size='small' 
                            onClick={()=>{handleClickOpenDetailsKeuntungan(dataKeuntungan.idpenjualan)}}
                            >
                                <DetailsIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Hapus Keuntungan">
                            <IconButton size='small' 
                            onClick={()=>{handleDeleteSingle(dataKeuntungan.idpenjualan)}}
                            >
                                <DeleteForeverIcon/>
                            </IconButton>
                        </Tooltip>
                    </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
      )
    
      const [totalPenjualan,setTotalPenjualan]=useState(0);
      const [hpp,setHpp]=useState(0);
      const [diskon,setDiskon]=useState(0);
      const [ongkir,setOngkir]=useState(0);
      const [laba,setLaba]=useState(0);
      useEffect(()=>{
        if(dataKeuntunganFromAPI.length>0){
            let totalPenjualan=dataKeuntunganFromAPI.reduce((Penjualan,TotalP)=>{
                return Penjualan + TotalP.penjualan
            },0)
            setTotalPenjualan(totalPenjualan);
            let totalHPP=dataKeuntunganFromAPI.reduce((HPP,TotalH)=>{
                return HPP + TotalH.modal
            },0)
            setHpp(totalHPP);
            let totalDiskon=dataKeuntunganFromAPI.reduce((Diskon,TotalD)=>{
                return Diskon + TotalD.diskon
            },0)
            setDiskon(totalDiskon);
            let totalOngkir=dataKeuntunganFromAPI.reduce((Ongkir,TotalO)=>{
                return Ongkir + TotalO.ongkir
            },0)
            setOngkir(totalOngkir);
            let labarugi = totalPenjualan-totalHPP-totalDiskon+totalOngkir;
            setLaba(labarugi);
        }else{
            setTotalPenjualan(0);
            setHpp(0);
            setDiskon(0);
            setOngkir(0);
            setLaba(0);
        }
        if(dataKeuntunganFromAPI.length<1){
            const data={
                'tanggalawal':format(formik.values.tanggalawal,'yyyy-MM-dd'),
                'tanggalakhir':format(formik.values.tanggalakhir,'yyyy-MM-dd')
            }
            dispatch(getDataKeuntungan(data,token,setOpenSnackbar));
            const dataRinci={
                'penjualan':totalPenjualan,
                'hpp':hpp,
                'diskon':diskon,
                'ongkir':ongkir,
                "laba":laba,
                "tglawal":formik.values.tanggalawal,
                "tglakhir":formik.values.tanggalakhir
            }
            dispatch(setlabarugi(dataRinci));
        }else{
            const dataRinci={
                'penjualan':totalPenjualan,
                'hpp':hpp,
                'diskon':diskon,
                'ongkir':ongkir,
                "laba":laba,
                "tglawal":formik.values.tanggalawal,
                "tglakhir":formik.values.tanggalakhir
            }
            dispatch(setlabarugi(dataRinci));
        }
      },[dataKeuntunganFromAPI,dispatch,formik.values.tanggalawal,formik.values.tanggalakhir,token,totalPenjualan,hpp,diskon,ongkir,laba])
      const [openDelete,setOpenDelete]=useState(false);
      const [statusButton,setStatusButton]=useState("");
      const [refIdPenjualan,setRefIdPenjualan]=useState("");
      const onCloseDialog = () =>{
          setOpenDelete(false);
      }
      const handleDeleteAll = (e) =>{
          setStatusButton("All");
          setOpenDelete(true);
      }
      const handleDeleteSingle = (idpenjualan) =>{
          setRefIdPenjualan(idpenjualan);
          setStatusButton("Single");
          setOpenDelete(true);
      }
      const deleteAll = () =>{
        if(dataKeuntunganFromAPI.length>0){
            const data={
                'keuntunganlist':dataKeuntunganFromAPI,
                'tanggalawal':format(formik.values.tanggalawal,'yyyy-MM-dd'),
                'tanggalakhir':format(formik.values.tanggalakhir,'yyyy-MM-dd')
            }
          dispatch(deleteAllKeuntungan(data,token,setOpenSnackbar,setOpenDelete));
        }else{
            dispatch(setMessageKeuntungan("Tidak terdapat data keuntungan",setOpenSnackbar));
            setOpenDelete(false)
        }
      }
      const deleteSingle = () =>{
        const data={
            'tanggalawal':format(formik.values.tanggalawal,'yyyy-MM-dd'),
            'tanggalakhir':format(formik.values.tanggalakhir,'yyyy-MM-dd')
        }
        dispatch(deleteSingleKeuntungan(refIdPenjualan,data,token,setOpenSnackbar,setOpenDelete));
      }
      const handleClickPrintlaba = () =>{
          history.push('/keuntungan/printlabarugi')
      }
  return (
    <div className={classes.root}>
        <Typography className={classes.title} variant="h5">Data Keuntungan</Typography>
        <Divider/>
            <div className={classes.dateWapper}>
                <DateField 
                    id="tanggalawal" 
                    name="tanggalAwal" 
                    label="Tanggal Awal" 
                    value={formik.values.tanggalawal}
                    errors={formik.touched.tanggalawal && Boolean(formik.errors.tanggalawal)}
                    helperText={formik.touched.tanggalawal && formik.errors.tanggalawal}
                    onChange={(value)=>formik.setFieldValue('tanggalawal',value)}
                />
                <DateField id="tanggalakhir" 
                    name="tanggalAkhir" 
                    label="Tanggal Akhir" 
                    value={formik.values.tanggalakhir}
                    errors={formik.touched.tanggalakhir && Boolean(formik.errors.tanggalakhir)}
                    helperText={formik.touched.tanggalakhir && formik.errors.tanggalakhir}
                    onChange={(value)=>formik.setFieldValue('tanggalakhir',value)}
                />
                <Tooltip title="Tampilkan keuntungan">
                    <IconButton
                    id='searchButton'
                    name='searchButton'
                    onClick={formik.handleSubmit}
                    >
                    <SearchIcon/>
                </IconButton>
                </Tooltip>
            </div>
            <div className={classes.content}>
                <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='7%' align="center">No Faktur</SytledTableCell>
                                    <SytledTableCell width='7%' align="center">Tanggal</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Penjualan</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Modal</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Diskon</SytledTableCell>
                                    <SytledTableCell width='5%' align="center">Ongkir</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Laba/Rugi</SytledTableCell>
                                    <SytledTableCell width='5%' align="center"/>
                                </TableRow>
                            </TableHead>
                                {dataKeuntunganFromAPI.length <1 ? null : tableKeuntungan}
                        </Table>
                    </TableContainer>
                    <div className={classes.buttonBottomTabel}>
                    <Button variant='contained' color="secondary" onClick={()=>handleDeleteAll()}>Hapus semua keuntungan</Button>
                    </div>
                </Paper>
                    <div className={classes.rightContent}>
                        <div className={classes.rightSide}>
                            <div className={classes.judul}>
                                <Typography variant='body1'>Total penjualan</Typography>
                                <Typography variant='body1'>HPP</Typography>
                                <Typography variant='body1'>Diskon</Typography>
                                <Typography variant='body1'>Ongkos Kirim</Typography>
                                <Typography variant='body1'>Laba/Rugi</Typography>
                            </div>
                            <div className={classes.isi}>
                                <Typography variant='body1'>{new Intl.NumberFormat().format(totalPenjualan)}</Typography>
                                <Typography variant='body1'>{new Intl.NumberFormat().format(hpp)}</Typography>
                                <Typography variant='body1'>{new Intl.NumberFormat().format(diskon)}</Typography>
                                <Typography variant='body1' style={{borderBottom:'1px solid black'}}>{new Intl.NumberFormat().format(ongkir)}</Typography>
                                <Typography variant='body1'>{new Intl.NumberFormat().format(laba)}</Typography>
                            </div>
                        </div>
                        <div className={classes.buttonBottom}>
                            <Button variant='contained' color="primary" onClick={()=>handleClickPrintlaba()}>Cetak Laba/Rugi</Button>
                        </div>
                    </div>
                </div>
                <SingleDialog openDialog={openDelete} handleClick={statusButton==="All" ? deleteAll :deleteSingle} okCommand="Hapus" handleCloseDialog={onCloseDialog} message={statusButton === "All"?"Anda yakin akan menghapus semua keuntungan?":"Anda yakin akan menghapus data keuntungan "+refIdPenjualan+"?"}/>
            
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} 
        severity={messageKeuntungan === "Berhasil menghapus keuntungan" ? "success" : "error"} 
        >
            {messageKeuntungan}
            </Alert>
        </Snackbar>
    </div>
  )
}

export default KeuntunganPage