import React, { Fragment } from 'react'
import { Banner, LeftMenu, MenuPage } from '../../component/molecules'
import {Route,Switch} from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import StokBarang from '../StokBarang';
import Barang from '../Barang';
import JenisBarang from '../JenisBarang';
import Pembelian from '../Pembelian';
import Gudang from '../Gudang';
import StokGudang from '../StokGudang';
import TransferBarang from '../TransferBarang';
import PengeluaranGudang from '../PengeluaranGudang';
import DataPengeluaranGudang from '../DataPengeluaranGudang';
import DataPengeluaranGudangList from '../DataPengeluaranGudangList';
import PrintPage from '../PrintPage';
import DataTransferList from '../DataTransferList';
import Penyimpanan from '../Penyimpanan';
import RiwayatPembelian from '../RiwayatPembelian';
import RiwayatPenjualan from '../RiwayatPenjualan';
import Penjualan from '../Penjualan';
import RiwayatPembelianDetails from '../RiwayatPembelianDetails';
import RiwayatPenjualanDetails from '../RiwayatPenjualanDetails';
import { ListStokBarang } from '../../component/organism/';
import PrintFakturPenjualan from '../../component/organism/PrintFakturPenjualan';
import StokRetur from '../StokRetur';
import PencatatanRetur from '../PencatatanRetur';
import ReturToSupplierList from '../ReturToSupplierList';
import FilterBarangPage from '../FilterBarang';
import DraftPenjualan from '../DraftPenjualan';
import KeuntunganPage from '../Keuntungan';
import RincianKeuntungan from '../../component/organism/RincianKeuntungan';
import PrintLabaRugi from '../../component/organism/PrintLabaRugi';
import { useSelector } from 'react-redux';
import ListOrder from '../ListOrder';
import ListPendingOrder from '../ListPendingOrder';
import PenggunaPage from '../Pengguna';
import PartnerPage from '../DataPartner';
import AllDataHutang from '../Modul Hutang/AllDataHutang';
import RincianHutang from '../Modul Hutang/RincianHutang';
import PrintFakturPembelian from '../../component/organism/PrintFakturPembelian';
import RincianPembayaran from '../Modul Hutang/RincianPembayaran';
import PrintFakturTransaksi from '../../component/organism/PrintFakturTransaksi';
import PrintPageHutang from '../Modul Hutang/PrintPageHutang';

const Home = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            height:'99vh',
            width:'100%',
        },
        contentWapper:{
            height:'inherit',
            width:'inherit',
            display:'flex'
        },
        leftMenu:{
            paddingTop:'50px',
        },
        content:{
            width:'100%',
            marginTop:'48px',
            marginLeft:'0',
            [theme.breakpoints.up('md')]:{
                marginLeft:'0',
            },
        },
        backdrop:{
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
            display:'flex',
            textAlign:'center'
        }
    }));
    const classes = useStyle();
    const {statusServer} = useSelector(state=>state.homeReducers);
    return (
        <div className={classes.root}>
            <Grid container>
                <Banner/>
            </Grid>
            <div className={classes.contentWapper}>
                <div className={classes.leftMenu}>
                    <LeftMenu/>
                </div>
                <div className={classes.content}>
                    <Box height='inherit'>
                        <Fragment>
                            <Backdrop
                                className={classes.backdrop}
                                open={statusServer === false ? true : false}
                                >
                                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <Typography>Connecting to server</Typography>
                                <CircularProgress color='secondary'/>
                                </Box>
                            </Backdrop>
                        <Switch>
                            <Route path="/stok">
                                <StokBarang/>
                            </Route>
                            <Route exact path="/filterstok">
                                <ListStokBarang/>
                            </Route>
                            <Route exact path="/filterstok/listorder">
                                <ListOrder/>
                            </Route>
                            <Route exact path="/filterstok/listpendingorder">
                                <ListPendingOrder/>
                            </Route>
                            <Route path="/barang">
                                <Barang/>
                            </Route>
                            <Route path="/jenisbarang">
                                <JenisBarang/>
                            </Route>
                            <Route path="/pembelian">
                                <Pembelian/>
                            </Route>
                            <Route path="/gudang">
                                <Gudang/>
                            </Route>
                            <Route path="/stokgudang">
                                <StokGudang/>
                            </Route>
                            <Route path="/transferbarang">
                                <TransferBarang/>
                            </Route>
                            <Route path="/pengeluarangudang">
                                <PengeluaranGudang/>
                            </Route>
                            <Route path="/datapengeluarangudang">
                                <DataPengeluaranGudang/>
                            </Route>
                            <Route path="/datapengeluarangudanglist">
                                <DataPengeluaranGudangList/>
                            </Route>
                            <Route path="/printPengeluaran">
                                <PrintPage/>
                            </Route>
                            <Route path="/datatransferlist">
                                <DataTransferList/>
                            </Route>
                            <Route path="/penyimpanan">
                                <Penyimpanan/>
                            </Route>
                            <Route exact path="/historyPembelian">
                                <RiwayatPembelian/>
                            </Route>
                            <Route exact path="/historyPembelian/details/:idpembelian">
                                <RiwayatPembelianDetails/>
                            </Route>
                            <Route exact path="/historyPenjualan">
                                <RiwayatPenjualan/>
                            </Route>
                            <Route exact path="/historyPenjualan/details/:idpenjualan">
                                <RiwayatPenjualanDetails/>
                            </Route>
                            <Route path="/Penjualan">
                                <Penjualan/>
                            </Route>
                            <Route path="/DraftPenjualan">
                                <DraftPenjualan/>
                            </Route>
                            <Route path="/returbarang">
                                <StokRetur/>
                            </Route>
                            <Route path="/pengguna">
                                <PenggunaPage/>
                            </Route>
                            <Route path="/partner">
                                <PartnerPage/>
                            </Route>
                            <Route exact path="/hutang">
                                <AllDataHutang/>
                            </Route>
                            <Route exact path="/hutang/print/:tanggal">
                                <PrintPageHutang/>
                            </Route>
                            <Route exact path="/hutang/rincian/:search/:counter/:jenisview/:index/:idpartner/:namaperusahaan">
                                <RincianHutang/>
                            </Route> 
                            <Route exact path="/hutang/rinciantransaksi/:namaperusahaan/:idtransaksi">
                                <RincianPembayaran/>
                            </Route>
                            <Route path="/pencatatanretur">
                                <PencatatanRetur/>
                            </Route>
                            <Route path="/retursupplierlist">
                                <ReturToSupplierList/>
                            </Route>
                            <Route path="/filterbarang">
                                <FilterBarangPage/>
                            </Route>
                            <Route path="/PrintPenjualan/:RFrom/:idpenjualan">
                                <PrintFakturPenjualan/>
                            </Route>
                            <Route path="/PrintPembelian/:FFORM/:idpembelian">
                                <PrintFakturPembelian/>
                            </Route>
                            <Route path="/PrintTransaksi/:FFORM/:idpembelian">
                                <PrintFakturTransaksi/>
                            </Route>
                            <Route exact path="/Keuntungan">
                                <KeuntunganPage/>
                            </Route>
                            <Route exact path="/Keuntungan/rincian/:idpenjualan">
                                <RincianKeuntungan/>
                            </Route>
                            <Route path="/Keuntungan/printlabarugi">
                                <PrintLabaRugi/>
                            </Route>
                            <Route path="/">
                                <MenuPage/>
                            </Route>
                        </Switch>
                        </Fragment>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Home
