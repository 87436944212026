import { Divider, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React,{useEffect, useState} from 'react'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import DataBarangPenjualan from '../DataBarangPenjualan';
import KeranjangPenjualan from '../KeranjangPenjualan';
import { useDispatch, useSelector } from 'react-redux';
import { getDataPelanggan, selectedIDPelanggan } from '../../config/redux/action';
import Cookies from 'js-cookie';

const Penjualan = () => { 
    const [value, setValue] = useState(0);
    // const dispatch = useDispatch();
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'inherit',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            alignContent:'center',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                textAlign:'center',
            },
            padding:'5px',
        },
        title:{
            paddingTop:'10px'
        },
        navigationButton:{
            paddingTop:'0px',
            width:'100%',
            [theme.breakpoints.up('md')]:{
                width:'100%',
            },
        },
        content:{
            display:'flex',
            flexDirection:'column',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                alignItems:'flex-end',
                marginRight:'10px',
            },
        },buttonNext:{
            height:'30px',
            marginTop:'0px',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                marginTop:'0px',
            },
        },
    }));
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    
    const classes = useStyle();
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            hidden={value !== index}
            id={`standart-prevent-tabpanel-${index}`}
            aria-labelledby={`standart-prevent-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>{children}</div>
            )}
          </div>
        );
      }
      function a11yProps(index) {
        return {
          id: `standart-prevent-tab-${index}`,
          'aria-controls': `standart-prevent-tabpanel-${index}`,
        };
      }
      
      const {data} = useSelector(state => state.loginReducers);
      const [token,setToken] = useState(data.token);
      if(token ===''){
        setToken(Cookies.get('u_tkn'));
      }
      const dispatch = useDispatch();
      const {dataPelanggan,selected} = useSelector(state=>state.pelangganReducers);
      const {selectedID,selectedNama} = selected;
      useEffect(() => {
        if(dataPelanggan.length<1){
            dispatch(getDataPelanggan('',token));
        }
        else if(dataPelanggan.length>0){
            if(selectedID === "" && selectedNama === ""){
                dispatch(selectedIDPelanggan(dataPelanggan[0].idpelanggan,dataPelanggan[0].nama));
            }
        }  
      }, [dataPelanggan,selectedID,selectedNama,dispatch,token])
    return (
        <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant='h5' className={classes.title}>Penjualan</Typography>
                    <div className={classes.navigationButton}>
                        <Tabs 
                        value={value}
                        onChange={handleChange} 
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{height:'58px'}}
                        >
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'110px',paddingTop:'0px'}} label="Data Barang" {...a11yProps(0)} icon={<EventOutlinedIcon fontSize="small"/>}/>
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'110px',paddingTop:'0px'}} label="Daftar Penjualan" {...a11yProps(1)} icon={<ShoppingBasketIcon fontSize="small"/>}/>
                        </Tabs>
                    </div>
                </div>
                <Divider/>
                <TabPanel value={value} index={0}>
                    <DataBarangPenjualan statusTransaksi="Penjualan"/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <KeranjangPenjualan/>
                </TabPanel>
         </div>
    )
}

export default Penjualan
