const penggunaInitialState={
    isLoadingPengguna:false,
    dataPengguna:[],
    messagePengguna:'',
}

const penggunaReducers = (state=penggunaInitialState,action)=>{
    switch(action.type){
        case 'FETCH DATA PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:true
            }
        }
        case 'SUCCESSED GET DATA PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:false,
                dataPengguna:action.payload
            }
        }
        case 'FAILED GET DATA PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:false,
                messagePengguna:action.payload
            }
        }
        case 'SUCCESSED RESET IDENTIFIER PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:false,
                messagePengguna:action.payload
            }
        }
        case 'FETCH ACTION PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:true
            }
        }
        case 'FAILED ACTION PENGGUNA':{
            return{
                ...state,
                isLoadingPengguna:false,
                messagePengguna:action.payload
            }
        }
        default: return state
    }
}
export default penggunaReducers;