import { Button, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { Search } from '../../component/molecules';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import {setSearch } from '../../config/redux/action/barangAction';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { setDataStokGudang, setFillTableDataPengeluaranGudang, setSearchStokGudang } from '../../config/redux/action';
import FormPengeluaranGudang from '../../component/organism/FormPengeluaranGudang';
import { setFormPengeluaran } from '../../config/redux/action/pengeluaranGudangAction';
import { useHistory } from 'react-router-dom';

const DataBarangGudang = () => {
    const {dataStokGudang,pageGudang,searchGudang} = useSelector(state => state.stokGudangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const [openCart,setOpenCart] = useState(false);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataStokGudang(searchGudang,counter,token));
    },[searchGudang,counter,token,dispatch])
    
    const focus = () =>{
        setCounter(1);
        dispatch(setDataStokGudang(searchGudang,counter,token));
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageGudang.totalPage ? pageGudang.totalPage : counter + 1);
    }
    const HandleClickCloseFormTransferDialog = () =>{
        setCounter(1);
        dispatch(setDataStokGudang(searchGudang,counter,token));
        dispatch(setSearch(''));
        setOpenCart(false);
    }
    const HandleClickOpenFormTransferDialog = (idstokgudang,idbarang,namabarang,sisastok,variant) =>{
        dispatch(setFormPengeluaran(idstokgudang,idbarang,namabarang,sisastok,variant));
        setOpenCart(true);
    }
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%'
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'21%',
            },
        },
        table:{
            marginTop:'5px',
            width:'90%',
            maxWidth:'800px',
        },
        tableContainer:{
            height:'580px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    const handleClickOpenDataPengeluaranGudang = ()=>{
        dispatch(setFillTableDataPengeluaranGudang());
        history.push('/datapengeluarangudang');
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
            <Search className={classes.search} 
            label="Cari Barang" 
            name="cari-barang" 
            id="cari-barang" 
            onFocus={focus} 
            value={searchGudang}
            onChange={(e)=>dispatch(setSearchStokGudang(e.target.value))}/>
            <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                        <SytledTableCell width='3%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataStokGudang.map((dataStokGudangs=>(
                                        <StyledTableRow key={dataStokGudangs.idstokgudang}>
                                            <SytledTableCell align="center">{dataStokGudangs.idbarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataStokGudangs.namabarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataStokGudangs.variant}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                    <Tooltip title="Tambah ke daftar pengeluaran">
                                                        <IconButton size='small' 
                                                        onClick={()=>HandleClickOpenFormTransferDialog(dataStokGudangs.idstokgudang,dataStokGudangs.idbarang,dataStokGudangs.namabarang,dataStokGudangs.sisastok,dataStokGudangs.variant)}>
                                                            <AddShoppingCartIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </SytledTableCell>
                                        </StyledTableRow>
                                    )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrev}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                <Typography className={classes.textPage}>{pageGudang.currentPage} / {pageGudang.totalPage >= 1 ? pageGudang.totalPage : pageGudang.currentPage}</Typography>
                                <Tooltip title="Next">
                                    <IconButton size='small' onClick={handleNext}>
                                        <NavigateNext/>
                                    </IconButton>
                                </Tooltip> 
                                </div>
                         </div>
                    </Paper>
                    <FormPengeluaranGudang open={openCart} aria-labelledby="add-form-pengeluaran" HandleClickCloseDialog={HandleClickCloseFormTransferDialog}/>
                </div>
                <Button className={classes.buttonHistory} size="small" variant="contained" color="primary" aria-label="add" onClick={handleClickOpenDataPengeluaranGudang}>
                    Pengeluaran Gudang
                </Button>
            </div>
        </div>
    )
}

export default DataBarangGudang
