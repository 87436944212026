import { Button, FormControlLabel, IconButton, makeStyles, Paper, Radio, RadioGroup, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, withStyles } from '@material-ui/core'
import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DateField, SingleDialog } from '../../component/molecules';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@material-ui/lab/Alert';
import { getNoPencatatanRetur, savePencatatanRetur, setEmptyMessageRetur } from '../../config/redux/action';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const ReturList = () => {
    const useStyle=makeStyles((theme)=>({
        root:{
            witdh:'100%',
            height:'100vh'
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'0px',
            width:'100%',
            justifyContent:'center',
            alignItems:'center',
            [theme.breakpoints.down('xs')]:{
                flexDirection:"column",
                // width:'100%',
            }
        },content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'70%',
            // maxWidth:'800px',
            [theme.breakpoints.down('xs')]:{
                width:'90%',
            },
        },
        textFiled:{
            width:'150px',
            [theme.breakpoints.down('xs')]:{
                width:'150px',
            }
        },
        tanggalFiled:{
            display:'flex',
            marginLeft:'0px',
            [theme.breakpoints.down('xs')]:{
                marginLeft:'0px',
            },
        },
        radioWapper:{
            display:'flex',
            marginLeft:"5px",
        },
        radio:{
            display:'flex',
            flexDirection:'row',
            alignItems:"flex-end",
            [theme.breakpoints.down('xs')]:{
                alignItems:"flex-start",
                flexDirection:"column",
                marginLeft:"0px",
            }
        },
        textRadio:{
            display:'flex',
            marginBottom:"5px",
            width:'150px',
            [theme.breakpoints.down('xs')]:{
                alignItems:"flex-start",
                flexDirection:"column",
                marginTop:"0px",
                width:'150px',
            }
        },
        buttonSaveRetur:{
            marginTop:'20px'
        }
    }))
    const classes = useStyle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const {data}=useSelector(state=>state.loginReducers);
      const [token,setToken]=useState(data.token);
      if(token===''){
          setToken(Cookies.get('u_tkn'));
      }
      const {FormPencatatanRetur,NoRetur,fetchMessage,loadingSavePencatatanRetur} = useSelector(state=>state.returReducers);
      const [value,setValue]=useState("Pelanggan");
      const [asalRetur,setAsalRetur] = useState('');
      const [disableText,setDisableText]=useState(false);
      const [openSnackBar,setOpenSnackBar] = useState(false);
      const dispatch = useDispatch();
      const handleCloseSnackBar = () =>{
        setOpenSnackBar(false);
        setTimeout(()=>{
            setEmptyMessageRetur();
        },2000)
      }
      const validationSchema = yup.object().shape({
          nama:yup.string(),
          tanggal:yup.date().max(new Date(),'Tanggal tidak boleh melebihi hari ini')
      })
      const formik = useFormik({
          initialValues:{
              nama:'',
              tanggal:new Date()
          },
          validationSchema:validationSchema,
          onSubmit:(values)=>{
                const data ={
                    'noretur':NoRetur,
                    'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                    'pelanggan':asalRetur,
                    'total':FormPencatatanRetur.length,
                    'status':"Retur Diterima",
                    'details':FormPencatatanRetur
                }
                dispatch(savePencatatanRetur(data,token,setOpenSnackBar,history));
          }
      })
      const handleChangeRadio = (e)=>{
        setValue(e.target.value);
         if(e.target.value === 'Toko'){
            setAsalRetur(e.target.value);
            setDisableText(true);
            formik.setFieldValue('nama','');
         }else{
            setDisableText(false);
         }
      }
      const handleChangeTanggal = (value) =>{
        formik.setFieldValue('tanggal',value);
        if(new Date()<=formik.values.tanggal){
            setTimeout(() => {
                dispatch(getNoPencatatanRetur(format(value,'yyyy-MM-dd'),token));
            }, 500);
        }
      }
      const history = useHistory();
      const handleChangeNama = (e)=>{
          setAsalRetur(e.target.value);
          formik.setFieldValue('nama',e.target.value);
      }
      useEffect(() => { 
          if(NoRetur === ''){
            dispatch(getNoPencatatanRetur(new Date(),token));
          }
      }, [dispatch,NoRetur,token])
      const [openDelete,setOpenDelete]=useState(false);
      const [tempIdBarang,setTempIdBarang]=useState('');
      const openDeletePencatatan = (idbarang) =>{
        setOpenDelete(true);
        setTempIdBarang(idbarang);
      }
      const deleteDaftarPencatatan = () =>{
        const findBarang = FormPencatatanRetur.findIndex(catatRetur=>catatRetur.idbarang===tempIdBarang);
        FormPencatatanRetur.splice(findBarang,1);
        setTempIdBarang('');
        setOpenDelete(false);
    }
    const onCloseDialog=()=>{
        setOpenDelete(false);
    }
    return (
        <div className={classes.root}>
            <div className={classes.mainWapper}>
                <TextField
                className={classes.textFiled}
                id="noretur"
                name="noretur"
                label="No Retur"
                value = {NoRetur || ''}
                disabled={true}
                onChange={formik.handleChange}
                />
                <DateField  
                    // className={classes.tanggalFiled}
                    id="tanggal" 
                    name="tanggal" 
                    label="Tanggal"
                    value={formik.values.tanggal}
                    errors={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                    helperText={formik.touched.tanggal && formik.errors.tanggal}
                    onChange={(e)=>handleChangeTanggal(e)}
                />
                    <div className={classes.radioWapper}>
                        <RadioGroup className={classes.radio} aria-label="typeData" name="typeData" 
                        value={value} 
                        onChange={handleChangeRadio}
                        >
                            <FormControlLabel value="Pelanggan" control={<Radio />} label="Pelanggan" />
                            <TextField
                            className={classes.textRadio}
                            id="nama"
                            name="nama"
                            label="Nama Pelanggan"
                            value={formik.values.nama}
                            onChange={(e)=>handleChangeNama(e)}
                            disabled={disableText}/>
                            <FormControlLabel value="Toko" control={<Radio />} label="Toko" />
                        </RadioGroup>
                    </div>
            </div>
            <div className={classes.content}>
                    <div className={classes.table}>
                        <Paper>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="table retur" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                            <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                            <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                            <SytledTableCell width='30%' align="center">Keterangan</SytledTableCell>
                                            <SytledTableCell width='3%' align="center"></SytledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {FormPencatatanRetur.map((FormPencatatanRetur=>(
                                            <StyledTableRow key={FormPencatatanRetur.idbarang}>
                                                <SytledTableCell align="center">{FormPencatatanRetur.idbarang}</SytledTableCell>
                                                <SytledTableCell align="center">{FormPencatatanRetur.namabarang}</SytledTableCell>
                                                <SytledTableCell align="center">{FormPencatatanRetur.jumlah}</SytledTableCell>
                                                <SytledTableCell align="center">{FormPencatatanRetur.keterangan}</SytledTableCell>
                                                <SytledTableCell align="center">
                                                    <Tooltip title="Hapus dari list">
                                                        <IconButton size='small' 
                                                        onClick={()=>openDeletePencatatan(FormPencatatanRetur.idbarang)}
                                                        >
                                                            <CloseIcon style={{color:'red'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </SytledTableCell>
                                            </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                <Button 
                    className={classes.buttonSaveRetur}
                    aria-label="SavePencatatanRetur" 
                    onClick={formik.handleSubmit} 
                    variant="contained"
                    color="primary"
                    disabled={
                        loadingSavePencatatanRetur === false && FormPencatatanRetur.length > 0 ?
                        value === "Pelanggan" && formik.values.nama !== "" ? false : value === "Toko" && formik.values.nama === "" ? false : true
                        : true
                    }
                >
                {loadingSavePencatatanRetur === true ? "Tunggu..." : "Simpan Pencatatan Retur"}</Button>
            </div>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={fetchMessage === 'Data retur berhasil disimpan' ? "success" : "error"}>
                        {fetchMessage}
                </Alert>
            </Snackbar>
            <SingleDialog openDialog={openDelete} handleClick={deleteDaftarPencatatan} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
        </div>
    )
}

export default ReturList
