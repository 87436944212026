const initialStateKeuntungan={
    messageKeuntungan:'',
    isLoadingFetchRecord:false
}
const keuntunganReducer = (state = initialStateKeuntungan,action)=>{
    switch(action.type){
        case 'FETCH RECORD KEUNTUNGAN':{
            return{
                ...state,
                isLoadingFetchRecord:true
            }
        }
        case 'SUCCESS POST RECORD KEUNTUNGAN':{
            return{
                ...state,
                isLoadingFetchRecord:false,
                messageKeuntungan:action.payload
            }
        }
        case 'FAILED POST RECORD KEUNTUNGAN':{
            return{
                ...state,
                isLoadingFetchRecord:false,
                messageKeuntungan:action.payload
            }
        }
        default:
            return state
    }
}
export default keuntunganReducer