import { Button, Dialog, DialogContent, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
import { finishService } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const ServiceDialog = ({handleCloseServiceDialog,openDialog,...rest}) => {
    const useStyle=makeStyles({
        root:{
            width:'100%',
        },button:{
            display:'flex',
            justifyContent:'space-between',
            padding:"10px",
        },
        textField:{
            width:'100%',
            display:'flex',
            justifyContent:'center'
        }
    })
    const classes = useStyle();
    const {data}=useSelector(state=>state.loginReducers);
      const [token,setToken]=useState(data.token);
      if(token===''){
          setToken(Cookies.get('u_tkn'));
      }
    const {serviceDetails,fetchMessage,loadingSavePencatatanRetur} = useSelector(state=>state.returReducers);
    const {idbarang,namabarang,jumlah}=serviceDetails;
    const [openSnackBar,setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = () =>{
        setOpenSnackBar(false);
        setTimeout(()=>{
            // setEmptyMessageRetur();
        },2000)
      }
      const dispatch = useDispatch();
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required("Jumlah tidak boleh kosong").min(1,"Jumlah barang minimal 1").max(jumlah,"Jumlah barang retur tidak mencukupi")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            jumlah:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data={
                'jumlah':values.jumlah
            }
            dispatch(finishService(idbarang,data,token,setOpenSnackBar,handleCloseServiceDialog));
            formik.handleReset();
        }
    })
    return (
        <div className={classes.root}>
            <Dialog open={openDialog} {...rest}>
                <DialogContent>
                    <Typography>Masukkan jumlah barang {namabarang} yang telah selesai diperbaiki</Typography>
                    <div className={classes.textField}>
                    <TextField
                    id="jumlah"
                    nama="jumlah"
                    label="Jumlah"
                    value={formik.values.jumlah}
                    error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                    helperText={formik.touched.jumlah && formik.errors.jumlah}
                    onChange={formik.handleChange}
                    />
                    </div>
                    <div className={classes.button}>
                        <Button variant="contained" color="primary" onClick={formik.handleSubmit}
                        disabled={loadingSavePencatatanRetur}>Simpan</Button>
                        <Button onClick={handleCloseServiceDialog} variant="contained" color="secondary"
                        disabled={loadingSavePencatatanRetur}>Keluar</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={fetchMessage === 'Perbaikan barang berhasil disimpan' ? "success" : "error"}
                    >
                        {fetchMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ServiceDialog
