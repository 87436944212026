import { withStyles, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Fab, Button, IconButton, Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DateField, SingleDialog } from '../../component/molecules';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FailedAddToReturSupplier, getIDRetur, saveReturToSupplier, setEmptyReturToSupplierList } from '../../config/redux/action';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const ReturToSupplierList = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'0px',
            width:'100%',
            justifyContent:'center'
        },input:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                marginLeft:'5px',
            },
        },table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'370px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'100%',
            margin:'0px',
        },tableBottom:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-end'
        },buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
        },buttonSave:{
            marginTop:'10px'
        }
    }))
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const classes = useStyle();
      const {data} = useSelector(state => state.loginReducers);
      const [token,setToken] = useState(data.token);
      if(token ===''){
          setToken(Cookies.get('u_tkn'));
      }
      const {noretur,messageRetur,dataReturSupplier,isLoading}=useSelector(state=>state.returSupplierReducers);
      const history = useHistory();
      const dispatch = useDispatch();
      const [totalItem,setTotalItem]=useState(0);
      const [totalRetur,setTotalRetur]=useState(0);
      const handleCloseSnackBar = () =>{
        setOpenSnackBar(false);
        setTimeout(()=>{
            dispatch(FailedAddToReturSupplier(''));
            dispatch(setEmptyReturToSupplierList());
            history.push(history.goBack());
        },500)
      }
      useEffect(()=>{
        if(noretur===""){
            dispatch(getIDRetur(new Date(),token));
        }
        if(dataReturSupplier.length>0){
            setTotalItem(dataReturSupplier.length);
        }
        if(dataReturSupplier.length>0){
            let totalRetur=dataReturSupplier.reduce((retur,totalR)=>{
                return retur + totalR.subtotal
            },0)
            setTotalRetur(totalRetur);
        }else{
            setTotalRetur(0);
        }
      },[noretur,dispatch,token,dataReturSupplier,totalItem,totalRetur])
      const handleChangeTanggal = (value)=>{
          formik.setFieldValue('tanggal',value);
          if(value<=new Date()){
            setTimeout(() => {
                dispatch(getIDRetur(format(value,'yyyy-MM-dd'),token));
            }, 500);
          }
      }
      const validationSchema = yup.object().shape({
          tanggal:yup.date().max(new Date(),"Tanggal retur tidak boleh melebihi hari ini"),
          supplier:yup.string().required("Nama supplier tidak boleh kosong")
      }) 
      const formik = useFormik({
          initialValues:{
              tanggal:new Date(),
              supplier:''
          },
          validationSchema:validationSchema,
          onSubmit:(values)=>{
            const data ={
                'noretur':noretur,
                'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                'supplier':values.supplier,
                'total':totalRetur,
                'status':"Retur Berhasil",
                'details':dataReturSupplier
            }
            dispatch(saveReturToSupplier(data,token,setOpenSnackBar));
            formik.resetForm();
          }
      })
      
      const [openDelete,setOpenDelete]=useState(false);
      const [tempIdBarang,setTempIdBarang]=useState('');
      const [openSnackBar,setOpenSnackBar] = useState(false);
     
      const openDeleteRetur = (idbarang)=>{
        setOpenDelete(true);
        setTempIdBarang(idbarang);
      }
      const deleteDaftarRetur = () =>{
        const findBarang = dataReturSupplier.findIndex(dataRetur=>dataRetur.idbarang===tempIdBarang);
        dataReturSupplier.splice(findBarang,1);
        setTempIdBarang('');
        setOpenDelete(false);
      }
      const onCloseDialog=()=>{
        setOpenDelete(false);
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Typography variant="h5">Daftar Retur Ke Supplier</Typography>
            </div>
            <div className={classes.mainWapper}>
                <TextField className={classes.input} 
                id="noretur" 
                name="noretur" 
                label="No Retur"
                value = {noretur || ''}
                disabled={true}
                onChange={formik.handleChange}
                />
                <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                errors={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                helperText={formik.touched.tanggal && formik.errors.tanggal}
                onChange={(e)=>handleChangeTanggal(e)}
                />
                <TextField className={classes.input} 
                id="supplier" 
                name="supplier" 
                label="Nama Supplier"
                value={formik.values.supplier}
                error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                helperText={formik.touched.supplier && formik.errors.supplier}
                onChange={formik.handleChange}
                />
            </div>
            <div className={classes.content}>
                <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table retur" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                        <SytledTableCell width='10%' align="center">Harga</SytledTableCell>
                                        <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                                        <SytledTableCell width='3%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataReturSupplier.map((dataReturSuppliers=>(
                                        <StyledTableRow key={dataReturSuppliers.idbarang}>
                                            <SytledTableCell align="center">{dataReturSuppliers.idbarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataReturSuppliers.namabarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataReturSuppliers.jumlah}</SytledTableCell>
                                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataReturSuppliers.modal)}</SytledTableCell>
                                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataReturSuppliers.subtotal)}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                <Tooltip title="Hapus dari list">
                                                    <IconButton size='small' onClick={()=>openDeleteRetur(dataReturSuppliers.idbarang)}>
                                                        <CloseIcon style={{color:'red'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </SytledTableCell>
                                        </StyledTableRow>
                                    )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                            <div className={classes.page}>
                                <Typography>Total Item</Typography>
                                <Typography>{totalItem}</Typography>
                            </div>
                            <div className={classes.page}>
                                <Typography>Total</Typography>
                                <Typography>{new Intl.NumberFormat().format(totalRetur)}</Typography>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className={classes.buttonSave}>
                    <Button 
                    aria-label="save pembelian" 
                    onClick={formik.handleSubmit} 
                    variant="contained" 
                    color="primary"
                    disabled={dataReturSupplier.length < 1 ? true : false || isLoading}
                    >{isLoading === true ? "Tunggu" : "Simpan Retur Barang"}</Button>
                </div>
                </div>
                <SingleDialog openDialog={openDelete} handleClick={deleteDaftarRetur} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
                <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                    <ArrowBackIcon/>
                </Fab>
                <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={messageRetur === 'Data retur berhasil disimpan' ? "success" : "error"}>
                        {messageRetur}
                </Alert>
                </Snackbar>
        </div>
    )
}

export default ReturToSupplierList
