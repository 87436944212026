const initialStatePoOrder={
    dataPoOrder:[],
    dataPoOrderDetails:[],
    messagePoOrder:'',
    isLoadingPoOrder:false
}

const POOrderReducers = (state=initialStatePoOrder,action)=>{
    switch(action.type){
        case 'FETCH ACTION PO ORDER':{
            return{
                ...state,
                isLoadingPoOrder:true
            }
        }
        case 'FAILED FETCH ACTION PO ORDER':{
            return{
                ...state,
                isLoadingPoOrder:false,
                messagePoOrder:action.payload
            }
        }
        case 'SUCESSED ACTION PO ORDER':{
            return{
                ...state,
                isLoadingPoOrder:false,
                messagePoOrder:action.payload
            }
        }
        case 'SUCESSED POST PO ORDER DETAILS':{
            return{
                ...state,
                isLoadingPoOrder:false,
                messagePoOrder:action.payload
            }
        }
        case 'UPDATE DATA PO ORDER':{
            return{
                ...state,
                isLoadingPoOrder:false,
                dataPoOrder:action.payload
            }
        }
        case 'UPDATE DATA DETAILS PO ORDER':{
            return{
                ...state,
                isLoadingPoOrder:false,
                dataPoOrderDetails:action.payload
            }
        }
        case 'SET EMPTY MESSAGE PO ORDER':{
            return{
                ...state,
                messagePoOrder:action.payload
            }
        }
        default:
            return state
    }
}

export default POOrderReducers