import { Divider, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getAllPengguna, resetIdentifierPengguna } from '../../config/redux/action/penggunaAction';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const PenggunaPage = () => {
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        tableWapper:{
            display:'flex',
            flexDirection:'column',
            maxWidth:'90%',
            marginLeft:'5%',
            alignItems:'center',
        },
        table:{
            marginTop:'20px',
            [theme.breakpoints.up('md')]:{
                width:'50%',
            },
            width:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },addIcon:{
            paddingRight:'10px'
        }
    }));
    const classes = useStyles();
    const {dataPengguna,messagePengguna}=useSelector(state=>state.penggunaReducers);
    const {data}=useSelector(state=>state.loginReducers);
    const dispatch=useDispatch();
    const [token,setToken] = useState(data.token);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        if(dataPengguna.length<1){
            dispatch(getAllPengguna(token));
        }
    },[dispatch,token,dataPengguna])
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
      };
    const HandleClickResetIdentifier = (username)=>{
        dispatch(resetIdentifierPengguna(username,token,setOpenSnackbar));
    }
  return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h5">Data Pengguna</Typography>
            <Divider/>
            <div className={classes.tableWapper}>
                    <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table jenis barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">Username</SytledTableCell>
                                        <SytledTableCell width='25%' align="center">Nama Pengguna</SytledTableCell>
                                        <SytledTableCell width='25%' align="center">Hak Akses</SytledTableCell>
                                        <SytledTableCell width='5%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataPengguna.map((dataPenggunas)=>(
                                        <StyledTableRow key={dataPenggunas.username}>
                                            <SytledTableCell align="center">{dataPenggunas.username}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPenggunas.nama}</SytledTableCell>
                                            <SytledTableCell align="center">{dataPenggunas.level}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                {dataPenggunas.level==="Kasir"?
                                                <Tooltip title="Reset Identifier">
                                                    <IconButton size='small' 
                                                    onClick={()=>HandleClickResetIdentifier(dataPenggunas.username)}
                                                    >
                                                        <RotateLeftIcon/>
                                                    </IconButton>
                                                 </Tooltip>
                                                 :null}
                                                {/* <Tooltip title="Hapus">
                                                    <IconButton size='small' onClick={()=>HandleClickOpenDeleteDialog(dataJenisBarangs.idjenisbarang,dataJenisBarangs.jenisbarang)}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Tooltip> */}
                                            </SytledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                    </Paper>
                 </div>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackBar}>
            {messagePengguna === 'Berhasil melakukan reset identifier' ?
                <Alert onClose={handleCloseSnackBar} severity={"success"}>{messagePengguna}</Alert>:
                <Alert onClose={handleCloseSnackBar} severity={"error"}>{messagePengguna}</Alert>
            }
        </Snackbar>
        </div>
  )
}

export default PenggunaPage