import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Search } from '../../component/molecules';
import React, { useEffect, useState } from 'react'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDetailsBarang, setDataBarang, setSearch, updateStokDiskon } from '../../config/redux/action';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddCartPenjualan from '../../component/organism/AddCartPenjualan';
import AddCartDraftPenjualan from '../../component/organism/AddCartDraftPenjualan';


const DataBarangPenjualan = ({statusTransaksi}) => {
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%'
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'18%',
            },
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'580px',
            [theme.breakpoints.up('md')]:{
                height:'400px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    const {dataBarang,pageBarang,search} = useSelector(state => state.barangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const [counter,setCounter] = useState(1);
    const [openCart,setOpenCart] = useState(false);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataBarang(search,counter,token));
    },[search,counter,token,dispatch])
    const focus = () =>{
        setCounter(1);
        dispatch(setDataBarang(search,counter,token));
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageBarang.totalPage ? pageBarang.totalPage : counter + 1);
    }
    const HandleClickCloseAddCartDialog = () =>{
        setOpenCart(false);
    }
    const HandleClickOpenAddCartDialog = (idbarang)=>{
        dispatch(updateStokDiskon(idbarang,token));
        dispatch(getDetailsBarang(idbarang,token,setOpenCart));
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
            <Search className={classes.search} label="Cari Barang" name="cari-barang" id="cari-barang" onFocus={focus} onChange={(e)=>dispatch(setSearch(e.target.value))}/>
            <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                        <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                        <SytledTableCell width='3%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataBarang.map((dataBarangs=>(
                                        <StyledTableRow key={dataBarangs.idbarang}>
                                            <SytledTableCell align="center">{dataBarangs.idbarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataBarangs.namabarang}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                    <Tooltip title="Tambah ke list">
                                                        <IconButton size='small' 
                                                        onClick={()=>HandleClickOpenAddCartDialog(dataBarangs.idbarang)}
                                                        >
                                                            <AddShoppingCartIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </SytledTableCell>
                                        </StyledTableRow>
                                    )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                <div className={classes.page}>
                                <Tooltip title="Prev">
                                    <IconButton size='small' onClick={handlePrev}>
                                        <NavigateBefore/>
                                    </IconButton>
                                </Tooltip>
                                <Typography className={classes.textPage}>{pageBarang.currentPage} / {pageBarang.totalPage >= 1 ? pageBarang.totalPage : pageBarang.currentPage}</Typography>
                                <Tooltip title="Next">
                                    <IconButton size='small' onClick={handleNext}>
                                        <NavigateNext/>
                                    </IconButton>
                                </Tooltip> 
                                </div>
                         </div>
                    </Paper>
                    {statusTransaksi==="Penjualan"?
                        <AddCartPenjualan open={openCart} aria-labelledby="add-barang-cart" HandleClickCloseAddCartDialog={HandleClickCloseAddCartDialog}/>
                        :
                        <AddCartDraftPenjualan open={openCart} aria-labelledby="add-barang-draft-cart" HandleClickCloseAddCartDialog={HandleClickCloseAddCartDialog}/>}
                </div>
            </div>
        </div>
    )
}

export default DataBarangPenjualan
