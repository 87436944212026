import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputFiled } from '../../molecules';
import { setExistFormTransfer, setFormTransfer, fillMessage, setTransferMessage } from '../../../config/redux/action/TransferBarangAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormTransfer = ({HandleClickCloseDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
        avaliableEdit:{
            display:'flex',
        }
    }));
    const dispatch = useDispatch();
    const {FormBarang} = useSelector(state=>state.barangReducers);
    const {data} = useSelector(state => state.loginReducers);
    const {idbarang,namabarang,sisastok}=FormBarang;
    const {sisaStokBarang} = useSelector(state=>state.stokGudangReducers);
    const {FormTransfer,tempTransferBarang,transferMessage} = useSelector(state => state.transferBarangReducers);
    const [transferSnackbar,setTransferSnackbar] = useState(false);
    const [token,setToken] = useState(data.token);
    const [id,setId]=useState(0);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackBar = () => {
        setTransferSnackbar(false);
        setTimeout(()=>{
            dispatch(fillMessage());
        },500)
    };
    const validationSchema = yup.object().shape({
        jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
        variant:yup.string()
    })
    const [stok,setStok]=useState(sisastok);
    useEffect(()=>{
        if(idbarang!==''){
            const existBarangInTemp = tempTransferBarang.filter((temp) =>temp.idbarang === idbarang);
            if(existBarangInTemp.length > 0){
                setStok(parseInt(sisastok)-parseInt(sisaStokBarang)-parseInt(existBarangInTemp[0].jumlah));
            }else{
                setStok(parseInt(sisastok)-parseInt(sisaStokBarang));
            }
        } 
        if(FormTransfer.length>0){
            let tempID = FormTransfer[FormTransfer.length-1].id;
            let temp=tempID+1
            setId(temp);
        }
    },[idbarang,sisastok,tempTransferBarang,FormTransfer,sisaStokBarang])
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:idbarang || '',
            namabarang:namabarang || '',
            jumlah:stok || 0,
            variant:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = FormTransfer.filter(transfer => transfer.idbarang === idbarang && transfer.variant === values.variant);
            if(existBarang.length > 0){
                dispatch(setTransferMessage("Barang dengan varian ini sudah terdaftar"));
                setTransferSnackbar(true);
            }else if(existBarang.length < 1){
                const existBarangInTemp = tempTransferBarang.filter((temp) =>temp.idbarang === idbarang);
                if(existBarangInTemp.length>0){
                    let tempJumlah;
                    let index;
                    tempTransferBarang.find((indexs,i) => {
                        if(indexs.idbarang===idbarang){
                            tempJumlah = parseInt(indexs.jumlah) + parseInt(values.jumlah);
                            index=i;
                            return index;
                        }
                        return tempJumlah;
                    })
                    dispatch(setExistFormTransfer(id,values.idbarang,values.namabarang,values.jumlah,values.variant,index,tempJumlah))
                    HandleClickCloseDialog();
                }
                else if(existBarangInTemp.length<1){
                    dispatch(setFormTransfer(id,values.idbarang,values.namabarang,values.jumlah,values.variant))
                    HandleClickCloseDialog();
                }
                setTransferSnackbar(true);
            }
        }
    })
    const classes=useStyle();
    return (
        <div>
            <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-form-barang">Rincian Transfer Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                value={formik.values.idbarang}
                disabled={true} 
                />
                <InputFiled 
                id="namabarang"
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                disabled={true}
                />
                <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <InputFiled 
                id="variant" 
                name="variant" 
                label="variant"
                value={formik.values.variant}
                error={formik.touched.variant && Boolean(formik.errors.variant)}
                helperText={formik.touched.variant && formik.errors.variant}
                onChange={(e)=>formik.setFieldValue('variant',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={transferSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert 
                    onClose={handleCloseSnackBar} 
                    severity={transferMessage === 'Berhasil menambahkan data ke daftar' ? "success" : "error"}>
                        {transferMessage}
                </Alert>
            </Snackbar>
        </div>
        </div>
    )
}

export default FormTransfer
