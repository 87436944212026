import axios from "axios";
import { differenceInDays } from "date-fns";
import { apiUrl } from "../..";
import { getAllDataRiwayatPenjualan } from "./riwayatPenjualanActions";

export const setMessagePenjualan = (message,setOpenSnackbar) =>(dispatch)=>{
    if(message==='Modal lebih kecil dari harga jual dipotong diskon'){
        dispatch({
            type:'SET MESSAGE PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else if(message==='Barang telah terdaftar dalam keranjang penjualan'){
        dispatch({
            type:'SET MESSAGE PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
    }else{
        dispatch({
            type:'SET MESSAGE PENJUALAN',
            payload:message
        })
    }
}

export const addToCartPenjualan = (data,dataKeuntungan,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'ADD LIST PENJUALAN',
        payload:data,
        messagePayload:'Berhasil menambahkan ke keranjang penjualan'
    })
    dispatch({
        type:'ADD LIST KEUNTUNGAN',
        payload:dataKeuntungan,
    })
    setOpenSnackbar(true);
}
export const getNewIDPenjualan = (tanggal,token)=>(dispatch)=>{
    const data = {
        'tanggal':tanggal
    }
    dispatch({
        type:'FETCH GET ID PENJUALAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/getID`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SUCCESSED GET NO FAKTUR', 
            payload:ResponseAPI.data
         })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NO FAKTUR',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NO FAKTUR',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NO FAKTUR',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const getAllListPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'GET DATA PENJUALAN LIST'
    })
}
export const postPenjualanToAPI = (data,token,setOpenSnackbar,setOpenPrint)=>(dispatch)=>{
    dispatch({
        type:'FETCH GET ID PENJUALAN'
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/savepenjualan`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(results=>{
            const ResponseAPI = results.data;
            dispatch({
                type:'SUCCESSED SAVE PENJUALAN', 
                payload:ResponseAPI.message,
             })
             dispatch({
                type:'EDIT STATUS DRAFT TO PENJUALAN',
                payload:"Penjualan"
            })
             setOpenSnackbar(true);
             setOpenPrint(true);
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:'Disconnect from server'
                })
                setOpenSnackbar(true);
            }
        })
    },2000)
}
export const emptyMessagePenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE PENJUALAN'
    })
}

export const getDataToPrint = (idpenjualan,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH GET ID PENJUALAN'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/penjualan/${idpenjualan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(resPenjualan=>{
        const ResponsePenjualan = resPenjualan.data;
        axios.get(`${apiUrl.url}v1/suryaharapan/penjualan/details/${idpenjualan}`,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(resDetails=>{
            const ResponseDetails = resDetails.data;
            dispatch({
                type:'GET DATA PENJUALAN TO PRINT', 
                payload:ResponsePenjualan.data,
                payloadDetails:ResponseDetails.data
             })
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED GET DATA TO PRINT',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED GET DATA TO PRINT',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED GET DATA TO PRINT',
                        payload:'Disconnect from server'
                })
            }
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const resetAllFormPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'RESET PENJUALAN'
    })
    dispatch({
       type:'RESET KEUNTUNGAN LIST'
   })
   dispatch({
    type:'RESET PENJUALAN FROM DRAFT'
    })
}

export const emptyDataPrint = () =>(dispatch)=>{
    dispatch({
        type:'GET DATA PENJUALAN TO PRINT'
    })
}
export const updateStokDiskon = (idbarang,token)=>(dispatch)=>{
    const data={
        'idbarang':idbarang
    }
    dispatch({
        type:'FETCH UPDATE STOK DISKON'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/updatestokdiskon/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED UPDATE STOK DISKON',
            payload:ResponseAPI.message
            })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA TO PRINT',
                    payload:'Disconnect from server'
            })
        }
    })
    
}
export const getAllListPenjualanFromDraft = (token,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'FECTH DATA DRAFT'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getList`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }).then(res=>{
        const responseAPI = res.data;
        dispatch({
            type:'GET DATA FROM DRAFT TO PENJUALAN',
            payload:responseAPI.data
        })
    }).catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA DRAFT TO PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA DRAFT TO PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET DATA DRAFT TO PENJUALAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
// export const deleteDraftPenjualan =(idpenjualan,token,setOpenSnackbar,setTempId,onCloseDialog)=>(dispatch)=>{
//     const data={
//         'idpenjualan':idpenjualan
//     }
//     dispatch({
//         type:'FETCH DATA'
//     })
//     axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/delete`,data,{
//         headers:{
//             'Authorization':`Bearer ${token}`
//         }
//     })
//     .then(res=>{
//         const ResponseAPI = res.data;
//         dispatch({
//             type:'SUCCESSED ACTION DRAFT PENJUALAN',
//             payload:ResponseAPI.message
//         })
//         dispatch(getAllListDraftPenjualan(token,setOpenSnackbar));
//         setTempId("");
//         setOpenSnackbar(true);
//         onCloseDialog();
//     })
//     .catch(error=>{
//         if(error.response){
//             if(error.response.status === 500){
//                 dispatch({
//                     type:'FAILED ACTION DRAFT PENJUALAN',
//                     payload:error.response.data.message
//                 })
//             }else if(error.response.status === 401){
//                 dispatch({
//                     type:'FAILED ACTION DRAFT PENJUALAN',
//                     payload:error.response.data.message
//                 })
//             }
//         }else if(error.request){
//             dispatch({
//                     type:'FAILED ACTION DRAFT PENJUALAN',
//                     payload:'Disconnect from server'
//             })
//         }
//         setOpenSnackbar(true);
//     })
    
// }
export const loadDataToPenjualan = (idpenjualan,token,setOpenSnackbar,formikEdit)=>(dispatch)=>{
    const data={
        'idpenjualan':idpenjualan
    }
    dispatch({
        type:'FECTH DATA DRAFT'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/draftpenjualan/getPenjualan`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'EMPTY PENJUALAN FROM DRAFT'
        })
        dispatch({
            type:'SUCCESSED GET NO FAKTUR DRAFT TO PENJUALAN', 
            payload:ResponseAPI.data[0].idpenjualan
         })
         let days=0;
         days=differenceInDays(new Date(ResponseAPI.data[0].jatuhtempo),new Date(ResponseAPI.data[0].tanggal));
         const dataDraftPenjualan={
            "idpenjualan": ResponseAPI.data[0].idpenjualan,
            "pelanggan": ResponseAPI.data[0].pelanggan,
            "tanggal": new Date(ResponseAPI.data[0].tanggal),
            "pembayaran": ResponseAPI.data[0].pembayaran,
            "jatuhtempo": new Date(ResponseAPI.data[0].jatuhtempo),
            "lamatempo":days,
            "diskon": ResponseAPI.data[0].diskon,
            "total": ResponseAPI.data[0].total,
            "ongkir": ResponseAPI.data[0].ongkir,
            "status": "Draft",
        }
        dispatch({
            type:'GET DRAFT TO PENJUALAN FROM API',
            payload:dataDraftPenjualan
        })
        formikEdit.setFieldValue('namapelanggan',ResponseAPI.data[0].pelanggan);
        formikEdit.setFieldValue('tanggal',ResponseAPI.data[0].tanggal);
        formikEdit.setFieldValue('jatuhtempo',ResponseAPI.data[0].jatuhtempo);
        formikEdit.setFieldValue('lamatempo',days);
        formikEdit.setFieldValue('pembayaran',ResponseAPI.data[0].pembayaran);
        formikEdit.setFieldValue('ongkir',ResponseAPI.data[0].ongkir);
        formikEdit.setFieldValue('diskon',ResponseAPI.data[0].diskon);
        dispatch({
            type:'SELECTED ID PELANGGAN',
            payload:{
                selectedID:"FromDraft",
                selectedNama:ResponseAPI.data[0].pelanggan
            }
        })
        axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/getdatadetailsforpenjualan`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            ResponseAPI.keuntungan.map(listuntung=>{
                dispatch({
                    type:'ADD LIST KEUNTUNGAN',
                    payload:listuntung
                })
                return listuntung;
            })
            ResponseAPI.data.map(list=>{
                if(list.barangstokdiskon==="Tidak"){
                    const newDataDetails = {
                        "idpenjualandetails":list.idpenjualandetails,
                        "idpenjualan":list.idpenjualan,
                        "idbarang":list.idbarang,
                        "namabarang": list.namabarang,
                        "jumlah": list.jumlah,
                        "satuan": list.satuan,
                        "note": list.note,
                        "barangstokdiskon":list.barangstokdiskon,
                        "harga": list.harga,
                        "subtotal": list.subtotal,
                        "stokdiskon": list.stokdiskon
                    }
                    dispatch({
                        type:'ADD DRAFT TO PENJUALAN LIST FROM API',
                        payload:newDataDetails
                    })
                }else if(list.barangstokdiskon==="Ya"){
                    const newDataDetails = {
                        "idpenjualandetails":list.idpenjualandetails,
                        "idpenjualan":list.idpenjualan,
                        "idbarang":list.idbarang,
                        "namabarang": list.namabarang,
                        "jumlah": list.jumlah,
                        "satuan": list.satuan,
                        "note": list.note,
                        "barangstokdiskon":list.barangstokdiskon,
                        "harga": list.harga,
                        "subtotal": list.subtotal,
                        "stokdiskon": list.stokdiskon - list.jumlah
                    }
                    dispatch({
                        type:'ADD DRAFT TO PENJUALAN LIST FROM API',
                        payload:newDataDetails
                    })
                }
                return list
            })
            dispatch({
                type:'EDIT STATUS DRAFT TO PENJUALAN',
                payload:"Draft"
            })
        })
        .catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED ACTION DRAFT TO PENJUALAN',
                        payload:error.response.data.message
                    })
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED ACTION DRAFT TO PENJUALAN',
                        payload:error.response.data.message
                    })
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED ACTION DRAFT TO PENJUALAN',
                        payload:'Disconnect from server'
                })
            }
            setOpenSnackbar(true);
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION DRAFT TO PENJUALAN',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION DRAFT TO PENJUALAN',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION DRAFT TO PENJUALAN',
                    payload:'Disconnect from server'
            })
        }
        setOpenSnackbar(true);
    })
}
export const postPenjualanFromDraftToAPI = (data,token,setOpenSnackbar,setOpenPrint)=>(dispatch)=>{
    dispatch({
        type:'FETCH GET ID PENJUALAN'
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/penjualanfromdraft`,data,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(results=>{
            const ResponseAPI = results.data;
            dispatch({
                type:'SUCCESSED SAVE PENJUALAN', 
                payload:ResponseAPI.message,
             })
             dispatch({
                 type:'EDIT STATUS DRAFT TO PENJUALAN',
                 payload:"Penjualan"
             })
             setOpenSnackbar(true);
             setOpenPrint(true);
        }).catch(error=>{
            if(error.response){
                if(error.response.status === 500){
                    dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }else if(error.response.status === 401){
                    dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:error.response.data.message
                    })
                    setOpenSnackbar(true);
                }
            }else if(error.request){
                dispatch({
                        type:'FAILED SAVE PENJUALAN',
                        payload:'Disconnect from server'
                })
                setOpenSnackbar(true);
            }
        })
    },2000)
}
export const deleteSinglePenjualan = (idpenjualan,data,token,setOpenSnackbar,setOpenDelete)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/penjualan/${idpenjualan}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCESSED ACTION PENJUALAN',
            payload:ResponseAPI.message
        })
        setOpenDelete(false);
        setOpenSnackbar(true);
        dispatch(getAllDataRiwayatPenjualan(data,token,1));
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const deleteAllPenjualan = (data,token,setOpenSnackbar,setOpenDelete)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PENJUALAN'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/penjualan/deleteall`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCESSED ACTION PENJUALAN',
            payload:ResponseAPI.message
        })
        setOpenDelete(false);
        setOpenSnackbar(true);
        dispatch(getAllDataRiwayatPenjualan(data,token,1));
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:error.response.data.message
                })
                setOpenSnackbar(true);
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION PENJUALAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const setMessagePenjualanEmpty = (message,setOpenSnackbar) =>(dispatch)=>{
        dispatch({
            type:'SET MESSAGE PENJUALAN',
            payload:message
        })
        setOpenSnackbar(true);
}