const initalStatePelanggan = {
    dataPelanggan:[],
    isLoadingPelanggan:false,
    messagePelanggan:'',
    selected:{
        selectedID:'',
        selectedNama:''
    }
    
}

const pelangganReducers = (state=initalStatePelanggan,action)=>{
    switch(action.type){
        case 'FETCH Pelanggan':{
            return{
                ...state,
                isLoadingPelanggan:true
            }
        }
        case 'SUCCESSED FETCH DATA PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                dataPelanggan:action.payload
            }
        }
        case 'FAILED FETCH DATA PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                messagePelanggan:action.payload
            }
        }
        case 'EMPTY DATA PELANGGAN':{
            return{
                ...state,
                dataPelanggan:[]
            }
        }
        case 'SELECTED ID PELANGGAN':{
            return{
                ...state,
                selected:action.payload
            }
        }
        default: return state
    }
}
export default pelangganReducers;