import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core'
import React, { useState } from 'react'
import { DateField, InputFiled } from '../../molecules';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { postKoreksiBarang } from '../../../config/redux/action';
import Cookies from 'js-cookie';


const KoreksiStokBarang = ({OpenKoreksi,handleCloseKoreksi,tempIdBarang,tempNamaBarang,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
      const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'500px',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        button:{
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }))
    const classes = useStyle();
    const dispatch = useDispatch();
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingDataStokBarang,fetchMessage} = useSelector(state=>state.stokbarangReducers);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const validationSchema = Yup.object().shape({
        jumlah:Yup.number().required("Jumlah belum diisi").min(1,"Jumlah tidak boleh kurang dari 1"),
        tanggal:Yup.date().max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
    })
    const formik = useFormik({
        initialValues:{
            tanggal:new Date(),
            jumlah:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data={
                'idbarang':tempIdBarang,
                'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                'jumlah':values.jumlah
            }
            dispatch(postKoreksiBarang(data,token,setOpenSnackbar,handleCloseKoreksi,formik));
        }
    })
    
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
  return (
    <div>
            <Dialog open={OpenKoreksi} {...rest}>
            <DialogTitle className={classes.titleDialog} id="add-edit-stok">
                <div>Form Koreksi Stok</div><div>{tempNamaBarang}</div> </DialogTitle>
            <Divider/>
            <DialogContent style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div style={{width:'200px'}}>
                    <InputFiled 
                    style={{width:'200px'}}
                    id="idbarang" 
                    name="idbarang" 
                    label="ID barang" 
                    value={tempIdBarang}
                    disabled={true} 
                    />
                    <DateField id="tanggal" 
                    name="tanggal" 
                    label="Tanggal" 
                    value={formik.values.tanggal}
                    errors={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                    helperText={formik.touched.tanggal && formik.errors.tanggal}
                    onChange={(value)=>formik.setFieldValue('tanggal',value)}
                    />
                    <InputFiled 
                    style={{width:'200px'}}
                    id="jumlah" 
                    name="jumlah" 
                    label="Jumlah"
                    value={formik.values.jumlah}
                    error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                    helperText={formik.touched.jumlah && formik.errors.jumlah}
                    onChange={formik.handleChange}
                    />
                </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingDataStokBarang}>Proses</Button>
                <Button variant="contained" color="secondary" onClick={handleCloseKoreksi} disabled={isLoadingDataStokBarang}>Keluar</Button>
                </div>
            </DialogContent>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {fetchMessage==='Koreksi Barang Berhasil disimpan' ?
                    <Alert onClose={handleCloseSnackBar} severity="success" onClick={handleCloseKoreksi}>{fetchMessage}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error">{fetchMessage}</Alert>
                }
            </Snackbar>
            </Dialog>
    </div>
  )
}

export default KoreksiStokBarang