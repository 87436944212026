import { Tabs,Divider,Typography,makeStyles,Tab } from '@material-ui/core';
import React, { useState } from 'react'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DataBarangGudang from '../DataBarangGudang';
import DnsIcon from '@material-ui/icons/Dns';
import PengeluaranList from '../PengeluaranList';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getNoSuratJalan, setEmptyNoSuratJalan } from '../../config/redux/action';


const PengeluaranGudang = () => {
    const [value, setValue] = useState(0);
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'inherit',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            alignContent:'center',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                textAlign:'center',
            },
            padding:'5px',
        },
        title:{
            paddingTop:'10px'
        },
        navigationButton:{
            paddingTop:'0px',
            width:'100%',
            [theme.breakpoints.up('md')]:{
                width:'70%',
            },
        },
        content:{
            display:'flex',
            flexDirection:'column',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                alignItems:'flex-end',
                marginRight:'10px',
            },
        },buttonNext:{
            height:'30px',
            marginTop:'0px',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                marginTop:'0px',
            },
        },
    }));
    const dispatch = useDispatch();
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleChange = (e, newValue) => {
        setValue(newValue);
        if(newValue === 1){
            dispatch(getNoSuratJalan(token));
        }else{
            dispatch(setEmptyNoSuratJalan());
        }
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            hidden={value !== index}
            id={`standart-prevent-tabpanel-${index}`}
            aria-labelledby={`standart-prevent-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>{children}</div>
            )}
          </div>
        );
      }
      function a11yProps(index) {
        return {
          id: `standart-prevent-tab-${index}`,
          'aria-controls': `standart-prevent-tabpanel-${index}`,
        };
      }
      const classes = useStyle();
    return (
        <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant='h5' className={classes.title}>Pengeluaran Gudang</Typography>
                    <div className={classes.navigationButton}>
                        <Tabs 
                        value={value}
                        onChange={handleChange} 
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{height:'58px'}}
                        >
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'110px',paddingTop:'0px'}} label="Stok Gudang" {...a11yProps(0)} icon={<DnsIcon fontSize="small"/>}/>
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'110px',paddingTop:'0px'}} label="Daftar" {...a11yProps(1)} icon={<FormatListNumberedIcon fontSize="small"/>}/>
                        </Tabs>
                    </div>
                </div>
                <Divider/>
                <TabPanel value={value} index={0}>
                    <DataBarangGudang/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PengeluaranList/>
                </TabPanel>
         </div>
    )
}

export default PengeluaranGudang
