import { Divider ,Dialog, DialogTitle,Button,Typography,makeStyles,DialogContent,Snackbar } from '@material-ui/core'
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { deletePartner, setEmptyPartnerMessage, setFillFormPartner } from '../../../config/redux/action/partnerAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormPartnerDelete = ({HandleClickCloseDeleteDialog,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingPartner,messagePartner,FormPartner} = useSelector(state=>state.partnerReducers);
    const {idpartner,namaperusahaan} = FormPartner;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setFillFormPartner());
            dispatch(setEmptyPartnerMessage());
        },500)
      };
    const onSubmit=()=>{
        dispatch(deletePartner(idpartner,token,setOpenSnackbar));
        dispatch(setFillFormPartner());
        HandleClickCloseDeleteDialog();
    }
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-partner-dialog">Hapus Data Partner</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data Partner</Typography>
                    <Typography align='center'>({namaperusahaan})?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingPartner}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog} disabled={isLoadingPartner}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={messagePartner === 'Data partner berhasil dihapus' ?"success" :"error"} onClick={HandleClickCloseDeleteDialog}>{messagePartner}</Alert>
            </Snackbar>
        </div>
    )
}

export default FormPartnerDelete
