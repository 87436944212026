import { 
    // InputLabel,
     makeStyles, Select, Typography } from '@material-ui/core';
import React from 'react'

const PilihHargaCombo = ({id,name,label,error,handleChange,helperText,data,...rest}) => {
    const useStyle = makeStyles({
        root:{
            width:'100%',
        },
        option:{
            width:'100%',
            marginBottom:'10px'
        },
        select:{
            width:'100%',
        },
        helperText:{
            color:'#FD0F04',
            fontSize:'12px',
            marginBottom:'10px',
        }
    });
    const classes = useStyle();
    return (
        <div className={classes.root}>
            {/* <InputLabel
            className={classes.option} 
            htmlFor='pilihharga'>Pilih Harga</InputLabel> */}
            <Select
            className={classes.select}
            variant='outlined'
            {...rest}
            id={id}
            name={name}
            label={label}
            native
            error={error}
            onChange={handleChange}>
                {data.map((datas=>(
                    <option key={datas.id} value={`${datas.id}`}>{datas.tipe}</option>
                )))}
            </Select>
            <Typography className={classes.helperText}>{helperText}</Typography>
        </div>  
    )
}

export default PilihHargaCombo
