import { Button, Card, CardActions, CardContent, Divider, Fab, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography, makeStyles } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Search } from '../../../component/molecules';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DetailsIcon from '@material-ui/icons/Details';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getAllHutang, getIdHutang, setEmptyDataHutang, setSearchHutang } from '../../../config/redux/action/hutangAction';
import { format } from 'date-fns';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NewHutang from '../NewHutang';
import PilihTanggalPage from '../PilihTanggalPage';

const AllDataHutang = () => {
    const useStyles=makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center'
        },
        titleWapper:{
            width:'100%'
        },
        title:{
            textAlign:'center',
            paddingTop:'5px',
        },
        searchWapper:{
            width:'98%',
            display:'flex',
            alignItems:'flex-end',
        },
        mainContentWapper:{
            width:'100%',
            height:'75vh',
            display:'flex',
            justifyContent:'space-between',
            marginTop:'10px',
            marginRight:'5px',
            marginLeft:'5px'
        },
        contentWapper:{
            width:'90%',
            flexWrap:'wrap',
            display:'flex',
            justifyContent:'flex-start'
        },
        cardWapper:{
            width:'230px',
            height:'110px',
            borderRadius:'10px',
            boxShadow:'2px 2px 2px rgba(0,0,0,0.2)',
            margin:'3px'
        },
        contentCard:{
            padding:'3px'
        },
        cardButton:{
            padding:'3px',
            alignItems:'flex-end'
        },
        titleCardHead:{
            fontSize:'14px',
            fontWeight:'bold',
            textAlign:'center'
        },
        titleCard:{
            fontSize:'12px'
        },
        cardActionWapper:{
            paddingTop:'1px',
            justifyContent:'flex-end'
        },
        leftCardWapper:{
            alignContent:'center'
        },
        rigthCardWapper:{
            alignContent:'center'
        },
        nullDataWapper:{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        buttonAddButton:{
            display:'relative',
            justifyContent:'flex-end',
            alignItems:'flex-end',
        },
        buttonPrintWapper:{
            width:'30%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'flex-end'
        },
        radioWapper:{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'flex-end',
            marginLeft:'13%'
        },
    }))
    const classes=useStyles();
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.loginReducers);
    const {searchDataHutang,pageHutang,dataAllHutang}=useSelector(state=>state.hutangReducers);
    const [counter,setCounter]=useState(1);
    const [token,setToken] = useState(data.token);
    const [valueRadio,setValueRadio]=useState("Jatuh Tempo Terdekat");
    const [openAddHutang,setOpenAddHutang] = useState(false);
    const [openPilihTanggal,setOpenPilihTanggal] = useState(false);
    const history =useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleChangeRadio=(e)=>{
        setValueRadio(e.target.value);
        dispatch(getAllHutang(searchDataHutang,counter,token,e.target.value,20));
    }
    useEffect(()=>{
        if(dataAllHutang.length<1){
            dispatch(getAllHutang(searchDataHutang,counter,token,valueRadio,20));
        }
    },[dispatch,searchDataHutang,pageHutang,dataAllHutang.length,counter,token,valueRadio])
    const handleSearch = () =>{
        setCounter(1);
        dispatch(getAllHutang(searchDataHutang,counter,token,valueRadio,20));
    }
    const typingSearch = (e)=>{
        if(e.target.value.length>=3){
            dispatch(getAllHutang(e.target.value,counter,token,valueRadio,20));
            dispatch(setSearchHutang(e.target.value));
        }else{
            dispatch(setSearchHutang(""));
            dispatch(getAllHutang("",counter,token,valueRadio,20));
        }
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
        dispatch(setEmptyDataHutang());
    }
    const handleNext = () =>{
        setCounter(counter === pageHutang.totalPage ? pageHutang.totalPage : counter + 1);
        dispatch(setEmptyDataHutang());
    }
    const handleOpenAddHutangForm = () =>{
        setOpenAddHutang(true);
        dispatch(getIdHutang(token));
    }
    const handleCloseAddHutang = () =>{
        setOpenAddHutang(false);
        dispatch(getAllHutang(searchDataHutang,counter,token,valueRadio,20));
    }
    const handleClickRincianHutang = (i,idpartner,namaperusahaan) =>{
        if(searchDataHutang===""){
            history.push(`/hutang/rincian/N/${counter}/${valueRadio}/${i}/${idpartner}/${namaperusahaan}`);
        }else{
            history.push(`/hutang/rincian/${searchDataHutang}/${counter}/${valueRadio}/${i}/${idpartner}/${namaperusahaan}`);
        }
        dispatch(getAllHutang(searchDataHutang,counter,token,valueRadio,100));
    }
    const dataContent=(
        <Fragment>
        {dataAllHutang.map((dataHutangs,i)=>(
            <Card className={classes.cardWapper} variant='outlined' key={dataHutangs.idhutang}>
                <CardContent className={classes.contentCard}>
                    <Typography className={classes.titleCardHead}>{dataHutangs.namaperusahaan}</Typography>
                    <Typography className={classes.titleCard}>Tanggal Nota : {format(new Date(dataHutangs.tanggal),'dd MMMM yyyy')}
                    </Typography>
                    <Typography className={classes.titleCard}>Jatuh Tempo : {format(new Date(dataHutangs.jatuhtempo),'dd MMMM yyyy')}</Typography>
                    <Typography className={classes.titleCard}>Total : {new Intl.NumberFormat().format(dataHutangs.sisahutang)}</Typography>
                </CardContent>
                <CardActions disableSpacing className={classes.cardActionWapper}>
                    <Tooltip title="Lihat Rincian Hutang">
                        <IconButton 
                        aria-label="add to favorites" 
                        size='small'
                        onClick={()=>{handleClickRincianHutang(i,dataHutangs.idpartner,dataHutangs.namaperusahaan)}}>
                            <DetailsIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
            ))}
        </Fragment>
    )
    const pilihTanggal = ()=>{
        setOpenPilihTanggal(true);
    }
    const handleClosePilihTanggalPage = ()=>{
        setOpenPilihTanggal(false);
    }
  return (
    <div className={classes.root}>
        <div className={classes.titleWapper}>
            <Typography className={classes.title} variant="h5">Data Hutang</Typography>
            <Divider/>
        </div>
        <div className={classes.searchWapper}>
            <div className={classes.buttonAddButton}>
                <Tooltip title="Tambah data hutang">
                <Fab size="small" 
                onClick={()=>{handleOpenAddHutangForm()}} 
                color="primary" 
                aria-label="add">
                    <AddIcon/>
                </Fab>
                </Tooltip>
            </div>
            <div className={classes.buttonPrintWapper}>
                <Tooltip title="Print Data Hutang">
                <Button size="small" 
                onClick={()=>{pilihTanggal()}} 
                variant='contained'
                color='default'
                aria-label="add">Print Hutang
                </Button>
                </Tooltip>
            </div>
            <RadioGroup className={classes.radioWapper} aria-label="view" name="viewHutang" value={valueRadio} onChange={handleChangeRadio}>
                <FormControlLabel value="Jatuh Tempo Terdekat" control={<Radio />} label="Jatuh Tempo Terdekat" />
                <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
            </RadioGroup>
            <Search className={classes.search} label="ID Partner / No Transaksi" name="cari-hutang" id="cari-hutang"
            disabled={valueRadio==='Semua' ? false : true}
            onChange={(e)=>typingSearch(e)}/>
            <IconButton size='small' style={{alignItems:"center"}}
            disabled={valueRadio==='Semua' ? false : true}
            onClick={handleSearch}>
                <SearchIcon/>
            </IconButton> 
        </div>
        <div className={classes.mainContentWapper}>
            <div className={classes.leftCardWapper}>
                {counter===1?
                <IconButton disabled={true}>
                    <ArrowBackIosIcon/>
                </IconButton> :
                <IconButton onClick={handlePrev} disabled={false}>
                    <ArrowBackIosIcon/>
                </IconButton>}
            </div>
            <div className={classes.contentWapper}>
                {dataAllHutang.length > 0 ? dataContent :
                <div className={classes.nullDataWapper}>
                    <Typography variant='h4' color='primary'>Belum Terdapat Data Hutang</Typography>
                </div>
                }
            </div>
            <div className={classes.rigthCardWapper}>
                {counter===pageHutang.totalPage?
                <IconButton disabled={true}>
                    <ArrowForwardIosIcon/>
                </IconButton> :
                <IconButton onClick={handleNext} disabled={false}>
                    <ArrowForwardIosIcon/>
                </IconButton>}
            </div>
        </div>
        <NewHutang open={openAddHutang} handleCloseNewHutang={handleCloseAddHutang}/>
        <PilihTanggalPage open={openPilihTanggal} HandleClickClosePilihTanggal={handleClosePilihTanggalPage}/>
    </div>
  )
}

export default AllDataHutang