import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { emptyRiwayatPembelianDetails, getItemPembelian, setEmptyRiwayatTransferAll, setExistFormTransfer, setFormTransfer, setRiwayatTransferAll, setTempFakturForTransfer, setTransferListMessage } from '../../../config/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import ListItemForDirectTransfer from '../ListItemForDirectTransfer';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const TransferAllItemPembelian = ({HandleClickCloseDeleteDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'500px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const {data} = useSelector(state=>state.loginReducers);
    const {dataRiwayatPembelianDetails,tempFakturForTransfer} = useSelector(state=>state.riwayatPembelianReducers);
    const dispatch = useDispatch();
    const {isLoadingTransfer,transferListMessage,FormTransfer,tempTransferBarang} = useSelector(state=>state.transferBarangReducers);
    const [token,setToken] = useState(data.token);
    const [openListTransfer,setOpenListTransfer]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setTransferListMessage(""));
        },500)
      };
      const onSubmit = async()=>{
        setOpenListTransfer(true);
        loopSubmitData();
        HandleClickCloseDeleteDialog();
      }
      useEffect(()=>{
        if(dataRiwayatPembelianDetails.length<1 && tempFakturForTransfer!==""){
            dispatch(getItemPembelian(tempFakturForTransfer,token,setOpenSnackbar));
        }
      },[FormTransfer,dispatch,dataRiwayatPembelianDetails,tempFakturForTransfer,token,setOpenSnackbar])
    const loopSubmitData=()=>{
        dataRiwayatPembelianDetails.forEach((DRPD,i) => {
            let temp=0;
            let tempID;
            if(FormTransfer.length>0){
                tempID = FormTransfer.length+1
                temp=tempID+i
            }else{
                temp=i+1;
            }
            const existBarang = FormTransfer.filter(transfer => transfer.idbarang === DRPD.idbarang && transfer.variant === DRPD.varian);
                if(existBarang.length > 0){
                    dispatch(setRiwayatTransferAll(temp,DRPD.idbarang,DRPD.namabarang,DRPD.jumlah,DRPD.varian,DRPD.satuan,"Failed"));
                }else if(existBarang.length < 1){
                    const existBarangInTemp = tempTransferBarang.filter((temp) =>temp.idbarang === DRPD.idbarang);
                    if(existBarangInTemp.length>0){
                        let tempJumlah;
                        let index;
                        tempTransferBarang.find((indexs,i) => {
                            if(indexs.idbarang===DRPD.idbarang){
                                tempJumlah = parseInt(indexs.jumlah) + parseInt(DRPD.jumlah);
                                index=i;
                                return index;
                            }
                            return tempJumlah;
                        })
                        dispatch(setExistFormTransfer(temp,DRPD.idbarang,DRPD.namabarang,DRPD.jumlah,DRPD.varian,index,tempJumlah))
                        dispatch(setRiwayatTransferAll(temp,DRPD.idbarang,DRPD.namabarang,DRPD.jumlah,DRPD.varian,DRPD.satuan,"Successed"));
                    }
                    else if(existBarangInTemp.length<1){
                        dispatch(setFormTransfer(temp,DRPD.idbarang,DRPD.namabarang,DRPD.jumlah,DRPD.varian))
                        dispatch(setRiwayatTransferAll(temp,DRPD.idbarang,DRPD.namabarang,DRPD.jumlah,DRPD.varian,DRPD.satuan,"Successed"));
                    }
                }
            });
    }
      const handleCloseOpenListItemForTransfer = () =>{
        dispatch(setTempFakturForTransfer(""));
        dispatch(emptyRiwayatPembelianDetails());
        dispatch(setEmptyRiwayatTransferAll());
        setOpenListTransfer(false);
      }
  return (
    <div>
        <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-transfer-dialog">Transfer Semua Item</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin melakukan transfer untuk semua barang</Typography>
                    <Typography align='center'>pada faktur pembelian {(tempFakturForTransfer)}?</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" 
                onClick={onSubmit} disabled={isLoadingTransfer}
                >Transfer Semua</Button>
                <Button variant="contained" color="secondary" 
                onClick={HandleClickCloseDeleteDialog} disabled={isLoadingTransfer}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={transferListMessage === 'Penghapusan data transfer barang berhasil' ?"success" :"error"} onClick={HandleClickCloseDeleteDialog}>{transferListMessage}</Alert>
            </Snackbar>
            <ListItemForDirectTransfer open={openListTransfer} HandleClickCloseDialog={handleCloseOpenListItemForTransfer} nofaktur={tempFakturForTransfer} statusTransfer="AllTransfer"/>
    </div>
  )
}

export default TransferAllItemPembelian