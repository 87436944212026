import { Divider,  Fab,  IconButton,  Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getAllHutang, getDataRincianTransaksi, getRincianHutang, setDataForPayment, setMessageHutang, setSearchHutang } from '../../../config/redux/action/hutangAction';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { PembayaranCombo } from '../../../component/molecules';
import { CheckMark, Payment, RincianLogo, notReceiveInvoice, printHutang } from '../../../component/assets';
import { getDataForPrintPembelian, refreshPrintPembelian } from '../../../config/redux/action';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DialogNotaAsli from '../../../component/organism/DialogTerimaNotaAsli';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteHutang from '../DeleteHutang';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const RincianHutang = () => {
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 12
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            // border:'1px solid black'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginTop:'5px',
            width:'100%',
            maxHeight:'100%',
            maxWidth:'100%',
            display:"flex",
            justifyContent:'center'
        },
        statusHutangWapper:{
            width:'90%',
            display:"flex",
            justifyContent:'flex-start',
        },
        tableContainer:{
            // border:'1px solid black',
            width:'1230px',
            maxWidth:'100%',
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },addIcon:{
            paddingRight:'10px'
        },
        titleWapper:{
            width:'100%',
            marginTop:'5px'
        }
        ,hutangNull:{
            width:'100%',
            height:'300px',
            maxHeight:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        },leftCardWapper:{
            alignContent:'center'
        },
        rigthCardWapper:{
            alignContent:'center'
        }, 
        mainContentWapper:{
            width:'100%',
            height:'70vh',
            display:'flex',
            justifyContent:'space-between',
            marginTop:'10px',
            // marginRight:'5px',
            // marginLeft:'5px'
        },
    }));
    const classes = useStyles();
    const {search,counter,jenisview,index,namaperusahaan,idpartner} = useParams();
    const {data}=useSelector(state=>state.loginReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    const {dataRincianHutang,hutangMessage,dataAllHutang}=useSelector(state=>state.hutangReducers);
    const [openSnackbar,setOpenSnackbar]=useState(false);
    const [openDialogKonfirmNota,setOpenDialogKonfirmNota]=useState(false);
    const [tempIdForKonfirm,setTempIdFormKonfirm]=useState('');
    const [tempIdHutang,setTempIdHutang]=useState('');
    const [openDialogHutang,setOpenDialogHutang] = useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const [statusHutang,setStatusHutang]=useState('Semua');
    const history = useHistory();
    useEffect(()=>{
        dispatch(getRincianHutang(idpartner,statusHutang,counter,token));
        if(dataAllHutang.length<1){
            if(search==="N"){
                dispatch(getAllHutang("",counter,token,jenisview,100));
            }else{
                dispatch(getAllHutang(search,counter,token,jenisview,100));
            }
        }
    },[dispatch,idpartner,statusHutang,counter,token,search,jenisview,dataAllHutang.length])
    const dataStatusHutang =[
        {id:'Semua',tipe:'Semua'},
        {id:'Lunas',tipe:"Lunas"},
        {id:'Bon Lunas Nota Belum',tipe:'Bon Lunas Nota Belum'},
        {id:'Belum Lunas',tipe:"Belum Lunas"},
        {id:'Terima Bukti/Nota',tipe:"Terima Bukti/Nota"}
    ]
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        dispatch(setMessageHutang(""));
      };
    const handleOpenPembayaran =(idhutang,idpartner,idtransaksi,tanggal,jenishutang,jatuhtempo,totalhutang,pembayaran,keterangan,notaasli)=>{
        const data = {
            'idhutang':idhutang,
            'idpartner':idpartner,
            'idtransaksi':idtransaksi,
            'tanggal':tanggal,
            'jenishutang':jenishutang,
            'jatuhtempo':jatuhtempo,
            'totalhutang':totalhutang,
            'pembayaran':pembayaran,
            'notaasli':notaasli
        }
        if(keterangan.substring(0,9)==="Pembelian"){
            dispatch(getDataForPrintPembelian(idtransaksi,token,setOpenSnackbar,"PH"));
            dispatch(setDataForPayment(data,"PH",history));
        }else if(keterangan.substring(0,9)!=="Pembelian"){
            dispatch(getDataForPrintPembelian(idtransaksi,token,setOpenSnackbar,"TH"));
            dispatch(setDataForPayment(data,"TH",history));
        }
    }
    const handlePrintFakturPembelian = (idtransaksi,keterangan)=>{
        if(keterangan.substring(0,9)==="Pembelian"){
            dispatch(refreshPrintPembelian(idtransaksi,token,"PH",history));
            history.push(`/PrintPembelian/PHP/${idtransaksi}`);
        }else if(keterangan.substring(0,9)!=="Pembelian"){
            dispatch(refreshPrintPembelian(idtransaksi,token,"TH",history));
            history.push(`/PrintTransaksi/THP/${idtransaksi}`);
        }
    }
    const handleOpenRincianTransaksi = (idtransaksi)=>{
        dispatch(getDataRincianTransaksi(idtransaksi,token));
        history.push(`/hutang/rinciantransaksi/${namaperusahaan}/${idtransaksi}`);
    }
    const handlePrev = () =>{
        history.push(`/hutang/rincian/${search}/${counter}/${jenisview}/${parseInt(index)-1}/${dataAllHutang[parseInt(index)-1].idpartner}/${dataAllHutang[parseInt(index)-1].namaperusahaan}`);
    }
    const handleNext = () =>{
        history.push(`/hutang/rincian/${search}/${counter}/${jenisview}/${parseInt(index)+1}/${dataAllHutang[parseInt(index)+1].idpartner}/${dataAllHutang[parseInt(index)+1].namaperusahaan}`);
    }
    const handleClickNotaAsli = (idtransaksi) =>{
        setOpenDialogKonfirmNota(true);
        setTempIdFormKonfirm(idtransaksi);
    }
    const handleCloseKonfirmDialog = () =>{
        setOpenDialogKonfirmNota(false);
        dispatch(getRincianHutang(idpartner,statusHutang,counter,token));
        if(dataAllHutang.length<1){
            if(search==="N"){
                dispatch(getAllHutang("",counter,token,jenisview,100));
            }else{
                dispatch(getAllHutang(search,counter,token,jenisview,100));
            }
        }
    }
    const handleOpenDeleteHutang = (idhutang) =>{
        setTempIdHutang(idhutang);
        setOpenDialogHutang(true);
    }
    const handleCloseDeleteHutang =()=>{
        setOpenDialogHutang(false);
        dispatch(getRincianHutang(idpartner,statusHutang,counter,token));
        if(dataAllHutang.length<1){
            if(search==="N"){
                dispatch(getAllHutang("",counter,token,jenisview,100));
            }else{
                dispatch(getAllHutang(search,counter,token,jenisview,100));
            }
        }
    }
    const dataHutang = (
        <Paper>
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table barang" size="small">
                <TableHead style={{fontSize:"10px"}}>
                    <TableRow>
                        <SytledTableCell width='4%' align="center"></SytledTableCell>
                        <SytledTableCell width='10%' align="center">ID Transaksi</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Tanggal</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Tipe</SytledTableCell>
                        <SytledTableCell width='10%' align="center">J Tempo</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Total</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Pembayaran</SytledTableCell>
                        <SytledTableCell width='10%' align="center">Sisa</SytledTableCell>
                        <SytledTableCell width='12%' align="center">Bukti Transaksi</SytledTableCell>
                        <SytledTableCell width='7%' align="center">Status</SytledTableCell>
                        <SytledTableCell width='12%' align="center"></SytledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRincianHutang.map((dataRincianHutangs=>(
                        <StyledTableRow key={dataRincianHutangs.idhutang}>
                            {dataRincianHutangs.totalhutang-dataRincianHutangs.pembayaran===0 && dataRincianHutangs.notaasli==="Sudah Terima" ?
                                <SytledTableCell>
                                <Tooltip title="Hapus Hutang">
                                        <IconButton 
                                        size='small' 
                                        onClick={()=>handleOpenDeleteHutang(dataRincianHutangs.idtransaksi)}
                                        >
                                            <DeleteForeverIcon color='secondary'/>
                                        </IconButton>
                                </Tooltip>
                            </SytledTableCell>:<SytledTableCell><DeleteForeverIcon color='disabled'/></SytledTableCell>}
                            <SytledTableCell align="center">{dataRincianHutangs.idtransaksi}</SytledTableCell>
                            <SytledTableCell align="center">{format(new Date(dataRincianHutangs.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                            <SytledTableCell align="center">{dataRincianHutangs.jenishutang}</SytledTableCell>
                            <SytledTableCell align="center">{format(new Date(dataRincianHutangs.jatuhtempo),'dd/MM/yyyy')}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianHutangs.totalhutang)}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianHutangs.pembayaran)}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianHutangs.totalhutang-dataRincianHutangs.pembayaran)}</SytledTableCell>
                            <SytledTableCell align="center">{dataRincianHutangs.notaasli==='Belum Terima'?
                            <IconButton size='small' onClick={()=>{handleClickNotaAsli(dataRincianHutangs.idtransaksi)}}>
                            <img src={notReceiveInvoice} alt='notReceivedInvoice'/> 
                            </IconButton>:
                            <img src={CheckMark} alt='checkMark'/> }
                            </SytledTableCell>
                            <SytledTableCell align="center">{parseInt(dataRincianHutangs.totalhutang)-parseInt(dataRincianHutangs.pembayaran)===0?"Lunas":"Belum Lunas"}</SytledTableCell>
                            <SytledTableCell align="center">
                                <Tooltip title="Pembayaran Hutang">
                                    <IconButton 
                                    size='small' 
                                    onClick={()=>handleOpenPembayaran(dataRincianHutangs.idhutang,
                                        dataRincianHutangs.idpartner,
                                        dataRincianHutangs.idtransaksi,
                                        dataRincianHutangs.tanggal,
                                        dataRincianHutangs.jenishutang,
                                        dataRincianHutangs.jatuhtempo,
                                        dataRincianHutangs.totalhutang,
                                        dataRincianHutangs.pembayaran,
                                        dataRincianHutangs.keterangan,
                                        dataRincianHutangs.notaasli)}
                                    >
                                        <img src={Payment} alt='payment'/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="History Pembayaran">
                                    <IconButton 
                                    size='small' 
                                    onClick={()=>handleOpenRincianTransaksi(dataRincianHutangs.idtransaksi)}
                                    >
                                        <img src={RincianLogo} alt='detailsHutang'/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Print Nota">
                                    <IconButton 
                                    size='small' 
                                    onClick={()=>handlePrintFakturPembelian(dataRincianHutangs.idtransaksi,dataRincianHutangs.keterangan)}
                                    >
                                        <img src={printHutang} alt='printHutang'/>
                                    </IconButton>
                                </Tooltip>
                            </SytledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    )
    const hutangNull = (
        <div className={classes.hutangNull}>
            <Typography variant='h3' color='primary'>Tidak Terdapat Data Hutang</Typography>
        </div>
    )
    const handleClickBack = () =>{
        dispatch(getAllHutang("",counter,token,"Jatuh Tempo Terdekat",20));
        dispatch(setSearchHutang(""));
        history.push('/hutang');
    }
    const handleChangeStatusView = (e)=>{
        setStatusHutang(e.target.value);
        dispatch(getRincianHutang(idpartner,e.target.value,counter,token));
    }
  return (
    <div className={classes.root}>
        <div className={classes.titleWapper}>
            <Typography className={classes.title} variant="h5">Data Rincian Hutang {namaperusahaan}</Typography>
            <Divider/>
        </div>
            <div className={classes.statusHutangWapper}>
            <PembayaranCombo style={{width:'200px'}}
                    id="statushutang"
                    name="statushutang"
                    data={dataStatusHutang}
                    label="Status Hutang"
                    value={statusHutang}
                    handleChange={(e)=>handleChangeStatusView(e)}
                    />
            </div>
            <div className={classes.mainContentWapper}>
                <div className={classes.leftCardWapper}>
                    <IconButton onClick={handlePrev} disabled={parseInt(index)===0?true:false}>
                        <ArrowBackIosIcon/>
                    </IconButton>
                </div>
                <div className={classes.table}>
                        {dataRincianHutang.length>0 ? dataHutang : hutangNull}
                </div>
                <div className={classes.rigthCardWapper}>
                    <IconButton onClick={handleNext} disabled={parseInt(index)===parseInt(dataAllHutang.length-1)?true:false}>
                        <ArrowForwardIosIcon/>
                    </IconButton> 
                </div>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackBar}>
            {hutangMessage === 'Berhasil melakukan reset identifier' ?
                <Alert onClose={handleCloseSnackBar} severity={"success"}>{hutangMessage}</Alert>:
                <Alert onClose={handleCloseSnackBar} severity={"error"}>{hutangMessage}</Alert>
            }
            </Snackbar>
            <DialogNotaAsli open={openDialogKonfirmNota} tempID={tempIdForKonfirm} HandleClickCloseDeleteDialog={handleCloseKonfirmDialog}/>
            <DeleteHutang open={openDialogHutang} tempID={tempIdHutang} HandleClickCloseDeleteDialog={handleCloseDeleteHutang}/>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={handleClickBack}>
                <ArrowBackIcon/>
            </Fab>
    </div>
  )
}

export default RincianHutang