import { Divider, makeStyles, Typography,TableCell,TableRow, Paper, TableContainer, Table, TableHead, Tooltip, IconButton, withStyles, TableBody, Snackbar} from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import DetailsIcon from '@material-ui/icons/Details';
import { useDispatch, useSelector } from 'react-redux';
import { emptyReturDetails, emptyRiwayatPembelianDetails, emptyTableRiwayatPembelian, getAllDataRiwayatPembelian, getDataReturByID, getPembelianDetails } from '../../config/redux/action';
import Cookies from 'js-cookie';
import { DateField } from '../../component/molecules';
import SearchIcon from '@material-ui/icons/Search';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useHistory } from 'react-router-dom';
import DeletePembelian from '../../component/organism/DeletePembelian';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const RiwayatPembelian = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        table:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'5px',
            width:'100%',
        },
        paper:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'90%',
            [theme.breakpoints.down('xs')]:{
                width:'90%',
            },
        },
        dateWapper:{
            display:'flex',
            justifyContent:'space-between',
            width:'25%',
            [theme.breakpoints.down('xs')]:{
                width:'80%',
            },
            alignItems:'center'
        },
        tableContainer:{
            height:'420px',
            [theme.breakpoints.down('xs')]:{
                height:'520px'
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
    }));
    const classes = useStyle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const {dataRiwayatPembelian,pageRiwayatPembelian,messageRiwayatPembelian} = useSelector(state=>state.riwayatPembelianReducers);
      const {messageRetur} = useSelector(state=>state.returSupplierReducers);
      const {data} = useSelector(state => state.loginReducers);
      const validationSchema = yup.object().shape({
            tanggalAwal:yup.date().max(yup.ref('tanggalAkhir'),'Tanggal awal tidak boleh melebihi tanggal akhir').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
            tanggalAkhir:yup.date().min(yup.ref('tanggalAwal'),'Tanggal Akhir tidak boleh kurang dari tanggal awal').max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
      })
      const formik = useFormik({
        initialValues:{
            tanggalAwal:new Date(),
            tanggalAkhir:new Date()
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'tanggalAwal':format(values.tanggalAwal,'yyyy-MM-dd'),
                'tanggalAkhir':format(values.tanggalAkhir,'yyyy-MM-dd')
            }
            dispatch(getAllDataRiwayatPembelian(data,token,counter));
        }
      })
      const [token,setToken] = useState(data.token);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const [counter,setCounter] = useState(1);
      const dispatch = useDispatch();
      const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
      }
      const handleNext = () =>{
        setCounter(counter === pageRiwayatPembelian.totalPage ? pageRiwayatPembelian.totalPage : counter + 1);
      }
      const history = useHistory();
      const [openSnackbar,setOpenSnackbar] = useState(false);
      const [openDeletePembelian,setOpenDeletePembelian]=useState(false);
      const [tempIdPembelian,setTempIdPembelian]=useState('');
      const handleCloseSnackBar = () => {
          setOpenSnackbar(false);
          setTimeout(()=>{
            //   dispatch(setMessageSave(""));
              history.push('/historypembelian');
          },200)
      };
      const handleClickOpenDetailsPembelian = (idpembelian,noretur,totalpembelian,totalretur)=>{
          dispatch(emptyRiwayatPembelianDetails());
          dispatch(emptyReturDetails());
          if(idpembelian !== "" &&  noretur !== ""){
            dispatch(getDataReturByID(noretur,token,setOpenSnackbar,totalretur));
            dispatch(getPembelianDetails(idpembelian,token,setOpenSnackbar,totalpembelian,history));
          }else if(idpembelian !== "" &&  noretur === ""){
            dispatch(getPembelianDetails(idpembelian,token,setOpenSnackbar,totalpembelian,history));
          }
      }
      useEffect(()=>{
        dispatch(emptyTableRiwayatPembelian());
        if(format(formik.values.tanggalAwal,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd') && format(formik.values.tanggalAkhir,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd')){
            setTimeout(()=>{
                const data = {
                    'tanggalAwal':format(formik.values.tanggalAwal,'yyyy-MM-dd'),
                    'tanggalAkhir':format(formik.values.tanggalAkhir,'yyyy-MM-dd')
                }
                dispatch(getAllDataRiwayatPembelian(data,token,counter));
            },1000)
        }
    },[dispatch,token,counter,formik.values.tanggalAwal,formik.values.tanggalAkhir])
    const handleClickHapusPembelian = (idpembelian) =>{
        setOpenDeletePembelian(true);
        setTempIdPembelian(idpembelian);
    }
    const handleCloseDeletePembelian = () =>{
        setOpenDeletePembelian(false);
        dispatch(emptyTableRiwayatPembelian());
        if(format(formik.values.tanggalAwal,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd') && format(formik.values.tanggalAkhir,'yyyy-MM-dd') === format(new Date(),'yyyy-MM-dd')){
            setTimeout(()=>{
                const data = {
                    'tanggalAwal':format(formik.values.tanggalAwal,'yyyy-MM-dd'),
                    'tanggalAkhir':format(formik.values.tanggalAkhir,'yyyy-MM-dd')
                }
                dispatch(getAllDataRiwayatPembelian(data,token,counter));
            },1000)
        }
    }
      const tableRiwayatPembelian =(
        <TableBody>
            {dataRiwayatPembelian.map((dataRiwayatPembelians=>(
            <StyledTableRow key={dataRiwayatPembelians.idpembelian}>
                <SytledTableCell align="center">
                <Tooltip title="Hapus Pembelian">
                    <IconButton size='small' 
                    onClick={()=>{handleClickHapusPembelian(dataRiwayatPembelians.idpembelian)}}
                    >
                        <DeleteForeverIcon/>
                    </IconButton>
                </Tooltip></SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPembelians.idpembelian}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPembelians.noretur}</SytledTableCell>
                <SytledTableCell align="center">{dataRiwayatPembelians.supplier}</SytledTableCell>
                <SytledTableCell align="center">{format(new Date(dataRiwayatPembelians.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPembelians.totalpembelian)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPembelians.totalretur)}</SytledTableCell>
                <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRiwayatPembelians.total)}</SytledTableCell>
                <SytledTableCell align="center">
                        <Tooltip title="Rincian transaksi">
                            <IconButton size='small' 
                            onClick={()=>{handleClickOpenDetailsPembelian(dataRiwayatPembelians.idpembelian,dataRiwayatPembelians.noretur,dataRiwayatPembelians.totalpembelian,dataRiwayatPembelians.totalretur)}}
                            >
                                <DetailsIcon/>
                            </IconButton>
                        </Tooltip>
                    </SytledTableCell>
            </StyledTableRow>
        )))}
        </TableBody>
      )
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>Riwayat Pembelian</Typography>
            </div>
            <Divider/>
            <div className={classes.table}>
                <div className={classes.dateWapper}>
                    <DateField id="tanggalAwal" 
                    name="tanggalAwal" 
                    label="Tanggal Awal" 
                    value={formik.values.tanggalAwal}
                    errors={formik.touched.tanggalAwal && Boolean(formik.errors.tanggalAwal)}
                    helperText={formik.touched.tanggalAwal && formik.errors.tanggalAwal}
                    onChange={(value)=>formik.setFieldValue('tanggalAwal',value)}
                    />

                    <DateField id="tanggalAkhir" 
                    name="tanggalAkhir" 
                    label="Tanggal Akhir" 
                    value={formik.values.tanggalAkhir}
                    errors={formik.touched.tanggalAkhir && Boolean(formik.errors.tanggalAkhir)}
                    helperText={formik.touched.tanggalAkhir && formik.errors.tanggalAkhir}
                    onChange={(value)=>formik.setFieldValue('tanggalAkhir',value)}
                    />
                     <Tooltip title="Cari data pembelian">
                    <IconButton
                    id='searchButton'
                    name='searchButton'
                    onClick={formik.handleSubmit}
                    >
                       <SearchIcon/>
                    </IconButton>
                    </Tooltip>
                </div>
            <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell width='3%' align="center"/>
                                    <SytledTableCell width='10%' align="center">No Faktur</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">No Retur</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Supplier</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Tanggal</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Total Pembelian</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Total Retur</SytledTableCell>
                                    <SytledTableCell width='10%' align="center">Total Transaksi</SytledTableCell>
                                    <SytledTableCell width='3%' align="center"/>
                                </TableRow>
                            </TableHead>
                                {tableRiwayatPembelian}
                        </Table>
                    </TableContainer>
                        <div className={classes.page}>
                        <Tooltip title="Prev">
                            <IconButton size='small'
                             onClick={handlePrev}
                             >
                                <NavigateBefore/>
                            </IconButton>
                        </Tooltip>
                        <Typography className={classes.textPage}>
                            {pageRiwayatPembelian.currentPage} / {pageRiwayatPembelian.totalPage >= 1 ? pageRiwayatPembelian.totalPage : pageRiwayatPembelian.currentPage}
                            </Typography>
                        <Tooltip title="Next">
                            <IconButton size='small' 
                            onClick={handleNext}
                            >
                                <NavigateNext/>
                            </IconButton>
                        </Tooltip> 
                        </div>
                </Paper>
                </div>
                <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageRiwayatPembelian === "Berhasil menampilkan rincian pembelian" || 
                messageRetur ===  "Berhasil menampilkan rincian retur" ? "success" : "error"} 
                >
                    {messageRiwayatPembelian || messageRetur}</Alert>
                </Snackbar>
                <DeletePembelian open={openDeletePembelian} tempID={tempIdPembelian} HandleClickCloseDeleteDialog={handleCloseDeletePembelian}/>
        </div>
    )
}

export default RiwayatPembelian
