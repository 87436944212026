import { Divider, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DataBarangToRetur from '../DataBarangToRetur';
import ReturList from '../ReturList';

const PencatatanRetur = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            width:'20%',
            padding:'5px',
        },
        contenWapper:{
            display:'flex',
        },
        navigationButton:{
            paddingTop:'0px',
            width:'60%'
        },
        content:{
            display:'flex',
            flexDirection:'column',
            [theme.breakpoints.up('xs')]:{
                flexDirection:'row',
                alignItems:'flex-end',
                marginRight:'10px',
            },
            height:'inherit'
        },buttonNext:{
            height:'30px',
            marginTop:'0px',
            [theme.breakpoints.up('md')]:{
                flexDirection:'row',
                marginTop:'0px',
            },
        },
    }));
    const classes = useStyle();
    const [value, setValue] = useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            hidden={value !== index}
            id={`standart-prevent-tabpanel-${index}`}
            aria-labelledby={`standart-prevent-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>{children}</div>
            )}
          </div>
        );
      }
      function a11yProps(index) {
        return {
          id: `standart-prevent-tab-${index}`,
          'aria-controls': `standart-prevent-tabpanel-${index}`,
        };
      }
      const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <div className={classes.contenWapper}>
                <Typography className={classes.title} variant="h5">Pencatatan Retur</Typography>
                <div className={classes.navigationButton}>
                        <Tabs 
                        value={value}
                        onChange={handleChange} 
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{height:'58px'}}
                        >
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'50px',paddingTop:'0px'}} label="Data Barang" {...a11yProps(0)} icon={<EventOutlinedIcon fontSize="small"/>}/>
                            <Tab className={classes.tabHeight} style={{fontSize:'10px',width:'50px',paddingTop:'0px'}} label="Daftar Retur" {...a11yProps(1)} icon={<AssignmentReturnIcon fontSize="small"/>}/>
                        </Tabs>
                    </div>
            </div>
            <Divider/>
                <TabPanel value={value} index={0}>
                    <DataBarangToRetur/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReturList/>
                </TabPanel>
        </div>
    )
}

export default PencatatanRetur
