import { Button, makeStyles } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import React from 'react'

const PrintDialog = ({HandleClickClosePrintDialog,judul,content,forwardLink,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes = useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="Print-dialog">{judul}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>{content}</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={forwardLink}>Ya</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickClosePrintDialog}>Tidak</Button>
                </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default PrintDialog
