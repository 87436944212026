import { Backdrop, Box, Button, CircularProgress, Divider, Fab, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteFormOrder, getDataPendingOrder} from '../../config/redux/action';
import Cookies from 'js-cookie';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import CancelIcon from '@material-ui/icons/Cancel';
import OrderDeleteForm from '../../component/organism/OrderDeleteForm';

const ListPendingOrder = React.forwardRef ((props,ref)  => {
    const useSytle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px'
        },
        title:{
            paddingTop:'5px',
            textAlign:'center'
        },
        selectWapper:{
            height:'100%',
            display:'flex',
            width:'100%',
            marginTop:'10px',
            justifyContent:'center'
        },
        input:{
            width:'40px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'40px',
                marginLeft:'5px',
                textAlign:'center'
            },
        },
        table:{
            marginTop:'10px',
            // width:'350px',
            maxWidth:'100%'
        },buttonPrintWapper:{
            marginTop:'20px',
            marginBottom:'10px',
            width:'100%',
            display:'flex',
            justifyContent:'center'
        },buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        },
        inputWapper:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
        },
        tableWapper:{
            width:'100%',
            display:'flex',
            justifyContent:'center'
        },
        backdrop:{
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
            display:'flex',
            textAlign:'center'
        }
    }))
    const classes=useSytle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const {data} = useSelector(state=>state.loginReducers);
    const {dataPendingOrder,isLoadingOrder}=useSelector(state=>state.orderReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    const [openDelete,setOpenDelete]=useState(false);
    const [hiddenButton,setHiddenButton]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
    });
    const onClickPrint = () =>{
        setHiddenButton(true);
        handlePrint();
        setTimeout(()=>{
            setHiddenButton(false);
        },[1000])
    }
    const history = useHistory();
    const handleClickBack = () =>{
        history.push('/filterstok')
    }
    const validationSchema = yup.object().shape({
        days:yup.number().min(1,'Jumlah barang tidak boleh kurang dari 1')
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            days:7
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data={
                'days':values.days
            }
            dispatch(getDataPendingOrder(token,data));
        }
    })
    useEffect(()=>{
        if(formik.values.days===7){
            const data={
                'days':formik.values.days
            }
            dispatch(getDataPendingOrder(token,data));
        }
    },[token,dispatch,formik.values.days])
    const HandleOpenDeleteOrder = (idbarang,namabarang,tglorder,qtyorder) =>{
        dispatch(deleteFormOrder(idbarang,namabarang,qtyorder,format(new Date(tglorder),'dd/MM/yyyy')));
        setOpenDelete(true);
      }
    const HandleCloseDeleteOrder = () =>{
    setOpenDelete(false);
    const data={
        'days':formik.values.days
    }
    dispatch(getDataPendingOrder(token,data));
    }
    const tablePrintStok=(
        <TableBody>
            {dataPendingOrder.map((newDataOrder=>(
                <StyledTableRow key={newDataOrder.idbarang}>
                    <SytledTableCell width='40%' align="left">{newDataOrder.namabarang}</SytledTableCell>
                    <SytledTableCell width='50%'>
                    <Typography component="div" variant='caption' align='left' style={{paddingLeft:'10%'}}>
                        <Box>Order {format(new Date(newDataOrder.tglorder),'dd/MM/yyyy')} = {newDataOrder.qtyorder} Unit </Box>
                        <Box>Sudah Datang {newDataOrder.qtyfulfilled} unit </Box>
                        <Box>Sisa Pendingan {parseInt(newDataOrder.qtyorder) - parseInt(newDataOrder.qtyfulfilled)} unit</Box>
                    </Typography>
                    </SytledTableCell>
                    {hiddenButton===true ? null  :
                    <SytledTableCell width='10%' align="center">
                    <Tooltip title="Batalkan Order">
                        <IconButton size='small' color="secondary"
                        onClick={()=>HandleOpenDeleteOrder(newDataOrder.idbarang,newDataOrder.namabarang,newDataOrder.tglorder,newDataOrder.qtyorder)}
                        >
                            <CancelIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                    </SytledTableCell>}
                    
                </StyledTableRow>
            )))}
        </TableBody>
    )
  return (
    <div className={classes.root}>
        <div ref={componentRef}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>List Pending Order</Typography>
             </div>
            <Divider/>
            <div className={classes.inputWapper}>
                <Typography>Lama Pending Order</Typography>
                <TextField className={classes.input}
                    id="days"
                    name="days"
                    inputProps={{style:{textAlign:'center'}}}
                    value={formik.values.days}
                    error={formik.touched.days && Boolean(formik.errors.days)}
                    helperText={formik.touched.days && formik.errors.days}
                    onChange={formik.handleChange}
                    />
                    <Typography>Hari</Typography>
                    {hiddenButton===true ? null  : <Tooltip title="Cari data pending order">
                    <IconButton
                    id='searchButton'
                    name='searchButton'
                    onClick={formik.handleSubmit}
                    >
                        <SearchIcon/>
                    </IconButton>
                </Tooltip>}
            </div>
            <div className={classes.selectWapper}> 
                    <div className={classes.tableWapper}>
                        <div className={classes.table}>
                            {dataPendingOrder.length > 0 ?
                            <Paper>
                                <TableContainer>
                                    <Table stickyHeader aria-label="table list order" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <SytledTableCell width='40%' align="center">Nama Barang</SytledTableCell>
                                                <SytledTableCell width='50%' align="center">Keterangan</SytledTableCell>
                                                {hiddenButton===true ? null  :<SytledTableCell width='10%' align="center">action</SytledTableCell>}
                                            </TableRow>
                                        </TableHead>
                                        {tablePrintStok}
                                    </Table>
                                </TableContainer>                        
                            </Paper>
                        : <Typography variant='h5' align='center'>Tidak terdapat daftar pending order</Typography>}
                        </div> 
                    </div>
            </div>
        </div>
        <div className={classes.buttonPrintWapper}> 
            <Button 
            size="small" 
            variant="contained" 
            color="primary" 
            aria-label="add"
            disabled={dataPendingOrder.length>0 ? false : true}
            onClick={()=>{onClickPrint()}}
            >
                Print
            </Button>
        </div>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={handleClickBack}>
            <ArrowBackIcon/>
        </Fab>
        <Backdrop
            className={classes.backdrop}
            open={isLoadingOrder === true ? true : false}
            >
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Typography>Loading</Typography>
            <CircularProgress color='secondary'/>
            </Box>
        </Backdrop>
        <OrderDeleteForm open={openDelete} aria-labelledby="delete-barang-dialog" HandleClickCloseDeleteDialog={HandleCloseDeleteOrder}/>
    </div>
  )
})

export default ListPendingOrder