import { Button, Checkbox, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, withStyles} from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { fillNoFaktur, getAllDataBarangMasuk, getAllDataReturBarang, PostPembelianWithReturToAPI} from '../../config/redux/action';
import { DateField,SingleDialog } from '../../component/molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { addDays, format } from 'date-fns';
import Cookies from 'js-cookie';
import { PostPembelianToAPI, setMessageSave, setNoFaktur } from '../../config/redux/action/pembelianAction';
import { getIDRetur,emptyNoRetur} from '../../config/redux/action/returSupplierActions';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Autocomplete } from '@material-ui/lab';
import { filledPartnerCombo } from '../../config/redux/action/partnerAction';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const KeranjangBelanja = () => {
    const{cartBarangMasuk,cartReturBarang,noFaktur,messageSavePembelian,loadingSavePembelian} = useSelector(state=>state.pembelianReducers);
    const dispatch = useDispatch();
    const [totalPembelian,setTotalPembelian]=useState(0);
    const [totalRetur,setTotalRetur]=useState(0);
    const [total,setTotal]=useState(0);
    const {data} = useSelector(state => state.loginReducers);
    const {noretur}=useSelector(state=>state.returSupplierReducers);
    const {dataPartner}=useSelector(state=>state.partnerReducers);
    const [check,setCheck] = useState(false);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'18%',
            },
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'100%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'0px',
            width:'100%',
            justifyContent:'center',
        },
        check:{
            marginTop:'18px',
        },
        input:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'150px',
                marginLeft:'5px',
            },
        },
        inputCombo:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'200px',
                marginLeft:'5px',
            },
        },
        selectItem:{
            width:'150px',
            marginRight:'10px',
            marginLeft:'10px',marginTop:'5px',
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const [showDialog,setShowDialog]=useState(false);
    const [tempIdBarang,setTempIdBarang]=useState('');
    const [optionCombo,setOptionCombo]=useState([]);
    const [filterStatus,setFilterStatus] = useState(false);
    const openDeletePembelian=(idbarang)=>{
        setShowDialog(true);
        setTempIdBarang(idbarang);
    }
    const deleteDaftarPembelian = () =>{
        const findBarang = cartBarangMasuk.findIndex(barang=>barang.idbarang===tempIdBarang);
        cartBarangMasuk.splice(findBarang,1);
        dispatch(getAllDataBarangMasuk());
        setTempIdBarang('');
        setShowDialog(false);
    }
    const onCloseDialog=()=>{
        setShowDialog(false);
    }
    const [dialogDeleteRetur,setdialogDeleteRetur]=useState(false);
    const [tempIdRetur,setTempIdRetur]=useState('');
    const openDeleteRetur=(idbarang)=>{
        setdialogDeleteRetur(true);
        setTempIdRetur(idbarang);
    }
    const deleteDaftarRetur = () =>{
        const findRetur = cartReturBarang.findIndex(retur =>retur.idbarang === tempIdRetur);
        cartReturBarang.splice(findRetur,1);
        dispatch(getAllDataReturBarang());
        setTempIdRetur('');
        setdialogDeleteRetur(false);
    }
    const onCloseDialogRetur=()=>{
        setdialogDeleteRetur(false);
    }
    const dataPembelian=(
        <div className={classes.content}>
            <Typography>Data Pembelian</Typography>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cartBarangMasuk.map((barangMasuk=>(
                                <StyledTableRow key={barangMasuk.idbarang}>
                                    <SytledTableCell align="center">{barangMasuk.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{barangMasuk.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{barangMasuk.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{barangMasuk.satuan}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(barangMasuk.modal)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(barangMasuk.subtotal)}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        <Tooltip title="Hapus dari list">
                                            <IconButton size='small' onClick={()=>openDeletePembelian(barangMasuk.idbarang)}>
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Pembelian</Typography>
                        <Typography>{new Intl.NumberFormat().format(totalPembelian)}</Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    const dataRetur=(
        <div className={classes.content}>
            <Typography>Data Retur</Typography>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table retur" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Ref</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cartReturBarang.map((barangRetur=>(
                                <StyledTableRow key={barangRetur.idbarang}>
                                    <SytledTableCell align="center">{barangRetur.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{barangRetur.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{barangRetur.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(barangRetur.modal)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(barangRetur.subtotal)}</SytledTableCell>
                                    <SytledTableCell align="center">{barangRetur.ref}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        <Tooltip title="Hapus dari list">
                                            <IconButton size='small' onClick={()=>openDeleteRetur(barangRetur.idbarang)}>
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Retur</Typography>
                        <Typography>{new Intl.NumberFormat().format(totalRetur)}</Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    const history = useHistory();
    const validationSchema = yup.object().shape({
        nofaktur:yup.string().required('No Faktur tidak boleh kosong').min(8,'No Faktur minimal 8 karakter'),
        tanggal:yup.date().max(new Date(),'Tanggal tidak valid'),
        namaperusahaan:yup.string().required('Supplier tidak boleh kosong'),
        jatuhtempo:yup.date().min(yup.ref('tanggal'),'Jatuh tempo tidak boleh kurang dari tanggal penjualan'),
        statuspembayaran:yup.string().required('Status pembayaran belum di pilih')
    })
    const formik = useFormik({
        initialValues:{
            nofaktur:"",
            tanggal:new Date(),
            lamatempo:'0',
            jatuhtempo:new Date(),
            idpartner:"",
            namaperusahaan:"",
            statuspembayaran:""
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            dispatch(emptyNoRetur());
            if(values.statuspembayaran==="Kredit" && values.lamatempo<1){
                dispatch(setMessageSave("Status pembayaran kredit, wajib memiliki lama jatuh tempo"));
                setFilterStatus(true);
                setOpenSnackbar(true);
            }else{
                if(cartReturBarang.length < 1){
                    const data = {
                        'nofaktur':values.nofaktur,
                        'noretur':"",
                        'idpartner':values.idpartner,
                        'supplier':values.namaperusahaan,
                        'tanggal' :format(values.tanggal,'yyyy-MM-dd HH:mm:ss'),
                        "jatuhtempo":format(values.jatuhtempo,'yyyy-MM-dd HH:mm:ss'),
                        'totalPembelian':totalPembelian,
                        'totalretur':totalRetur,
                        'total':total,
                        'statusPembayaran':values.statuspembayaran,
                        'details':cartBarangMasuk
                    }
                    dispatch(PostPembelianToAPI(data,token,formik,setCheck,setOpenSnackbar,history));
                }else if(cartReturBarang.length>0){
                    const data = {
                        'nofaktur':values.nofaktur,
                        'noretur':noretur,
                        'idpartner':values.idpartner,
                        'supplier':values.namaperusahaan,
                        'tanggal' :format(values.tanggal,'yyyy-MM-dd HH:mm:ss'),
                        "jatuhtempo":format(values.jatuhtempo,'yyyy-MM-dd HH:mm:ss'),
                        'totalPembelian':totalPembelian,
                        'totalretur':totalRetur,
                        'total':total,
                        'statusPembayaran':values.statuspembayaran,
                        'details':cartBarangMasuk
                    }
                    const retur ={
                        'noretur':noretur,
                        'tanggal':format(values.tanggal,'yyyy-MM-dd'),
                        'idpartner':values.idpartner,
                        'supplier':values.namaperusahaan,
                        'total':totalRetur,
                        'status':"Retur Berhasil",
                        'statusPembayaran':values.statuspembayaran,
                        'details':cartReturBarang
                    }
                    dispatch(PostPembelianWithReturToAPI(data,retur,token,formik,setCheck,setOpenSnackbar,history));
                }
                setFilterStatus(false);
            }
        }
    })
    useEffect(()=>{
        if(cartBarangMasuk.length>0){
            let totalPembelian=cartBarangMasuk.reduce((Pembelian,totalP)=>{
                return Pembelian + totalP.subtotal
            },0)
            setTotalPembelian(totalPembelian);
        }else{
            setTotalPembelian(0);
        }
        if(cartReturBarang.length>0){
            let totalRetur=cartReturBarang.reduce((retur,totalR)=>{
                return retur + totalR.subtotal
            },0)
            setTotalRetur(totalRetur);
        }else{
            setTotalRetur(0);
        }
        let total = totalPembelian-totalRetur
        setTotal(total);
    },[cartBarangMasuk,cartReturBarang,totalPembelian,totalRetur])
    
    const [save,setSave]=useState(false);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    useEffect(() => {
        if(dataPartner.length<1){
            dispatch(filledPartnerCombo(token));
        }else{
            setOptionCombo(dataPartner);
        }
        if(noFaktur === ""){
            dispatch(setNoFaktur(format(formik.values.tanggal,'ddMMyy'),token));
        }
        if(cartReturBarang.length>0 && noretur === ""){
            dispatch(getIDRetur(format(formik.values.tanggal,'yyyy-MM-dd'),token));
        }
        if(cartBarangMasuk.length>0){
            setSave(true);
        }
    }, [dispatch,optionCombo,dataPartner,formik.values.tanggal,token,noretur,noFaktur,cartReturBarang,save,messageSavePembelian,openSnackbar,cartBarangMasuk])
    const handleChangeCheck=()=>{
        setCheck(true);
        formik.setFieldValue('nofaktur',noFaktur);
    }
    const handleChangeUnCheck=()=>{
        setCheck(false);
        dispatch(fillNoFaktur());
        formik.setFieldValue('nofaktur',"");
    }
    const handleCloseSnackBar = () => {
        setSave(false);
        setOpenSnackbar(false);
        if(filterStatus===false){
            setTimeout(()=>{
                dispatch(setMessageSave(""));
                history.push('/historypembelian');
            },200)
        }
    };
    const handleChangeTanggal = (value)=>{
        formik.setFieldValue('tanggal',value)
        dispatch(fillNoFaktur());
        setCheck(false);
        formik.setFieldValue('nofaktur',"");
    }
    const handleChangeJatuhTempo = (value)=>{
        formik.setFieldValue('jatuhtempo',value);
    }
    const handleChangePembayaran = (e)=>{
        if(e.target.value==="Tunai"){
            formik.setFieldValue('statuspembayaran',e.target.value);
            formik.setFieldValue('lamatempo',0);
            formik.setFieldValue('jatuhtempo',new Date());
        }else if(e.target.value==="Kredit"){
            formik.setFieldValue('statuspembayaran',e.target.value);
        }
    }
    const onEnterLamaTempo = (value)=>{
        const newJatuhTempo = addDays(formik.values.tanggal,value);
        formik.setFieldValue('lamatempo',value);
        formik.setFieldValue('jatuhtempo',newJatuhTempo);
    }
    return (
        <div className={classes.root}>
            <div className={classes.mainWapper}>
                <DateField 
                    id="tanggal" 
                    name="tanggal" 
                    label="Tanggal" 
                    value={formik.values.tanggal}
                    onChange={(value)=>handleChangeTanggal(value)}
                />
                <Checkbox className={classes.check} 
                checked={check}
                size="small"
                inputProps={{ 'aria-label': 'checkbox with small size' }}
                onClick={check === true ? handleChangeUnCheck : handleChangeCheck}
                />
                <TextField className={classes.input} 
                id="nofaktur" 
                name="nofaktur" 
                label="No Faktur"
                value={formik.values.nofaktur}
                error={formik.touched.nofaktur && Boolean(formik.errors.nofaktur)}
                helperText={formik.touched.nofaktur && formik.errors.nofaktur}
                onChange={formik.handleChange}
                disabled={check}
                />
                
                <Autocomplete
                className={classes.inputCombo}
                id='namaperusahaan'
                name='namaperusahaan'
                options={optionCombo}
                getOptionLabel={(optionCombo)=>optionCombo.namaperusahaan||""}
                getOptionSelected={(optionCombo)=>optionCombo.idpartner||""}
                onChange={(e,newValue)=>{
                    if(newValue!==null){
                        formik.setFieldValue('namaperusahaan',newValue.namaperusahaan)
                        formik.setFieldValue('idpartner',newValue.idpartner)
                    }else{
                        formik.setFieldValue('namaperusahaan',"")
                        formik.setFieldValue('idpartner',"")
                    }
                }}
                renderInput={(params) => <TextField {...params} 
                id='namaperusahaan'
                label="Nama Perusahaan" 
                value={formik.values.namaperusahaan||""}
                error={formik.touched.namaperusahaan && Boolean(formik.errors.namaperusahaan)}
                helperText={formik.touched.namaperusahaan && formik.errors.namaperusahaan}
                onChange={formik.handleChange}
                 />}
                />
                <FormControl required className={classes.selectItem}>
                    <InputLabel id='statuspembayaran'>Pembayaran</InputLabel>
                    <Select
                    labelId='statuspembayaran'
                    id='statuspembayaran'
                    name='statuspembayaran'
                    value={formik.values.statuspembayaran} 
                    error={formik.touched.statuspembayaran && Boolean(formik.errors.statuspembayaran)}
                    onChange={(e)=>handleChangePembayaran(e)}
                    >
                        <MenuItem value="Tunai">Tunai</MenuItem>
                        <MenuItem value="Kredit">Kredit</MenuItem>
                    </Select>
                    <Typography color='error' variant='caption'>{formik.touched.statuspembayaran && formik.errors.statuspembayaran}</Typography>
                </FormControl>
                <TextField className={classes.input}
                    id="lamatempo "
                    name="lamatempo"
                    label="Lama Tempo"
                    value={formik.values.lamatempo}
                    disabled={formik.values.statuspembayaran === 'Kredit' ? false : true}
                    error={formik.touched.lamatempo && Boolean(formik.errors.lamatempo)}
                    helperText={formik.touched.lamatempo && formik.errors.lamatempo}
                    onKeyDown={(e)=>{
                        if(e.key === 'Enter'){
                            onEnterLamaTempo(e.target.value);
                        }
                    }}
                    onChange={formik.handleChange}
                    /> 
                <DateField 
                    id="jatuhtempo" 
                    name="jatuhtempo" 
                    label="Jatuh Tempo" 
                    disabled={true}
                    value={formik.values.jatuhtempo}
                    errors={formik.touched.jatuhtempo && Boolean(formik.errors.jatuhtempo)}
                    helperText={formik.touched.jatuhtempo && formik.errors.jatuhtempo}
                    onChange={(e)=>handleChangeJatuhTempo(e)}
                />
            </div>
            {dataPembelian}
            <div className={classes.divinder}/>
            {dataRetur}
            <div className={classes.divinder}/>
            <div className={classes.content}>
            <div className={classes.GrandTotal}>
                <Typography>Grand Total</Typography>
                <Typography>{new Intl.NumberFormat().format(total)}</Typography>
            </div>
            <Button 
            aria-label="save pembelian" 
            onClick={formik.handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={cartBarangMasuk.length < 1 ? true : false || loadingSavePembelian}>Simpan Pembelian</Button>
            </div>
            <div className={classes.divinder}/>
            <SingleDialog openDialog={showDialog} handleClick={deleteDaftarPembelian} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
            <SingleDialog openDialog={dialogDeleteRetur} handleClick={deleteDaftarRetur} okCommand="Hapus" handleCloseDialog={onCloseDialogRetur} message="Anda yakin akan menghapus data ini?"/>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={()=>handleCloseSnackBar}>
                {messageSavePembelian === 'Data pembelian berhasil disimpan' ?
                    <Alert onClose={handleCloseSnackBar} severity={"success"}>{messageSavePembelian}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity={"error"}>{messageSavePembelian}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default KeranjangBelanja
