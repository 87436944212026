import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Button, Divider} from '@material-ui/core';
import { InputFiled } from '../../molecules';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import {makeStyles,Dialog,DialogTitle,DialogContent,Snackbar} from '@material-ui/core'
import { addNewPartner, editPartner, setEmptyPartnerMessage, setFillFormPartner } from '../../../config/redux/action/partnerAction';
import NumberFormat from 'react-number-format';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const FormPartner = ({HandleClickCloseDialog,statusForm,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingPartner,newIDPartner,FormPartner,messagePartner} = useSelector(state=>state.partnerReducers);
    const {idpartner,namaperusahaan,alamat,notlp,namasales,tipepartner} = FormPartner;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setFillFormPartner());
            dispatch(setEmptyPartnerMessage());
        },500)
      };
    const validationSchema = yup.object().shape({
        namaperusahaan:yup.string().required("Nama perusahaan tidak boleh kosong"),
        alamat:yup.string().required("Alamat partner tidak boleh kosong"),
        tipepartner:yup.string().required("Tipe partner tidak boleh kosong"),
        notlp:yup.number().required("Nomor telephone tidak boleh kosong"),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idpartner:newIDPartner || idpartner || '',
            namaperusahaan:namaperusahaan || '',
            alamat:alamat||'',
            notlp:notlp||'',
            namasales:namasales||'',
            tipepartner:tipepartner||''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            if(statusForm === 'Data Partner Baru'){
                const data = new URLSearchParams(); 
                data.append('idpartner',values.idpartner);
                data.append('namaperusahaan',values.namaperusahaan);
                data.append('alamat',values.alamat);
                data.append('notlp',values.notlp);
                data.append('namasales',values.namasales);
                data.append('tipepartner',values.tipepartner);
                dispatch(addNewPartner(data,token,setOpenSnackbar));
                formik.resetForm();
                HandleClickCloseDialog();
            }else if(statusForm === 'Ubah Data Partner'){
                const data = new URLSearchParams();
                data.append('namaperusahaan',values.namaperusahaan);
                data.append('alamat',values.alamat);
                data.append('notlp',values.notlp);
                data.append('namasales',values.namasales);
                data.append('tipepartner',values.tipepartner);
                dispatch(editPartner(data,idpartner,token,setOpenSnackbar));
                formik.resetForm();
                HandleClickCloseDialog();
            }
        }
    })
    const classes = useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-partner-dialog">{statusForm}</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled
                id="idpartner" 
                name="idpartner" 
                label="ID Partner" 
                disabled={true} 
                value={formik.values.idpartner||""}
                />
                <InputFiled 
                id="namaperusahaan" 
                name="namaperusahaan" 
                label="Nama Perusahaan"
                value={formik.values.namaperusahaan}
                error={formik.touched.namaperusahaan && Boolean(formik.errors.namaperusahaan)}
                helperText={formik.touched.namaperusahaan && formik.errors.namaperusahaan}
                onChange={(e)=>formik.setFieldValue('namaperusahaan',e.target.value.toUpperCase())}
                />
                <InputFiled 
                multiline
                id="alamat" 
                name="alamat" 
                label="Alamat"
                value={formik.values.alamat}
                error={formik.touched.alamat && Boolean(formik.errors.alamat)}
                helperText={formik.touched.alamat && formik.errors.alamat}
                onChange={(e)=>formik.setFieldValue('alamat',e.target.value)}
                />
                <InputFiled 
                id="tipepartner" 
                name="tipepartner" 
                label="Tipe Partner"
                value={formik.values.tipepartner}
                error={formik.touched.tipepartner && Boolean(formik.errors.tipepartner)}
                helperText={formik.touched.tipepartner && formik.errors.tipepartner}
                onChange={(e)=>formik.setFieldValue('tipepartner',e.target.value)}
                />
                <NumberFormat customInput={InputFiled} 
                format="#### ###########"
                style={{width:'100%'}}
                id='notlp'
                name='notlp'
                label="Nomor Telephone"
                variant="outlined"
                value={formik.values.notlp}
                error={formik.touched.notlp && Boolean(formik.errors.notlp)}
                helperText={formik.touched.notlp && formik.errors.notlp}
                onChange={(e)=>formik.setFieldValue('notlp',e.target.value)}
                />
                <InputFiled 
                id="namasales" 
                name="namasales" 
                label="Nama Sales"
                value={formik.values.namasales}
                error={formik.touched.namasales && Boolean(formik.errors.namasales)}
                helperText={formik.touched.namasales && formik.errors.namasales}
                onChange={(e)=>formik.setFieldValue('namasales',e.target.value)}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingPartner}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={messagePartner === 'Data partner baru berhasil disimpan' || 'Perubahan data distributor berhasil' ? "success" :"error"}>
                {messagePartner}
            </Alert>
            </Snackbar>
        </div>
    )
}

export default FormPartner
