const initialState = {
    leftMenuOpen : false,
    statusServer:false,
    openHutang:false
}

const leftMenuReducers = (state=initialState, action )=>{
    switch (action.type){
        case 'SET LEFT MENU OPEN':{
            return{
                ...state,
                leftMenuOpen : action.payload
            }
        }
        case 'SET NETWORK':{
            return{
                ...state,
                statusServer:action.payload
            }
        }
        case 'SET OPEN HUTANG':{
            return{
                ...state,
                openHutang:action.payload
            }
        }
        default: return state
    }
}
export default leftMenuReducers;