import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { InputFiled } from '../../../component/molecules';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
import { Autocomplete } from '@material-ui/lab';
import { setDataBarang } from '../../../config/redux/action';
import { createNewPODetails, setEmptyPoOrderMessage } from '../../../config/redux/action/PoOrderAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DialogAddPoOrderDetails = ({handleCloseDialog,tempId,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        }
    }));
    const classes = useStyle();
    const {isLoadingPoOrder,messagePoOrder,dataPoOrderDetails} = useSelector(state=>state.PoOrderReducers);
    const {dataBarang}=useSelector(state=>state.barangReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    const [optionCombo,setOptionCombo]=useState([]);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const validationSchema = yup.object().shape({
        namabarang:yup.string().required("Nama Barang tidak boleh kosong"),
        jumlah:yup.number().min(1,"jumlah barang tidak boleh kurang dari 1")
    })
    const formik=useFormik({
        initialValues:{
            idpoorder:tempId||'',
            idbarang:'',
            namabarang:'',
            jumlah:'',
            keterangan:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = dataPoOrderDetails.filter(barang =>barang.idbarang === values.idbarang);
            if(existBarang.length>0){
                dispatch(setEmptyPoOrderMessage("Data barang sudah terdaftar"));
                setOpenSnackbar(true);
            }else{
                const data={
                    'idpoorder':tempId,
                    'idbarang':values.idbarang,
                    'jumlah':values.jumlah,
                    'keterangan':values.keterangan
                }
                dispatch(createNewPODetails(data,token,setOpenSnackbar,formik));
            }
        }
    })
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        handleCloseDialog();
      };
    const handleChangeNamaBarang = (e) =>{
        const tempNamaBarang = e.target.value;
        if(tempNamaBarang.length>=3){
            dispatch(setDataBarang(e.target.value,1,token));
            if(dataBarang!==undefined){
                setOptionCombo(dataBarang);
            }else{
                setOptionCombo([]);
            }
        }
    }
    const handleCloseDialogDet = () =>{
        formik.handleReset();
        handleCloseDialog();
    }
  return (
    <div>
        <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-partner-dialog">Tambah PO Order</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled
                id="idpoorder" 
                name="idpoorder" 
                label="ID PO Order" 
                disabled={true}
                value={tempId}
                />
                <Autocomplete
                className={classes.textFiled}
                id='namabarang'
                name='namabarang'
                options={optionCombo}
                getOptionLabel={(optionCombo)=>optionCombo.namabarang||""}
                getOptionSelected={(optionCombo)=>optionCombo.idbarang||""}
                onChange={(e,newValue)=>{
                    if(newValue!==null){
                        formik.setFieldValue('namabarang',newValue.namabarang)
                        formik.setFieldValue('idbarang',newValue.idbarang)
                    }else{
                        formik.setFieldValue('namabarang',"")
                        formik.setFieldValue('idbarang',"")
                    }
                }}
                renderInput={(params) => <TextField {...params} 
                id='namabarang'
                label="Nama Barang" 
                variant='outlined'
                value={formik.values.namabarang||''}
                error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                helperText={formik.touched.namabarang && formik.errors.namabarang}
                onChange={(e)=>handleChangeNamaBarang(e)}
                 />}
                />
                <InputFiled
                id="jumlah" 
                name="jumlah" 
                label="Jumlah" 
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <InputFiled
                multiline
                id="keterangan" 
                name="keterangan" 
                label="Keterangan" 
                value={formik.values.keterangan}
                error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
                helperText={formik.touched.keterangan && formik.errors.keterangan}
                onChange={formik.handleChange}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingPoOrder}>Simpan</Button>
                <Button onClick={handleCloseDialogDet} variant="contained" color="secondary">Keluar</Button>
                </div>
            </DialogContent>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={messagePoOrder === 'Berhasil menambahkan barang PO Order' ? "success" :"error"}>
                {messagePoOrder}
            </Alert>
            </Snackbar>
        </Dialog>
    </div>
  )
}

export default DialogAddPoOrderDetails