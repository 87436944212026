import React, { useEffect, useState } from 'react'
import { InputFiled } from '../../molecules'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { fillMessage, setExistFormTransfer, setFormTransfer, setTransferMessage } from '../../../config/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const FormQtyTransfer = ({HandleClickCloseDialog,idbarang,namabarang,tempJumlah,...rest}) => {
  const useStyle = makeStyles((theme)=>({
    titleDialog:{
        maxWidth:'400px',
        [theme.breakpoints.down('sm')]:{
            maxWidth:'250px',
        },
        textAlign:'center',
    },
    button:{
        display:'flex',
        justifyContent:'space-between',
        paddingBottom:"5px",
    },
    textFiled:{
        width:'100%',
        paddingBottom:"7px",
    },
    avaliableEdit:{
        display:'flex',
    }
  })); 
  const classes = useStyle();
  const [transferSnackbar,setTransferSnackbar] = useState(false);
  const {FormTransfer,tempTransferBarang,transferMessage} = useSelector(state => state.transferBarangReducers);
  const dispatch = useDispatch();
  const [id,setId]=useState(0);
  const handleCloseSnackBar = () => {
    setTransferSnackbar(false);
    setTimeout(()=>{
        dispatch(fillMessage());
    },500)
  };
  useEffect(()=>{
    if(FormTransfer.length>0){
        let tempID = FormTransfer[FormTransfer.length-1].id;
        let temp=tempID+1
        setId(temp);
    }
},[FormTransfer])
  const validationSchema = yup.object().shape({
    jumlah:yup.number().required('Jumlah tidak boleh kosong').positive('Jumlah tidak boleh bernilai negative').min(1,'Jumlah tidak boleh kurang dari 1'),
    variant:yup.string()
})
  const formik = useFormik({
    enableReinitialize:true,
    initialValues:{
      jumlah: tempJumlah || 0,
      varian:''
    },
    validationSchema:validationSchema,
    onSubmit:(values)=>{
        const existBarang = FormTransfer.filter(transfer => transfer.idbarang === idbarang && transfer.variant === values.varian);
        if(existBarang.length > 0){
          dispatch(setTransferMessage("Barang dengan varian ini sudah terdaftar"));
          setTransferSnackbar(true);
        }else if(existBarang.length < 1){
          const existBarangInTemp = tempTransferBarang.filter((temp) =>temp.idbarang === idbarang);
          if(existBarangInTemp.length>0){
              let tempJumlah;
              let index;
              tempTransferBarang.find((indexs,i) => {
                  if(indexs.idbarang===idbarang){
                      tempJumlah = parseInt(indexs.jumlah) + parseInt(values.jumlah);
                      index=i;
                      return index;
                  }
                  return tempJumlah;
              })
              dispatch(setExistFormTransfer(id,idbarang,namabarang,values.jumlah,values.varian,index,tempJumlah))
              HandleClickCloseDialog();
          }
          else if(existBarangInTemp.length<1){
              dispatch(setFormTransfer(id,idbarang,namabarang,values.jumlah,values.varian))
              HandleClickCloseDialog();
          }
          setTransferSnackbar(true);
        }     
      }
  })
  return (
    <div>
      <Dialog open={false} {...rest} maxWidth="md">
        <DialogTitle className={classes.titleDialog} id="add-form-barang">Masukan Jumlah Barang</DialogTitle>
          <Divider/>
          <DialogContent>
            <InputFiled 
                id="jumlah" 
                name="jumlah" 
                label="Jumlah"
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <InputFiled 
                id="varian" 
                name="varian" 
                label="Varian"
                value={formik.values.varian}
                error={formik.touched.varian && Boolean(formik.errors.varian)}
                helperText={formik.touched.varian && formik.errors.varian}
                onChange={(e)=>formik.setFieldValue('varian',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
          </DialogContent>
      </Dialog>
      <Snackbar open={transferSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <Alert 
            onClose={handleCloseSnackBar} 
            severity={transferMessage === 'Berhasil menambahkan data ke daftar' ? "success" : "error"}>
                {transferMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default FormQtyTransfer