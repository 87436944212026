import { makeStyles, Divider,Typography,Button, Fab} from '@material-ui/core';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import React, { useEffect, useState , useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {useParams} from 'react-router-dom';
import { refreshPrintPembelian } from '../../../config/redux/action';
import { useReactToPrint } from 'react-to-print';
import { DebtPayment } from '..';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PrintFakturPembelian = React.forwardRef ((props,ref) => {
    const useStyle = makeStyles((theme)=>({
        root:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        mainFaktur:{
            width:'21.59cm',
            marginTop:'30px'
        },
        content:{
            display:'flex',
            width:'98%',
            flexDirection:'column',
            alignItems:'center',
            borderBottom:'1px dotted black',
        },
        tableBody:{
            height:'100%',
            textAlign:'center',
        },
        table:{
            marginTop:'3px',
            width:'100%',
        },
        tableRetur:{
            marginTop:'10px',
            width:'100%',
        },
        tableContainer:{
            height:'100%',
            width:'100%',
            textAlign:'center',
            borderBottom:'1px dotted black'
        },
        tableHead:{
            borderTop:'1px dotted black',
            borderBottom:'1px dotted black'
        },
        mainWapper:{
            width:'98%',
            display:'flex',
            marginBottom:'0px',
            justifyContent:'space-between',
            marginTop:'5px',
        },
        subHeaderLeft:{
            width:'300px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            paddingLeft:'20px'
        },
        subHeaderRight:{
            width:'250px',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end',
            paddingRight:'20px'
        },
        subTitle:{
            width:'100%',
            display:'flex',
        },
        contentBottom:{
            width:'98%',
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            borderBottom:'1px dotted black',
        },
        contentTransaksiBottom:{
            width:'97%',
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
        },
        titleContentBottom:{
            width:'80px',
            textAlign:'right',
        },
        buttonLast:{
            display:'flex',
            flexDirection:'column',
            marginTop:'20px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const {data} = useSelector(state => state.loginReducers);
    const {pembelianToPrint} = useSelector(state => state.pembelianReducers);
    const {payment,details} = pembelianToPrint;
    const {returSupplierToPrint}=useSelector(state=>state.returSupplierReducers);
    const {returDetails} = returSupplierToPrint;
    const [token,setToken] = useState(data.token);
    const [totalTransaksi,setTotalTransaksi] = useState(0);
    const [totalPembelian,setTotalPembelian] = useState(0);
    const [totalPembayaran,setTotalPembayaran]=useState(0);
    const [grandTotal,setGrandTotal]=useState(0);
    const [totalRetur,setTotalRetur]=useState(0);
    const [openHutang,setOpenHutang]=useState(false);
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const {FFORM,idpembelian}=useParams();
   
    useEffect(() => {
        if(pembelianToPrint===""){
            dispatch(refreshPrintPembelian(idpembelian,token,FFORM));
        }else if(pembelianToPrint!==""){
            if(details.length>0){
                let tempTotalPembelian=details.reduce((Total,TotalP)=>{
                    return Total + TotalP.subtotal
                },0)
                setTotalPembelian(tempTotalPembelian);
            }
            if(returSupplierToPrint!==""){
                if(returDetails.length>0){
                    let tempTotalRetur=returDetails.reduce((Total,TotalR)=>{
                        return Total + (TotalR.jumlah*TotalR.harga)
                    },0)
                    setTotalRetur(tempTotalRetur);
                }
                let tempTotalPembayaran=payment.reduce((Total,TotalP)=>{
                    return Total + (TotalP.pembayaran)
                },0)
                setTotalPembayaran(tempTotalPembayaran);
                setTotalTransaksi(totalPembelian-totalRetur);
                setGrandTotal(totalPembelian-totalRetur-tempTotalPembayaran);
            }else{
                let tempTotalPembayaran=payment.reduce((Total,TotalP)=>{
                    return Total + (TotalP.pembayaran)
                },0)
                setTotalPembayaran(tempTotalPembayaran);
                setTotalTransaksi(totalPembelian);
                setGrandTotal(totalPembelian-tempTotalPembayaran);
            }
        }
    }, [pembelianToPrint,idpembelian,grandTotal,totalPembayaran,payment,returSupplierToPrint,dispatch,token,totalPembelian,totalRetur,totalTransaksi,details,returDetails,FFORM])
      const classes = useStyle();
      const componentRef = useRef();
      const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
      });
      
      const handleClickBayar = () =>{
        setOpenHutang(true);
      }
      const handleClickPrint = ()=>{
        handlePrint();
      }
      const handleClosePembayaran = () =>{
        setOpenHutang(false);
      }
      const handleCloseBayar = () =>{
        setOpenHutang(false);
        dispatch(refreshPrintPembelian(idpembelian,token,FFORM));
        setTimeout(()=>{
            handlePrint();
            history.goBack();
        },1000)
      }
      const listPembelian = (
        <div className={classes.table}>
        <table aria-label="table barang" size="small" className={classes.tableContainer}>
            <thead>
                <tr>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>ID Barang</Typography></th>
                <th className={classes.tableHead} style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
                <th className={classes.tableHead} style={{width:'5%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Harga</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Subtotal</Typography></th>
                </tr>
            </thead>
            {pembelianToPrint!==""?
            <tbody>
            {details.map(((detailsToPrints,index)=>(
                <tr className={classes.tableBody} key={detailsToPrints.idpembeliandetails}>
                    <td align="center"><Typography variant='body1'><strong>{detailsToPrints.idbarang  || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsToPrints.namabarang || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsToPrints.satuan || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsToPrints.jumlah || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><strong>{new Intl.NumberFormat().format(detailsToPrints.harga) || '0'}</strong></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><strong>{new Intl.NumberFormat().format(detailsToPrints.subtotal) || '0'}</strong></Typography></td>
                </tr>
            )))}
            </tbody>:<tbody></tbody>}
        </table>
        </div>
      )
      const listRetur = (
        <div className={classes.tableRetur}>
        <Typography variant='h6' align='center'><strong>DAFTAR RETUR</strong></Typography>
        <table aria-label="table barang" size="small" className={classes.tableContainer}>
            <thead>
                <tr>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>ID Barang</Typography></th>
                <th className={classes.tableHead} style={{width:'30%',textAlign:"center"}}><Typography variant='body2'>Nama Barang</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Satuan</Typography></th>
                <th className={classes.tableHead} style={{width:'5%',textAlign:"center"}}><Typography variant='body2'>Jumlah</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Harga</Typography></th>
                <th className={classes.tableHead} style={{width:'10%',textAlign:"center"}}><Typography variant='body2'>Subtotal</Typography></th>
                </tr>
            </thead>
            {returSupplierToPrint!=="" ?
            <tbody>
                {returDetails.map(((detailsSupplierToPrints,index)=>(
                <tr className={classes.tableBody} key={detailsSupplierToPrints.idretursupplier}>
                    <td align="center"><Typography variant='body1'><strong>{detailsSupplierToPrints.idbarang  || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsSupplierToPrints.namabarang || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsSupplierToPrints.satuan || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1'><strong>{detailsSupplierToPrints.jumlah || ''}</strong></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><strong>{new Intl.NumberFormat().format(detailsSupplierToPrints.harga) || '0'}</strong></Typography></td>
                    <td align="center"><Typography variant='body1' fontFamily='Raleway' fontWeight='bold'><strong>{new Intl.NumberFormat().format(detailsSupplierToPrints.jumlah * detailsSupplierToPrints.harga) || '0'}</strong></Typography></td>
                </tr>
                )))}
            </tbody>: <tbody></tbody>}
        </table>
        </div>
      )
      const bottomFiled = (
        <div className={classes.contentBottom}>
            <div>
                <Typography variant="body1">Total Faktur</Typography></div>
            <div>
                <Typography variant="body1">:</Typography></div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(totalPembelian) || '0'}</strong></Typography></div>
        </div>
      )
      const bottomFiledRetur = (
        <div className={classes.contentBottom}>
            <div>
                <Typography variant="body1">Total Retur</Typography></div>
            <div>
                <Typography variant="body1">:</Typography></div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(totalRetur) || '0'}</strong></Typography></div>
        </div>
      )
      const bottomFiledTransaksi = (
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Total Transaksi</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(totalTransaksi) || '0'}</strong></Typography>
            </div>
        </div>
      )
      const bottomFiledPembayaran = (
        payment!==undefined?
        payment.map(((payments,idx)=>(
            <div className={classes.contentTransaksiBottom}>
                <div>
                    <Typography variant="body1">Pembayaran ke {idx+1}</Typography>
                </div>
                <div>
                    <Typography variant="body1">:</Typography>
                </div>
                <div className={classes.titleContentBottom}>
                    <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(payments.pembayaran) || '0'}</strong></Typography>
                </div>
            </div>
        ))):
        <div className={classes.contentTransaksiBottom}>
                <div>
                    <Typography variant="body1">Pembayaran ke 1</Typography>
                </div>
                <div>
                    <Typography variant="body1">:</Typography>
                </div>
                <div className={classes.titleContentBottom}>
                    <Typography variant="body1" fontFamily='Raleway'><strong>0</strong></Typography>
                </div>
            </div>
      )
      const bottomFiledGrandTotal = (
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Grand Total</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(grandTotal) || '0'}</strong></Typography>
            </div>
        </div>
      )
      const headerTransaksi = (
        <div className={classes.mainWapper}>
            <div className={classes.subHeaderLeft}>
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">No Faktur</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{pembelianToPrint.idpembelian || ''}</Typography></div>
                </div>
                <div className={classes.subTitle}>
                <div><Typography variant="body1">Supplier</Typography> </div>
                <div><Typography variant="body1">:</Typography></div>
                <div><Typography variant="body1"><strong>{pembelianToPrint.supplier  || ''}</strong></Typography></div>
                </div>
            </div>
            <div className={classes.subHeaderRight}>
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">Tanggal</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{pembelianToPrint.tanggal !== undefined ? format(new Date(pembelianToPrint.tanggal),'dd MMM yyyy') : ''}</Typography></div>
                </div>
                <Typography variant="caption">
                {pembelianToPrint.jatuhtempo !== undefined ?
                <div className={classes.subTitle}>
                    <div>Jatuh Tempo</div>
                    <div>:</div>
                    <div>{format(new Date(pembelianToPrint.jatuhtempo),'dd MMM yyyy')}</div>
                </div>:
                null}
                </Typography>
            </div>
        </div>
      )
    return (
        <div className={classes.root}>
            <div className={classes.mainFaktur} ref={componentRef}>
                <Typography variant='h6' align='center'>{FFORM==="PH" ? <strong>FAKTUR PEMBELIAN</strong>:<strong>NOTA TRANSKASI</strong>} </Typography>
                <Divider variant='middle'/>
                {headerTransaksi}
                <div className={classes.content}>
                    {pembelianToPrint!==undefined?listPembelian:null}
                    {bottomFiled}
                    {returSupplierToPrint!==""?listRetur:null}
                    {returSupplierToPrint!==""?bottomFiledRetur:null}
                </div>
                {bottomFiledTransaksi}
                {bottomFiledPembayaran}
                {bottomFiledGrandTotal}
            </div>
            <div className={classes.buttonLast}>
                {FFORM==='PHP' ?
                <Button variant='contained' color='primary' 
                onClick={handleClickPrint}
                >Print</Button> : 
                <Button variant='contained' color='primary' 
                onClick={handleClickBayar}
                >Bayar</Button>}
            </div>
            <DebtPayment open={openHutang} handleClickBayar={handleCloseBayar} HandleClickCloseDialog={handleClosePembayaran}/>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                <ArrowBackIcon/>
            </Fab>
        </div>
    )
})

export default PrintFakturPembelian
