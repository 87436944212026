import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchStok = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH TEXT',
        payload :search
    })
}
export const setDataStokBarang = (search,salesPeriod,page,token) => (dispatch) =>{
    const data = {
        'chooseday' : salesPeriod
    }
    dispatch({
        type:'FETCH DATA STOK BARANG',
        payload:true,
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/stok/?search=${search}&page=${page}&perPage=50`,data,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA STOK BARANG', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed'
        })
        dispatch({
            type:'UPDATE PAGE STOK BARANG',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH STOK BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH STOK BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH STOK BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setDataStokFilterSemua = (idpenyimpanan,periode,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA STOK BARANG',
        payload:true,
    })
    const data={
        'idpenyimpanan':idpenyimpanan,
        'chooseday':periode
    }
    axios.post(`${apiUrl.url}v1/suryaharapan/stok/filterpenyimpanan`,data,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA STOK FILTER', 
           payload:ResponseAPI.data,
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setDataStokFilterMin = (idpenyimpanan,periode,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA STOK BARANG',
        payload:true,
    })
    const data={
        'idpenyimpanan':idpenyimpanan,
        'chooseday':periode
    }
    axios.post(`${apiUrl.url}v1/suryaharapan/barang/minStok`,data,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA STOK FILTER', 
           payload:ResponseAPI.data,
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH STOK FILTER',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const emptyStokFilter = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA STOK FILTER'
    })
}

export const setSelectIDPenyimpanan = (idpenyimpanan,keterangan) =>(dispatch)=>{
    dispatch({
        type:'SET ID PENYIMPANAN',
        PenyimpananSelect:{
            idPenyimpanan:idpenyimpanan,
            keterangan:keterangan
        }
    })
}
export const postKoreksiBarang = (data,token,setOpensnackbar,handleCloseKoreksi,formik) =>(dispatch)=>{
    dispatch({
        type:'FETCH KOREKSI STOK'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/stok/koreksi`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then((res)=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED KOREKSI STOK',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        setTimeout(()=>{
            formik.handleReset();
            handleCloseKoreksi()
        },2000)
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}
export const dataBestSelling = (data,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA STOK BARANG',
        payload:true,
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/stok/bestselling`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET DATA SALES',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED KOREKSI STOK',
                    payload:'Disconnect from server'
            })
        }
        setOpensnackbar(true);
    })
}
export const emptyStokMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE STOK BARANG',
        payload:message
    })
}
export const emptyDataSales = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA SALES'
    })
}