import { Divider, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { format } from 'date-fns';
import { DeleteIcon } from '../../../component/assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDataRincianTransaksi } from '../../../config/redux/action/hutangAction';
import DeleteTransaksi from '../../../component/organism/DeleteTransaksi';


const RincianPembayaran = () => {
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        table:{
            marginTop:'20px',
            width:'100%',
            maxHeight:'100%',
            maxWidth:'100%',
            display:"flex",
            justifyContent:'center'
        },
        tableContainer:{
            // border:'1px solid black',
            width:'1200px',
            maxWidth:'100%',
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        titleWapper:{
            width:'100%',
            marginTop:'5px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        }
    }));
    const classes = useStyles();
    const {data}=useSelector(state=>state.loginReducers);
    const {dataRincianTransaksi} = useSelector(state=>state.hutangReducers);
    const dispatch = useDispatch();
    const {namaperusahaan,idtransaksi} = useParams();
    const [token,setToken] = useState(data.token);
    const [openDelete,setOpenDelete]=useState(false);
    const [tempId,setTempId]=useState('');
    const [tempKet,setTempKet]=useState('');
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleOpenDeleteDialog = (idhutang,keterangan)=>{
        setOpenDelete(true);
        setTempId(idhutang);
        setTempKet(keterangan);
    }
    useEffect(()=>{
        if(dataRincianTransaksi.length<1){
            dispatch(getDataRincianTransaksi(idtransaksi,token));
        }
    },[dispatch,idtransaksi,dataRincianTransaksi,token])
    const history = useHistory();
    const handleCloseDeleteDialog = () =>{
        setOpenDelete(false);
        dispatch(getDataRincianTransaksi(idtransaksi,token));
    }
    const dataTransaksi = (
        <Paper>
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="table barang" size="small">
                <TableHead style={{fontSize:"10px"}}>
                    <TableRow>
                        <SytledTableCell width='12%' align="center">ID Hutang</SytledTableCell>
                        <SytledTableCell width='12%' align="center">Tanggal</SytledTableCell>
                        <SytledTableCell width='25%' align="center">Keterangan</SytledTableCell>
                        <SytledTableCell width='15%' align="center">Total Hutang</SytledTableCell>
                        <SytledTableCell width='15%' align="center">Pembayaran</SytledTableCell>
                        <SytledTableCell width='15%' align="center">Saldo</SytledTableCell>
                        <SytledTableCell width='12%' align="center"></SytledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRincianTransaksi.map(((dataRincianTransaksis,i)=>(
                        <StyledTableRow key={dataRincianTransaksis.idhutang}>
                            <SytledTableCell align="center">{dataRincianTransaksis.idhutang}</SytledTableCell>
                            <SytledTableCell align="center">{format(new Date(dataRincianTransaksis.tanggal),'dd/MM/yyyy')}</SytledTableCell>
                            <SytledTableCell align="center">{dataRincianTransaksis.keterangan}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianTransaksis.totalhutang)}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianTransaksis.pembayaran)}</SytledTableCell>
                            <SytledTableCell align="center">{new Intl.NumberFormat().format(dataRincianTransaksis.saldo)}</SytledTableCell>
                            <SytledTableCell align="center">
                                <Tooltip title="Hapus Transaksi">
                                    <IconButton 
                                    size='small' 
                                    disabled={i===0 && dataRincianTransaksis.keterangan.substring(0,9)==="Pembelian" ? true:false}
                                    onClick={()=>handleOpenDeleteDialog(dataRincianTransaksis.idhutang,dataRincianTransaksis.keterangan)}
                                    >
                                        <img src={DeleteIcon} alt='payment'/>
                                    </IconButton>
                                </Tooltip>
                            </SytledTableCell>
                        </StyledTableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    )
  return (
    <div className={classes.root}>
        <div className={classes.titleWapper}>
            <Typography className={classes.title} variant="h5">Data Rincian Transaksi {namaperusahaan} ({idtransaksi})</Typography>
            <Divider/>
            <div className={classes.table}>
                {dataTransaksi}
            </div>
        </div>
        <DeleteTransaksi open={openDelete} tempID={tempId} tempKeterangan={tempKet} HandleClickCloseDeleteDialog={handleCloseDeleteDialog}/>
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                <ArrowBackIcon/>
            </Fab>
    </div>
  )
}

export default RincianPembayaran