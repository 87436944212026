import React from 'react'
import { Avatar, Button, Container, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { bgLogin } from '../../component/assets';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { postToAPI } from '../../config/redux/action';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {data,isLoading} = useSelector(state => state.loginReducers);
  const validationSchema = yup.object().shape({
    username : yup
    .string('Enter your username')
    .min(6,'Username cannot less 6 character')
    .required('Username is required'),
    password : yup
    .string('Enter your password')
    .min(6,'Password minimum 6 characters')
    // .matches( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,'Password minimum 6 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number')
    .required('Password is required'),
  });
  const formik = useFormik({
    initialValues:{
      username:'',
      password:''
    }
    ,
    validationSchema:validationSchema,
    onSubmit: (values) =>{
      const width=window.outerWidth;
        dispatch(postToAPI(values.username,values.password,width,history));
      }
  })
  
    const useStyle = makeStyles((theme)=>({
        root:{
          height:'100vh',
          width:'100%',
          marginTop:'0',
          backgroundImage: `url(${bgLogin})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        containterWapper:{
          width:'100%',
          height:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        },
        paper: {
          width:'30%',
          height:'70%',
          marginTop:'1%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          opacity:'95%',
          [theme.breakpoints.down('xs')]:{
            width:'80%',
            height:'85%',
            marginTop:'1%',
            // marginLeft:'17%',
          },
          // [theme.breakpoints.up('lg')]:{
          //   width:'30%',
          //   height:'75%',
          //   marginTop:'5%',
          //   marginLeft:'30%',
          // }
        },
        avatar: {
          marginTop: '40px',
          backgroundColor: theme.palette.primary.light,
        },
        form: {
          width: '90%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        },
      }));
      const classes = useStyle();
    return (
      <div className={classes.root}>
            <Container className={classes.containterWapper}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                      <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      autoComplete="username"
                      value={formik.values.username}
                      error={formik.touched.username && Boolean(formik.errors.username)}
                      helperText={formik.touched.username && formik.errors.username}
                      onChange={formik.handleChange}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={formik.values.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                    />
                    <Typography color='error' align='center'>{data.errorMessage}</Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={formik.handleSubmit}
                      disabled={Boolean(isLoading)}
                    >
                      {isLoading ? "Loading..." : "Sign In"}
                    </Button>
                  </form>
                </Paper>
            </Container>
          </div>
  );
}

export default Login
