import { Button, Tooltip, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles,Snackbar } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { DateField, GudangCombo, SingleDialog } from '../../component/molecules';
import CloseIcon from '@material-ui/icons/Close';
import { emptyForm, getAllTransferList, postNewTransferToAPI, setTempIdTransfer, setTransferMessage, updateTempTransfer } from '../../config/redux/action/TransferBarangAction';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const TrasnsferList = () => {
    const {FormTransfer,tempIdTransfer,tempTransferBarang,transferMessage} = useSelector(state => state.transferBarangReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        search:{
            marginRight:'5%',
            [theme.breakpoints.up('md')]:{
                marginRight:'18%',
            },
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'95%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'95%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'95%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        mainWapper:{
            display:'flex',
            marginBottom:'10px',
            marginLeft:'10px',
            width:'95%',
            justifyContent:'left',
            [theme.breakpoints.up('md')]:{
                marginLeft:'35px',
            },
        },
        check:{
            marginTop:'18px',
        },
        input:{
            width:'95px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'150px',
                marginLeft:'5px',
            },
        },
    }));
    const classes = useStyle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const [totalItem,setTotalItem]=useState(0);
      const [showDialog,setShowDialog]=useState(false);
      const [openSnackbar,setOpenSnackbar]=useState(false);
      useEffect(()=>{
          if(FormTransfer.length>0){
              setTotalItem(FormTransfer.length);
          }else{
              setTotalItem(0);
          }
    },[FormTransfer])
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setTransferMessage(""));
        },500)
    };
    const openDeleteTransfer=(id)=>{
        setShowDialog(true);
        dispatch(setTempIdTransfer(id));
    }
    const deleteTransferList = () =>{
        const findBarang = FormTransfer.findIndex(barang=>barang.id===tempIdTransfer);
        const details = FormTransfer.filter(det=>det.id === tempIdTransfer);
        let tempJumlah;
        let index;
        tempTransferBarang.find((indexs,i) => {
            if(indexs.idbarang===details[0].idbarang){
                tempJumlah = parseInt(indexs.jumlah) - parseInt(details[0].jumlah);
                index=i;
                    FormTransfer.splice(findBarang,1);
                    return index;
            }
            return tempJumlah;
        })
        dispatch(updateTempTransfer(index,tempJumlah));
        dispatch(getAllTransferList());
        setTempIdTransfer('');
        setShowDialog(false);
    }
    const onCloseDialog=()=>{
        setShowDialog(false);
    }
    const validationSchema = yup.object().shape({
        tanggal:yup.date().max(new Date(),'Tanggal tidak valid'),
        idgudang:yup.string().required('Gudang belum dipilih')
    })
    const formik = useFormik({
        initialValues:{
            tanggal:new Date(),
            idgudang:""
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'idgudang':values.idgudang,
                'tanggal' :format(values.tanggal,'yyyy-MM-dd'),
                'list':FormTransfer
            } 
            dispatch(postNewTransferToAPI(data,token));
            setOpenSnackbar(true);
            dispatch(emptyForm());
            formik.resetForm();
        }
    })
    return (
        <div className={classes.root}>
        <div className={classes.mainWapper}>
            <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                onChange={(value)=>formik.setFieldValue('tanggal',value)}
            />
            <GudangCombo 
            id="idgudang" 
            name="idgudang"
            error={formik.touched.idgudang && Boolean(formik.errors.idgudang)}
            onChange={formik.handleChange}
            value={formik.values.idgudang}
            helperText={formik.touched.idgudang && formik.errors.idgudang}
            />
        </div>
        <div className={classes.content}>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Varian</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {FormTransfer.map((FormTransfers=>(
                                <StyledTableRow key={FormTransfers.id}>
                                    <SytledTableCell align="center">{FormTransfers.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{FormTransfers.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{FormTransfers.jumlah}</SytledTableCell>
                                    <SytledTableCell align="center">{FormTransfers.variant}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        <Tooltip title="Hapus dari list">
                                            <IconButton size='small' 
                                            onClick={()=>openDeleteTransfer(FormTransfers.id)}
                                            >
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
        </div>
        <div className={classes.content}>
        <div className={classes.GrandTotal}>
            <Typography>Total Transfer Barang</Typography>
            <Typography>{totalItem} Item</Typography>
        </div>
        <Button 
        aria-label="save transfer" 
        onClick={formik.handleSubmit} 
        variant="contained" 
        color="primary"
        disabled={FormTransfer.length < 1 ? true : false}
        >Transfer Data</Button>
        </div>
        <div className={classes.divinder}/>
        <SingleDialog openDialog={showDialog} handleClick={deleteTransferList} okCommand="Hapus" handleCloseDialog={onCloseDialog} message="Anda yakin akan menghapus data ini?"/>
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
            <Alert 
            onClose={handleCloseSnackBar} 
            severity={transferMessage === "Data stok gudang berhasil disimpan"?"success":"error"}>
                {transferMessage}
                </Alert>
        </Snackbar>
    </div>
    )
}

export default TrasnsferList
