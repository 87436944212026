import {combineReducers} from 'redux';
import loginReducers from './loginReducers';
import homeReducers from './homeReducers';
import stokbarangReducers from './stokbarangReducers';
import jenisbarangReducers from './jenisbarangReducers';
import barangReducers from './barangReducers';
import pembelianReducers from './pembelianReducers';
import returReducers from './returReducers';
import recordKeuntunganReducers from './recordKeuntunganReducers';
import hargaJualReducers from './HargaJualReducers';
import returSupplierReducers from './returSupplierReducers';
import gudangReducers from './gudangReducers';
import transferBarangReducers from './transferBarangReducers';
import stokGudangReducers from './stokGudangReducers'
import pengeluaranGudangReducers from './pengeluaranGudangReducers';
import pengeluaranReducers from './pengeluaranReducers';
import penyimpananReducers from './penyimpananReducers';
import riwayatPembelianReducers from './riwayatPembelianReducers';
import riwayatPenjualanReducers from './riwayatPenjualanReducers';
import penjualanReducers from './penjualanReducers';
import pelangganReducers from './pelangganReducers';
import keuntunganReducers from './keuntunganReducers';
import stokReturReducers from './stokReturReducers';
import filterBarangReducers from './filterBarangReducers';
import draftPenjualanReducers from './draftPenjualanReducers';
import orderReducers from './orderReducers';
import penggunaReducers from './penggunaReducers';
import partnerReducers from './partnerReducers';
import hutangReducers from './hutangReducers';
import PoOrderReducers from './PoOrderReducers';

const reducer = combineReducers({
    loginReducers,
    homeReducers,
    stokbarangReducers,
    jenisbarangReducers,
    barangReducers,
    pembelianReducers,
    returReducers,
    recordKeuntunganReducers,
    hargaJualReducers,
    returSupplierReducers,
    gudangReducers,
    transferBarangReducers,
    stokGudangReducers,
    pengeluaranGudangReducers,
    pengeluaranReducers,
    penyimpananReducers,
    riwayatPembelianReducers,
    riwayatPenjualanReducers,
    penjualanReducers,
    pelangganReducers,
    keuntunganReducers,
    stokReturReducers,
    filterBarangReducers,
    draftPenjualanReducers,
    orderReducers,
    penggunaReducers,
    partnerReducers,
    hutangReducers,
    PoOrderReducers
})

export default reducer;