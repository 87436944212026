const initialValueStokGudang = {
    sisaStokBarang:'',
    dataStokGudang:[],
    formStokGudang:{},
    isLoadingStokGudang:false,
    gudangMessage:'',
    searchGudang:'',
    pageGudang : {
        currentPage:'1',
        totalPage:'1'
    },
    perPage:'1',
    editStokMessage:''
}

const stokGudangReducers = (state=initialValueStokGudang,action)=>{
    switch(action.type){
        case 'GET SISA STOK GUDANG':{
            return{
                ...state,
                sisaStokBarang:action.payload
            }
        }
        case 'FETCH STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:true
            }
        }
        case 'UPDATE PAGE STOK GUDANG':{
            return {
                ...state,
                pageGudang:action.payload
            }
        }
        case 'UPDATE DATA STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                dataStokGudang:action.payload,
                gudangMessage:action.message
            }
        }
        case 'FAILED STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                gudangMessage:action.message
            }
        }
        case 'SET SEARCH TEXT GUDANG':{
            return{
                ...state,
                searchGudang : action.payload
            }
        }
        case 'ADD FORM KOREKSI STOK GUDANG':{
            return{
                ...state,
                formStokGudang : action.payload
            }
        }
        case 'EMPTY FORM KOREKSI STOK GUDANG':{
            return{
                ...state,
                formStokGudang : []
            }
        }
        case 'FETCH EDIT STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:true
            }
        }
        case 'SUCCESSED EDIT STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                editStokMessage:action.payload
            }
        }
        case 'FAILED EDIT STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                editStokMessage:action.payload
            }
        }
        case 'SET EMPTY GUDANG MESSAGE':{
            return{
                ...state,
                editStokMessage:action.payload
            }
        }
        case 'SUCCESSED DELETE STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                editStokMessage:action.payload
            }
        }
        case 'FAILED DELETE STOK GUDANG':{
            return{
                ...state,
                isLoadingStokGudang:false,
                editStokMessage:action.payload
            }
        }
        default:
            return state
    }
}
export default stokGudangReducers