import React, { useEffect, useState } from 'react'
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import { AddIcon } from '../../../component/assets';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getDataPODetails, getDataPOOrder, setEmptyPoOrderDetails, setEmptyPoOrderMessage } from '../../../config/redux/action/PoOrderAction';
import DialogAddPoOrder from '../FormAddPoOrder';
import { format } from 'date-fns';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeletePoOrder from '../HapusPoOrder';
import DialogAddPoOrderDetails from '../FormAddPoOrderDetails';
import DeletePoOrderDetails from '../HapusPoOrderDetails';
import { ArrowForwardIos } from '@material-ui/icons';


const PoOrderPage = () => {
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 12
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const useStyles = makeStyles((theme)=>({
        root:{
            height:'100%',
            display:'flex',
            flexDirection:'column',
            textAlign:'center',
        },
        tableContent:{
            [theme.breakpoints.up('md')]:{
                width:'100%',
            },
            [theme.breakpoints.down('md')]:{
                width:'100%',
            },
            display:'flex',
            justifyContent:'space-between'
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        paper1:{
            width:'100%', 
        },
        contentTabel1:{
            width:'35%',
            [theme.breakpoints.up('md')]:{
                marginLeft:'10px',
                marginRight:'10px'
            },
            [theme.breakpoints.down('md')]:{
                marginRight:'10px',
                marginLeft:'10px'
            },
        },
        contentTabel2:{ 
            width:'65%', 
            [theme.breakpoints.up('md')]:{
                marginRight:'10px'
            },
            [theme.breakpoints.down('md')]:{
                marginRight:'10px'
            },
        },paper2:{
            width:'100%'
        },
        search:{
            width:'100px',
        }
        }))
        const classes=useStyles();
        const dispatch = useDispatch();
        const {data} = useSelector(state => state.loginReducers);
        const [token,setToken] = useState(data.token);
        const [openDialogNewPO,setOpenDialogNewPO]=useState(false);
        const {dataPoOrder,dataPoOrderDetails} = useSelector(state=>state.PoOrderReducers);
        const [deletePODialog,setDeletePODialog] = useState(false);
        const [tempIdPO,setTempIdPO] = useState('');
        const [disableAddDetails,setDisabledAddDetails]=useState(false);
        const [openDialogPODetails,setOpenDialogPODetails]=useState(false);
        const [tempDataDet,setTempDataDet]=useState('');
        const [deletePoDetDialog,setDeletePoDetDialog]=useState(false);
        const [idPoSign,setIdPoSign]=useState('');
        useEffect(()=>{
            if(dataPoOrder.length<1){
                dispatch(getDataPOOrder(token));
            }
            setTimeout(()=>{
                dispatch(getDataPOOrder(token));
            },10000)
        },[dispatch,token,dataPoOrder])
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
        const handleClickOpenNewPO = () =>{
            setOpenDialogNewPO(true);
        }
        const handleCloseNewPO = () =>{
            setOpenDialogNewPO(false);
            dispatch(getDataPOOrder(token));
        }
        const handleClickOpenDeletePO = (tempId) =>{
            setTempIdPO(tempId);
            setDeletePODialog(true);
        }
        const handleCloseDeletePO = () =>{
            setDeletePODialog(false);
            dispatch(getDataPOOrder(token));
            dispatch(setEmptyPoOrderDetails());
        }
        const addBarangToPODetails=(idpoorder)=>{
            setIdPoSign(idpoorder);
            setDisabledAddDetails(true)
            dispatch(setEmptyPoOrderDetails());
            setTempIdPO(idpoorder);
            dispatch(getDataPODetails(idpoorder,token));
        }
        const handleClickOpenNewPODetails = () =>{
            setOpenDialogPODetails(true);
        }
        const handleCloseNewPODetails = () =>{
            dispatch(setEmptyPoOrderMessage(""));
            setOpenDialogPODetails(false);
        }
        const handleClickOpenDeletePODet = (idpoorder,idpoorderDetails,namabarang) =>{
            setDeletePoDetDialog(true);
            setTempDataDet({
                'idpoorder':idpoorder,
                'idpoorderDetails':idpoorderDetails,
                'namabarang':namabarang
            })
        }
        const handleClickCloseDeletePODet = () =>{
            setDeletePoDetDialog(false);
        }
        const tablePOOrder = (
            <TableBody>
                {dataPoOrder.map((dataPoOrders)=>(
                    <StyledTableRow key={dataPoOrders.idpoorder}>
                        <SytledTableCell align="center">
                        <Tooltip title="Hapus Po Order">
                            <IconButton size='small' 
                            onClick={()=>{handleClickOpenDeletePO(dataPoOrders.idpoorder)}}
                            >
                                <DeleteForeverIcon/>
                            </IconButton>
                        </Tooltip></SytledTableCell>
                        <SytledTableCell align="center">{format(new Date(dataPoOrders.tanggal),'dd MMMM yyyy')}</SytledTableCell>
                        <SytledTableCell align="center"><Button size='small' 
                        onClick={()=>{addBarangToPODetails(dataPoOrders.idpoorder)}}>{dataPoOrders.pelanggan}</Button></SytledTableCell>
                        {idPoSign===dataPoOrders.idpoorder ? 
                        <SytledTableCell align="center">
                            <ArrowForwardIos fontSize='small'/> 
                        </SytledTableCell>: <SytledTableCell align="center"/>}
                    </StyledTableRow> 
                    ))}
            </TableBody>
        )
        const tablePOOrderDetails = (
            <TableBody>
                {dataPoOrderDetails.map((dataPoOrderDetail)=>(
                    <StyledTableRow key={dataPoOrderDetail.idpoorderdetails}>
                        <SytledTableCell align="center">
                        <Tooltip title="Hapus Data Barang">
                            <IconButton size='small' 
                            onClick={()=>{handleClickOpenDeletePODet(dataPoOrderDetail.idpoorder,dataPoOrderDetail.idpoorderdetails,dataPoOrderDetail.namabarang)}}
                            >
                                <DeleteForeverIcon/>
                            </IconButton>
                        </Tooltip></SytledTableCell>
                        <SytledTableCell align="center">{dataPoOrderDetail.idbarang}</SytledTableCell>
                        <SytledTableCell align="center">{dataPoOrderDetail.namabarang}</SytledTableCell>
                        <SytledTableCell align="center">{dataPoOrderDetail.jumlah}</SytledTableCell>
                        <SytledTableCell align="center">{dataPoOrderDetail.keterangan!=="" ? dataPoOrderDetail.keterangan : "-"}</SytledTableCell>
                    </StyledTableRow> 
                    ))}
            </TableBody>
        )
  return (
    <div className={classes.root}>
        <div className={classes.tableContent}>
        <div className={classes.contentTabel1}>
        <Paper className={classes.paper1}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="table jenis barang" size="small">
                    <TableHead>
                        <TableRow>
                            <SytledTableCell width='2%' align="center">
                                <Tooltip title="Tambah PO Order">
                                    <IconButton 
                                    aria-label="addToPO" 
                                    size='small'
                                    onClick={handleClickOpenNewPO}
                                    >
                                        <img src={AddIcon} alt='add'/>
                                    </IconButton>
                                </Tooltip></SytledTableCell>
                            <SytledTableCell width='20%' align="center">Tanggal</SytledTableCell>
                            <SytledTableCell width='35%' align="center">Pelanggan</SytledTableCell>
                            <SytledTableCell width='2%' align="center"/>
                        </TableRow>
                    </TableHead> 
                    {tablePOOrder}
                </Table> 
            </TableContainer>
            </Paper>
        </div>
        <div className={classes.contentTabel2}>
        <Paper className={classes.paper2}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="table jenis barang" size="small">
                    <TableHead>
                        <TableRow>
                        {disableAddDetails===true?
                        <SytledTableCell width='2%' align="center">
                            <Tooltip title="Tambah Barang">
                                <IconButton 
                                aria-label="addToPO" 
                                size='small'
                                onClick={handleClickOpenNewPODetails}
                                >
                                    <img src={AddIcon} alt='add'/>
                                </IconButton> 
                            </Tooltip>
                        </SytledTableCell>
                        :<SytledTableCell width='2%' align="center"/> }
                            <SytledTableCell width='30%' align="center">ID Barang</SytledTableCell>
                            <SytledTableCell width='30%' align="center">Nama Barang</SytledTableCell>
                            <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                            <SytledTableCell width='30%' align="center">Keterangan</SytledTableCell>
                        </TableRow>
                    </TableHead> 
                    {tablePOOrderDetails}
                </Table> 
            </TableContainer>
            </Paper>
        </div>
        </div>
        <DialogAddPoOrder open={openDialogNewPO} handleCloseDialog={handleCloseNewPO}/>
        <DeletePoOrder open={deletePODialog} tempID={tempIdPO} HandleClickCloseDeleteDialog={handleCloseDeletePO}/>
        <DialogAddPoOrderDetails open={openDialogPODetails} tempId={tempIdPO} handleCloseDialog={handleCloseNewPODetails}/>
        <DeletePoOrderDetails open={deletePoDetDialog} tempData={tempDataDet} HandleClickCloseDeleteDialog={handleClickCloseDeletePODet}/>
    </div>
  )
}

export default PoOrderPage