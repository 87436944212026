import React from 'react'
import { InputLabel, makeStyles, Select, Typography } from '@material-ui/core';

const PembayaranCombo = ({id,name,label,error,handleChange,helperText,data,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100px',
            [theme.breakpoints.down('xs')]:{
                width:'150px'
            },
        },
        option:{
            width:'100px',
            [theme.breakpoints.down('xs')]:{
                width:'150px'
            },
            // marginBottom:'10px'
        },
        select:{
            width:'100px',
            [theme.breakpoints.down('xs')]:{
                width:'150px'
            },
        },
        helperText:{
            color:'#FD0F04',
            fontSize:'12px',
            // marginBottom:'10px',
        }
    }));
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <InputLabel
            className={classes.option} 
            htmlFor='pembayaran'><Typography variant="body2"> {label}</Typography></InputLabel>
            <Select
            className={classes.select}
            {...rest}
            id={id}
            name={name}
            label={label}
            native
            error={error}
            onChange={handleChange}>
                {data.map((datas=>(
                    <option key={datas.id} value={`${datas.id}`}>{datas.tipe}</option>
                )))}
            </Select>
            <Typography className={classes.helperText}>{helperText}</Typography>
        </div>  
    )
}

export default PembayaranCombo
