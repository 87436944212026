const initialStatePengeluaranGudang={
    FormPengeluaranGudang:{},
    FormListPengeluaranGudang:[],
    pengeluaranGudangMessage:'',
    isLoadingPengeluaranGudang:false,
    tempIdPengeluaran:'',
    noSuratJalan:''
}

const pengeluaranGudangReducers = (state=initialStatePengeluaranGudang,action)=>{
    switch(action.type){
        case 'SET FROM PENGELUARAN':{
            return{
                ...state,
                FormPengeluaranGudang:action.payload,
            }
        }
        case 'SET LOADING PENGELUARAN':{
            return{
                ...state,
                isLoadingPengeluaranGudang:true
            }
        }
        case 'ADD LIST PENGELUARAN GUDANG':{
            return{
                ...state,
                FormListPengeluaranGudang:[...state.FormListPengeluaranGudang,action.payload],
                pengeluaranGudangMessage:action.message
            }
        }
        case 'SUCCESS POST PENGELUARAN GUDANG':{
            return{
                ...state,
                isLoadingPengeluaranGudang:false,
                pengeluaranGudangMessage:action.payload
            }
        }
        case 'FAILED POST PENGELUARAN GUDANG':{
            return{
                ...state,
                isLoadingPengeluaranGudang:false,
                pengeluaranGudangMessage:action.payload
            }
        }
        case 'FILL EMPTY FORM PENGELUARAN GUDANG':{
            return{
                ...state,
                FormPengeluaranGudang:[]
            }
        }
        case 'SET MESSAGE PENGELUARAN GUDANG':{
            return{
                ...state,
                pengeluaranGudangMessage:action.payload
            }
        }
        case 'SET TEMP ID PENGELUARAN GUDANG':{
            return{
                ...state,
                tempIdPengeluaran:action.payload
            }
        }
        case 'GET ALL LIST PENGELUARAN':{
            return{
                ...state,
                FormListPengeluaranGudang:[...state.FormListPengeluaranGudang]
            }
        }
        case 'EMPTY PENGELUARAN FORM':{
            return{
                ...state,
                FormListPengeluaranGudang:[]
            }
        }
        case 'SET NO SURAT JALAN':{
            return{
                ...state,
                noSuratJalan:action.payload
            }
        }
        case 'FAILED GET NO SURAT JALAN':{
            return{
                ...state,
                noSuratJalan:""
            }
        }
        default:
            return state
    }
}

export default pengeluaranGudangReducers;