import { Button, makeStyles, Paper, Table, TableBody,withStyles, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Tooltip, IconButton, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { DateField, PembayaranCombo, SingleDialog} from '../../component/molecules';
import { DataPelanggan } from '../../component/organism';
import { emptyDataPelanggan, emptyMessageDraftPenjualan, getNewIDDraftPenjualan, resetAllFormDraftPenjualan, getAllListDraftPenjualan, setEmptyLoadDraft, deleteAPIPenjualanDetails, getDataToPrint, postDraftPenjualanToAPI, postEditDraftPenjualan, setMessageDraftPenjualan } from '../../config/redux/action';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { addDays, format } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NumberFormat from 'react-number-format';
import MuiAlert from '@material-ui/lab/Alert';
import PrintDialog from '../../component/organism/PrintDialog';
import { useHistory } from 'react-router-dom';
import { EditQty, OpenDraft, Profit } from '../../component/assets';
import GetDataDraft from '../../component/organism/GetDataDraft';
import EditJumlahDraft from '../../component/organism/EditJumlahDraft';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const KeranjangDraftPenjualan = () => {
    const {data} = useSelector(state => state.loginReducers);
    const [token,setToken] = useState(data.token);
    const {selected} = useSelector(state=>state.pelangganReducers);
    const {selectedNama} = selected;
    const dispatch = useDispatch();
    const{nofakturDraft,listDraftPenjualan,loadingDraftPenjualan,dataPenjualan,messageDraftPenjualan,statusDraft} = useSelector(state=>state.draftPenjualanReducers);
    const {listKeuntungan} = useSelector(state=>state.keuntunganReducers);
    const [totalPenjualan,setTotalPenjualan]=useState(0);
    const [statusLockOngkir,setStatusLockOngkir]=useState(true);
    const [statusLockDiskon,setStatusLockDiskon]=useState(true);
    const [grandTotal,setGrandTotal]=useState(0);
    const [totalModal,setTotalModal]=useState(0);
    const [totalLaba,setTotalLaba]=useState(0);
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [openDraftDialog,setOpenDraftDialog]=useState(false);
    const [openEditJumlah,setOpenEditJumlah]=useState(false);
    const [tempNamaBarang,setTempNamaBarang]=useState("");
    const [tempIdDetails,setTempIdDetails]=useState("");
    const [tempJumlah,setTempJumlah]=useState(0);
    const [tempIdPenjualan,setTempIdPenjualan]=useState("");
    const [namaItem,setNamaItem]=useState("");
    const [totalItem,setTotalItem]=useState(0);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        headerContent:{
            width:'90%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'90%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        tableContainer:{
            height:'520px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        },page:{
            display:'flex',
            justifyContent:'space-between',
            width:'100%',
            margin:'0px',
        },
        OngkirWapper:{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end',
            width:'90%',
            margin:'0px',
        },
        GrandTotal:{
            display:'flex',
            justifyContent:'space-between',
            width:'90%',
            marginTop:'10px',
            marginBottom:'10px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },
        divinder:{
            padding:'5px'
        },
        mainWapper:{
            display:'flex',
            flexDirection:'row',
            marginBottom:'5px',
            marginLeft:'0px',
            width:'100%',
            justifyContent:'center',
            alignItems:'center',
            [theme.breakpoints.down('xs')]:{
                flexDirection:'column'
            },
        },
        iconSearch:{
            marginTop:'35px',
            height:'10px',
        },
        input:{
            width:'150px',
            marginTop:'5px',
            marginLeft:'5px',
            [theme.breakpoints.up('md')]:{
                width:'150px',
                marginLeft:'5px',
            },
        },
        blokFaktur:{
            display:'flex',
            width:'350px',
            justifyContent:'center',
            marginTop:'10px',
            [theme.breakpoints.down('xs')]:{
                justifyContent:'flex-start',
            },
        },
        blokTempo:{
            display:'flex',
            width:'200px',
            justifyContent:'center',
            [theme.breakpoints.down('xs')]:{
                width:'350px',
                justifyContent:'flex-start',
            },
        },
        lock:{
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-end'
        },
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyle();
    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(emptyMessageDraftPenjualan(""));
        },200)
    };
    const handleOpenDraftDialog = () =>{
        dispatch(getAllListDraftPenjualan(token,setOpenSnackbar));
        setOpenDraftDialog(true);
    }
    const [openPelanggan,setOpenPelanggan]=useState(false);
    const listPenjualan=(
        <div className={classes.content}>
            <div className={classes.headerContent}>
                <div style={{width:'60%',display:'flex',justifyContent:'flex-end',alignItems:'center',}}>
                    <Typography>Data Penjualan</Typography>
                    <Tooltip title="Ambil Data Draft">
                        <IconButton
                        onClick={()=>handleOpenDraftDialog()}><img src={OpenDraft} alt="openDraft"/></IconButton>
                    </Tooltip>
                </div>
                <div style={{width:'40%',display:'flex',justifyContent:'flex-end'}}>
                    <Tooltip title={"Rp "+new Intl.NumberFormat().format(totalLaba)}>
                        <IconButton><img src={Profit} alt="profit" style={{width:'20px',height:'20px'}}/></IconButton>
                    </Tooltip>
                </div>
            </div>
        <div className={classes.table}>
            <Paper>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID Barang</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Barang</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Jumlah</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                                <SytledTableCell width='10%' align="center">Catatan</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Harga</SytledTableCell>
                                <SytledTableCell width='20%' align="center">Subtotal</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listDraftPenjualan.map((listPenjualans=>(
                                <StyledTableRow key={statusDraft==="EditDraft" ? listPenjualans.idpenjualandetails : listPenjualans.idbarang}>
                                    <SytledTableCell align="center">{listPenjualans.idbarang}</SytledTableCell>
                                    <SytledTableCell align="center">{listPenjualans.namabarang}</SytledTableCell>
                                    <SytledTableCell align="center">{listPenjualans.jumlah}
                                    <Tooltip title={statusDraft==="EditDraft" ? "Ubah Qty" : ""}>
                                        <IconButton 
                                        disabled = {statusDraft==="EditDraft" ? false : true}
                                     size='small' onClick={()=>onOpenEditJumlahDraftDialog(listPenjualans.idbarang,listPenjualans.namabarang,listPenjualans.idpenjualandetails,listPenjualans.jumlah,listPenjualans.idpenjualan)}>
                                            <img src={EditQty} alt="editQty"/>
                                        </IconButton>
                                    </Tooltip>
                                    </SytledTableCell>
                                    <SytledTableCell align="center">{listPenjualans.satuan}</SytledTableCell>
                                    <SytledTableCell align="center">{listPenjualans.note}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(listPenjualans.harga)}</SytledTableCell>
                                    <SytledTableCell align="center">{new Intl.NumberFormat().format(listPenjualans.subtotal)}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        {listDraftPenjualan.length>=2 ? <Tooltip title="Hapus dari list">
                                            <IconButton size='small' onClick={()=>openDeletePenjualan(listPenjualans.idbarang,listPenjualans.idpenjualan,listPenjualans.idpenjualandetails,listPenjualans.namabarang)}>
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip> : <Tooltip title="Hapus dari list">
                                            <IconButton disabled={true} size='small' onClick={()=>openDeletePenjualan(listPenjualans.idbarang,listPenjualans.idpenjualan,listPenjualans.idpenjualandetails,listPenjualans.namabarang)}>
                                                <CloseIcon style={{color:'red'}}/>
                                            </IconButton>
                                        </Tooltip>}
                                        
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Item</Typography>
                        <Typography>{new Intl.NumberFormat().format(totalItem) + " Item"}</Typography>
                    </div>
                </div>
                <div className={classes.tableBottom}>
                    <div className={classes.page}>
                        <Typography>Total Draft Penjualan</Typography>
                        <Typography>{new Intl.NumberFormat().format(totalPenjualan)}</Typography>
                    </div>
                </div>
            </Paper>
        </div>
        </div>
    )
    const handleClickOpenPelanggan = ()=>{
        setOpenPelanggan(true);
    }
    const handleClosePelanggan = () =>{
        setOpenPelanggan(false);
        setTimeout(()=>{
            dispatch(emptyDataPelanggan());
        },200)
        formik.setFieldValue('namapelanggan',selectedNama);
    }
    const validationSchema = yup.object().shape({
        tanggal:yup.date().max(new Date(),'Tanggal tidak boleh melebihi hari ini'),
        namapelanggan:yup.string().required('Nama pelanggan tidak boleh kosong'),
        jatuhtempo:yup.date().min(yup.ref('tanggal'),'Jatuh tempo tidak boleh kurang dari tanggal penjualan'),
        lamatempo:yup.number().min(0,'lama tempo tidak boleh kurang dari 0'),
        ongkir:yup.number().min(0,'Ongkir tidak boleh kurang dari 0'),
        diskon:yup.number().min(0,'Diskon tidak boleh kurang dari 0'),
        pembayaran:yup.string().required('Pembayaran belum diisi'),
      })
    const formik = useFormik({
        initialValues:{
            nofaktur:"",
            namapelanggan:selectedNama,
            tanggal:dataPenjualan.tanggal,
            jatuhtempo:dataPenjualan.jatuhtempo,
            pembayaran:dataPenjualan.pembayaran,
            lamatempo:dataPenjualan.lamatempo,
            ongkir:dataPenjualan.ongkir,
            diskon:dataPenjualan.diskon
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            var tempArr=[];
            for(var i=0;i<listDraftPenjualan.length;i++){
                for(var j=i+1;j<listDraftPenjualan.length;j++){
                    if(listDraftPenjualan[i].idbarang===listDraftPenjualan[j].idbarang && !tempArr.includes(listDraftPenjualan[i].idbarang)){
                        tempArr.push(listDraftPenjualan[i].idbarang);
                    }
                }
            }
            if(tempArr.length<1){
                const data = {
                    "idpenjualan":nofakturDraft,
                    "pelanggan":values.namapelanggan,
                    "tanggal":format(values.tanggal,'yyyy-MM-dd'),
                    "pembayaran":values.pembayaran,
                    "jatuhtempo":format(values.jatuhtempo,'yyyy-MM-dd'),
                    "diskon":values.diskon,
                    "total":grandTotal,
                    "ongkir":values.ongkir,
                    "status":"Draft",
                    "penjualan":totalPenjualan,
                    "modal":totalModal,
                    "keuntungan":parseInt(totalPenjualan)-parseInt(totalModal)+parseInt(values.ongkir)-parseInt(values.diskon),
                    "details":listDraftPenjualan,
                    "detailsKeuntungan":listKeuntungan
                }
                if(statusDraft==="Draft"){
                    dispatch(postDraftPenjualanToAPI(data,token,setOpenSnackbar,setOpenPrint));
                }else if(statusDraft==="EditDraft"){
                    dispatch(postEditDraftPenjualan(data,token,setOpenSnackbar,setOpenPrint));
                }
            }else{
                dispatch(setMessageDraftPenjualan('Draft Penjualan gagal disimpan,terdapat item yang sama dalam 1 keranjang penjualan',setOpenSnackbar));
            }
        }
    })
    useEffect(() => {
        if(nofakturDraft === ''){
            dispatch(getNewIDDraftPenjualan(format(formik.values.tanggal,'ddMMyy'),token));
        }
        if(listDraftPenjualan.length>=0){
            let totalPenjualan=listDraftPenjualan.reduce((Penjualan,TotalP)=>{
                return Penjualan + TotalP.subtotal
            },0)
            setTotalPenjualan(totalPenjualan);
            let totalItemList=listDraftPenjualan.reduce((Penjualan,TotalI)=>{
                return parseInt(Penjualan) + parseInt(TotalI.jumlah)
            },0)
            setTotalItem(totalItemList);
            let totalModal=listKeuntungan.reduce((Keuntungan,TotalK)=>{
                return Keuntungan + TotalK.subtotalmodal
            },0)
            setTotalModal(totalModal);
            let totalKeuntungan = listKeuntungan.reduce((Keuntungan,TotalL)=>{
                return Keuntungan + TotalL.laba
            },0)
            setTotalLaba(parseInt(totalKeuntungan) + parseInt(formik.values.ongkir) - parseInt(formik.values.diskon));
        }else{
            setTotalPenjualan(0);
            setTotalModal(0);
            setTotalLaba(0);
        }
        if(statusLockOngkir === true && statusLockDiskon === true){
            setGrandTotal(parseInt(totalPenjualan) + parseInt(formik.values.ongkir) - parseInt(formik.values.diskon));
        }
    }, [dispatch,nofakturDraft,formik.values.tanggal,formik.values.diskon,formik.values.ongkir,token,listDraftPenjualan.length,listDraftPenjualan,totalItem,listKeuntungan,totalPenjualan,grandTotal,totalModal,
        statusLockOngkir,statusLockDiskon,totalLaba])
    const handleChangeTanggal = (value)=>{
        if(statusDraft==="Draft"){
            formik.setFieldValue('tanggal',value);
            setTimeout(() => {
                dispatch(getNewIDDraftPenjualan(format(value,'ddMMyy'),token));
                formik.setFieldValue('nofaktur',nofakturDraft);
            }, 500);
        }else{
            formik.setFieldValue('tanggal',value);
        }
    }
    const handleChangeJatuhTempo = (e) =>{
        formik.setFieldValue('jatuhtempo',e);
    }
    const [openDelete,setOpenDelete]=useState(false);
    const [tempIdBarang,setTempIdBarang]=useState('');
    const [tempIdPenjualanDetails,setTempIdPenjualanDetails]=useState("");
    const openDeletePenjualan = (idbarang,idpenjualan,idpenjualandetails,namabarang) =>{
        setOpenDelete(true);
        setNamaItem(namabarang);
        setTempIdBarang(idbarang);
        setTempIdPenjualan(idpenjualan);
        setTempIdPenjualanDetails(idpenjualandetails);
    }
    const deletePenjualanDetails = ()=>{
        const data = {
            'idpenjualan':tempIdPenjualan,
            'idpenjualandetails':tempIdPenjualanDetails
        }
        const findBarangKeuntungan = listKeuntungan.findIndex(barang=>barang.idbarang===tempIdBarang);
        listKeuntungan.splice(findBarangKeuntungan,1);
        dispatch(deleteAPIPenjualanDetails(data,token,setOpenSnackbar,onCloseDialog));
    }
    const deleteDaftarPenjualan = () =>{
        const findBarang = listDraftPenjualan.findIndex(barang=>barang.idbarang===tempIdBarang);
        listDraftPenjualan.splice(findBarang,1);
        const findBarangKeuntungan = listKeuntungan.findIndex(barang=>barang.idbarang===tempIdBarang);
        listKeuntungan.splice(findBarangKeuntungan,1);
        dispatch(getAllListDraftPenjualan(token,setOpenSnackbar));
        setTempIdBarang('');
        setOpenDelete(false);
        if(listDraftPenjualan.length<1){
            formik.setFieldValue('ongkir',0);
            formik.setFieldValue('diskon',0);
        }
    }
    const onCloseDialog=()=>{
        setOpenDelete(false);
    }
    const handleOpenStatusLockOngkir = () =>{
        setStatusLockOngkir(!statusLockOngkir)
    }
    const handleOpenStatusLockDiskon = () =>{
        setStatusLockDiskon(!statusLockDiskon);
    }
    const onEnterLamaTempo = (value)=>{
        const newJatuhTempo = addDays(new Date(formik.values.tanggal),value);
        formik.setFieldValue('lamatempo',value);
        formik.setFieldValue('jatuhtempo',newJatuhTempo);
    }
    const dataPembayaran =[
        {id:'Tunai',tipe:'Tunai'},
        {id:'Kredit',tipe:"Kredit"}
    ]
    const [openPrint,setOpenPrint]=useState(false);
    const HandleClickCloseDraftPrintPenjualan = () =>{
        dispatch(resetAllFormDraftPenjualan());
        setOpenPrint(false);
        history.push('/historyPenjualan');
    }
    const history = useHistory();
    const handleClickForwardDraftLink = () =>{
        setTimeout(()=>{
            let RFrom = "Draft";
            dispatch(resetAllFormDraftPenjualan());
            dispatch(getDataToPrint(nofakturDraft,token));
            history.push(`/PrintPenjualan/${RFrom}/${nofakturDraft}`);
        },2000)
    }
    const handleCloseDraftDialog = () =>{
        dispatch(setEmptyLoadDraft());
        setOpenDraftDialog(false);
    }
    const onOpenEditJumlahDraftDialog = (idbarang,namabarang,idpenjualandetails,jumlah,idpenjualan) =>{
        setTempIdBarang(idbarang);
        setTempNamaBarang(namabarang);
        setTempIdDetails(idpenjualandetails);
        setTempJumlah(jumlah);
        setTempIdPenjualan(idpenjualan);
        setOpenEditJumlah(true);
    }
    const onChangePembayaran= (e) =>{
        if(e.target.value === "Tunai"){
            formik.setFieldValue('lamatempo',0);
            formik.setFieldValue('jatuhtempo',formik.values.tanggal);
            formik.setFieldValue('pembayaran',e.target.value);
        }else{
            formik.setFieldValue('pembayaran',e.target.value);
        }
    }
    const onCloseEditJumlahDraftDialog = () =>{
        setOpenEditJumlah(false);
    }
    return (
        <div className={classes.root}>
            <div className={classes.mainWapper}>
                <div className={classes.blokFaktur}>
                    <DateField 
                        id="tanggal" 
                        name="tanggal" 
                        label="Tanggal" 
                        value={formik.values.tanggal}
                        errors={formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                        helperText={formik.touched.tanggal && formik.errors.tanggal}
                        onChange={(e)=>handleChangeTanggal(e)}
                    />
                    <TextField className={classes.input} 
                    id="nofaktur" 
                    name="nofaktur" 
                    label="No Faktur"
                    value={nofakturDraft}
                    disabled={true}
                    />
                </div>
                <div className={classes.blokTempo}>
                    <PembayaranCombo
                    id="pembayaran"
                    name="pembayaran"
                    data={dataPembayaran}
                    label="Pembayaran"
                    value={formik.values.pembayaran}
                    error={formik.touched.pembayaran && Boolean(formik.errors.pembayaran)}
                    helperText={formik.touched.pembayaran && formik.errors.pembayaran}
                    handleChange={(e)=>onChangePembayaran(e)}
                    />
                    <TextField className={classes.input}
                    id="lamatempo "
                    name="lamatempo"
                    label="Lama Tempo"
                    value={formik.values.lamatempo}
                    disabled={formik.values.pembayaran === 'Kredit' ? false : true}
                    error={formik.touched.lamatempo && Boolean(formik.errors.lamatempo)}
                    helperText={formik.touched.lamatempo && formik.errors.lamatempo}
                    onKeyDown={(e)=>{
                        if(e.key === 'Enter'){
                            onEnterLamaTempo(e.target.value);
                        }
                    }}
                    onChange={formik.handleChange}
                    /> 
                </div>
                <div className={classes.blokFaktur}>
                    <DateField 
                        id="jatuhtempo" 
                        name="jatuhtempo" 
                        label="Jatuh Tempo" 
                        disabled={true}
                        value={formik.values.jatuhtempo}
                        errors={formik.touched.jatuhtempo && Boolean(formik.errors.jatuhtempo)}
                        helperText={formik.touched.jatuhtempo && formik.errors.jatuhtempo}
                        onChange={(e)=>handleChangeJatuhTempo(e)}
                    />
                    <TextField className={classes.input}
                    id="namapelanggan"
                    name="namapelanggan"
                    label="Pelanggan"
                    value={formik.values.namapelanggan}
                    error={formik.touched.namapelanggan && Boolean(formik.errors.namapelanggan)}
                    helperText={formik.touched.namapelanggan && formik.errors.namapelanggan}
                    onChange={(e)=>formik.setFieldValue('namapelanggan',e.target.value.toUpperCase())}
                    />
                    <Tooltip title="Cari data pelanggan">
                        <IconButton className={classes.iconSearch} size='small' onClick={()=>handleClickOpenPelanggan()}>
                            <SearchIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {listPenjualan}
            <div className={classes.divinder}/>
            <div className={classes.content}>
                <div className={classes.OngkirWapper}>
                    <div className={classes.lock}>
                        <IconButton size="small" onClick={()=>handleOpenStatusLockOngkir()}>
                            {statusLockOngkir === true ? <LockIcon style={{'color':'red'}} fontSize="small"/> : <LockOpenIcon style={{'color':'blue'}} fontSize="small"/>}
                        </IconButton>
                        <TextField className={classes.input}
                        id="ongkir"
                        name="ongkir"
                        label="Ongkir"
                        disabled={statusLockOngkir}
                        value={formik.values.ongkir}
                        error={formik.touched.ongkir && Boolean(formik.errors.ongkir)}
                        helperText={formik.touched.ongkir && formik.errors.ongkir}
                        onChange={formik.handleChange}
                        InputProps={{
                            style:{fontSize:20,fontWeight:'bold'},
                            inputComponent: NumberFormatCustom
                        }}
                        />
                    </div>
                    <div className={classes.lock}>
                        <IconButton size="small" onClick={()=>handleOpenStatusLockDiskon()}>
                                {statusLockDiskon === true ? <LockIcon style={{'color':'red'}} fontSize="small"/> : <LockOpenIcon style={{'color':'blue'}} fontSize="small"/>}
                        </IconButton>
                        <TextField className={classes.input}
                        id="diskon"
                        name="diskon"
                        label="Diskon"
                        disabled={statusLockDiskon}
                        value={formik.values.diskon}
                        error={formik.touched.diskon && Boolean(formik.errors.diskon)}
                        helperText={formik.touched.diskon && formik.errors.diskon}
                        onChange={formik.handleChange}
                        InputProps={{
                            style:{fontSize:20,fontWeight:'bold'},
                            inputComponent: NumberFormatCustom
                        }}
                        />
                    </div>
                </div>
                <div className={classes.GrandTotal}>
                <Typography>Grand Total</Typography>
                <Typography><b>{new Intl.NumberFormat().format(grandTotal)}</b></Typography>
            </div>
            <Button 
            aria-label="save penjualan" 
            onClick={formik.handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={
                loadingDraftPenjualan === false ?
                listDraftPenjualan.length > 0 && statusLockDiskon === true && statusLockOngkir === true ? false : true
                : true
            }
            >
                {loadingDraftPenjualan === true ? "Tunggu..." : "Simpan Draft Penjualan"}</Button>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={()=>handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} 
                severity={messageDraftPenjualan !== "Data draft penjualan berhasil disimpan" && "Berhasil menghapus item dari daftar draft penjualan" ? "error" : "success"}
                >
                    {messageDraftPenjualan}
                    </Alert>
            </Snackbar>
            <div className={classes.divinder}/>
            <SingleDialog openDialog={openDelete} handleClick={statusDraft==="EditDraft" ? deletePenjualanDetails :deleteDaftarPenjualan} okCommand="Hapus" handleCloseDialog={onCloseDialog} message={"Anda yakin akan menghapus data "+namaItem+"?"}/>
            <DataPelanggan open={openPelanggan} aria-labelledby="data-pelanggan" HandleClickClosePelangganDialog={handleClosePelanggan}/>
            <PrintDialog 
            open={openPrint} 
            aria-labelledby="Print-dialog"
            judul="Print Penjualan" 
            content="Apakah anda akan melakukan print faktur draft penjualan?"
            forwardLink={()=>handleClickForwardDraftLink()}
            HandleClickClosePrintDialog={HandleClickCloseDraftPrintPenjualan}/>
            <GetDataDraft OpenDraftListDialog={openDraftDialog} formikEdit={formik} CloseDraftListDialog={handleCloseDraftDialog} aksesfrom="Draft"/>
            <EditJumlahDraft openDialogEditJumlah={openEditJumlah} onCloseDialogEditJumlah={onCloseEditJumlahDraftDialog} tempIdBarang={tempIdBarang} tempNamaBarang={tempNamaBarang} tempIdDetails={tempIdDetails} tempJumlah={tempJumlah} tempIdPenjualan={tempIdPenjualan}/>
        </div>
    )
}

export default KeranjangDraftPenjualan