import axios from 'axios';
import {apiUrl} from '../..';

export const setFormTransfer =  (id,idbarang,namabarang,jumlah,variant) =>(dispatch)=>{
    const data = {
        'id':id,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'variant':variant
    }
    const tempJumlah = {
        'idbarang':idbarang,
        'jumlah':jumlah
    }
    dispatch({
        type: 'SET FROM TRANSFER',
        payload: data,
        tempTransfer:tempJumlah,
        message:'Berhasil menambahkan data ke daftar'
    })
}
export const setExistFormTransfer = (id,idbarang,namabarang,jumlah,variant,index,tempjumlah) => (dispatch)=>{
    const data = {
        'id':id,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'variant':variant
    }
    dispatch({
        type:'UPDATE TEMP TRANSFER',
        payload:data,
        index:index,
        value:tempjumlah,
        message:'Berhasil menambahkan data ke daftar'
    })
}
export const fillMessage = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY MESSAGE'
    })
}
export const setTransferMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE',
        payload:message
    })
}
export const setTempIdTransfer = (id) =>(dispatch)=>{
    dispatch({
        type:'SET ID BARANG TRANSFER',
        payload:id
    })
}
export const getAllTransferList = () => (dispatch)=>{
    dispatch({
        type:'GET DATA TRANSFER LIST'
    })
}
export const updateTempTransfer = (index,tempJumlah) =>(dispatch)=>{
    dispatch({
        type:'UPDATE AFTER REMOVE TRANSFER',
        index:index,
        value:tempJumlah
    })
}
export const postNewTransferToAPI = (data,token) =>(dispatch)=>{
    dispatch({
        type:'SET LOADING SAVE TRANSFER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/transfer/newTransfer`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS SAVE TRANSFER',
            payload:ResponseAPI.message,
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE TRANSFER',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE TRANSFER',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const emptyForm = () =>(dispatch)=>{
    dispatch({
        type:'FILL EMPTY FORM'
    })
}
export const getAllDataTransferList = (data,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH DATA TRANSFER LIST'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/transfer/getlist`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED DATA TRANSFER LIST',
            payload:ResponseAPI.data,
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED DATA TRANSFER LIST',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED DATA TRANSFER LIST',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const setTransferListMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'SET TRANSFER LIST MESSAGE',
        payload:message
    })
}
export const setArrayListEmpty = () =>(dispatch)=>{
    dispatch({
        type:'SET ARRAY LIST EMPTY'
    })
}
export const setEmptyFormDelete = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY FORM TRANSFER DELETE'
    })
}
export const setFormTransferDelete = (idgudang,tanggalString,tanggal) =>(dispatch)=>{
    const data={
        'idgudang':idgudang,
        'tanggalString':tanggalString,
        'tanggal':tanggal
    }
    dispatch({
        type:'SET FORM TRANSFER DELETE',
        payload:data
    })
}
export const deleteAllTransfer = (idgudang,data,token) =>(dispatch)=>{
    dispatch({
        type:'SET TRANSFER LIST MESSAGE',
        payload:""
    })
    dispatch({
        type:'FETCH DELETE TRANSFER'
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/transfer/${idgudang}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED DELETE TRANSFER',
            payload:ResponseAPI.message,
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED DELETE TRANSFER',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED DELETE TRANSFER',
                    payload:'Disconnect from server'
            })
        }
    })
}
