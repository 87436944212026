import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDataPelanggan, selectedIDPelanggan } from '../../../config/redux/action';
import CheckIcon from '@material-ui/icons/Check';
import { Search } from '../../molecules';

const DataPelanggan = ({HandleClickClosePelangganDialog,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            maxWidth:'400px',
            width:'400px',
            maxHeight:'100%',
            [theme.breakpoints.down('sm')]:{
                maxWidth:'250px',
            },
            textAlign:'center',
        },
        table:{
            marginTop:'5px',
            marginLeft:'0px',
            width:'100%',
            height:'100%',
            // maxWidth:'800px',
            [theme.breakpoints.up('md')]:{
                // marginLeft:'5%',
            },
        },
        search:{
            width:'100%',
            marginTop:'2px',
            display:'flex',
            justifyContent:'center'
        },
        button:{
            display:'flex',
            justifyContent:'center',
            marginTop:'5px',
            paddingBottom:"5px",
        },
        tableContainer:{
            height:'380px',
            [theme.breakpoints.up('md')]:{
                height:'340px',
            },
        }
    }));
    const classes = useStyle();
    const {data} = useSelector(state => state.loginReducers);
    const {dataPelanggan} = useSelector(state=>state.pelangganReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const onSelectedPelangganID = (idpelanggan,nama)=>{
            dispatch(selectedIDPelanggan(idpelanggan,nama));
            HandleClickClosePelangganDialog();
      }
      const tablePelanggan=(
        <div className={classes.table}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="table barang" size="small">
                        <TableHead>
                            <TableRow>
                                <SytledTableCell width='10%' align="center">ID</SytledTableCell>
                                <SytledTableCell width='35%' align="center">Nama Pelanggan</SytledTableCell>
                                <SytledTableCell width='3%' align="center"></SytledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPelanggan.map((datapelanggans=>(
                                <StyledTableRow key={datapelanggans.idpelanggan}>
                                    <SytledTableCell align="center">{datapelanggans.idpelanggan}</SytledTableCell>
                                    <SytledTableCell align="center">{datapelanggans.nama}</SytledTableCell>
                                    <SytledTableCell align="center">
                                        <IconButton size='small' onClick={()=>{onSelectedPelangganID(datapelanggans.idpelanggan,datapelanggans.nama)}}>
                                            <CheckIcon style={{color:'blue'}}/>
                                        </IconButton>
                                    </SytledTableCell>
                                </StyledTableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </div>
    )
    return (
        <div>
        <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="data-pelanggan">Data Pelanggan</DialogTitle>
            <Divider/>
                <DialogContent>
                <Search className={classes.search} label="Cari Pelanggan" name="cari-barang" id="cari-barang" onChange={(e)=>dispatch(getDataPelanggan(e.target.value,token))}/>
                <div>
                {tablePelanggan}
                </div>
                <div className={classes.button}>
                <Button 
                variant="contained" 
                color="secondary" 
                onClick={HandleClickClosePelangganDialog}
                >Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            
        </div>
    )
}

export default DataPelanggan
