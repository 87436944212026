import { Button, Divider, FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { BarangMasuk, DraftLogo, FilterBarangMenu, Gudang, HistoryPembelian, HistoryPenjualan, Hutang, Kategori, Partner, PengeluaranGudang, PenggunaIcon, PenjualanIcon, Product, RakIcon, ReturBarang, RPengeluaranGudang, StokBarang, StokGudang, TransferBarang } from '../../assets';
import ViewMinStok from '../ViewMinStok';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import PoOrderPage from '../../../pages/ModulPoOrder/PoOrder';
import { getDataPOOrder } from '../../../config/redux/action/PoOrderAction';

const MenuPage = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            height:'100%',
            display:'flex',
            flexDirection:'column',
            textAlign:'center',
        },
        subJudul:{
            zIndex:'1',
            paddingTop:'10px',
            backgroundColor:'white',
            width:'100%',
            position:'fixed',
        },
        collectionButton:{
            display:'flex',
            flexDirection:'column',
            // paddingTop:'10%',
            justifyContent:'center',
            [theme.breakpoints.down('xs')]:{
                // marginTop:'20px',
                flexDirection:'column',
                alignItems: 'center',
            },
        },
        switchArea:{
            display:'flex',
            justifyContent:'space-between',
            marginTop:"60px",
            marginLeft:'10px',
            [theme.breakpoints.down('xs')]:{
                marginLeft:'10px',
                marginTop:'60px',
                display:'flex',
                justifyContent:'flex-start',
            },
        },
        areaSwitch:{
            width:'50%',
        },
        areaSwitchRight:{
            width:'50%',
            display:'flex',
            justifyContent:'flex-end',
        },
        button:{
            backgroundColor:'white',
            height:'85px',
            width:'150px',
            margin:'10px',
            '&:hover':{
                backgroundColor: '#73c7e6'
            }
        },
        
    }))
    const classes = useStyle();
    const [stateCheck, setStateCheck] = useState(false);
    const [stateCheckPO,setStateCheckPO]=useState(false);
    const [subJudul,setSubJudul]=useState('Menu Panel');
    const {data} = useSelector(state => state.loginReducers);
    const {openHutang}=useSelector(state=>state.homeReducers);
    const [level,setLevel]=useState(data.level);
    const [token,setToken] = useState(data.token);
    const [username,setUsername]=useState(data.username);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    if(level===''){
        setLevel(Cookies.get('level'));
    }
    if(username===''){
        setUsername(Cookies.get('username'));
    }
    
    const handleStateChacke = (e)=>{
        setStateCheck(e.target.checked);
        if(e.target.checked===true){
            setSubJudul("Analisa Data");
            setStateCheckPO(false);
        }else {
            setSubJudul("Menu Panel")
            setStateCheckPO(false);
        }
    }
    const dispatch = useDispatch();
    const handleStateChackePO = (e)=>{
        setStateCheckPO(e.target.checked);
        if(e.target.checked===true){
            setSubJudul("PO Order");
            dispatch(getDataPOOrder(token));
        }else{
            if(stateCheck===true){
                setSubJudul("Analisa Data");
            }else {
                setSubJudul("Menu Panel")
            }
        }
    }
    const modulHutang = (
        <Link to="/hutang" style={{textDecoration:'none'}}>
            <Button className={classes.button} variant="outlined" color='primary'>
                <div style={{display:'flex',flexDirection:'column'}}>
                <div>
                    <img src={Hutang} width="40px" height="40px" alt="hutang"/>
                </div>
                <div>
                    <Typography style={{color:'GrayText',fontSize:'12px'}}>Hutang</Typography>
                </div>
                </div>
            </Button>
        </Link>
    )
    const menuPanel = (
        <div className={classes.collectionButton}>
                <div>
                <Link to="/pembelian" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={BarangMasuk} width="40px" height="40px" alt="Pembelian"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Pembelian</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/pengguna" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={PenggunaIcon} width="40px" height="40px" alt="pengguna"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Pengguna</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/barang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                            <img src={Product} width="40px" height="40px" alt="DataBarang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Data Barang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/stok" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={StokBarang} width="40px" height="40px" alt="StokBarang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Stok Barang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/partner" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={Partner} width="40px" height="40px" alt="partner"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Partner</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                </div>
                <div>
                <Link to="/jenisbarang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={Kategori} width="40px" height="40px" alt="JenisBarang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Jenis Barang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/gudang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={Gudang} width="40px" height="40px" alt="gudang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Data Gudang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/stokgudang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={StokGudang} width="40px" height="40px" alt="stokGudang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Stok Gudang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/transferbarang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={TransferBarang} width="40px" height="40px" alt="transfer"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Transfer Barang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/pengeluarangudang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={PengeluaranGudang} width="40px" height="40px" alt="PengeluaranGudang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Pengeluaran Gudang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                </div>
                <div>
                <Link to="/penyimpanan" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div>
                            <img src={RakIcon} width="40px" height="40px" alt="Penyimpanan"/>
                        </div>
                        <div>
                            <Typography style={{color:'GrayText',fontSize:'12px'}}>Penyimpanan</Typography>
                        </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/penjualan" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={PenjualanIcon} width="40px" height="40px" alt="PenjualanBarang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Penjualan</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/DraftPenjualan" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={DraftLogo} width="40px" height="40px" alt="DraftPenjualan"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Draft Penjualan</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/historyPembelian" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={HistoryPembelian} width="40px" height="40px" alt="RiwayatPembelian"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Riwayat Pembelian</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/historyPenjualan" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={HistoryPenjualan} width="40px" height="40px" alt="RiwayatPenjualan"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Riwayat Penjualan</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                </div>
                <div>
                {openHutang===true?modulHutang:null}
                <Link to="/returbarang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                        <div>
                            <img src={ReturBarang} width="40px" height="40px" alt="ReturBarang"/>
                        </div>
                        <div>
                            <Typography style={{color:'GrayText',fontSize:'12px'}}>Retur Barang</Typography>
                        </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/filterbarang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={FilterBarangMenu} width="40px" height="40px" alt="Filter Barang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Filter Barang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                <Link to="/datapengeluarangudang" style={{textDecoration:'none'}}>
                    <Button className={classes.button} variant="outlined" color='primary'>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div>
                                <img src={RPengeluaranGudang} width="40px" height="40px" alt="RiwayatPengeluarangGudang"/>
                            </div>
                            <div>
                                <Typography style={{color:'GrayText',fontSize:'12px'}}>Pengeluaran Gudang</Typography>
                            </div>
                        </div>
                    </Button>
                </Link>
                </div>
            </div>
    )
    const kasirMenu = (
        <Link to="/stok" style={{textDecoration:'none'}}>
            <Button className={classes.button} variant="outlined" color='primary'>
            <div style={{display:'flex',flexDirection:'column'}}>
                <div>
                    <img src={StokBarang} width="40px" height="40px" alt="StokBarang"/>
                </div>
                <div>
                    <Typography style={{color:'GrayText',fontSize:'12px'}}>Stok Barang</Typography>
                </div>
                </div>
            </Button>
        </Link>
    )
    return (
        <div className={classes.root}>
            <div className={classes.subJudul}>
            <Typography variant='h5'>{subJudul}</Typography>
            <Divider />
            </div>
            <div className={classes.switchArea}>
                <div className={classes.areaSwitch}>
                    <Typography style={{display:'flex',alignItems:'center',marginRight:'10px'}}>Menu Panel
                    <FormControlLabel style={{marginLeft:'1px'}} 
                        control={<Switch checked={stateCheck} onChange={handleStateChacke} name="checkedA" color='primary' 
                        disabled={level === "Pemilik" ? false:true}/>}
                        label="Analisa Data"
                    />
                    </Typography>
                </div>
                <div className={classes.areaSwitchRight}>
                <FormControlLabel
                    control={<Switch checked={stateCheckPO} onChange={handleStateChackePO} name="checkedB" color='primary' 
                    disabled={false}/>}
                    label="PO Order"
                />
                </div>
            </div>
            {subJudul==="Menu Panel"? level === "Pemilik" ? menuPanel:kasirMenu : subJudul==="PO Order" ? <PoOrderPage/> : <ViewMinStok/>}
        </div>
    )
}

export default MenuPage
