import axios from "axios";
import { apiUrl } from "../..";

export const setSearchPartner = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH PARTNER',
        payload :search
    })
}
export const getAllPartner = (search,page,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PARTNER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/partner/?search=${search}&page=${page}&perPage=50`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'UPDATE DATA PARTNER',
            payload:ResponseAPI.data
        })
        dispatch({
            type:'UPDATE PAGE PARTNER',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_partner/ResponseAPI.per_page)
            }
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:'Disconnect from server'
            })
        }
    })
}
export const getDetailsPartner = (idpartner,token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PARTNER'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/partner/${idpartner}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI =results.data;
        dispatch({
            type:'SET FORM PARTNER',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED ACTION PARTNER',
                    message:'Disconnect from server'
            })
        }
    })
}
export const setFillFormPartner = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY FORM PARTNER'
    })
}
export const setEmptyPartnerMessage = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY MESSAGE PARTNER'
    })
}
export const setNewIDPartner = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PARTNER',
        payload:true
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/partner/getid`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI =results.data;
        dispatch({
            type:'UPDATE NEW ID PARTNER',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                type:'SET FAILED ACTION PARTNER',
                message:'Disconnect from server'
            })
        }
    })
}
export const addNewPartner = (data,token,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:'FECTH ACTION PARTNER'
    })

    axios.post(`${apiUrl.url}v1/suryaharapan/partner/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SET SUCESSED PARTNER ACTION',
            message:ResponseAPI.message
        })
        setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:ResponseError.message,
                })
        }else if(error.request){
            dispatch({
                type:'SET FAILED ACTION PARTNER',
                message:'Disconnect from server',
            })
        }
    })
}
export const editPartner = (data,id,token,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'FECTH ACTION PARTNER',
    })
    axios.patch(`${apiUrl.url}v1/suryaharapan/partner/${id}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED PARTNER ACTION',
            message:ResponseAPI.message
            })
            setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:'Disconnect from server'
            })
        }
    })
}
export const deletePartner = (id,token,setOpenSnackbar) =>(dispatch)=>{
    dispatch({
        type:'FECTH ACTION PARTNER',
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/partner/${id}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED PARTNER ACTION',
            message:ResponseAPI.message
            })
            setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:'Disconnect from server'
            })
        }
    })
}

export const filledPartnerCombo = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PARTNER',
        payload:true
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/partner/combo`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'UPDATE DATA PARTNER',
            payload:ResponseAPI.data
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED ACTION PARTNER',
                    message:'Disconnect from server'
            })
        }
    })
}