import { Button, Dialog, DialogContent, makeStyles, Typography} from '@material-ui/core'
import React from 'react'

const SingleDialog = ({handleCloseDialog,handleClick,message,openDialog,okCommand,...rest}) => {
    const useStyle = makeStyles({
        root:{
            width:'100%'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            padding:"5px",
        }
    })
    const classess = useStyle();
    return (
        <div className={classess.root}>
            <Dialog open={openDialog} {...rest}>
                <DialogContent>
                    <Typography>{message}</Typography>
                    <div className={classess.button}>
                        <Button variant="contained" color="primary" onClick={handleClick}>{okCommand}</Button>
                        <Button onClick={handleCloseDialog} variant="contained" color="secondary">Keluar</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SingleDialog
