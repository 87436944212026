import axios from 'axios';
import { apiUrl } from '../..';

export const getAllPengguna = (token) => (dispatch) =>{
    dispatch({
        type:"FETCH DATA PENGGUNA"
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/auth/getallpengguna`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:"SUCCESSED GET DATA PENGGUNA",
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}

export const resetIdentifierPengguna = (username,token,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:"FETCH DATA PENGGUNA"
    })
    const data={
        'identifier':""
    }
    axios.patch(`${apiUrl.url}v1/suryaharapan/auth/resetidentifier/${username}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(results=>{
        const ResponseAPI=results.data;
        dispatch({
            type:"SUCCESSED RESET IDENTIFIER PENGGUNA",
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:error.response.data.message
                })
            }
        }else if(error.request){
                 dispatch({
                    type:'FAILED GET DATA PENGGUNA',
                    fetchPayload:'Disconnect from server'
            })
        }
    })

}