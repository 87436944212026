const initialValueRiwayatPenjualan ={
    dataRiwayatPenjualan : [],
    dataRiwayatPenjualanDetails:[],
    isLoadingRiwayatPenjualan:false,
    messageRiwayatPenjualan:'',
    details:{
        diskon:'',
        ongkir:'',
        total:''
    }
}

const riwayatPenjualanReducers = (state=initialValueRiwayatPenjualan,action)=>{
    switch (action.type) {
        case 'FETCH RIWAYAT PENJUALAN':{
            return{
                ...state,
                isLoadingRiwayatPenjualan:true
            }
        }
        case 'UPDATE DATA RIWAYAT PENJUALAN':{
            return{
                ...state,
                isLoadingRiwayatPenjualan:false,
                dataRiwayatPenjualan:action.payload
            }
        }
        case 'SUCCESSED GET DATA DETAILS PENJUALAN':{
            return{
                ...state,
                dataRiwayatPenjualanDetails:action.payload,
                details:action.details
            }
        }
        case 'FAILED RIWAYAT PENJUALAN':{
            return{
                ...state,
                isLoadingRiwayatPenjualan:false,
                messageRiwayatPenjualan:action.payload,
                details:''
            }
        }
        case 'EMPTY RIWAYAT PENJUALAN':{
            return{
                ...state,
                dataRiwayatPenjualan:[]
            }
        }
        default:
            return state;
    }
}

export default riwayatPenjualanReducers;