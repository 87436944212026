import axios from 'axios';
import { apiUrl } from '../..';

export const postHargaJual = (idbarang,hargajual,hargajual2,modalprogram,hargaprogram,token) => (dispatch)=>{
    dispatch({
        type:'FETCH HARGA JUAL'
    })
    const data =new URLSearchParams();
    data.append('idbarang',idbarang);
    data.append('hargajual',hargajual);
    data.append('hargajual2',hargajual2);
    data.append('modalprogram',modalprogram);
    data.append('hargaprogram',hargaprogram);
    axios.post(`${apiUrl.url}v1/suryaharapan/harga/`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS POST HARGA JUAL',
            payload:ResponseAPI.message,
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED POST HARGA JUAL',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED POST HARGA JUAL',
                    payload:'Disconnect from server'
            })
        }
    })
}