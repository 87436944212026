import { makeStyles, Divider,Typography,Button, Fab} from '@material-ui/core';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import React, { useEffect, useState , useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {useParams} from 'react-router-dom';
import { refreshPrintPembelian } from '../../../config/redux/action';
import { useReactToPrint } from 'react-to-print';
import { DebtPayment } from '..';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PrintFakturTransaksi = React.forwardRef ((props,ref) => {
    const useStyle = makeStyles((theme)=>({
        root:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        mainFaktur:{
            width:'21.59cm',
            marginTop:'30px'
        },
        content:{
            display:'flex',
            width:'98%',
            flexDirection:'column',
            alignItems:'center',
            borderBottom:'1px dotted black',
        },
        tableBody:{
            height:'100%',
            textAlign:'center',
        },
        table:{
            marginTop:'3px',
            width:'100%',
        },
        tableRetur:{
            marginTop:'10px',
            width:'100%',
        },
        tableContainer:{
            height:'100%',
            width:'100%',
            textAlign:'center',
            borderBottom:'1px dotted black'
        },
        tableHead:{
            borderTop:'1px dotted black',
            borderBottom:'1px dotted black'
        },
        mainWapper:{
            width:'98%',
            display:'flex',
            marginBottom:'0px',
            justifyContent:'space-between',
            marginTop:'5px',
        },
        subHeaderLeft:{
            width:'300px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            paddingLeft:'20px'
        },
        subHeaderRight:{
            width:'250px',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-end',
            paddingRight:'20px'
        },
        subTitle:{
            width:'100%',
            display:'flex',
        },
        contentBottom:{
            width:'98%',
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
            borderBottom:'1px dotted black',
        },
        contentTransaksiBottom:{
            width:'97%',
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
        },
        titleContentBottom:{
            width:'80px',
            textAlign:'right',
        },
        buttonLast:{
            display:'flex',
            flexDirection:'column',
            marginTop:'20px'
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        },
        mainContent:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            paddingLeft:'10px'
        }
    }));
    const {data} = useSelector(state => state.loginReducers);
    const {pembelianToPrint} = useSelector(state => state.pembelianReducers);
    const {payment}=pembelianToPrint;
    const [token,setToken] = useState(data.token);
    const [grandTotal,setGrandTotal]=useState(0);
    const [openHutang,setOpenHutang]=useState(false);
    const [totalPembayaran,setTotalPembayaran]=useState(0);
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const {FFORM,idpembelian}=useParams();
    useEffect(() => {
        if(pembelianToPrint===""){
            dispatch(refreshPrintPembelian(idpembelian,token,FFORM));
        }else if(pembelianToPrint!==""){
            let tempTotalPembayaran=payment.reduce((Total,TotalP)=>{
                return Total + (TotalP.pembayaran)
            },0)
            setTotalPembayaran(tempTotalPembayaran);
            setGrandTotal(pembelianToPrint.totalhutang-tempTotalPembayaran);
        }
    }, [FFORM,idpembelian,grandTotal,dispatch,payment,totalPembayaran,token,pembelianToPrint])
      const classes = useStyle();
      const componentRef = useRef();
      const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
      });
      const handleClickBayar = () =>{
        setOpenHutang(true);
      }
      const handleClickPrint = () =>{
        handlePrint();
      }
      const handleClosePembayaran = () =>{
        setOpenHutang(false);
      }
      const handleCloseBayar = () =>{
        setOpenHutang(false);
        dispatch(refreshPrintPembelian(idpembelian,token,FFORM));
        setTimeout(()=>{
            handlePrint();
            history.goBack();
        },1000)
      }
    
      const bottomFiledTransaksi = (
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Total Transaksi</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(pembelianToPrint.totalhutang) || '0'}</strong></Typography>
            </div>
        </div>
      )
      const bottomFiledPembayaran = (
        payment!==undefined?
        payment.map(((payments,idx)=>(
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Pembayaran ke {idx+1}</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(payments.pembayaran) || '0'}</strong></Typography>
            </div>
        </div>
        ))):
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Pembayaran ke 1</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>0</strong></Typography>
            </div>
        </div>
      )
      const bottomFiledGrandTotal = (
        <div className={classes.contentTransaksiBottom}>
            <div>
                <Typography variant="body1">Grand Total</Typography>
            </div>
            <div>
                <Typography variant="body1">:</Typography>
            </div>
            <div className={classes.titleContentBottom}>
                <Typography variant="body1" fontFamily='Raleway'><strong>{new Intl.NumberFormat().format(grandTotal) || '0'}</strong></Typography>
            </div>
        </div>
      )
      const headerTransaksi = (
        <div className={classes.mainWapper}>
            <div className={classes.subHeaderLeft}>
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">No Faktur</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{pembelianToPrint.idpembelian || ''}</Typography></div>
                </div>
                <div className={classes.subTitle}>
                <div><Typography variant="body1">Partner</Typography> </div>
                <div><Typography variant="body1">:</Typography></div>
                <div><Typography variant="body1"><strong>{pembelianToPrint.namaperusahaan  || ''}</strong></Typography></div>
                </div>
            </div>
            <div className={classes.subHeaderRight}>
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">Tanggal</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{pembelianToPrint.tanggal !== undefined ? format(new Date(pembelianToPrint.tanggal),'dd MMM yyyy') : ''}</Typography></div>
                </div>
                {pembelianToPrint.jatuhtempo !== undefined ?
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">Jatuh Tempo</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{format(new Date(pembelianToPrint.jatuhtempo),'dd MMM yyyy')}</Typography></div>
                </div>:
                null}
            </div>
        </div>
      )
      const mainTransaksi = (
        <div className={classes.mainWapper}>
            <div className={classes.mainContent}>
                <div className={classes.subTitle}>
                    <div><Typography variant="body1">Jenis Hutang</Typography></div>
                    <div><Typography variant="body1">:</Typography></div>
                    <div><Typography variant="body1">{pembelianToPrint.jenishutang || ''}</Typography></div>
                </div>
                <div className={classes.subTitle}>
                <div><Typography variant="body1">Keterangan</Typography> </div>
                <div><Typography variant="body1">:</Typography></div>
                <div><Typography variant="body1"><strong>{pembelianToPrint.keterangan  || ''}</strong></Typography></div>
                </div>
            </div>
        </div>
      )
    return (
        <div className={classes.root}>
            <div className={classes.mainFaktur} ref={componentRef}>
                <Typography variant='h6' align='center'>{<strong>FAKTUR TRANSAKSI</strong>} </Typography>
                <Divider variant='middle'/>
                {headerTransaksi}
                <div className={classes.content}>
                    {mainTransaksi}
                </div>
                {bottomFiledTransaksi}
                {bottomFiledPembayaran}
                {bottomFiledGrandTotal}
            </div>
            <div className={classes.buttonLast}>
                {FFORM==='THP' ? <Button variant='contained' color='primary' 
                onClick={handleClickPrint}
                >Print</Button>:
                <Button variant='contained' color='primary' 
                onClick={handleClickBayar}
                >Bayar</Button>}
            </div>
            <DebtPayment open={openHutang} handleClickBayar={handleCloseBayar} HandleClickCloseDialog={handleClosePembayaran}/>
            <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>history.goBack()}>
                <ArrowBackIcon/>
            </Fab>
        </div>
    )
})

export default PrintFakturTransaksi