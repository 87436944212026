import axios from 'axios';
import { apiUrl } from '../..';

export const fillNoFaktur = () => (dispatch) =>{
    dispatch({
        type:'FILL NO FAKTUR'
    })
}

export const setNoFaktur = (tanggal,token) => (dispatch) =>{
    dispatch({
        type:'FETCH NO FAKTUR',
        payload:tanggal
    })
    const data = new URLSearchParams();
    data.append('tanggal',tanggal);
    axios.post(`${apiUrl.url}v1/suryaharapan/pembelian/getID`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'SET NO FAKTUR',
            payload:ResponseAPI.data,
            message:ResponseAPI.message
        })
    })
}
export const addCartBarangMasuk = (idbarang,namabarang,jumlah,satuan,modal,subtotal,stokdiskon,diskonprogram) =>(dispatch)=>{
    const data={
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'satuan':satuan,
        'modal':modal,
        'subtotal':subtotal,
        'stokdiskon':stokdiskon,
        'diskonprogram':diskonprogram
    }
    dispatch({
        type:'ADD CART BARANG MASUK',
        payload:data,
        messagePayload:'Berhasil menambahkan data ke daftar'
    })
}
export const failedAddCartBarangMasuk = () =>(dispatch)=>{
    dispatch({
        type:'FAILED ADD CART BARANG MASUK',
        messagePayload:'Data barang sudah terdapat dalam daftar'
    })
}
export const setMessageAddToCart = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE',
        messagePayload:message
    })
}
export const setMessageSave = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE SAVE',
        messagePayload:message
    })
}
export const addCartReturBarang = (idbarang,namabarang,jumlah,modal,subtotal,ref) =>(dispatch)=>{
    const data = {
        'idbarang':idbarang,
        'namabarang':namabarang,
        'jumlah':jumlah,
        'modal':modal,
        'subtotal':subtotal,
        'ref':ref
    }
    dispatch({
        type:'ADD CART RETUR BARANG',
        payload:data,
        messagePayload:'Berhasil menambahkan data ke daftar'
    })
}
export const failedAddCartBarangRetur = () =>(dispatch)=>{
    dispatch({
        type:'FAILED ADD CART BARANG RETUR',
        messagePayload:'Data barang sudah terdapat dalam daftar'
    })
}
export const setMessageAddToRetur = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE RETUR',
        messagePayload:message
    })
}
export const getAllDataBarangMasuk = () =>(dispatch)=>{
    dispatch({
        type:'GET DATA BARANG MASUK'
    })
}
export const getAllDataReturBarang = () =>(dispatch)=>{
    dispatch({
        type:'GET DATA RETUR BARANG'
    })
}
export const PostPembelianToAPI = (data,token,formik,setCheck,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING SAVE PEMBELIAN',
        payload:true
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/pembelian/savepembelian`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS SAVE PEMBELIAN',
            payload:ResponseAPI.message,
            })
        dispatch({
            type:'EMPTY TABLE',
            emptyBarangMasuk:[],
            returBarang:[]
        })
        dispatch({
            type:'FILL NO FAKTUR'
        })
        formik.resetForm();
        setCheck(false);
        setOpenSnackbar(true);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:ResponseError.message
                })
            setOpenSnackbar(true);
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const PostPembelianWithReturToAPI = (data,retur,token,formik,setCheck,setOpenSnackbar)=>(dispatch)=>{
    dispatch({
        type:'SET LOADING SAVE PEMBELIAN'
    })

    axios.post(`${apiUrl.url}v1/suryaharapan/pembelian/savepembelian`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESS SAVE PEMBELIAN',
            payload:ResponseAPI.message,
            })
            axios.post(`${apiUrl.url}v1/suryaharapan/retursupplier/saveretur`,retur,{
                headers:{
                    'Authorization':`Bearer ${token}`
                }
            })
            .then(res=>{
                const ResponseAPI = res.data;
                dispatch({
                    type:'SUCCESS SAVE RETUR SUPPLIER',
                    payload:false,
                    message:ResponseAPI.message,
                    })
                dispatch({
                    type:'EMPTY TABLE',
                    emptyBarangMasuk:[],
                    returBarang:[]
                })
                dispatch({
                    type:'FILL NO FAKTUR'
                })
                formik.resetForm();
                setCheck(false);
                setOpenSnackbar(true);
            })
            .catch(error=>{
                if(error.response){
                    const ResponseError = error.response.data;
                    dispatch({
                            type:'FAILED SAVE RETUR SUPPLIER',
                            payload:false,
                            message:ResponseError.message,
                        })
                    setOpenSnackbar(true);
                }else if(error.request){
                    dispatch({
                            type:'FAILED SAVE RETUR SUPPLIER',
                            payload:false,
                            message:'Disconnect from server',
                    })
                    setOpenSnackbar(true);
                }
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const fillTable =()=> (dispatch) =>{
    dispatch({
        type:'EMPTY TABLE',
        emptyBarangMasuk:[],
        returBarang:[]
    })
}

export const getDataForPrintPembelian = (idpembelian,token,setOpenSnackbar,view) =>(dispatch) =>{
    dispatch({
        type:'SET LOADING SAVE PEMBELIAN',
        payload:true
    })
    dispatch({
        type:'EMPTY PRINT PEMBELIAN'
        })
        dispatch({
            type:"SET RETUR SUPPLIER TO PRINT",
            payload:''
        })
    axios.get(`${apiUrl.url}v1/suryaharapan/pembelian/getforprint/${view}/${idpembelian}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET UPDATE PRINT PEMBELIAN',
            payload:ResponseAPI.data,
            })
        dispatch({
            type:'SET RETUR SUPPLIER TO PRINT',
            payload:ResponseAPI.dataRetur
        })
       
        // history.push(`/PrintPembelian/${idpembelian}`);
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:ResponseError.message
                })
            setOpenSnackbar(true);
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}
export const refreshPrintPembelian = (idpembelian,token,view,history) =>(dispatch) =>{
    dispatch({
        type:'SET LOADING SAVE PEMBELIAN',
        payload:true
    })
    dispatch({
        type:'EMPTY PRINT PEMBELIAN'
        })
    axios.get(`${apiUrl.url}v1/suryaharapan/pembelian/getforprint/${view}/${idpembelian}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET UPDATE PRINT PEMBELIAN',
            payload:ResponseAPI.data,
            })
        if(view==="PH"){
            dispatch({
                type:'SET RETUR SUPPLIER TO PRINT',
                payload:ResponseAPI.dataRetur
            })
        }
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:'Disconnect from server'
            })
        }
    })
}

export const deletePembelian = (idpembelian,token,setOpenSnackbar,HandleClickCloseDeleteDialog) =>(dispatch) =>{
    dispatch({
        type:'SET LOADING SAVE PEMBELIAN',
        payload:true
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/pembelian/${idpembelian}`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESS SAVE PEMBELIAN',
            payload:ResponseAPI.message
        })
        setOpenSnackbar(true);
        HandleClickCloseDeleteDialog();
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:ResponseError.message
                })
            setOpenSnackbar(true);
        }else if(error.request){
            dispatch({
                    type:'FAILED SAVE PEMBELIAN',
                    payload:'Disconnect from server'
            })
            setOpenSnackbar(true);
        }
    })
}