import { makeStyles, TextField, Typography } from '@material-ui/core'
import React from 'react'

const Search = ({title,id,label,justifyContent,...rest}) => {
    const useStyles = makeStyles({
        root:{
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-end',
        },
        textFiled:{
            maxWidth:"400px",
            marginRight:"5%",
        },
    })
    const classes = useStyles();
    return (
        <form className={classes.root} noValidate autoComplete="off">
            <Typography variant="body1">{title}</Typography>
            <TextField size='small' className={classes.textFiled} id={id} label={label} {...rest}/>
        </form>
    )
}

export default Search
