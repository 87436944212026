import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { jenisBarangPutToAPI } from '../../../config/redux/action';
import { InputFiled } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@material-ui/lab/Alert';


const JenisBarangEditForm = ({HandleClickCloseEditDialog,...rest}) => {
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {data} = useSelector(state=>state.loginReducers);
    const {isLoadingJenisBarangSave,PostJenisBarangStatus,fetchMessageJenisBarang,FormJenisBarang} = useSelector(state=>state.jenisbarangReducers);
    const {idjenisbarang,jenisbarang} = FormJenisBarang;
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
    const validationSchema = yup.object().shape({
        jenisbarang:yup.string().required("Jenis barang tidak boleh kosong")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            jenisbarang:jenisbarang
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('jenisbarang',values.jenisbarang);
            dispatch(jenisBarangPutToAPI(data,idjenisbarang,token));
            setOpenSnackbar(true);
        }
    })
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="edit-jenisbarang-dialog">Ubah Jenis Barang</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idjenisbarang" 
                name="idjenisbarang" 
                label="ID jenis barang" 
                disabled={true} 
                value={idjenisbarang}/>
                <InputFiled 
                id="jenisbarang" 
                name="jenisbarang" 
                label="Jenis Barang"
                value={formik.values.jenisbarang}
                error={formik.touched.jenisbarang && Boolean(formik.errors.jenisbarang)}
                helperText={formik.touched.jenisbarang && formik.errors.jenisbarang}
                onChange={(e)=>formik.setFieldValue('jenisbarang',e.target.value.toUpperCase())}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={PostJenisBarangStatus==='Successed' ? true : isLoadingJenisBarangSave}>Ubah</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseEditDialog} disabled={PostJenisBarangStatus==='Successed' ? true : false}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {fetchMessageJenisBarang==='Perubahan data jenis barang berhasil' ?
                    <Alert onClose={handleCloseSnackBar} severity="success" onClick={HandleClickCloseEditDialog}>{fetchMessageJenisBarang}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error">{fetchMessageJenisBarang}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default JenisBarangEditForm
