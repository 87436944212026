const initialStateBarang = {
    dataBarang : [],
    pageBarang : {
        currentPage:'1',
        totalPage:'1'
    },
    FormBarang:{},
    search : '',
    fetchMessage : '',
    isLoadingDataBarang : false,
    perPage:'1',
    newIDBarang:'',
    isLoadingNewID:false,
    PostBarangStatus:'',
    isLoadingBarangSave:false,
    DetailsBarang:[]
}

const barangReducers = (state=initialStateBarang,action) =>{
    switch(action.type){
        case 'FETCH DATA BARANG':{
            return{
                ...state,
                isLoadingDataBarang:action.payload,
            }
        }
        case 'SET SEARCH TEXT':{
            return{
                ...state,
                search : action.payload
            }
        }
        case 'UPDATE PAGE BARANG':{
            return {
                ...state,
                pageBarang:action.payload
            }
        }
        case 'UPDATE DATA BARANG':{
            return{
                ...state,
                isLoadingDataBarang:false,
                dataBarang:action.payload,
                fetchMessage:action.fetchPayload
            }
        }
        case 'FAILED FETCH BARANG':{
            return{
                ...state,
                isLoadingDataBarang:false,
                fetchMessage:action.fetchPayload
            }
        }
        case 'FETCH NEW ID BARANG':{
            return{
                ...state,
                isLoadingNewID:action.payload
            }
        }
        case 'UPDATE NEW ID BARANG':{
            return{
                ...state,
                isLoadingNewID:false,
                newIDBarang:action.payload,
                fetchMessage:action.fetchPayload
            }
        }
        case 'FAILED GET NEW ID BARANG':{
            return{
                ...state,
                isLoadingNewID:false,
                fetchMessage:action.payload
            }
        }
        case 'FECTH BARANG POST':{
            return{
                ...state,
                isLoadingBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED BARANG POST':{
            return{
                ...state,
                isLoadingBarangSave:false,
                PostBarangStatus:action.payload,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET FAILED ADD BARANG':{
            return{
                ...state,
                PostBarangStatus:action.payload,
                isLoadingBarangSave:false,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET EDIT BARANG':{
            return{
                ...state,
                FormBarang:action.payload
            }
        }
        case 'FECTH BARANG PATCH':{
            return{
                ...state,
                isLoadingBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED BARANG PATCH':{
            return{
                ...state,
                isLoadingBarangSave:false,
                PostBarangStatus:action.payload,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET FAILED EDIT BARANG':{
            return{
                ...state,
                PostBarangStatus:action.payload,
                isLoadingBarangSave:false,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET FETCH STATUS BARANG':{
            return{
                ...state,
                fetchMessage:action.payload
            }
        }
        case 'FECTH BARANG DELETE':{
            return{
                ...state,
                isLoadingBarangSave:action.payload,
            }
        }
        case 'SET SUCESSED BARANG DELETE':{
            return{
                ...state,
                isLoadingBarangSave:false,
                PostBarangStatus:action.payload,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET FAILED DELETE BARANG':{
            return{
                ...state,
                PostBarangStatus:action.payload,
                isLoadingBarangSave:false,
                fetchMessage:action.PostPayload
            }
        }
        case 'SET ADD BARANG TO CART':{
            return{
                ...state,
                FormBarang:action.payload
            }
        }
        case 'SET FILL BARANG TO CART':{
            return{
                ...state,
                FormBarang:action.payload
            }
        }
        case 'SET EMPTY MESSAGE':{
            return{
                ...state,
                PostBarangStatus:action.payload
            }
        }
        case 'SET EMPTY NEWIDBARANG':{
            return{
                ...state,
                newIDBarang:action.payload
            }
        }
        case 'GET DETAILS BARANG':{
            return{
                ...state,
                DetailsBarang:action.payload
            }
        }
        default:
            return state
    }
}

export default barangReducers