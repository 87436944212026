import { Divider, Fab, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Search } from '../../component/molecules';
import { setDataJenisBarang, setEditJenisBarang, setFetchStatusJenis, setNewIDJenisBarang, setSearchJenisBarang } from '../../config/redux/action';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import JenisBarangForm from '../../component/organism/JenisBarangForm';
import { JenisBarangEditForm } from '../../component/organism';
import JenisBarangDeleteForm from '../../component/organism/JenisBarangDeleteForm';

const JenisBarang = () => {
    const {datajenisbarang,searchJenisBarang,pageJeniBarang} = useSelector(state=>state.jenisbarangReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const dispatch = useDispatch();
    const [open,setOpen] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [openDelete,setOpenDelete] = useState(false);
    const [counter,setCounter] = useState(1);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataJenisBarang(searchJenisBarang,counter,token))
    },[searchJenisBarang,counter,token,dispatch])
    const focus = () =>{
        setCounter(1);
        dispatch(setDataJenisBarang(searchJenisBarang,counter,token));
    }
    const handlePrev=()=>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageJeniBarang.totalPage ? pageJeniBarang.totalPage : counter + 1);
    }
    const HandleClickCloseDialog = () =>{
        setOpen(false);
        setCounter(1);
        dispatch(setDataJenisBarang(searchJenisBarang,counter,token));
    }
    const HandleClickOpenDialog = () =>{
        setOpen(true);
        dispatch(setSearchJenisBarang(''));
        dispatch(setNewIDJenisBarang(token));
    }
    const HandleClickCloseEditDialog = () =>{
        setOpenEdit(false);
        setCounter(1);
        dispatch(setDataJenisBarang(searchJenisBarang,counter,token));
        dispatch(setEditJenisBarang("",""));
    }
    const HandleClickOpenEditDialog = (idjenisbarang,jenisbarang) =>{
        setOpenEdit(true);
        dispatch(setEditJenisBarang(idjenisbarang,jenisbarang));
        dispatch(setSearchJenisBarang(''));
        dispatch(setFetchStatusJenis(''));
    }
    const HandleClickCloseDeleteDialog = () =>{
        setOpenDelete(false);
        setCounter(1);
        dispatch(setDataJenisBarang(searchJenisBarang,counter,token));
        dispatch(setEditJenisBarang("",""));
    }
    const HandleClickOpenDeleteDialog = (idjenisbarang,jenisbarang) =>{
        setOpenDelete(true);
        dispatch(setEditJenisBarang(idjenisbarang,jenisbarang));
        dispatch(setSearchJenisBarang(''));
        dispatch(setFetchStatusJenis(''));
    }
    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        tableWapper:{
            display:'flex',
            flexDirection:'column',
            maxWidth:'90%',
            marginLeft:'5%',
            alignItems:'center',
        },
        table:{
            marginTop:'20px',
            [theme.breakpoints.up('md')]:{
                width:'50%',
            },
            width:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            flexDirection:'row',
            alignContent:'space-between'
        },addIcon:{
            paddingRight:'10px'
        }
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h5">Data Jenis Barang</Typography>
                <Divider/>
                <Search label="Cari Jenis Barang" value={searchJenisBarang} name="cari-jenis-barang" id="cari-jenis-barang" onFocus={focus} onChange={(e)=>dispatch(setSearchJenisBarang(e.target.value))}/>
                <div className={classes.tableWapper}>
                    <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table jenis barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID</SytledTableCell>
                                        <SytledTableCell width='25%' align="center">Jenis Barang</SytledTableCell>
                                        <SytledTableCell width='5%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datajenisbarang.map((dataJenisBarangs)=>(
                                        <StyledTableRow key={dataJenisBarangs.idjenisbarang}>
                                            <SytledTableCell align="center">{dataJenisBarangs.idjenisbarang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataJenisBarangs.jenisbarang}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                <Tooltip title="Ubah">
                                                    <IconButton size='small' onClick={()=>HandleClickOpenEditDialog(dataJenisBarangs.idjenisbarang,dataJenisBarangs.jenisbarang)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                 </Tooltip>
                                                <Tooltip title="Hapus">
                                                    <IconButton size='small' onClick={()=>HandleClickOpenDeleteDialog(dataJenisBarangs.idjenisbarang,dataJenisBarangs.jenisbarang)}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </SytledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                            <div className={classes.page}>
                            <Tooltip title="Prev">
                                <IconButton size='small' onClick={handlePrev}>
                                    <NavigateBefore/>
                                </IconButton>
                            </Tooltip>
                            <Typography className={classes.textPage}>{pageJeniBarang.currentPage} / {pageJeniBarang.totalPage >= 1 ? pageJeniBarang.totalPage : pageJeniBarang.currentPage}</Typography>
                            <Tooltip title="Next">
                                <IconButton size='small' onClick={handleNext}>
                                    <NavigateNext/>
                                </IconButton>
                            </Tooltip> 
                            </div>
                            <div className={classes.addIcon}>
                                <Tooltip title="Tambah jenis barang">
                                    <Fab size="small" onClick={()=>{HandleClickOpenDialog()}} color="primary" aria-label="add">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                                <JenisBarangForm open={open} aria-labelledby="new-jenisbarang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                                <JenisBarangEditForm open={openEdit} aria-labelledby="ubah-jenisbarang-dialog" HandleClickCloseEditDialog={HandleClickCloseEditDialog}/>
                                <JenisBarangDeleteForm open={openDelete} aria-labelledby="delete-jenisbarang-dialog" HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}/>
                            </div>
                        </div>
                    </Paper>
                 </div>
            </div>
        </div>
    )
}

export default JenisBarang
