const initialStateTransferBarang ={
    FormTransfer : [],
    tempTransferBarang :[],
    isLoadingTransfer : false,
    transferMessage : '',
    tempIdTransfer:'',
    transferBarangList:[],
    transferListMessage:'',
    formTransferDelete:{}
}
const transferBarangReducer = (state =initialStateTransferBarang,action)=>{
    switch(action.type){
        case 'SET FROM TRANSFER':{
            return{
                ...state,
                FormTransfer:[...state.FormTransfer,action.payload],
                tempTransferBarang:[...state.tempTransferBarang,action.tempTransfer],
                transferMessage:action.message
            }
        }
        case 'UPDATE TEMP TRANSFER':{
            return{
                ...state,
                FormTransfer:[...state.FormTransfer,action.payload],
                tempTransferBarang:[...state.tempTransferBarang.map((temp,i)=>
                    i === action.index ? {
                        ...state.tempTransferBarang[action.index], jumlah:action.value
                    }: temp
                )],
                transferMessage:action.message
            }
        }
        case 'UPDATE AFTER REMOVE TRANSFER':{
            return{
                ...state,
                tempTransferBarang:[...state.tempTransferBarang.map((temp,i)=>
                    i === action.index ? {
                        ...state.tempTransferBarang[action.index], jumlah:action.value
                    }: temp
                )]
            }
        }
        case 'SET LOADING TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:true
            }
        }
        case 'FILL EMPTY FORM':{
            return{
                ...state,
                FormTransfer:[],
                tempTransferBarang:[],
            }
        }
        case 'EMPTY MESSAGE':{
            return{
                ...state,
                transferMessage:''
            }
        }
        case 'SET MESSAGE':{
            return{
                ...state,
                transferMessage:action.payload
            }
        }
        case 'SET ID BARANG TRANSFER':{
            return{
                ...state,
                tempIdTransfer:action.payload
            }
        }
        case 'GET DATA TRANSFER LIST':{
            return{
                ...state,
                FormTransfer:[...state.FormTransfer]
            }
        }
        case 'SET LOADING SAVE TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:true
            }
        }
        case 'SUCCESS SAVE TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferMessage:action.payload
            }
        }
        case 'FAILED SAVE PEMBELIAN':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferMessage:action.payload
            }
        }
        case 'FETCH DATA TRANSFER LIST':{
            return{
                ...state,
                isLoadingTransfer:true
            }
        }
        case 'SUCCESSED DATA TRANSFER LIST':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferBarangList:action.payload
            }
        }
        case 'FAILED DATA TRANSFER LIST':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferListMessage:action.payload
            }
        }
        case 'SET TRANSFER LIST MESSAGE':{
            return{
                ...state,
                transferListMessage:action.payload
            }
        }
        case 'SET ARRAY LIST EMPTY':{
            return{
                ...state,
                transferListMessage:[]
            }
        }
        case 'SET FORM TRANSFER DELETE':{
            return{
                ...state,
                formTransferDelete:action.payload
            }
        }
        case 'EMPTY FORM TRANSFER DELETE':{
            return{
                ...state,
                formTransferDelete:[]
            }
        }
        case 'FETCH DELETE TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:true
            }
        }
        case 'SUCCESSED DELETE TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferListMessage:action.payload
            }
        }
        case 'FAILED DELETE TRANSFER':{
            return{
                ...state,
                isLoadingTransfer:false,
                transferListMessage:action.payload
            }
        }
        default:
            return state
    }
}

export default transferBarangReducer;