import { Button, Fab, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useReactToPrint } from 'react-to-print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAllPrintHutang } from '../../../config/redux/action/hutangAction';
import Cookies from 'js-cookie';

const PrintPageHutang = () => {
    const useStyle = makeStyles((theme)=>({
        root:{
            witdh:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        mainFaktur:{
            width:'21.59cm',
            marginTop:'30px',
        },
        content:{
            display:'flex',
            width:'100%',
            // flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'center',
        },
        contentHutang:{
            display:'flex',
            width:'100%',
            // flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'center',
        },
        wapper:{
            height:'90%',
            display:'flex',
            flexDirection:'column',
            width:'100%',
            justifyContent:'space-between',
        },
        wapperContent:{
            display:'flex',
            width:'100%',
            justifyContent:'center',
            marginTop:'5px'
        },
        title:{
            textAlign:'center',
            paddingTop:'5px',
            paddingBottom:'5px',
            borderBottom:'1px dotted black',
        },
        buttonHistory:{
            position: 'fixed',
            bottom: theme.spacing(5),
            left: theme.spacing(3),
        },
        valueTabel:{
            border:'1px solid black',
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        }
    }));
    const classes = useStyle();
    const componentRef = useRef();
    const {tanggal}=useParams();
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    const {dataPrintHutang} = useSelector(state=>state.hutangReducers);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
      });
    const handleClickPrint = () =>{
        handlePrint();
    }
    const history=useHistory();
    const handleClickBack = () =>{
        history.push('/hutang');
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        if(dataPrintHutang.length<1){
            const data={
                'tanggal':tanggal
            }
            dispatch(getAllPrintHutang(data,token,"",""));
        }
    },[dispatch,tanggal,dataPrintHutang.length,token])
    const dataHutang=(
        <div>
        {dataPrintHutang.map((dataHutang,i)=>(
            <div className={classes.contentHutang} key={i}>
                <Typography variant='body1' align='left' style={{width:'25%'}}><b>{dataHutang.namaperusahaan}</b></Typography>
                <Typography variant='body1' align='center' style={{width:'25%'}}><b>{dataHutang.idtransaksi}</b></Typography>
                <Typography variant='body1' align='center' style={{width:'25%'}}><b>{format(new Date(dataHutang.jatuhtempo),'dd MMMM yyyy')}</b></Typography>
                <Typography variant='body1' align='right' style={{width:'25%'}}><b>{new Intl.NumberFormat().format(dataHutang.sisahutang)}</b></Typography>
            </div>
        ))}
        </div>
    )
  return (
    <div className={classes.root}>
        <div className={classes.mainFaktur} ref={componentRef}>
            <div className={classes.title}>
                <Typography variant='body1' ><b>Daftar Hutang Jatuh Tempo</b></Typography>
                <Typography variant='body1' ><b>Sampai Tanggal {format(new Date(tanggal),'dd MMM yyyy')}</b></Typography>
            </div>
            <div className={classes.wapperContent}>
                <div className={classes.wapper}>
                    <div className={classes.content}>
                        <Typography variant='body1' align='center' style={{width:'25%',borderBottom:'1px solid black'}}><b>Supplier</b></Typography>
                        <Typography variant='body1' align='center' style={{width:'25%',borderBottom:'1px solid black'}}><b>Nomor Faktur</b></Typography>
                        <Typography variant='body1' align='center' style={{width:'25%',borderBottom:'1px solid black'}}><b>Jatuh Tempo</b></Typography>
                        <Typography variant='body1' align='center' style={{width:'25%',borderBottom:'1px solid black'}}><b>Tagihan</b></Typography>
                    </div>
                    {dataHutang}
                </div>
            </div>
        </div>
        <Button variant="contained" color="primary" style={{marginTop:'30px'}} onClick={()=>handleClickPrint()}>Cetak Laporan Hutang</Button>
    
        <Fab className={classes.buttonHistory} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
            <ArrowBackIcon/>
        </Fab>
    </div>
  )
}

export default PrintPageHutang