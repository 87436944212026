import { Button, Dialog, DialogContent, DialogTitle, Divider, makeStyles, Snackbar } from '@material-ui/core'
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { InputFiled, JenisBarangCombo, PenyimpananCombo } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@material-ui/lab/Alert';
import { BarangPostToAPI, emptyMessage, emptyIDBarang } from '../../../config/redux/action';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const BarangForm = ({HandleClickCloseDialog,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const {newIDBarang,isLoadingBarangSave,PostBarangStatus} = useSelector(state=>state.barangReducers);
    const dispatch = useDispatch();
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(() => {
            dispatch(emptyMessage(""));
        }, 100);
      };

    const validationSchema = yup.object().shape({
        namabarang:yup.string().required("Nama barang tidak boleh kosong"),
        idjenisbarang:yup.string().required('Jenis barang belum dipilih'),
        satuan:yup.string().required('Satuan barang tidak boleh kosong'),
        idpenyimpanan:yup.string().required('Penyimpanan belum dipilih'),
        jmlh_min:yup.number().required('Jumlah minimum tidak boleh kosong').min(0,"Jumlah minimum tidak boleh kurang dari 0")
    })
    const resetForm = () =>{
        formik.resetForm();
    }
    const formik = useFormik({
        initialValues:{
            namabarang:'',
            idjenisbarang:null,
            satuan:'',
            varian:'',
            idpenyimpanan:'',
            jmlh_min:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idbarang',values.idbarang);
            data.append('namabarang',values.namabarang);
            data.append('idjenisbarang',values.idjenisbarang);
            data.append('satuan',values.satuan);
            data.append('varian',values.varian);
            data.append('idpenyimpanan',values.idpenyimpanan);
            data.append('jmlh_min',values.jmlh_min);
            dispatch(BarangPostToAPI(data,token,setOpenSnackbar,HandleClickCloseDialog,resetForm));
            dispatch(emptyIDBarang());
        }
    })
    const classes=useStyle();
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="new-barang-dialog">Data Barang Baru</DialogTitle>
                <Divider/>
                <DialogContent>
                <InputFiled 
                id="idbarang" 
                name="idbarang" 
                label="ID barang" 
                disabled={true} 
                value={newIDBarang}/>
                <InputFiled 
                id="namasbarang" 
                name="namabarang" 
                label="Nama Barang"
                value={formik.values.namabarang}
                error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                helperText={formik.touched.namabarang && formik.errors.namabarang}
                onChange={(e)=>formik.setFieldValue('namabarang',e.target.value.toUpperCase())}
                />
                <JenisBarangCombo
                error={formik.touched.jenisbarang_id && Boolean(formik.errors.jenisbarang_id)}
                id='idjenisbarang'
                name='idjenisbarang'
                onChange={formik.handleChange}
                value={formik.values.jenisbarang_id}
                helperText={formik.touched.jenisbarang_id && formik.errors.jenisbarang_id}
                />
                <InputFiled 
                id="satuan" 
                name="satuan" 
                label="Satuan"
                value={formik.values.satuan}
                error={formik.touched.satuan && Boolean(formik.errors.satuan)}
                helperText={formik.touched.satuan && formik.errors.satuan}
                onChange={(e)=>formik.setFieldValue('satuan',e.target.value.toUpperCase())}
                />
                <InputFiled 
                id="varian" 
                name="varian" 
                label="Varian"
                value={formik.values.varian}
                error={formik.touched.varian && Boolean(formik.errors.varian)}
                helperText={formik.touched.varian && formik.errors.varian}
                onChange={(e)=>formik.setFieldValue('varian',e.target.value)}
                />
                <PenyimpananCombo
                error={formik.touched.idpenyimpanan && Boolean(formik.errors.idpenyimpanan)}
                id='idpenyimpanan'
                name='idpenyimpanan'
                onChange={formik.handleChange}
                value={formik.values.idpenyimpanan}
                helperText={formik.touched.idpenyimpanan && formik.errors.idpenyimpanan}
                />
                <InputFiled 
                id="jmlh_min" 
                name="jmlh_min" 
                label="Jumlah Minimum"
                value={formik.values.jmlh_min}
                error={formik.touched.jmlh_min && Boolean(formik.errors.jmlh_min)}
                helperText={formik.touched.jmlh_min && formik.errors.jmlh_min}
                onChange={(e)=>formik.setFieldValue('jmlh_min',e.target.value)}
                />
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoadingBarangSave}>Simpan</Button>
                <Button onClick={HandleClickCloseDialog} variant="contained" color="secondary"  disabled={isLoadingBarangSave}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                {PostBarangStatus === 'Data barang baru berhasil disimpan'?
                    <Alert onClose={handleCloseSnackBar} severity="success">{PostBarangStatus}</Alert>:
                    <Alert onClose={handleCloseSnackBar} severity="error">{PostBarangStatus}</Alert>
                }
            </Snackbar>
        </div>
    )
}

export default BarangForm
