import React, { useEffect, useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, TextField, makeStyles } from '@material-ui/core';
import { DateField, InputFiled } from '../../../component/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { filledPartnerCombo } from '../../../config/redux/action/partnerAction';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { getIdHutang, postHutang } from '../../../config/redux/action/hutangAction';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
        thousandSeparator
        isNumericString
        prefix={'Rp '}
      />
    );
  }
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const NewHutang = ({handleCloseNewHutang,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        }
    }));
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };
    const {data} = useSelector(state=>state.loginReducers);
    const {hutangMessage,isLoadingHutang,tempIdHutang,dataAllHutang} = useSelector(state=>state.hutangReducers);
    const {dataPartner}=useSelector(state=>state.partnerReducers);
    const [checkBukti,setCheckBukti]=useState(false);
    const dispatch = useDispatch();
    const [optionCombo,setOptionCombo]=useState([]);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        if(dataPartner.length<1){
            dispatch(filledPartnerCombo(token));
        }else{
            setOptionCombo(dataPartner);
        }   
        if(tempIdHutang===""){
            dispatch(getIdHutang(token));
        }
    },[dataPartner,dispatch,token,tempIdHutang])
    const validationSchema = yup.object().shape({
        namaperusahaan:yup.string().required("Nama perusahaan tidak boleh kosong"),
        tanggal:yup.date().max(new Date(),'Tanggal tidak valid'),
        jatuhtempo:yup.date().min(yup.ref('tanggal'),'Jatuh tempo tidak boleh kurang dari tanggal awal hutang'),
        totalhutang:yup.number().min(1,"Nilai tidak boleh kurang dari 1"),
        keterangan:yup.string().required('Keterangan tidak boleh kurang dari 10 karakter')
    })
    const formik = useFormik({
        initialValues:{
            idpartner:'',
            tanggal:new Date(),
            jenishutang:'',
            keterangan:'',
            jatuhtempo:new Date(),
            totalhutang:0,
            buktiTransaksi:'Belum Terima'
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data={
                'idtransaksi':tempIdHutang,
                'idpartner':values.idpartner,
                'tanggal' :format(values.tanggal,'yyyy-MM-dd HH:mm:ss'),
                'jenishutang':values.jenishutang,
                'keterangan':values.keterangan,
                'jatuhtempo':format(values.jatuhtempo,'yyyy-MM-dd HH:mm:ss'),
                'totalhutang':values.totalhutang,
                'pembayaran':0,
                'notaasli':values.buktiTransaksi
            }
            dispatch(postHutang(data,token,setOpenSnackbar,formik,handleCloseNewHutang));
        }
    })
    const handleChangeTanggal = (value)=>{
        formik.setFieldValue('tanggal',value)
    }
    const handleChangeJatuhTempo = (value)=>{
        formik.setFieldValue('jatuhtempo',value)
    }
    const handleCheck = (e) =>{
        setCheckBukti(!checkBukti);
        if(e.target.checked===true){
            formik.setFieldValue('buktiTransaksi',"Sudah Terima");
        }else if(e.target.checked===false){
            formik.setFieldValue('buktiTransaksi',"Belum Terima");
        }
    }
  return (
    <div>
        <Dialog open={false} {...rest}>
        <DialogTitle className={classes.titleDialog} id="new-partner-dialog">Tambah Hutang</DialogTitle>
            <Divider/>
            <DialogContent>
            <InputFiled
            id="idhutang" 
            name="idhutang" 
            label="ID Hutang" 
            disabled={true} 
            value={tempIdHutang}
            />
            <InputFiled
            id="idtransaksi" 
            name="idtransaksi" 
            label="ID Transaksi" 
            disabled={true} 
            value={tempIdHutang}
            />
            <Autocomplete
                className={classes.textFiled}
                id='namaperusahaan'
                name='namaperusahaan'
                options={optionCombo}
                getOptionLabel={(optionCombo)=>optionCombo.namaperusahaan||""}
                getOptionSelected={(optionCombo)=>optionCombo.idpartner||""}
                onChange={(e,newValue)=>{
                    if(newValue!==null){
                        formik.setFieldValue('namaperusahaan',newValue.namaperusahaan)
                        formik.setFieldValue('idpartner',newValue.idpartner)
                    }else{
                        formik.setFieldValue('namaperusahaan',"")
                        formik.setFieldValue('idpartner',"")
                    }
                }}
                renderInput={(params) => <TextField {...params} 
                id='namaperusahaan'
                label="Nama Perusahaan" 
                variant='outlined'
                value={formik.values.namaperusahaan||''}
                error={formik.touched.namaperusahaan && Boolean(formik.errors.namaperusahaan)}
                helperText={formik.touched.namaperusahaan && formik.errors.namaperusahaan}
                onChange={formik.handleChange}
                 />}
                />
                <DateField 
                    id="tanggal" 
                    name="tanggal" 
                    label="Tanggal" 
                    value={formik.values.tanggal}
                    onChange={(value)=>handleChangeTanggal(value)}
                />
                <FormControl required className={classes.textFiled}>
                    <InputLabel id='jenishutang'>Jenis Hutang</InputLabel>
                    <Select
                    labelId='jenishutang'
                    id='jenishutang'
                    name='jenishutang'
                    value={formik.values.jenishutang} 
                    error={formik.touched.jenishutang && Boolean(formik.errors.jenishutang)}
                    onChange={formik.handleChange}
                    >
                        <MenuItem value="Hutang Usaha">Hutang Usaha</MenuItem>
                        <MenuItem value="Hutang Asuransi">Hutang Asuransi</MenuItem>
                        <MenuItem value="Hutang Lain - lain ">Hutang Lain - lain </MenuItem>
                        <MenuItem value="Biaya Yang Masih Harus Dibayar">Biaya Yang Masih Harus Dibayar</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 21">Hutang Pajak - PPh 21</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 22">Hutang Pajak - PPh 22</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 23">Hutang Pajak - PPh 23</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 26">Hutang Pajak - PPh 26</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 25">Hutang Pajak - PPh 25</MenuItem>
                        <MenuItem value="Hutang Pajak - PPh 29">Hutang Pajak - PPh 29</MenuItem>
                        <MenuItem value="PPN - Keluaran">PPN - Keluaran</MenuItem>
                        <MenuItem value="Hutang Bank - Jangka Panjang">Hutang Bank - Jangka Panjang</MenuItem>
                        <MenuItem value="Hutang Leasing">Hutang Leasing</MenuItem>
                        <MenuItem value="Hutang Prive">Hutang Prive</MenuItem>
                    </Select>
                </FormControl>
            <InputFiled 
            multiline
            id="keterangan" 
            name="keterangan" 
            label="Keterangan"
            value={formik.values.keterangan}
            error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
            helperText={formik.touched.keterangan && formik.errors.keterangan}
            onChange={(e)=>formik.setFieldValue('keterangan',e.target.value)}
            />
            <DateField 
                    id="jatuhtempo" 
                    name="jatuhtempo" 
                    label="Jatuh Tempo" 
                    value={formik.values.jatuhtempo}
                    onChange={(value)=>handleChangeJatuhTempo(value)}
                />
            <InputFiled 
                className={classes.textFiled}
                id="totalhutang" 
                name="totalhutang" 
                label="Total"
                value={formik.values.totalhutang}
                error={formik.touched.totalhutang && Boolean(formik.errors.totalhutang)}
                helperText={formik.touched.totalhutang && formik.errors.totalhutang}
                onChange={(formik.handleChange)}
                InputProps={{
                    style:{fontSize:20,fontWeight:'bold'},
                    inputComponent: NumberFormatCustom
                }}
                />
            <FormControlLabel
                className={classes.textFiled}
                control={<Checkbox 
                    checked={checkBukti} 
                    onChange={(e)=>handleCheck(e)} 
                    name="buktitransaksi" />}
                label="Terima Bukti Transaksi"
            />
            <div className={classes.button}>
            <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={dataAllHutang.length<1 ? false : isLoadingHutang}>Simpan</Button>
            <Button onClick={handleCloseNewHutang} variant="contained" color="secondary">Keluar</Button>
            </div>
            </DialogContent>
        </Dialog>
        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <Alert 
        onClose={handleCloseSnackBar} 
        severity={hutangMessage === 'Data partner baru berhasil disimpan' || 'Perubahan data distributor berhasil' ? "success" :"error"}>
            {hutangMessage}
        </Alert>
        </Snackbar>
    </div>
  )
}

export default NewHutang