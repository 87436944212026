import { Divider,  makeStyles, Paper, Table, TableBody,withStyles, TableCell, TableContainer, TableHead, TableRow, Typography, Button, RadioGroup, FormControlLabel, Radio, IconButton, Tooltip, Box } from '@material-ui/core'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PembayaranCombo, PenyimpananCombo } from '../../molecules';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { addFormOrder, deleteFormOrder, emptyFormOrder, setDataStokFilterMin, setDataStokFilterSemua, setSelectIDPenyimpanan } from '../../../config/redux/action';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import OrderDeleteForm from '../OrderDeleteForm';
import NewOrderForm from '../NewOrderForm';
import { listOrder, ListPendingOrder } from '../../assets';
import { Link } from 'react-router-dom';

const ListStokBarang = React.forwardRef ((props,ref) =>  {
    const useSytle = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center'
        },
        header:{
            display:'flex',
            textAlign:'center',
            flexDirection:'column',
            justifyContent:'center',
            padding:'5px',
        },
        title:{
            paddingTop:'10px',
            textAlign:'center'
        },
        selectWapper:{
            height:'50px',
            display:'flex',
            width:'100%',
            marginTop:'10px',
            justifyContent:'center',
        },
        selectItem:{
            width:'200px',
            height:'50px',
            marginRight:'20px'
        },table:{
            marginLeft:'2%',
            marginTop:'20px',
            maxWidth:'95%',
        },
        buttonPrintWapper:{
            marginTop:'20px',
            marginBottom:'10px',
            width:'100%',
            display:'flex',
            justifyContent:'center'
        },
        radioWapper:{
            display:'flex',
            paddingRight:'100px'
        },
        keteranganOrder:{
            gutterBottom : true
        }
    }))
    const classes = useSytle();
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
      const dataSales =[
        {id:5,tipe:'5 Hari'},
        {id:10,tipe:'10 Hari'},
        {id:15,tipe:'15 Hari'},
        {id:20,tipe:'20 Hari'},
        {id:30,tipe:'30 Hari'},
        {id:45,tipe:'45 Hari'},
        {id:60,tipe:'60 Hari'}
      ]
      const dispatch = useDispatch();
      const {dataStokFilter} = useSelector(state=>state.stokbarangReducers);
      const {data} = useSelector(state => state.loginReducers);
      const [valueRadio,setValueRadio]=useState('Semua');
      const [token,setToken] = useState(data.token);
      const [salesPeriod,setSalesPeriod]=useState(20);
      const [openDelete,setOpenDelete]=useState(false);
      const [openNewOrder,setOpenNewOrder]=useState(false);
        if(token ===''){
            setToken(Cookies.get('u_tkn'));
        }
      const validationSchema = yup.object().shape({
          idpenyimpanan : yup.string().required('Penyimpanan belum dipilih')
      })
      const formik = useFormik({
          enableReinitialize:true,
          initialValues:{
              idpenyimpanan:'L-0001' || ''
          },
          validationSchema:validationSchema,
          onSubmit:(values)=>{
              dispatch(setDataStokFilterSemua(values.idpenyimpanan,salesPeriod,token));
          }
      })
      useEffect(()=>{
          if(formik.values.idpenyimpanan!==""){
              if(valueRadio==='Semua'){
                dispatch(setDataStokFilterSemua(formik.values.idpenyimpanan,salesPeriod,token));
              }else{
                dispatch(setDataStokFilterMin(formik.values.idpenyimpanan,salesPeriod,token));
              }
            // dispatch(setSelectIDPenyimpanan(formik.values.idpenyimpanan))
          }
      },[dispatch,formik.values.idpenyimpanan,token,valueRadio,salesPeriod])
      const HandleOpenAddOrder = (idbarang,namabarang,qtysales,salesPeriod,forcastOrder,forcast90,stokbarang) =>{
        dispatch(addFormOrder(idbarang,namabarang,salesPeriod,qtysales,forcastOrder,forcast90,stokbarang));
        setOpenNewOrder(true);
      }
      const HandleOpenDeleteOrder = (idbarang,namabarang,tglorder,qtyorder) =>{
        dispatch(deleteFormOrder(idbarang,namabarang,qtyorder,format(new Date(tglorder),'dd/MM/yyyy')));
        setOpenDelete(true);
      }
      const HandleCloseDeleteOrder = () =>{
            dispatch(emptyFormOrder());
            setOpenDelete(false);
            if(valueRadio==='Semua'){
                dispatch(setDataStokFilterSemua(formik.values.idpenyimpanan,salesPeriod,token));
            }else{
            dispatch(setDataStokFilterMin(formik.values.idpenyimpanan,salesPeriod,token));
            }
            dispatch(setSelectIDPenyimpanan(formik.values.idpenyimpanan))
      }
        const handleCloseNewOrder = () =>{
            setOpenNewOrder(false);
            dispatch(emptyFormOrder());
            if(formik.values.idpenyimpanan!==""){
                if(valueRadio==='Semua'){
                  dispatch(setDataStokFilterSemua(formik.values.idpenyimpanan,salesPeriod,token));
                }else{
                  dispatch(setDataStokFilterMin(formik.values.idpenyimpanan,salesPeriod,token));
                }
              dispatch(setSelectIDPenyimpanan(formik.values.idpenyimpanan))
            }
        }
    const tablePrintStok=(
        <TableBody>
            {dataStokFilter.map((dataStokFilters=>(
                <StyledTableRow key={dataStokFilters.idbarang}>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.qtysales}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.forcastOrder}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.forcast90}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='25%' align="left"><b>{dataStokFilters.namabarang}</b></SytledTableCell>
                    {valueRadio!=='Semua' && dataStokFilters.qtyorder>0?<SytledTableCell variant='body1' width='30%' align="center" className={classes.keteranganOrder}>
                    <Typography component="div" variant='caption' align='left'>
                        <Box>PO {format(new Date(dataStokFilters.tglorder),'dd/MM/yyyy')} = {dataStokFilters.qtyorder} item </Box>
                        <Box>Terpenuhi {dataStokFilters.qtyfulfilled} item </Box>
                        <Box>Sisa {parseInt(dataStokFilters.qtyorder) - parseInt(dataStokFilters.qtyfulfilled)} item</Box>
                    </Typography>
                    </SytledTableCell>:
                    valueRadio!=='Semua' ? <SytledTableCell variant='body1' width='30%' align="center">-</SytledTableCell>:null}
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{parseInt(dataStokFilters.stoktoko)+parseInt(dataStokFilters.stokgudang)}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.stoktoko}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.stokgudang}</b></SytledTableCell>
                    <SytledTableCell variant='body1' width='10%' align="center"><b>{dataStokFilters.stokretur}</b></SytledTableCell>
                    {valueRadio==='Semua'?null:<SytledTableCell variant='body1' align="center"><b>{dataStokFilters.jmlh_min}</b></SytledTableCell>}
                    {dataStokFilters.qtyorder>0?
                    <SytledTableCell width='10%' align="center">
                    <IconButton size='small' disabled={true} color="primary">
                        <AddIcon fontSize='small'/>
                    </IconButton>
                    <Tooltip title="Hapus Order">
                        <IconButton size='small' color="secondary"
                        onClick={()=>HandleOpenDeleteOrder(dataStokFilters.idbarang,dataStokFilters.namabarang,dataStokFilters.tglorder,dataStokFilters.qtyorder)}
                        >
                            <CancelIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                    </SytledTableCell>:
                    <SytledTableCell width='10%' align="center">
                    <Tooltip title="Tambah Order">
                        <IconButton size='small' color="primary"
                            onClick={()=>HandleOpenAddOrder(dataStokFilters.idbarang,dataStokFilters.namabarang,
                            dataStokFilters.qtysales,salesPeriod,dataStokFilters.forcastOrder,
                            dataStokFilters.forcast90,parseInt(dataStokFilters.stoktoko)+parseInt(dataStokFilters.stokgudang))}
                        >
                            <AddIcon fontSize='small'/>
                        </IconButton>
                        </Tooltip>
                        <IconButton size='small' disabled={true} color="secondary">
                            <CancelIcon fontSize='small'/>
                        </IconButton>
                    </SytledTableCell>}
                </StyledTableRow>
            )))}
        </TableBody>
    )
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content:()=>componentRef.current,
    });
    const handleChangeRadio = (e)=>{
        setValueRadio(e.target.value);
    }
    const handleChangePeriod = (e) =>{
        setSalesPeriod(e.target.value);
        if(valueRadio==='Semua'){
            dispatch(setDataStokFilterSemua(formik.values.idpenyimpanan,salesPeriod,token));
        }else{
        dispatch(setDataStokFilterMin(formik.values.idpenyimpanan,salesPeriod,token));
        }
        dispatch(setSelectIDPenyimpanan(formik.values.idpenyimpanan))
    }
    return (
        <div className={classes.root}>
            <div ref={componentRef}>
            <div className={classes.header}>
                <Typography variant='h5' className={classes.title}>Print Stok Barang</Typography>
            </div>
            <Divider/>
            <div className={classes.selectWapper}>
            <div className={classes.radioWapper}>
                <RadioGroup aria-label='chooseFilter' name='filter' value={valueRadio} onChange={handleChangeRadio}>
                    <FormControlLabel value='Semua' control={<Radio/>} label="Semua"/>
                    <FormControlLabel value='' control={<Radio/>} label="Stok Minimum"/>
                </RadioGroup>
            </div>
            <div>
                <PenyimpananCombo
                className={classes.selectItem}
                id='idpenyimpanan'
                name='idpenyimpanan'
                value={formik.values.idpenyimpanan}
                error={formik.touched.idpenyimpanan && Boolean(formik.errors.idpenyimpanan)}
                helperText={formik.touched.idpenyimpanan && formik.errors.idpenyimpanan}
                onChange={formik.handleChange}
                />
            </div>
                <div>
                    <PembayaranCombo
                        id="sales"
                        name="Data Penjualan"
                        data={dataSales}
                        label="Data Penjualan"
                        defaultValue={20}
                        value={formik.values.pembayaran}
                        error={formik.touched.pembayaran && Boolean(formik.errors.pembayaran)}
                        helperText={formik.touched.pembayaran && formik.errors.pembayaran}
                        handleChange={(e)=>{handleChangePeriod(e)}}
                    />
                </div>
                <Fragment>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Tooltip title="Daftar Order">
                        <Link to="/filterstok/listorder" style={{textDecoration:'none'}}>
                            <Button style={{marginLeft:'10px'}}>
                                <img src={listOrder} alt="list"/>
                            </Button>
                        </Link>
                    </Tooltip>
                </div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Tooltip title="Daftar Pending Order">
                        <Link to="/filterstok/listpendingorder" style={{textDecoration:'none'}}>
                            <Button style={{marginLeft:'10px'}}>
                                <img src={ListPendingOrder} alt="listPending"/>
                            </Button>
                        </Link>
                    </Tooltip>
                </div>
                </Fragment>
            </div>
            <div className={classes.table}>
                <Paper>
                    <TableContainer >
                        <Table stickyHeader aria-label="table barang" size="small">
                            <TableHead>
                                <TableRow>
                                    <SytledTableCell variant='body1' width='10%' align="center">Sales H-{salesPeriod}</SytledTableCell>
                                    <SytledTableCell variant='body1' width='10%' align="center">Forecast Order</SytledTableCell>
                                    <SytledTableCell variant='body1' width='10%' align="center">AVG 30 Hari</SytledTableCell>
                                    <SytledTableCell variant='body1' width='25%' align="center">Nama Barang</SytledTableCell>
                                    {valueRadio!=='Semua' ? <SytledTableCell variant='body1' width='25%' align="center">Keterangan</SytledTableCell>:null}
                                    <SytledTableCell variant='body1' width='10%' align="center">Stok Total</SytledTableCell>
                                    <SytledTableCell variant='body1' width='10%' align="center">Stok Toko</SytledTableCell>
                                    <SytledTableCell variant='body1' width='10%' align="center">Stok Gudang</SytledTableCell>
                                    <SytledTableCell variant='body1' width='10%' align="center">Stok Retur</SytledTableCell>
                                    {valueRadio==='Semua'?null:<SytledTableCell variant='body1' width='10%' align="center">Stok Minimal</SytledTableCell>}
                                    <SytledTableCell variant='body1' width='10%' align="center">Order</SytledTableCell>
                                 </TableRow>
                            </TableHead>
                            {formik.values.idpenyimpanan !== "" ? tablePrintStok : null}
                        </Table>
                    </TableContainer>                        
                </Paper>
            </div>
            </div>
            <div className={classes.buttonPrintWapper}> 
                <Button 
                size="small" 
                variant="contained" 
                color="primary" 
                aria-label="add"
                disabled={formik.values.idpenyimpanan!=="" ? false : true}
                onClick={handlePrint}
                >
                    Print
                </Button>
            </div>
            <OrderDeleteForm open={openDelete} aria-labelledby="delete-barang-dialog" HandleClickCloseDeleteDialog={HandleCloseDeleteOrder}/>
            <NewOrderForm open={openNewOrder} aria-labelledby="new-order-barang-dialog" onCloseDialogNewOrder = {handleCloseNewOrder}/>
        </div>
    )
})

export default ListStokBarang;
