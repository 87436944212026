const initialStateRetur = {
    dataRetur : [],
    pageRetur : {
        currentPage:'1',
        totalPage:'1'
    },
    FormRetur:[],
    searchRetur : '',
    fetchMessage : '',
    isLoadingDataRetur : false,
    perPage:'1',
    formReturBarang:[],
    FormAddRetur:[],
    FormPencatatanRetur:[],
    NoRetur:'',
    loadingSavePencatatanRetur:false,
    serviceDetails:{
        idbarang:'',
        namabarang:'',
        jumlah:''
    }
}

const returReducers = (state=initialStateRetur,action) =>{
    switch(action.type){
        case 'FETCH DATA RETUR':{
            return{
                ...state,
                isLoadingDataRetur:action.payload,
            }
        }
        case 'SET SEARCH RETUR':{
            return{
                ...state,
                searchRetur : action.payload
            }
        }
        case 'UPDATE PAGE RETUR':{
            return {
                ...state,
                pageRetur:action.payload
            }
        }
        case 'UPDATE DATA RETUR':{
            return{
                ...state,
                isLoadingDataRetur:false,
                dataRetur:action.payload,
                fetchMessage:action.fetchPayload
            }
        }
        case 'FAILED FETCH RETUR':{
            return{
                ...state,
                isLoadingDataRetur:false,
                fetchMessage:action.fetchPayload
            }
        }
        case 'SET FORM EDIT RETUR':{
            return{
                ...state,
                FormRetur:action.payload
            }
        }
        case 'SET FORM ADD RETUR':{
            return{
                ...state,
                FormAddRetur:action.payload
            }
        }
        case 'SET FILL FORM RETUR':{
            return{
                ...state,
                FormRetur:action.payload
            }
        }
        case 'SET FORM RETUR BARANG':{
            return{
                ...state,
                formReturBarang:[...state.formReturBarang,action.payload],
                fetchMessage:action.messageAddRetur
            }
        }
        case 'SET FORM PENCATATAN RETUR':{
            return{
                ...state,
                FormPencatatanRetur:[...state.FormPencatatanRetur,action.payload],
                fetchMessage:action.messageAddRetur
            }
        }
        case 'SET MESSAGE RETUR':{
            return{
                ...state,
                fetchMessage:action.payload
            }
        }
        case 'SET EMPTY MESSAGE RETUR':{
            return{
                ...state,
                fetchMessage:''
            }
        }
        case 'SUCCESSED GET NO PENCATATAN RETUR':{
            return{
                ...state,
                NoRetur:action.payload,
                loadingSavePencatatanRetur:false
            }
        }
        case 'FAILED GET NO PENCATATAN RETUR':{
            return{
                ...state,
                fetchMessage:action.payload,
                loadingSavePencatatanRetur:true
            }
        }
        case 'FETCH SAVE PENCATATAN':{
            return{
                ...state,
                loadingSavePencatatanRetur:true
            }
        }
        case 'FETCH SUCCESSED SAVE PENCATATAN RETUR':{
            return{
                ...state,
                fetchMessage:action.payload,
                loadingSavePencatatanRetur:false
            }
        }
        case 'FETCH FAILED SAVE PENCATATAN RETUR':{
            return{
                ...state,
                fetchMessage:action.payload,
                loadingSavePencatatanRetur:false
            }
        }
        case 'SET EMPTY FORM PENCATATAN RETUR':{
            return{
                ...state,
                FormPencatatanRetur:[]
            }
        }
        case 'GET DATA SERVICE':{
            return{
                ...state,
                serviceDetails:action.payload
            }
        }
        case 'SUCCESSED EDIT SERVICE':{
            return{
                ...state,
                fetchMessage:action.payload,
                serviceDetails:'',
                loadingSavePencatatanRetur:false
            }
        }
        case 'FAILED EDIT SERVICE':{
            return{
                ...state,
                fetchMessage:action.payload,
                loadingSavePencatatanRetur:false
            }
        }
        default:
            return state
    }
}

export default returReducers