import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { emptyMessagePembelianDetails, emptyRiwayatPembelianDetails, getSisaStokGudang} from '../../../config/redux/action';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FormQtyTransfer from '../FormQtyTransfer';
import { DeleteIcon, OKIcon } from '../../assets';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ListItemForDirectTransfer = ({HandleClickCloseDialog,nofaktur,statusTransfer,...rest}) => {
  const useStyle = makeStyles((theme)=>({
    dialog:{
      width:'100%'
    },
    titleDialog:{
        width:'100%',
        maxWidth:'700px',
        [theme.breakpoints.down('sm')]:{
            maxWidth:'250px',
        },
        textAlign:'center',
    },
    button:{
        display:'flex',
        justifyContent:'center',
        padding:"5px",
    },
    textFiled:{
        width:'100%',
        paddingBottom:"7px",
    },
    avaliableEdit:{
        display:'flex',
    },
    tableContainer:{
      width:'700px',
      height:'580px',
      [theme.breakpoints.up('md')]:{
          height:'400px',
      },
    }
  }));
  const SytledTableCell = withStyles((theme)=>({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.black,
      },
      body: {
        fontSize: 14,
        // border:'1px solid black'
      },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  const classes = useStyle();
  const dispatch = useDispatch();
  const {data} = useSelector(state => state.loginReducers);
  const [openSnackbar,setOpenSnackbar] = useState(false);
  const [token,setToken] = useState(data.token);
  const {dataRiwayatPembelianDetails,tempRiwayatTransferAll,messageRiwayatPembelian} = useSelector(state=>state.riwayatPembelianReducers);
  const [openQtyTransfer,setOpenQtyTransfer]=useState(false);
  const [idbarang,setIdBarang]=useState('');
  const [namabarang,setNamabarang]=useState('');
  const [jumlah,setJumlah]=useState('');
  if(token ===''){
    setToken(Cookies.get('u_tkn'));
  }
  const tempTableTransferAll = (
    <TableBody>
      {tempRiwayatTransferAll.map((tempRiwayatTransferAlls=>(
          <StyledTableRow key={tempRiwayatTransferAlls.idbarang}>
              <SytledTableCell align="center">{tempRiwayatTransferAlls.idbarang}</SytledTableCell>
              <SytledTableCell align="center">{tempRiwayatTransferAlls.namabarang}</SytledTableCell>
              <SytledTableCell align="center">{tempRiwayatTransferAlls.jumlah}</SytledTableCell>
              <SytledTableCell align="center">{tempRiwayatTransferAlls.satuan}</SytledTableCell>
              {tempRiwayatTransferAlls.status==='Successed'?
              <SytledTableCell>
              <Tooltip title="Item Berhasil di masukan ke daftar transfer">
                <IconButton size='small'>
                    <img src={OKIcon} alt='okicon'/>
                </IconButton>
              </Tooltip>
              </SytledTableCell>
              :
              <SytledTableCell>
              <Tooltip title="Item gagal di masukan ke daftar transfer">
                <IconButton size='small'>
                    <img src={DeleteIcon} alt='deleteicon'/>
                </IconButton>
              </Tooltip>
            </SytledTableCell>}
          </StyledTableRow>
      )))}
    </TableBody>
  )
  const pembelianTable = (
    <TableBody>
      {dataRiwayatPembelianDetails.map((pembelianDetails=>(
          <StyledTableRow key={pembelianDetails.idbarang}>
              <SytledTableCell align="center">{pembelianDetails.idbarang}</SytledTableCell>
              <SytledTableCell align="center">{pembelianDetails.namabarang}</SytledTableCell>
              <SytledTableCell align="center">{pembelianDetails.jumlah}</SytledTableCell>
              <SytledTableCell align="center">{pembelianDetails.satuan}</SytledTableCell>
              <SytledTableCell>
                <Tooltip title="Tambahkan ke daftar transfer">
                  <IconButton size='small' 
                  onClick={()=>handleClickOpenQtyTransfer(pembelianDetails.idbarang,pembelianDetails.namabarang,pembelianDetails.jumlah)}
                  >
                      <AddBoxIcon/>
                  </IconButton>
                </Tooltip>
              </SytledTableCell>
          </StyledTableRow>
      )))}
    </TableBody>
  )
  const handleClickOpenQtyTransfer = (idbarang,namabarang,jumlah)=>{
      setIdBarang(idbarang);
      setNamabarang(namabarang);
      setJumlah(jumlah);
      const data = new URLSearchParams();
      data.append('idbarang',idbarang);
      dispatch(getSisaStokGudang(data,token));
      setOpenQtyTransfer(true);
  }
  const handleCloseQtyTransferForm = ()=>{
    setIdBarang('');
    setNamabarang('');
    setJumlah('');
    setOpenQtyTransfer(false);
  }
  const handleCloseSnackBar = () => {
    dispatch(emptyMessagePembelianDetails())
    setOpenSnackbar(false);
  };
  const closeDialog = () =>{
    dispatch(emptyRiwayatPembelianDetails());
    HandleClickCloseDialog();
  }
  return (
    <div className={classes.dialog}>
      <Dialog open={false} {...rest} maxWidth="md">
        <DialogTitle className={classes.titleDialog} id="add-form-barang">Daftar Barang No Faktur {nofaktur}</DialogTitle>
          <Divider/>
            <DialogContent>
              <Paper>
                  <TableContainer className={classes.tableContainer}>
                      <Table stickyHeader aria-label="table barang" size="small">
                          <TableHead>
                              <TableRow>
                              <SytledTableCell width='15%' align="center">ID Barang</SytledTableCell>
                              <SytledTableCell width='30%' align="center">Nama Barang</SytledTableCell>
                              <SytledTableCell width='10%' align="center">QTY</SytledTableCell>
                              <SytledTableCell width='10%' align="center">Satuan</SytledTableCell>
                              <SytledTableCell width='10%' align="center"/>
                              </TableRow>
                          </TableHead>
                          { statusTransfer==="singleTransfer" ? pembelianTable : tempTableTransferAll}
                      </Table>
                  </TableContainer>
              </Paper>
              <div className={classes.button}>
                <Button onClick={closeDialog} variant="contained" color="secondary">Keluar</Button>
                </div>
            </DialogContent>
        </Dialog>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackBar}>
            {messageRiwayatPembelian === 'Berhasil menampilkan rincian pembelian' ?
                <Alert onClose={handleCloseSnackBar} severity={"success"}>{messageRiwayatPembelian}</Alert>:
                <Alert onClose={handleCloseSnackBar} severity={"error"}>{messageRiwayatPembelian}</Alert>
            }
        </Snackbar>
        <FormQtyTransfer open={openQtyTransfer} idbarang={idbarang} namabarang={namabarang} tempJumlah={jumlah} HandleClickCloseDialog={handleCloseQtyTransferForm}/>
      </div>
  )
}

export default ListItemForDirectTransfer