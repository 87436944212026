const initialStatePembelian={
   noFaktur:'',
   loadingGetNoFaktur:false,
   message:'',
   messageFaktur:'',
   messageSavePembelian:'',
   tanggalFaktur:'',
   cartBarangMasuk:[],
   cartReturBarang:[],
   dataPembelianList:[],
   isLoadingDataPembelian:false,
   pagePembelian:{
       currentPage:'1',
       totalPage:'1'
   },
   loadingSavePembelian:false,
   pembelianToPrint:'',
}
const pembelianReducers = (state = initialStatePembelian,action)=>{
    switch(action.type){
        case 'FILL NO FAKTUR':{
            return{
                ...state,
                noFaktur:'',
                tanggalFaktur:'',
                message:''
            }
        }
        case 'FETCH NO FAKTUR':{
            return{
                ...state,
                loadingGetNoFaktur:true,
                tanggalFaktur:action.payload
            }
        }
        case 'SET NO FAKTUR':{
            return{
                ...state,
                noFaktur:action.payload,
                tanggalFaktur:'',
                messageFaktur:action.message
            }
        }
        case 'FAILED NO FAKTUR':{
            return{
                ...state,
                loadingGetNoFaktur:false,
                noFaktur:action.payload
            }
        }
        case 'ADD CART BARANG MASUK':{
            return{
                ...state,
                cartBarangMasuk:[...state.cartBarangMasuk,action.payload],
                message:action.messagePayload
            }
        }
        case 'GET DATA BARANG MASUK':{
            return{
                ...state,
                cartBarangMasuk:[...state.cartBarangMasuk]
            }
        }
        case 'FAILED ADD CART BARANG MASUK':{
            return{
                ...state,
                message:action.messagePayload
            }
        }
        case 'SET MESSAGE':{
            return{
                ...state,
                message:action.messagePayload
            }
        }
        case 'SET MESSAGE SAVE':{
            return{
                ...state,
                messageSavePembelian:action.messagePayload,
                loadingSavePembelian:false
            }
        }
        case 'GET DATA RETUR BARANG':{
            return{
                ...state,
                cartReturBarang:[...state.cartReturBarang]
            }
        }
        case 'ADD CART RETUR BARANG':{
            return{
                ...state,
                cartReturBarang:[...state.cartReturBarang,action.payload],
                messageRetur:action.messagePayload
            }
        }
        case 'FAILED ADD CART BARANG RETUR':{
            return{
                ...state,
                messageRetur:action.messagePayload
            }
        }
        case 'SET MESSAGE RETUR':{
            return{
                ...state,
                messageRetur:action.messagePayload,
                loadingSavePembelian:false
            }
        }
        case 'SET LOADING SAVE PEMBELIAN':{
            return{
                ...state,
                loadingSavePembelian:true
            }
        }
        case 'SUCCESS SAVE PEMBELIAN':{
            return{
                ...state,
                loadingSavePembelian:false,
                messageSavePembelian:action.payload
            }
        }
        case 'FAILED SAVE PEMBELIAN':{
            return{
                ...state,
                loadingSavePembelian:false,
                messageSavePembelian:action.messageSave
            }
        }
        case 'EMPTY TABLE':{
            return{
                ...state,
                cartBarangMasuk:action.emptyBarangMasuk,
                cartReturBarang:action.returBarang
            }
        }
        case 'SET UPDATE PRINT PEMBELIAN':{
            return{
                ...state,
                pembelianToPrint:action.payload,
            }
        }
        case 'EMPTY PRINT PEMBELIAN':{
            return{
                ...state,
                pembelianToPrint:''
            }
        }
        case 'SET RETUR SUPPLIER TO PRINT':{
            return{
                ...state,
                returSupplierToPrint:action.payload
            }
        }
        default:
            return state
    }
}
export default pembelianReducers;