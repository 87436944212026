import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { DateField } from '../../../component/molecules';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPrintHutang } from '../../../config/redux/action/hutangAction';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PilihTanggalPage = ({HandleClickClosePilihTanggal,...rest}) => {
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'350px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            display:'flex',
            justifyContent:'center'
        }
    }));
    const classes=useStyle();
    const dispatch = useDispatch();
    const {data} = useSelector(state=>state.loginReducers);
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const history = useHistory();
    
    const formik = useFormik({
        initialValues:{
            tanggal:new Date()
        },
        onSubmit:(values)=>{
            const data={
                'tanggal':format(values.tanggal,'yyyy-MM-dd')
            }
            dispatch(getAllPrintHutang(data,token,history,HandleClickClosePilihTanggal));
        }
    })
    const handleChangeTanggal = (value)=>{
        formik.setFieldValue('tanggal',value)
    }
  return (
    <div>
        <Dialog open={false} {...rest}>
        <DialogTitle className={classes.titleDialog} id="new-partner-dialog">Pilih Tanggal Jatuh Tempo</DialogTitle>
        <DialogContent>
        <div className={classes.textFiled}>
            <DateField 
                id="tanggal" 
                name="tanggal" 
                label="Tanggal" 
                value={formik.values.tanggal}
                onChange={(value)=>handleChangeTanggal(value)}
            />
        </div>
        <div className={classes.button}>
            <Button variant="contained" color="primary" 
            onClick={formik.handleSubmit}
            >Print</Button>
            <Button 
            onClick={HandleClickClosePilihTanggal} 
            variant="contained" color="secondary">Keluar</Button>
            </div>
        </DialogContent>
        </Dialog>
    </div>
  )
}

export default PilihTanggalPage