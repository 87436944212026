import React from 'react'
import { Route,Router,Switch} from 'react-router-dom';
import { ProtectedRoute } from '..';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import { createBrowserHistory } from "history";

const Routes = () => {
  const history = createBrowserHistory();
    return (
      <Router basename="/v1" history={history}>
        <Switch>
          <Route path="/login" component={Login}/>
          <ProtectedRoute path="/" component={Home}/>
        </Switch>
      </Router>
  )
}
export default Routes
