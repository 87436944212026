import { Divider, Fab, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDetailsGudang, setDataGudang, setFillFormGudang, setNewIDGudang } from '../../config/redux/action';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormGudang from '../../component/organism/FormGudang';
import FormGudangDelete from '../../component/organism/FormGudangDelete';

const Gudang = () => {
    const {dataGudang} = useSelector(state=>state.gudangReducers);
    const {data} = useSelector(state=>state.loginReducers);
    const dispatch = useDispatch();
    const [token,setToken]=useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(setDataGudang(token));
    },[token,dispatch])

    const useStyles = makeStyles((theme)=>({
        root:{
            width:'100%',
            height:'100%',
            alignItems:'center',
        },
        title:{
            textAlign:'center',
            padding:'5px',
        },
        tableWapper:{
            display:'flex',
            flexDirection:'column',
            maxWidth:'90%',
            marginLeft:'5%',
            alignItems:'center',
        },
        table:{
            marginTop:'20px',
            [theme.breakpoints.up('md')]:{
                width:'35%',
            },
            width:'90%',
        },
        tableContainer:{
            maxHeight:'520px',
            [theme.breakpoints.up('md')]:{
                height:'420px',
            },
        },page:{
            display:'flex',
            width:'100%',
            margin:'0px',
        },
        textPage:{
            padding:'10px',
        },
        tableBottom:{
            display:'flex',
            width:'100%',
            justifyContent:'flex-end',
        },
    }));
    const SytledTableCell = withStyles((theme)=>({
        head: {
            backgroundColor: 'grey',
            color: theme.palette.common.black,
          },
          body: {
            fontSize: 14,
            // border:'1px solid black'
          },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const [open,setOpen]=useState(false);
    const [statusForm,setStatusForm]=useState('');
    const clickNewDialog = () =>{
        setStatusForm('Data Gudang Baru');
        dispatch(setNewIDGudang(token));
        setOpen(true);
    }
    const editDialog = (idgudang)=>{
        setStatusForm('Ubah Data Gudang')
        dispatch(getDetailsGudang(idgudang,token));
        setOpen(true);
    }
    const HandleClickCloseDialog = ()=>{
        setOpen(false);
        dispatch(setFillFormGudang());
        setTimeout(()=>{
            dispatch(setDataGudang(token));
        },1000)
    }
    const [openDelete,setOpenDelete]=useState(false);
    const clickOpenDeleteDialog = (idgudang) =>{
        dispatch(getDetailsGudang(idgudang,token));
        setOpenDelete(true);
    }
    const clickCloseDeleteDialog = () =>{
        setOpenDelete(false);
        dispatch(setFillFormGudang());
        setTimeout(()=>{
            dispatch(setDataGudang(token));
        },1000)
    }
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h5">Data Gudang</Typography>
                <Divider/>
                <div className={classes.tableWapper}>
                    <div className={classes.table}>
                    <Paper>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-label="table jenis barang" size="small">
                                <TableHead>
                                    <TableRow>
                                        <SytledTableCell width='10%' align="center">ID</SytledTableCell>
                                        <SytledTableCell width='25%' align="center">Nama Gudang</SytledTableCell>
                                        <SytledTableCell width='10%' align="center"></SytledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGudang.map((dataGudangs)=>(
                                        <StyledTableRow key={dataGudangs.idgudang}>
                                            <SytledTableCell align="center">{dataGudangs.idgudang}</SytledTableCell>
                                            <SytledTableCell align="center">{dataGudangs.namagudang}</SytledTableCell>
                                            <SytledTableCell align="center">
                                                <Tooltip title="Ubah">
                                                    <IconButton size='small' 
                                                    onClick={()=>{editDialog(dataGudangs.idgudang)}}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                 </Tooltip>
                                                <Tooltip title="Hapus">
                                                    <IconButton size='small' 
                                                    onClick={()=>clickOpenDeleteDialog(dataGudangs.idgudang)}
                                                    >
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </SytledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tableBottom}>
                                <Tooltip title="Tambah data gudang">
                                    <Fab size="small" 
                                    onClick={()=>{clickNewDialog()}} 
                                    color="primary" 
                                    aria-label="add">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                                <FormGudang open={open} statusForm={statusForm} aria-labelledby="new-gudang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                                <FormGudangDelete open={openDelete} aria-labelledby="delete-gudang-dialog" HandleClickCloseDeleteDialog={clickCloseDeleteDialog}/>
                                {/* <JenisBarangForm open={open} aria-labelledby="new-jenisbarang-dialog" HandleClickCloseDialog={HandleClickCloseDialog}/>
                                <JenisBarangEditForm open={openEdit} aria-labelledby="ubah-jenisbarang-dialog" HandleClickCloseEditDialog={HandleClickCloseEditDialog}/>
                                <JenisBarangDeleteForm open={openDelete} aria-labelledby="delete-jenisbarang-dialog" HandleClickCloseDeleteDialog={HandleClickCloseDeleteDialog}/> */}
                        </div>
                    </Paper>
                 </div>
            </div>
        </div>
    )
}

export default Gudang
