const initialStateHargaJual={
    messageHargaJual:'',
    isLoadingFetchHargaJual:false
}
const HargaJualReducer = (state = initialStateHargaJual,action)=>{
    switch(action.type){
        case 'FETCH HARGA JUAL':{
            return{
                ...state,
                isLoadingFetchHargaJual:true
            }
        }
        case 'SUCCESS POST HARGA JUAL':{
            return{
                ...state,
                isLoadingFetchHargaJual:false,
                messageHargaJual:action.payload
            }
        }
        case 'FAILED POST HARGA JUAL':{
            return{
                ...state,
                isLoadingFetchHargaJual:false,
                messageHargaJual:action.payload
            }
        }
        default:
            return state
    }
}
export default HargaJualReducer