import axios from 'axios';
import { apiUrl } from '../..';

export const getSisaStokGudang = (data,token)=>(dispatch)=>{
    axios.post(`${apiUrl.url}v1/suryaharapan/stokgudang/getstok`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'GET SISA STOK GUDANG',
            payload:ResponseAPI.data
        })
    })
}
export const setDataStokGudang = (searchGudang,pageGudang,token)=>(dispatch)=>{
    dispatch({
        type:'FETCH STOK GUDANG'
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/stokgudang/?search=${searchGudang}&page=${pageGudang}&perPage=50`,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
            type:'UPDATE DATA STOK GUDANG', 
            payload:ResponseAPI.data,
            message:'Successed'
         })
         dispatch({
             type:'UPDATE PAGE STOK GUDANG',
             payload:{
                 currentPage: ResponseAPI.current_page,
                 totalPage: Math.ceil(ResponseAPI.total_Barang/ResponseAPI.per_page)
             }
         })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED STOK GUDANG',
                    message:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED STOK GUDANG',
                    message:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED STOK GUDANG',
                    message:'Disconnect from server'
            })
        }
    })
}
export const setSearchStokGudang = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH TEXT GUDANG',
        payload :search
    })
}
export const addFormKoreksiGudang = (idstokgudang,idbarang,namabarang,variant,stokmasuk,stokkeluar,sisastok)=>(dispatch)=>{
    const data={
        'idstokgudang':idstokgudang,
        'idbarang':idbarang,
        'namabarang':namabarang,
        'variant':variant,
        'stokmasuk':stokmasuk,
        'stokkeluar':stokkeluar,
        'sisastok':sisastok
    }
    dispatch({
        type:'ADD FORM KOREKSI STOK GUDANG',
        payload:data
    })
}
export const emptyFormKoreksiStokGudang = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY FORM KOREKSI STOK GUDANG'
    })
}

export const editStokGudang = (idstokgudang,data,token) =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY GUDANG MESSAGE',
        payload:""
    })
    dispatch({
        type:'FETCH EDIT STOK GUDANG'
    })
    axios.patch(`${apiUrl.url}v1/suryaharapan/stokgudang/${idstokgudang}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED EDIT STOK GUDANG',
            payload:ResponseAPI.message
        })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'FAILED EDIT STOK GUDANG',
                    payload:ResponseError.message
                })
        }else if(error.request){
            dispatch({
                    type:'FAILED EDIT STOK GUDANG',
                    payload:'Disconnect from server'
            })
        }
    })
}
export const emptyGudangMessage = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY GUDANG MESSAGE',
        payload:""
    })
}
export const deleteStokGudang = (idstokgudang,token,setOpenSnackbar,HandleClickCloseDeleteDialog) =>(dispatch)=>{
    dispatch({
        type:'FETCH EDIT STOK GUDANG'
    })
    setTimeout(() => {
        axios.delete(`${apiUrl.url}v1/suryaharapan/stokgudang/${idstokgudang}`,{
            headers:{
                'Authorization':`Bearer ${token}`
            }
        }).then(res=>{
            const ResponseAPI = res.data;
            dispatch({
                type:'SUCCESSED DELETE STOK GUDANG',
                payload:ResponseAPI.message
            })
            setOpenSnackbar(true);
            HandleClickCloseDeleteDialog()
        }).catch(error=>{
            if(error.response){
                const ResponseError = error.response.data;
                dispatch({
                        type:'FAILED DELETE STOK GUDANG',
                        payload:ResponseError.message
                    })
                setOpenSnackbar(true);
            }else if(error.request){
                dispatch({
                        type:'FAILED DELETE STOK GUDANG',
                        payload:'Disconnect from server'
                })
                setOpenSnackbar(true);
            }
        })
    }, 2000);
    
}