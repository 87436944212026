import axios from 'axios';
import { apiUrl } from '../..';

export const setSearchJenisBarang = (search) => (dispatch) =>{
    dispatch({
        type:'SET SEARCH JENIS BARANG',
        payload :search
    })
}
export const setDataJenisBarang = (search,page,token) => (dispatch) =>{
    dispatch({
        type:'FETCH DATA JENIS BARANG',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/jenisbarang/?search=${search}&page=${page}&perPage=50`,{
       headers:{
           'Authorization':`Bearer ${token}`
       }
   })
   .then(results=>{
       const ResponseAPI = results.data;
       dispatch({
           type:'UPDATE DATA JENIS BARANG', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed'
        })
        dispatch({
            type:'UPDATE PAGE JENIS BARANG',
            payload:{
                currentPage: ResponseAPI.current_page,
                totalPage: Math.ceil(ResponseAPI.total_JenisBarang/ResponseAPI.per_page)
            }
        })
   })
   .catch(error=>{
       if(error.response){
           if(error.response.status === 500){
               dispatch({
                   type:'FAILED FETCH JENIS BARANG',
                   fetchPayload:error.response.data.message
               })
           }else if(error.response.status === 401){
               dispatch({
                   type:'FAILED FETCH JENIS BARANG',
                   fetchPayload:error.response.data.message
               })
           }
       }else if(error.request){
           dispatch({
                   type:'FAILED FETCH JENIS BARANG',
                   fetchPayload:'Disconnect from server'
           })
       }
   })
}
export const setNewIDJenisBarang = (token) =>(dispatch)=>{
    dispatch({
        type:'FETCH NEW ID',
        payload:true,
    })
    axios.get(`${apiUrl.url}v1/suryaharapan/jenisbarang/getID/`,
    {
        headers:{
            'Authorization':`Bearer ${token}`
        }
    })
    .then(results=>{
        const ResponseAPI = results.data;
        dispatch({
           type:'UPDATE NEW ID', 
           payload:ResponseAPI.data,
           fetchPayload:'Successed',
        })
    })
    .catch(error=>{
        if(error.response){
            if(error.response.status === 500){
                dispatch({
                    type:'FAILED GET NEW ID',
                    Payload:error.response.data.message
                })
            }else if(error.response.status === 401){
                dispatch({
                    type:'FAILED GET NEW ID',
                    Payload:error.response.data.message
                })
            }
        }else if(error.request){
            dispatch({
                    type:'FAILED GET NEW ID',
                    fetchPayload:'Disconnect from server'
            })
        }
    })
}
export const jenisBarangPostToAPI = (data,token) => (dispatch) =>{
    dispatch({
        type:'FECTH JENIS BARANG POST',
        payload:false,
    })
    axios.post(`${apiUrl.url}v1/suryaharapan/jenisbarang/newJenisBarang`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded'
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED JENIS BARANG POST',
            payload:ResponseAPI.message,
            PostPayload:'Successed',
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED ADD JENIS BARANG',
                    payload:ResponseError.message,
                    PostPayload:'Failed',
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED ADD JENIS BARANG',
                    payload:'Disconnect from server',
                    PostPayload:'Failed',
            })
        }
    })
}

export const setEditJenisBarang = (idjenisbarang,jenisbarang) => (dispatch) =>{
    dispatch({
        type:'SET EDIT JENIS BARANG',
        payload:{
            idjenisbarang,
            jenisbarang
        }
    })
}
export const jenisBarangPutToAPI = (data,id,token) => (dispatch) =>{
    dispatch({
        type:'FECTH JENIS BARANG PATCH',
        payload:false,
    })
    axios.patch(`${apiUrl.url}v1/suryaharapan/jenisbarang/${id}`,data,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED JENIS BARANG PATCH',
            payload:ResponseAPI.message,
            PostPayload:'Successed',
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED EDIT JENIS BARANG',
                    payload:ResponseError.message,
                    PostPayload:'Failed',
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED EDIT JENIS BARANG',
                    payload:'Disconnect from server',
                    PostPayload:'Failed',
            })
        }
    })
}
export const setFetchStatusJenis = (status) =>(dispatch) =>{
    dispatch({
        type:'SET FETCH STATUS',
        payload:status
    })
}
export const jenisBarangDeleteToAPI = (id,token) => (dispatch) =>{
    dispatch({
        type:'FECTH JENIS BARANG DELETE',
        payload:false,
    })
    axios.delete(`${apiUrl.url}v1/suryaharapan/jenisbarang/${id}`,{
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type':'application/x-www-form-urlencoded',
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SET SUCESSED JENIS BARANG DELETE',
            payload:ResponseAPI.message,
            PostPayload:'Successed',
            })
    })
    .catch(error=>{
        if(error.response){
            const ResponseError = error.response.data;
            dispatch({
                    type:'SET FAILED EDIT JENIS DELETE',
                    payload:ResponseError.message,
                    PostPayload:'Failed',
                })
        }else if(error.request){
            dispatch({
                    type:'SET FAILED EDIT JENIS DELETE',
                    payload:'Disconnect from server',
                    PostPayload:'Failed',
            })
        }
    })
}
