import { Button, Snackbar } from '@material-ui/core';
import { DialogContent, Divider, Typography } from '@material-ui/core';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { deleteAllTransfer, setTransferListMessage } from '../../../config/redux/action/TransferBarangAction';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const DeleteTransferList = ({HandleClickCloseDeleteDialog,...rest}) => {
    const {data} = useSelector(state=>state.loginReducers);
    const dispatch = useDispatch();
    const {isLoadingTransfer,transferListMessage,formTransferDelete} = useSelector(state=>state.transferBarangReducers);
    const {idgudang,tanggalString,tanggal}=formTransferDelete;
    const [token,setToken] = useState(data.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const useStyle = makeStyles((theme)=>({
        titleDialog:{
            width:'400px',
            [theme.breakpoints.down('sm')]:{
                width:'250px',
            },
            textAlign:'center',
        },
        contentDelete:{
            padding:'10px'
        },
        button:{
            display:'flex',
            justifyContent:'space-between',
            paddingBottom:"5px",
        },
        textFiled:{
            width:'100%',
            paddingBottom:"7px",
        },
    }));
    const classes = useStyle();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
        setTimeout(()=>{
            dispatch(setTransferListMessage(""));
        },500)
      };
      const onSubmit = ()=>{
            const data = new URLSearchParams();
            data.append('tanggal',tanggal);
            dispatch(deleteAllTransfer(idgudang,data,token));
            setOpenSnackbar(true);
            HandleClickCloseDeleteDialog();
            setOpenSnackbar(true);
      }
    return (
        <div>
            <Dialog open={false} {...rest}>
            <DialogTitle className={classes.titleDialog} id="delete-transfer-dialog">Hapus data pengeluaran</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.contentDelete}>
                    <Typography align='center'>Apakah anda yakin akan menghapus data transfer barang</Typography>
                    <Typography align='center'>pada tanggal {(tanggalString)}?</Typography>
                    <Typography align='center' style={{color:'red'}}>Perhatian!!! Data yang telah dihapus tidak dapat dikembalikan</Typography>
                    </div>
                <div className={classes.button}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={isLoadingTransfer}>Hapus</Button>
                <Button variant="contained" color="secondary" onClick={HandleClickCloseDeleteDialog} disabled={isLoadingTransfer}>Keluar</Button>
                </div>
                </DialogContent>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={transferListMessage === 'Penghapusan data transfer barang berhasil' ?"success" :"error"} onClick={HandleClickCloseDeleteDialog}>{transferListMessage}</Alert>
            </Snackbar>
        </div>
    )
}

export default DeleteTransferList